// React imports
import React from 'react';

export const LeftArrow = ({color}) => {
    return (
        <svg width='1.5em' height='1.5em' viewBox="0 0 30 30">
            <path d="M 20,3.25 L 8,15 L 20,27.25" strokeLinecap='round' fill="none" stroke={color || "white"} strokeWidth="3"/>
        </svg>
    );
};

export const RightArrow = ({color}) => {
    return (
        <svg width='1.5em' height='1.5em' viewBox="0 0 30 30">
            <path d="M 10,3.25 L 22,15 L 10,27.25" strokeLinecap='round' fill="none" stroke={color || "white"} strokeWidth="3"/>
        </svg>
    );
};

export const Email = ({eventData}) => {

    return (
        <svg width='1.5em' height='1.5em' viewBox="0 0 30 30">
            <rect stroke='white' fill='none' strokeWidth={3} x={2} y={5.5} width={26} height={19} rx={'3px'} ry={'3px'} />
            <path d="M 2,7.5 L 15,19 L 28,7.5" fill="none" stroke="white" strokeWidth="3"/>
            <path d="M 3,24.5 L 12,15" fill="none" stroke="white" strokeWidth="2"/>
            <path d="M 27,24.5 L 18.5,15" fill="none" stroke="white" strokeWidth="2"/>
        </svg>
    );
        

};

export const RedX = ({eventData}) => {
    const center = {
        x: 15,
        y: 15,
    };

    const segmentLength = 9;

    return (
        <svg width='1.5em' height='1.5em' viewBox="0 0 30 30">
            <line
              style={{ stroke: '#ff0039', strokeLinecap: 'round', strokeWidth: 3 }}
              x1={center.x + segmentLength * Math.sin(2.0 * Math.PI * 10.5 / 12.0)}
              y1={center.y - segmentLength * Math.cos(2.0 * Math.PI * 10.5 / 12.0)}
              x2={center.x + segmentLength * Math.sin(2.0 * Math.PI * 4.5 / 12.0)}
              y2={center.y - segmentLength * Math.cos(2.0 * Math.PI * 4.5 / 12.0)}
            />
            <line
              style={{ stroke: '#ff0039', strokeLinecap: 'round', strokeWidth: 3 }}
              x1={center.x + segmentLength * Math.sin(2.0 * Math.PI * 7.5 / 12.0)}
              y1={center.y - segmentLength * Math.cos(2.0 * Math.PI * 7.5 / 12.0)}
              x2={center.x + segmentLength * Math.sin(2.0 * Math.PI * 1.5 / 12.0)}
              y2={center.y - segmentLength * Math.cos(2.0 * Math.PI * 1.5 / 12.0)}
            />
        </svg>
    );
        

};

export const Circles = ({eventData}) => {
    const priority = eventData ? eventData.get('priority') : 0;

    return (
    <svg width='1.5em' height='1.5em' viewBox="0 0 30 30">
     <circle fill='white' stroke='white' strokeWidth='0.2em' cx="15" cy="15" r="4"/>
     {priority >= 2 ? <circle fill='none' stroke='white' strokeWidth='0.2em' cx="15" cy="15" r="8"/> : null}
     {priority >= 3 ? <circle fill='none' stroke='white' strokeWidth='0.2em' cx="15" cy="15" r="12"/> : null}
    </svg>
    );
};


// From https://gist.github.com/schmidsi/b0aca8b85ba41466948beb3793bef120
export const Clock = ({eventData}) => {
  const startDate = eventData.get('startDate');

  const center = {
    x: 15,
    y: 15,
  };
  const lengths = {
    hour: 5,
    minutes: 8,
  };
  const hours = startDate.getHours();
  const minutes = startDate.getMinutes();
  const floatingHour = (hours % 12) + (minutes / 60);
  const angle = {
    hour: 2.0 * Math.PI * floatingHour / 12.0,
    minute: 2.0 * Math.PI * floatingHour,
  };

  return (
    <svg width='1.5em' height='1.5em' viewBox="0 0 30 30">
     <circle fill='none' stroke='white' strokeWidth='0.2em' cx="15" cy="15" r="12"/>
      <g id="hands">
        <line
          style={{ stroke: 'white', strokeLinecap: 'round', strokeWidth: '0.15em' }}
          x1={center.x}
          y1={center.y}
          x2={center.x + lengths.hour * Math.sin(angle.hour)}
          y2={center.y - lengths.hour * Math.cos(angle.hour)}
        />
        <line
          style={{ stroke: 'white', strokeLinecap: 'round', strokeWidth: '0.15em' }}
          x1={center.x}
          y1={center.y}
          x2={center.x + lengths.minutes * Math.sin(angle.minute)}
          y2={center.y - lengths.minutes * Math.cos(angle.minute)}
      />
  </g>
    </svg>
  );
};


