import { expenseForm } from './expense';
import colors from 'lib/constants/resourceColors';
import Privileges from 'lib/constants/privileges';

function expenseBadgeStyle(value, data){
    const { status, paid_at } = data;

    if(status === 'pending'){
        return {status: 'processing', text: 'Pending'}; 
    } else if (status === 'approved' && paid_at){
        if(new Date() < new Date(data.paid_at)){
            return {status: 'success', text: 'Paying'}; 
        } else {
            return {status: 'success', text: 'Paid'}; 
        }
    } else if (status === 'approved'){
        return {status:'success', text:'Approved'}; 
    } else if (status === 'rejected') {
        return {status: 'error', text: 'Rejected'}; 
    } else { 
        return null;
    }
}

export default {
    defaults: {
        name: 'Expense',
        color: colors.expenses,
        appUrlBase: '/company/expenses',
        identifierParam: 'expense_number',
        formFields: expenseForm,
    },

    create: {
        urlFormatter:((expense) => `/expenses/${expense.expense_number}`),
        selectableOwner: true,
    },

    search: {
        privileges: [Privileges.SEARCH_EMPLOYEE_EXPENSES],
        cacheKey: 'cached_search_employee_expenses',
        allowDateFilter: true,
        dateFieldOptions: [{label: 'Submitted Date', value: 'created_at', description: 'submitted'},
                           {label: 'Expense Date', value: 'expense_date', description: 'occurring'},
                           {label: 'Paid Date', value: 'paid_at', description: 'paid'}],
        fields: ['id', 'expense_number', 'description', 'amount', 'created_at', 'status', 'paid_at', 'expense_date', 'employee'], 
        filterOptions: [
            {label: 'All', filters: null},
            {label: 'Approved (Not Paid)', filters: {is_paid: false, status: 'approved'}}, 
            {label: 'Pending', filters: {status: 'pending'}},
            {label: 'Rejected', filters: {status: 'rejected'}},
            {label: 'Paid', filters: {is_paid: true}}
        ],
        heading: 'Employee Expenses',
        links: [
            {url: '/company/expenses/new',
             title: 'New Employee Expense',
             requiredPrivileges: [Privileges.CREATE_EXPENSES]
            },
            {url: '/expense_payouts/new',
             title: 'Pay Out',
             requiredPrivileges: [Privileges.CREATE_EXPENSE_PAYOUTS]
            },
        ],
        tableProps: {
            rowURL: '/expenses/:expense_number',
            columns:[
                {heading: 'Expense Number', type: 'multiple', 
                         rows: [{heading: 'Employee', accessor: 'employee', style: 'bold'}, 
                                {heading: 'Expense Number', accessor: 'expense_number'}]},
                {heading: 'Description', type: 'multiple', 
                        rows: [{heading:'Description', accessor: 'description', maxWidth: '350px'}, 
                               {heading: 'Expense Date', type: 'date', dateFormat: 'MMM DD, YYYY', accessor: 'expense_date'}]},
                {heading: 'Amount', type: 'currency', accessor: 'amount'},
                {heading: 'Status', type: 'badge', accessor: 'status', badgePropsFunc: expenseBadgeStyle},
                {heading: 'Pay Date', placeholder: '—', type: 'date', dateFormat: 'MMM DD, YYYY',  value: 'paid_at', accessor: 'paid_at'}
            ],
        }
    },
}
