import React, { useEffect, useState } from 'react';
import { Avatar, List, Button, Checkbox, Skeleton, Icon, Spin } from 'antd';
import AutoComplete from '../AutoComplete';
import ErrorMessage from '../../ErrorMessage';
import api from 'api';
import './style.css';

const AutoCompleteMemo = React.memo(AutoComplete);

function AccessControl(props) {
    const { values, object_table, rulesUrl = (object) => '/access_rules' } = props;

    console.log("table ", object_table);
    const [selectedUserOption, setSelectedUserOption] = useState(null);
    const [userRules, setUserRules] = useState([]);
    const [pendingRead, setPendingRead] = useState([]);
    const [pendingWrite, setPendingWrite] = useState([]);
    const [pendingDelete, setPendingDelete] = useState([]);
    const [readers, setReaders] = useState([]);
    const [writers, setWriters] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        loadUsers();
    }, []); //if it depends on rulesUrl or values then you'll have an infinite loop.

    const loadUsers = () => {
        setLoading(true);
        setError(null);
        let url = rulesUrl(values);
        api.get(url, { object: values.id })
        .then(results => {
            setLoading(false);
            if(results.success) {
                 setUserRules(results.body);
            }
        })
        .catch(e => {
            throw new Error(e);
        });
    }

    useEffect(() => {
        let readingUsers = [];
        let writingUsers = [];

        userRules.forEach(rule => {
            if(rule.read) {
                readingUsers.push(rule.id);
            }

            if(rule.write){
                writingUsers.push(rule.id);
            }
        });

        setReaders(readingUsers);
        setWriters(writingUsers);
    }, [userRules]);


    const handleRead = (id) => {

        return (e) => {
            e.preventDefault();
            let value = e.target.checked;

            console.log("Read ", value, " for userRule ", id);
            if(pendingRead.indexOf(id) === -1) {
                setPendingRead(pendingRead.concat(id));
                if(value === false) {
                    value = null;
                }
                api.update(`/access_rules/${id}`, { read: value})
                .then((result) => {
                    setPendingRead(pendingRead.filter(p => p !== id));
                    if(result.success) {
                        if(value) {
                            setReaders(readers.concat(id));
                        } else {
                            setReaders(readers.filter(r => r !== id));
                        }
                    }

                });
            } else {

            }
        }

    }

    const handleWrite = (id) => {

        return (e) => {
            e.preventDefault();
            let value = e.target.checked;

            if(pendingWrite.indexOf(id) === -1) {
                setPendingWrite(pendingWrite.concat(id));
                if(value === false) {
                    value = null;
                }
                api.update(`/access_rules/${id}`, { write: value})
                .then((result) => {
                    setPendingWrite(pendingWrite.filter(p => p !== id));
                    if(result.success) {
                        if(value) {
                            setWriters(writers.concat(id));
                        } else {
                            setWriters(writers.filter(r => r !== id));
                        }
                    }
                });
            } else {

            }

            console.log("Write ", value, " for userRule ", id);
        }
    }

    const handleDelete = (id) => {

        return (e) => {
            e.preventDefault();

            if(pendingDelete.indexOf(id) === -1) {
                setPendingDelete(pendingDelete.concat(id));

                api.destroy(`/access_rules/${id}`)
                    .then((result) => {
                        setPendingDelete(pendingDelete.filter(p => p !== id));
                        if(result.success) {
                            setUserRules(userRules.map(r => r.id === id ? null : r).filter(v => v));
                        } else {
                            setError(result.body);
                        }
                    });
            }
        };
    }

    const LoadingCheckbox = ({label}) => {
        return <span style={{marginRight: '8px'}}><Spin size='small' style={{marginRight: '8px'}} />{label}</span>
    }

    const handleSelect = (user_id) => {
        setSelectedUserOption(user_id);
    }

    const handleAdd = () => {
        console.log("Add user ", selectedUserOption);
        api.create('/access_rules', {subject_id: selectedUserOption, object_id: values.id, read: true, object_table})
        .then(result => {
            if(result.success) {
                loadUsers();
            } else {
                setError(result.body);
            }
        })
        .catch(e => {
            throw new Error(e);
        });
    }

    const UserItem = (userRule) => {
        if(!userRule) {
            return null;
        }

        const { id, subject_id, first_name, last_name, email } = userRule;
        let username = subject_id ? [first_name, last_name].join(' ') : 'Null User'; 
        let checked_read = readers.indexOf(id) !== -1;
        let checked_write = writers.indexOf(id) !== -1;
        let user_email = subject_id ? email : '-';
        let readAction = pendingRead.indexOf(id) !== -1 ? <LoadingCheckbox label='Read' /> : <Checkbox checked={checked_read} onChange={handleRead(id)}>Read</Checkbox>;
        let writeAction = pendingWrite.indexOf(id) !== -1 ? <LoadingCheckbox label='Write' /> : <Checkbox checked={checked_write} onChange={handleWrite(id)}>Write</Checkbox>
            let deleteAction = pendingDelete.indexOf(id) !== -1 ? <Spin size='small' style={{marginLeft: '8px'}}/> : <a onClick={handleDelete(id)}><Icon style={{marginLeft: '8px', fontSize: '16px'}} type='delete' /></a>

            return <List.Item actions={[readAction, writeAction, deleteAction]} >
                <List.Item.Meta avatar={<Avatar icon="user" />} title={username} description={user_email}>
                    <span>hello</span>
                </List.Item.Meta>
            </List.Item>
    }


    return <div> 
            <ErrorMessage errors={error} />
            {loading ? <div style={{marginBottom: '12px'}}><Spin style={{marginRight: '12px'}}/><span>Loading</span></div> : null}
            <List>
                {!loading && userRules.length === 0 ? <div style={{marginBottom: '12px'}}><span>No extra users have been added to the Access Control List.</span></div> : null}
                {userRules.map(userRule => UserItem(userRule))}
            </List>
            <AutoCompleteMemo
                value={selectedUserOption}
                onChange={handleSelect}
                placeholder={'Search Users'}
                style={{marginBottom: '12px'}}
                valueLabel={selectedUserOption}
                suggestUrl={'/users'}
                suggestFields={['id', 'first_name', 'last_name']}
                suggestFormat={(c) => { 
                    return {value: c.id, text: [c.first_name, c.last_name].join(' ')} 
                }}
            /> 
            <Button disabled={!selectedUserOption} onClick={handleAdd}>Add User</Button>
        </div>
}




export default AccessControl;
