import React, { Component } from 'react';
import { Spin } from 'antd';
import api from 'api';

// eslint-disable-next-line
const debug = require('debug')('app:PrivilegesConfig:');


class PrivilegesConfig extends Component {


    state = {
        loading: 2,
        errors: null,
        allPrivileges: {},
        privilegeStatus: {},
        privilegeErrors: {},
    }

    componentDidMount(){
        const { role_id } = this.props;

        let id = role_id;
        //Get the enums for all the privileges
        api.get('/enums/privileges', {fields: 'id,name,category'})
        .then(response => {
            if(response.errors){
                let errors = this.state.errors.concat(response.errors);
                this.setState({errors});
            } else {
                let p = {};
                response.body.forEach(({id, name, category}) => {
                    if(!p[category]){
                       p[category] = []; 
                    }

                    p[category].push({id, name});
                });
                this.setState({allPrivileges: p});
            }

            this.setState({loading: this.state.loading - 1});
        })
        .catch(error => {
            this.setState({loading: this.state.loading - 1});
        });

        //Find the current privileges assigned to this role
        api.read('/role_privileges', {role: id})
        .then(response => {
            if(response.errors){
                let errors = this.state.errors.concat(response.errors);
                this.setState({errors});
            } else {
                let p = {};
                response.body.forEach(({id, privilege_id}) => {
                    p[privilege_id] = true;
                });
                this.setState({privilegeStatus: p});
            }

            this.setState({loading: this.state.loading - 1});
        })
        .catch(error => {
            this.setState({loading: this.state.loading - 1});
        });

    }

    render(){
        const { loading, allPrivileges, privilegeStatus, privilegeErrors } = this.state;

        if(loading){
            return <Spin tip='Loading' />
        }

        debug("Got privs ", allPrivileges);

        return <div>
           {Object.keys(allPrivileges).map((categoryName, index) => {
                let category = allPrivileges[categoryName];
                return <div key={categoryName+':'+index} >
                    <h4>{categoryName}</h4>
                    <ul style={{listStyle:'none'}}>
                        {category.map(({id, name}, index) => {
                            return <li key={name+':'+index}>
                                    <input onChange={this.handleCheck} 
                                              checked={privilegeStatus[id] || false}
                                               name={id} 
                                               type='checkbox' /> {name} 
                                    {privilegeErrors[id] ? <span> ! Error: {privilegeErrors[id]}</span> : null}
                                </li>
                        })}
                    </ul>
                </div>
           })}
        </div>
    }

    handleCheck = (e) => {
        const { privilegeStatus, privilegeErrors } = this.state;
        const { name, checked } = e.target;
        const role = this.props.data;

        let privilege_id = name;
        let newValue = checked;
        const originalValue = (privilegeStatus[privilege_id]) || false;

        let newStatus = Object.assign({}, privilegeStatus);
        newStatus[privilege_id] = newValue;
        let role_id = role.id;

        this.setState({privilegeStatus: newStatus}, () => {
            let success = (result => {
                    debug("Got result ", result);
                    let newPrivilegeErrors = this.state.privilegeErrors;
                    newPrivilegeErrors[name] = null;
                    this.setState({privilegeErrors: newPrivilegeErrors});
            });

            let error = (error => {
                    debug("Got error...", error);
                    let revertedStatus = privilegeStatus;
                    revertedStatus[privilege_id] = originalValue;
                    let newPrivilegeErrors = privilegeErrors;
                    newPrivilegeErrors[privilege_id] = error.message;
                    this.setState({privilegeStatus: revertedStatus, privilegeErrors: newPrivilegeErrors});
            });

            if(newValue){
                api.post('/role_privileges', {role_id, privilege_id} )
                .then(success)
                .catch(error);
            } else {
                api.destroy(`/role_privileges/roles/${role_id}/privileges/${privilege_id}`) 
                .then(success)
                .catch(error);
            }
        });
    }

}

export default PrivilegesConfig;
