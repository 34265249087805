import Privileges from '../lib/constants/privileges';
import colors from 'lib/constants/resourceColors';
import api from 'api';

const userFunc = (user) => {
    return {label: user.first_name + ' ' + user.last_name, value: user.id}
}

export default {
    defaults: {
        name: 'Project Packing Slip',
        color: colors.projectPackingSlips,
        formFields: [
            {type: 'options',
                ownerField: true,
                createOnly: true,
                key: 'created_by',
                display_key: 'posted_user',
                label: 'Posted By',
                defaultValue: (user) => user.id,
                optionsURL: '/lists/all_users',
                optionsMapper: userFunc},
            {static: true,
                key: 'posted_user',
                link: {url: '/users/:created_by', privileges: Privileges.READ_USERS},
                label: 'Posted By'},
            {   key: 'posted_date',
                required: true,
                label: 'Posted Date',
                placeholder: 'Select the Posted Date',
                type: 'date',
            },
            {key: 'project_id',
                required: true,
             displayKey: ['project_number', 'project_description'],
                readPrivileges: [Privileges.LIST_OPEN_PROJECT_NAMES],
                writePrivileges: [Privileges.LIST_OPEN_PROJECT_NAMES],
                label: 'Project Number',
                type: 'autocomplete',
                suggestFormat: (p) => {
                    let text = `(${p.project_number}) ${p.description}`;
                    return {value: p.id, text, }
                },
                suggestUrl: '/lists/open_project_names',
                suggestFields: ['id', 'project_number', 'description'],
                link: {url:'/projects/:project_id', privileges: Privileges.READ_PROJECTS},
            },
            'description',
            '^file(File){file_url}',
        ]
    },
    search: {
        allowDateFilter: true,
        fields: ['id','posted_date', 'posted_user', 'project_description', 'project_number', 'description', 'file_url'],
        links: ['new'],
        dateFieldOptions: [{label: 'Posted Date', value: 'posted_date', description: 'posted'}],
        searchBy: ['description', 'project name', 'user name', 'project number'],
        tableProps: {
            rowURL: '/project_packing_slips/:id',
            columns: [
                {heading: 'Posted Date', type: 'date', accessor: 'posted_date'},
                {heading: 'Posted By', accessor: 'posted_user'},
                {heading: 'Project', type:'multiple', rows: [
                    {accessor: 'project_description'},
                    {accessor: 'project_number', style: 'subtext'},
                ]},
                {heading: 'Description', accessor: 'description'},
                {type: 'file', key: 'file_url', label: 'View Packing Slip'},
            ],
        }
    },
    view: {}
}
