import colors from 'lib/constants/resourceColors';

export default {

    defaults: {
        name: 'App Log',
        colors: colors.appLogs,
        appUrlBase: '/activity_logs',
        actions: ['search'],
    },

    search: {
        fields: ['username', 'capture_time', 'application', 'window_title', 'duration'],
        heading: 'Activity Logs',
        tableProps: {
            columns: [
                {heading: 'Username', style: 'bold', accessor: 'username'},
                {heading: 'Time', type: 'date', dateFormat: 'MM/DD/YY h:mm A', accessor: 'capture_time'},
                {heading: 'Duration', accessor: 'duration'},
                {heading: 'Application', accessor: 'application', maxWidth: '200px'},
                {heading: 'Title', accessor: 'window_title', maxWidth: '400px'},
            ]
        }
    }


}
