import React, { Component } from 'react';



class FormProvider extends Component {

    state = {}

    onFormValueChange = (key, value) => {
        console.log(`Value change ${value}`)
        let update = {};
        update[key] = value;
        this.setState(update);
    }

    resetFormState = () => {
        this.setState({});
    }

    render(){
        const { context } = this.props;
        const actions = { onFormValueChange: this.onFormValueChange, 
                          resetFormState: this.resetFormState };

        return <context.Provider value={{state: this.state, actions}}>
            {this.props.children}
        </context.Provider>
    }

}

export default FormProvider;
