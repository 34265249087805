import React from 'react';

function NotFound() {
    return <div>
            <p style={{fontSize:'25px'}}>Welcome to <b>Engineering Systems Technology</b> <span role='img' aria-label='Sunrise'>☀️</span></p>
            <a href='https://est-app-files.s3.us-east-2.amazonaws.com/est_bookmarks.html' target='_blank'>🌇 Resource Links for Cities</a>
        </div>
};


export default NotFound;
