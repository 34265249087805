import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import api from 'api';
import titleCase from 'lib/helpers/titleCase';
import moment from 'moment';
import './style.css';

function Report() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fields = ['first_name', 'last_name', 'email', 'description', 'hours', 'start_date', 'end_date', 'type'];

    useEffect(() => {
        let response; 


        async function fetch() {
            setLoading(true);

            console.log("Fetch ");
            const startDay = moment(new Date()).startOf('week');
            const endDay = moment(new Date()).endOf('week');
            const start_date = moment(startDay).format("MM-DD-YYYY h:mm A");
            const end_date = moment(endDay).format("MM-DD-YYYY h:mm A");

            try { 
                response = await api.get('/employee_time_off', {sort: 'name', date_field: 'start_date', start_date, end_date});
            } catch (e) {
                setError(e);
            }

            setLoading(false);
            if(response.errors) {
                setData([]);
                setError(response.errors);
            } else {
                console.log("Response ", response);
                setData(response.body);
                setError(null);
            }

        };

        fetch();
    }, []);
    
    const isNumeric = (str) => {
        if (typeof str != "string") { 
            return false;
        }
        
        return !isNaN(str) && !isNaN(parseFloat(str));
    }
    
    const array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};

    const renderReport = () =>  {

        let keys = new Set();
        data.forEach(d => {
            Object.keys(d).forEach(k => keys.add(k));
        });
        keys = Array.from(keys).filter(e => { 
            let exclude = ['id', 'created_at', 'updated_at', 'tsv'];

            if(e.slice(-3) === '_id') {
                return false;
            }

            return !exclude.includes(e)
        }).sort();

        let resortedKeys = keys.slice();

        keys.forEach(k => {

            if(k.slice(0,4) === 'end_') {
                let ending = k.substring(4);
                let sIndex = keys.indexOf(`start_${ending}`);
                let eIndex = keys.indexOf(k);
                console.log("Resort ", k);
                resortedKeys = array_move(resortedKeys, eIndex, sIndex);
            }

            if(k === 'created_at') {

                resortedKeys = array_move(resortedKeys, keys.indexOf(k), keys.length);
            }
        });

        keys = resortedKeys;

        keys = fields;


        return <table>
            <thead>
                <tr>
                    {keys.map(k => <th>{titleCase(k)}</th>)}
                </tr>
            </thead>
            <tbody>
            {data.map(d => {
                return <tr>
                    {keys.map(key => {
                        let value = d[key];
                        if(isNumeric(value) === false && moment(value).isValid()) {
                            value = moment(value).format('M/DD/YYYY');
                        } else if (Array.isArray(value)) {
                            value = value.map(e => {
                                if(isNumeric(e) === false && moment(e).isValid()) {
                                    return moment(e).format('M/DD/YYYY h:mm a');
                                } else {
                                    return e;
                                }
                            });
                        } else if (typeof value === 'string' && value.charAt(0) === '[' && value.charAt(value.length-1) === ']') {
                            value = value.slice(1,value.length-2);
                            value = value.split(',');
                            value = value.map(e => {
                                if(isNumeric(e) === false && moment(e).isValid()) {
                                    return moment(e).format('M/DD/YYYY h:mm A');
                                } else {
                                    return e;
                                }
                            }).join(', ');
                        } else if (value === '(,)') {
                            value = null;
                        }

                        return <td>
                            {value || <span className='empty'>N/A</span>}
                        </td>
                    })}
                </tr>
            })}
            </tbody>
        </table>

    }

    return <div className='report'><span className='title'>This is All The Employee Time Off for the Current Week</span> {renderReport()}</div>
}

export default withRouter(Report);
