import colors from 'lib/constants/resourceColors';
import React from 'react';
import AccessRulesConfig from 'components/AccessRulesConfig';
import api from 'api';


const userForm = [
    {key: 'first_name'},

    {key: 'last_name'},

    {key: 'title'},

    {type: 'date', 
        key:'start_date'},

    {type: 'date', 
        key:'end_date', 
        showEmpty: false},

    '~profileImage(Profile Photo){avatar_url}',

    {key: 'phone', label: 'Phone Number'},

    {key: 'email', required: true},

    {key: 'password', 
        requiredOnCreate: true, 
        label: 'New Password', 
        writeOnly: true, 
        nullable: false},

    {key: 'roles',
        type: 'relations',
        label: 'Roles',
        placeholder: 'Assign a New Role...',
        submitLabel: 'Assign',
        addRelation: (roleId, user) => api.upsert(`/user_roles/users/${user.id}/roles/${roleId}`),
        deleteRelation: (roleId, user) => api.destroy(`/user_roles/users/${user.id}/roles/${roleId}`),
        optionsUrl: (user) => '/roles',
        optionTransform: (option) => { return {value: option.id, label: option.name} } , 
        getRelations: (user) => user.roles,
        relationTransform: (r, index) => { return {key:`${index}_${r.id}`, value: r.id, label: r.name} },
    },

];

export default {
    defaults: {
        name: 'User',
        color: colors.user,
        formFields: userForm,
    },

    view: {
        title: (u) => `${u.first_name} ${u.last_name}`,
        extraRender: (props) => {
            return <React.Fragment>
                <AccessRulesConfig user_id={props.match.params.id} />
            </React.Fragment>
        }
    },

    search: {
        fields: ['id','first_name','last_name', 'avatar_url'],
        dataTransform: (user) => Object.assign(user, {avatar_url: user.avatar_url, full_name: [user.first_name, user.last_name].filter(v => v).join(' ')}),
        links: ['new'],
        tableProps: {
            rowURL: '/users/:id',
            keyFunc: (item, index) => `${item.first_name}_${item.last_name}_${index}`,
            columns: [
                {heading: 'Image', cellWidth: '45px', imageWidth: '45px', type: 'image', accessor: 'avatar_url'}, 
                {heading: 'Name', type: 'multiple', rows: [
                {heading: 'Name', accessor: 'full_name', placeholder: 'No Name'}
            ]}],

            showHeading: false,
        }
    },
    
};
