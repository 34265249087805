import colors from 'lib/constants/resourceColors';

const form = [
    {key: 'position', type: 'number'},
    {key: 'product_number', label: 'Product'},
    {key: 'description', type: 'longtext'},
    {key: 'price', type: 'currency', label: 'Unit Price'},
    {key: 'quantity', type: 'number'},
    {key: 'taxable', type: 'boolean'},
];

export default {
    defaults: {
        name: 'Invoice Item',
        color: colors.invoiceItems,
        formFields: form,
        dataUrlBase: '/invoices/items'
    }
}


