import styled from 'styled-components';

export default styled.div`
    box-sizing: border-box;
    display: flex;
    width: ${props => props.numberOfColumns ? `${(100 / props.numberOfColumns)}%` : '14.285714285%'};
    border-bottom: 1px solid #DDDDDD;
    flex: 0 1 auto;
    justify-content: left;
    padding: 6px 6px;
    color: #878798;
    font-family: sans-serif;
    text-overflow: hidden;
    user-select: none;
    word-wrap: none;
`;


