import React from 'react';
import { UserContext } from 'contexts/User';

export const userHasAnyPrivileges = function(privileges, user){

    if(privileges.length === 0) {
        //nothing to check
        return true;
    }

    const user_privileges = user.privileges || {}; 

    let allowed = false;
    if(!user_privileges['superuser']){
        //Only check non admin users
        
        privileges.forEach((privilege, index) => {
            if(allowed){
                return;
            }

            if(!privilege){
                return;
            }

            // if(privilege.indexOf('RPC') === -1) {
            //     key = key.toLowerCase();
            // }

            if(user_privileges[privilege]){
                allowed = true;
            }
        }); 
    } else {
        allowed = true;
    }


    return allowed;
}

export const userHasPrivileges = function(privileges, user){

    if(privileges === undefined) {
        debugger;
    }

    if(privileges.length === 0) {
        //nothing to check
        return true;
    }

    const user_privileges = user.privileges || {}; 

    let denied = false;
    if(!user_privileges['superuser']){
        //Skip these checks for clients that have admin privilege enabled
        
        privileges.forEach((privilege, index) => {
            if(denied){
                return;
            }

            if(!privilege){
                return;
            }
            


            // if(privilege.indexOf('RPC') === -1) {
            //     key = key.toLowerCase();
            // }

            if(!user_privileges[privilege]){
                denied = true;
            }
        }); 
    }


    return !denied;
}


export default function(privileges, node, alternateNode = null){


    if(!privileges || !privileges.length){
        //If no privileges are to be checked, then automatically return the node to be rendered
        return node;
    }

    if(!Array.isArray(privileges)){
        throw new Error("Expected array of privileges for conditional rendering check");
    }

    return <UserContext.Consumer>
        {({state}) => {
            let allow = userHasPrivileges(privileges, state.user);


            if(allow){
                return node;
            } else {
                //the alternateNode can be shown instead of null if you wish to show an unauthorized user some other element
                return alternateNode || null;
            }
        }}
    </UserContext.Consumer>
}
