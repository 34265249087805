import colors from 'lib/constants/resourceColors';
import api from 'api';
import { Modal } from 'antd';
import { EST_PALE_GREEN , EST_PALE_RED } from 'lib/constants/colors';
import Privileges from 'lib/constants/privileges';
import moment from 'moment';
const ErrorModal = Modal.error;

export const taskFields = ['id', 'task_type', 'attachment_count', 'completed_at', 'created_at', 'due_date', 'task_name', 'last_activity_description', 'last_activity_user', 'task_number', 'user', 'completed', 'description', 'parent_type', 'parent_description', 'priority'];

const nameMapping = (task_type) => {
    return {label: task_type.name, value: task_type.id}
}

function priorityStyle(value, data) {
    switch(value) {
        case 1:
            return {status: 'default', text: 'Low'};
        case 2: 
            return {status: 'warning', text: 'Medium'};
        case 3:
            return {status: 'error', text: 'High'};
        default:
            return {status: 'default', text: 'Unknown'};
    }
}

export const taskTableProps = {
    rowURL: '/tasks/:id',
    rowColor: (task) => {
        let color = null;
        if(task.completed) {
            color = EST_PALE_GREEN;
        } else if (!task.user) {
            color = EST_PALE_RED; 
        }


        return color;
    },
    columns: [
        {heading: 'Task', accessor: 'task_number', cellWidth: '40px'},
        {type: 'date', accessor: 'created_at', cellWidth: '130px'}, 
        {heading: 'Project', placeholder: 'None', accessor: 'parent_description', cellWidth: '150px'}, 
        {heading: 'Description', type:'multiple', rows: [
            {heading: 'Type', style:'subtext', accessor: 'task_type'},
            {heading: 'Name', style: 'bold', accessor:'task_name'},
            {heading: 'Parent', style: 'subtext', accessor: 'parent_description'},
            {heading: 'Description', accessor:'description'},
            {heading: 'Last Activity', accessor:'last_activity_description', style:'subtext'},
            {heading: 'Last Activity User', accessor: 'last_activity_user', style:'subtext',}
        ]},
        {heading: 'Files', type: 'count', icon: 'file-text', accessor: 'attachment_count', cellWidth: '60px'},
        {heading: 'Assigned to', accessor: 'user', cellWidth: '120px'},
        {type: 'date', placeholder: 'None', accessor: 'due_date', cellWidth: '110px'}, 
        {heading: 'Completed', type: 'date', accessor: 'completed_at', cellWidth: '130px'}, 
        {type:'badge', 
            badgePropsFunc: priorityStyle, 
            heading: 'Priority', 
            accessor: 'priority', cellWidth: '60px'},
    ] 
}


export const taskConfig = {
    defaults: {
        name: 'Task',
        color: colors.tasks,
        formFields: [
            'task_name',
            {key: 'task_type_id', 
                //showDetails: true,
                type: 'options', 
                label: 'Task Type', 
                optionsURL: `/task_types`, 
                optionsMapper: nameMapping},
            {key: 'parent_id', 
             displayKey: ['parent_description'],
                readPrivileges: [Privileges.LIST_PROJECT_NAMES], 
                writePrivileges: [Privileges.LIST_PROJECT_NAMES],
                label: 'Project',
                type: 'autocomplete', 
                suggestFormat: (p) => { 
                    let text = `(${p.project_number}) ${p.description}`;
                    return {value: p.id, text, }
                },
                suggestUrl: '/lists/project_names', 
                suggestFields: ['id', 'project_number', 'description']},
            {type: 'date', key: 'due_date', icsFormat: (task) => {
                return {
                    title: task.task_name,
                    description: task.task_type || '',
                    location: '',
                    start_date: moment(task.due_date).format('L'),
                    end_date: moment(task.due_date).format('L'),
                }
            }},
            {type: 'radio', 
                required: true, 
                key: 'priority', 
                defaultValue: 1, 
                label: 'Priority', 
                options: [{label: 'Low', value: 1}, 
                    {label: 'Medium', value: 2}, 
                    {label: 'High', value: 3} ]},
            {key: 'user_id', 
             displayKey: 'user',
                label: 'Assigned to',
                type: 'autocomplete', 
                suggestFormat: (c) => { return {value: c.id, text: `${c.first_name} ${c.last_name}`} },
                suggestUrl: '/users?employed=true', 
                suggestFields: ['id', 'first_name', 'last_name']},
            {key: 'completed', type: 'boolean', showInCreateMode: false},
            '+description',
            {key: 'activity_logs',
                parent_type: 'tasks',
                label: 'Activity',
                type:'activity_logs',
            },
            {key: 'attachments',
            readPrivileges: [Privileges.READ_ATTACHMENTS], 
                type:'files',
            },
            {key: 'notified_users',
            readPrivileges: [Privileges.READ_NOTIFICATION_USERS], 
                type: 'notifications',
            },
        ],
        formTemplates: {
            sample: {
                name: 'Sample Template',
                values: {
                    priority: 3,
                    description: 'It worked',
                }
            }
        },
        formActions: [
            { label: 'Mark Task Completed',
                icon: 'check-square',
                visible: ({values}) => {
                    return values.completed === false
                },
                method: async ({values, reload}) => { 
                    try { 
                        await api.update(`/tasks/${values.id}`, {completed: true})
                    } catch (e) {
                        ErrorModal({
                            title: 'Error', 
                            content: e.message,
                            okText : 'Ok',
                        });
                    }
                    reload();
                },
            },
            { label: 'Undo Task Completed',
                icon: 'check-square',
                visible: ({values}) => {
                    return values.completed === true
                },
                method: async ({values, reload}) => { 
                    try { 
                        await api.update(`/tasks/${values.id}`, {completed: false})
                    } catch (e) {
                        ErrorModal({
                            title: 'Error', 
                            content: e.message,
                            okText : 'Ok',
                        });
                    }
                    reload();
                },
            }
        ],
    },

    search: {
        allowDateFilter: true,
        fields: taskFields, 
        filterOptions: [
            {label: 'Both', default: true, filters: { active: true, related_to: (currentUser) => currentUser.id }},
            {label: 'Assigned to me', filters: { active: true, user: (currentUser) => currentUser.id } }, 
            {label: 'Created by me', filters: { active: true, creator: (currentUser) => currentUser.id }},
        ],
        toggleOptions: [
            {key: 'hide_completed', 
                label: 'Hide Completed', 
                param: 'completed', 
                defaultValue: (currentUser) => true, 
                value: (currentUser) => false }
        ],
        heading: 'Your Tasks',
        links: ['new'],
        searchBy: ['description', 'assigned user', 'creator', 'task parent', 'task type', 'task name or number'], 
        tableProps: taskTableProps,
    },

    view: {
        title: (t) => `Task #${t.task_number} (${t.task_name})`,
        contextLinks: (task) => {
            if(task.parent_type === 'project') {
                return {
                    label: 'For Project',
                    text: task.parent_description,
                    url: `/projects/${task.parent_id}`,
                    privilege: [Privileges.READ_PROJECTS]
                }
            } else {
                return null;
            }
        }
    }
}
