export default function formatPhoneNumber(phone) {
    if(!phone) {
        return null;
    }

    let s = phone;
    let x = null;
    if(s.indexOf('x') >= 0){
        //has extension
        s = phone.substring(0, phone.indexOf('x'));
        x = phone.substring(phone.indexOf('x')+1);
    }

    if(s.length >= 12) {
        return null;
    }


    let f = null;
    if(s.length === 11) {
        s = s.substring(1);
        f = phone.substring(0, 1);
    }

  var s2 = (""+s).replace(/\D/g, '');
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return (!m) ? null : (f ? `${f} ` : '') + "(" + m[1] + ") " + m[2] + "-" + m[3] + (x ? `, Ext. ${x}` : '');
}
