
import colors from 'lib/constants/resourceColors';
import Privileges from 'lib/constants/privileges';
import api from 'api';

const inventoryLocationName = (o) => {
    const label = o.sublocation ? `${o.description}/${o.sublocation}` : o.description;
    return {label, value: o.id}
}

const form = [
    {key: 'slip_number', static: true}, 
    {key: 'received_by', displayKey: 'receiver_name', static: true},
    {key: 'received_at', type: 'date'},
    {key: 'ref_number', label: 'Reference Number'},
    {key: 'description'},
    {type: 'file',
        showInCreateMode: false,
       key: 'packingSlip',
        viewUploadText: 'View Packing Slip',
        urlKey: 'packing_slip_url',
        label: 'Packing Slip'},
    {key: 'distributions',
        type: 'references',
        heading: 'Parts Received',
        createLabel: 'Add a Received Part',
        editorActions: [ 
            { label: 'Reset Quantities to Zero', 
               icon: 'minus-circle', 
            method: async ({values, parameters, reload, loading, success, error}) => {
                    let receiving_slip_id = values.id;

                    loading('Resetting');
                    let result;
                    try {
                        result = await api.rpc('setReceivedQuantitiesZero', {receiving_slip_id})
                    } catch (e) {
                        throw new Error(e);
                    }
                    
                    if(result.success) {
                        success({
                            title: 'Success', 
                            content: 'Received Items have been set to Quantity of zero.',
                            okText : 'Ok',
                        });
                    } else {
                        error({
                            title: 'Error', 
                            content: `Couldn't set quantities.`,
                            okText : 'Ok',
                        });
                    }

                    reload();
                }
            },
            { label: 'Set Allocated Quantities for Matching Distributions', 
               icon: 'plus-circle', 
            method: async ({values, parameters, reload, loading, success, error}) => {
                    let receiving_slip_id = values.id;

                    loading('Setting');
                    let result;
                    try {
                        result = await api.rpc('setReceivedQuantitiesDefaultValues', {receiving_slip_id})
                    } catch (e) {
                        throw new Error(e);
                    }
                    
                    if(result.success) {
                        success({
                            title: 'Success', 
                            content: 'Received Item quantities have been set to the amounts from the purchase order.',
                            okText : 'Ok',
                        });
                    } else {
                        error({
                            title: 'Error', 
                            content: `Couldn't set quantities.`,
                            okText : 'Ok',
                        });
                    }

                    reload();
                }
            },
        ],
        dataTransform: (data) => {
            return data.distributions;
        },
        dataUrl: `/receiving_slips/:id?fields=distributions`,
        createUrl: (values) => { return `/receiving_slips/${values.id}/distributions` },
        deleteUrl: (values) => { return `/receiving_slips/${values.id}/distributions/:id` },
        formFields: [
            {key: 'product_id', 
                label: 'Part Number',
                type: 'autocomplete', 
                suggestFormat: (c) => { return {value: c.id, text: `${c.product_number}`} },
                suggestUrl: '/parts?exclude_discontinued=true',
                suggestFields: ['id', 'product_number']},
            {key: 'quantity', type: 'number',},
            {key: 'project_id', 
                type: 'autocomplete', 
                suggestFormat: (c) => { return {value: c.id, text: `${c.description} ${c.project_number}`} },
                suggestUrl: '/projects', 
                suggestFields: ['id', 'project_number', 'description']},
            // {key: 'inventory_location_id', 
            //     type: 'autocomplete', 
            //     suggestFormat: (c) => { return {value: c.id, text: `${c.description}/${c.sublocation}`} },
            //     suggestUrl: '/inventory_locations', 
            //     suggestFields: ['id', 'description', 'sublocation']},
            {key: 'inventory_location_id', 
                type: 'options', 
                label: 'Inventory Location',
                optionsMapper: inventoryLocationName,
                optionsURL: '/inventory_locations'},
            {key: 'bin_number'},
        ],
        tableProps: { 
            rowURL: '/receiving_slip_distributions/:id',
            columns: [
            {accessor:'product_number', readOnly: true, heading: 'Part Number', cellWidth: '150px'},
            {accessor: 'quantity', type: 'number', cellWidth: '50px'},
            {heading: 'Distribution', type: 'multiple', rows: [
                {heading: 'Project', accessor: 'project_description'},
                {heading: 'Project Number', accessor: 'project_number',  style: 'subtext'}
            ]},
            {heading: 'Inventory Location', type: 'multiple', rows: [
                {heading: 'Description', accessor: 'inventory_location_description'},
                {heading: 'Sublocation', accessor: 'inventory_location_sublocation',  style: 'bold'},
                {heading: 'Bin Number', accessor: 'inventory_bin_number', style: 'bold'},
            ]},
            {heading: 'Bin Number', accessor: 'inventory_bin_number', cellWidth: '75px'},
            ]
        },
    },
]

export default {
    defaults: {
        name: 'Receiving Slip',
        color: colors.receivingSlip,
        formFields: form,
    },

    view: {
        title: (t) => 'Receiving Slip',
        formProps: {
            editPrivileges: [Privileges.UPDATE_RECEIVING_SLIPS],
            deletePrivileges: [Privileges.DELETE_RECEIVING_SLIPS],
        }
    },

    search: {
        fields: ['id', 'description'],
        links: ['new'],
        tableProps: {
            rowURL: '/receiving_slips/:id',
            columns: [
                {heading: 'Slip Number', accessor: 'slip_number'},
                {heading: 'Description', accessor: 'description'},
                {heading: 'Reference Number', accessor: 'ref_number'},
                {heading: 'Received Date', accessor: 'received_at'},
                {heading: 'Received By', accessor: 'receiver_name'},
            ]
        }
    },
    
}
