import colors from 'lib/constants/resourceColors';
import React from 'react';
import AccessRulesConfig from 'components/AccessRulesConfig';
import PrivilegesConfig from 'components/PrivilegesConfig';
import Privileges from 'lib/constants/privileges';

const form = [
    {key: 'name', required: true},
    {key: 'description'},
]

export default {
    defaults: {
        name: 'Role',
        color: colors.role,
        formFields: form,
    },

    view: {
        title: (r) => r.name,
        formProps: {
            editPrivileges: [Privileges.READ_ROLES],
            deletePrivileges: [Privileges.DELETE_ROLES],
        },
        extraRender: (props) => {
            return <React.Fragment>
                <PrivilegesConfig role_id={props.match.params.id} data={props.data} />
                <AccessRulesConfig role_id={props.match.params.id} />
            </React.Fragment>
        }
    },
    
    search: {
        fields: ['id', 'description', 'name'],
        links: ['new'],
        searchBy: ['name', 'description'],
        tableProps: {
            rowURL: '/roles/:id',
            columns: [{heading: 'Name', style:'bold', cellWidth: '25%', accessor: 'name'}, {heading: 'Description', accessor: 'description'}],
        }
    },
    
}
