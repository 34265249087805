import React, { useState, useEffect } from 'react';
import { Select, Spin } from 'antd';
import api from 'api';
import debounce from 'lodash/debounce';

const { Option } = Select;

function Complete({style, ...props}) {
    const { labelInValue, value, onChange, onSelect, placeholder, valueLabel } = props;
    const { suggestUrl, suggestFields, suggestFormat } = props;

    const [ queryText, setQueryText ] = useState('');
    const [ dataSource, setDataSource ] = useState([]);
    const [ fetching, setFetching ] = useState(false);

    const onSearch = debounce((searchText) => {
        search(searchText);
    }, 300);

    const search = (searchText) => {
        if(!searchText || searchText === '') {
            return;
        }

        setFetching(true);
        api.get(suggestUrl, {query: searchText, per_page: 5, fields: suggestFields.join(',')})
        .then(result => {
            setFetching(false);
            const searchedTerm = result.headers['x-search-term'] || '';
            if(searchedTerm !== searchText){
                //stale result
                return;
            } 
            let completions = result.body.map(o => {
                return suggestFormat(o) || 'Unknown' ;
            });
            setDataSource(completions);
        })
        .catch(error => {
            setFetching(false);
        })
    }

    //useEffect(() => {
    //    setFetching(true);
    //    api.get(suggestUrl, {query: queryText, per_page: 5, fields: suggestFields.join(',')})
    //    .then(result => {
    //        setFetching(false);
    //        const searchedTerm = result.headers['x-search-term'] || '';
    //        if(searchedTerm !== queryText){
    //            //stale result
    //            return;
    //        } 
    //        let completions = result.body.map(o => {
    //            return suggestFormat(o) || 'Unknown' ;
    //        });
    //        setDataSource(completions);
    //    })
    //    .catch(error => {
    //        setFetching(false);
    //    })

    //}, [queryText]);

    let divStyle = Object.assign({display: 'flex'}, style);

    let content;
    if(dataSource.length) {
        content = dataSource.map(d => {
            let subtexts;
            if(d.subtexts) {
                subtexts = d.subtexts.map(subtext => {
                    return <div style={{fontSize: '12px', lineHeight: '16px', fontStyle: 'italic'}}>{subtext}</div>
                });
            }
            return <Option key={d.value}>{d.text}{subtexts}</Option>
        })
    } else if (value) {
        let label = `${valueLabel}`
        content = <Option key={value}>{label}</Option> 
    } else {
        content = null;
    }

    return <div style={divStyle}>
        <Select
            labelInValue={labelInValue || false}
            allowClear={true}
            value={labelInValue ? {key: value, label: valueLabel} : value}
            showSearch={true}
            showArrow={false}
            placeholder={placeholder}
            notFoundContent={fetching ? <Spin style={{marginLeft: '12px'}} size="small" /> : null}
            filterOption={false}
            onSearch={onSearch}
            onSelect={onSelect}
            onChange={onChange}
            style={{ width: '100%' }}>
            {content}
        </Select>
    </div>
};

export default Complete;
