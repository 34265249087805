import React, { Component } from 'react';
import { Button, Table, Spin } from 'antd';
import BackLink from 'components/BackLink';
import moment from 'moment';
import api from 'api';



class ConfirmInspections extends Component {

    state = {
        inspectionsWithEmails: [],
        selectedKeys: [],
        inspectionsNoEmails: [],
        unscheduledInspections: [],
        queriesLoading: 0,
        sendingEmails: false,
    }

    componentDidMount() {

        this.fetchInspections();
    }

    fetchInspections = () => {

        this.setState({queriesLoading: 3});

        api.get('/inspections', {is_scheduled: true, has_email: true, status: 'unconfirmed' })
        .then(result => {
            if(result.error) {
                console.error("Got error ", result.error);
                this.setState(previousState => { return {queriesLoading: (previousState.queriesLoading-1)}}); 
            } else {
                this.setState(previousState => { return {queriesLoading: (previousState.queriesLoading-1), inspectionsWithEmails: result.body}});
            }
        });

        api.get('/inspections', {is_scheduled: true, has_email: false, status: 'unconfirmed' })
        .then(result => {
            if(result.error) {
                console.error("Got error ", result.error);
                this.setState(previousState => { return {queriesLoading: (previousState.queriesLoading-1)}}); 
            } else {
                this.setState(previousState => { return {queriesLoading: (previousState.queriesLoading-1), inspectionsNoEmails: result.body}});
            }
        });

        api.get('/inspections', {is_scheduled: false, status: 'unconfirmed' })
        .then(result => {
            if(result.error) {
                console.error("Got error ", result.error);
                this.setState(previousState => { return {queriesLoading: (previousState.queriesLoading-1)}}); 
            } else {
                this.setState(previousState => { return {queriesLoading: (previousState.queriesLoading-1), unscheduledInspections: result.body}});
            }
        });

    }

    render() {
        const { queriesLoading } = this.state;

        if(queriesLoading > 0) {
            return <Spin tip='Loading' />
        }


        return <React.Fragment>
            {this.renderInspectionsWithEmails()}
        </React.Fragment>


    }

    handleSelect = (keys) => {
        this.setState({selectedKeys: keys});
    }

    sendEmails = () => {
        const { selectedKeys } = this.state;

        const params = {inspection_ids: selectedKeys};

        console.log("Send with params: ", params);
        this.setState({sendingEmails: true}, () => {
            api.rpc('sendInspectionEmailConfirmations', params)
            .then(result => {
                this.setState({sendingEmails: false});
            })
            .catch(e => {
                this.setState({sendingEmails: false});
            });
        });


    }

    renderInspectionsWithEmails = () => {
        const { selectedKeys, inspectionsWithEmails } = this.state;

        const columns = [{
            title:'Site Name', 
            dataIndex: 'site_name',
        } , {
            title: 'UL',
            dataIndex: 'ul_flag',
        }, {
            title: 'Start Date',
            dataIndex: 'start_date',
        }, {
            title: 'Email',
            dataIndex: 'contact_email',
        }
        
        ];

        let i = inspectionsWithEmails.map(i => {
            let ul_flag = i.is_ul ? 'Yes' : 'No';
            let first_date = i.scheduled_dates[0].start_date;
            let start_date = first_date ? moment(first_date).format('MMM DD, YYYY') : 'No date scheduled';
            return Object.assign({}, i, {key: i.id, ul_flag, start_date, sortDate: first_date})
        });
            
        i.sort((a,b) => { 
            if(a.sortDate > b.sortDate) { return 1 };
            if(a.sortDate < b.sortDate) { return -1};
            return 0;
        });

        let rowSelection = {
            selectedRowKeys: selectedKeys,
            onChange: this.handleSelect,
        }


        return <React.Fragment>
            <BackLink />
            <Table rowSelection={rowSelection} 
                        bordered 
                        pagination={false} 
                        dataSource={i} 
                        defaultSortOrder={'descend'}
                        columns={columns} 
                        title={this.header} />
        </React.Fragment>
    }

    header = () => {
        const { sendingEmails, selectedKeys } = this.state;

        const hasSelected = selectedKeys.length > 0;

        return <React.Fragment>
            <Button
                style={{marginRight: '12px'}}
                type="primary"
                onClick={this.sendEmails}
                disabled={!hasSelected}
                loading={sendingEmails}
              >
                Send Emails 
            </Button>
            <span>Confirmations can be requested by email for these customers.</span>
        </React.Fragment>



    }


}


export default ConfirmInspections;
