import React, { Component } from 'react';
import { Spin, Alert, Row, Card, Form, Icon, Input, Button } from 'antd';
import './style.css';
import AccountAction from '../../AccountAction/';

const FormItem = Form.Item;

class ResetForm extends Component {

    state = {
        resultMessage: null
    }



    handleSubmit = this.handleSubmit.bind(this);
    async handleSubmit(e){
        const { onSubmit } = this.props;
        e.preventDefault();
        this.props.form.validateFields( async (err, values) => {
          if (!err) {
              const response = await onSubmit(values.email);

              if(response.status === 200){
                  const message = `An email will be sent to "${values.email}" if an account exists with that address. Check your inbox.`;
                  this.setState({resultMessage: message});
                }
          }

        });
    }

    render() {

        const { resetting, error } = this.props;
        const { resultMessage } = this.state;

        if(resetting){
            return <AccountAction message='Sending Email...' />
        } else { 
            return <Row style={{height: '100vh'}} type="flex" justify="center" align="middle" >
                <Card className='reset-card' title={'Reset Password'} style={{boxShadow: '0px 2px 5px #8888AA33', width: 300}} >
              <Form onSubmit={this.handleSubmit} className="reset-form">
                  { resultMessage ? <FormItem><Alert message={resultMessage} type="success" showIcon /></FormItem> : null}
                  { error ? <FormItem><Alert message={error} type="error" showIcon /></FormItem> : null}
                {resultMessage ? null : this.renderEmailField() }
                {resultMessage ? this.renderReturn() : this.renderSubmit() }
              </Form>
                </Card>
                </Row>
        }
    }

    renderEmailField = () => {
        const { getFieldDecorator } = this.props.form;

        return <FormItem>
                  {getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Please input your email address' }],
                  })(
                    <Input key='ResetEmail' id='email' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email Address" />
                  )}
                </FormItem>
    }

    renderReturn = () => {
        const { onCancel } = this.props;

        return <FormItem>
            <Button className="reset-form-forgot" onClick={onCancel} >Return to Sign In</Button>
        </FormItem>
    }

    renderSubmit = () => {
        const { onCancel, loggingIn } = this.props;


        if(loggingIn) {
            return <div className="spinner"><Spin size='large' /></div>
        } else {
            return <FormItem>
                  <Button key="SubmitReset" id='submit' type="primary" htmlType="submit" className="reset-form-button">
                    Reset Password 
                  </Button>
                  <Button style={{border: '0px', boxShadow: '0px 0px'}} 
                      key="CancelReset" 
                      id='cancel' 
                      className="reset-form-forgot" onClick={onCancel} >Cancel</Button>
            </FormItem>
        }
    }

}


const WrappedResetForm = Form.create()(ResetForm);

export default WrappedResetForm;
