import React, { Component } from 'react';
import { Spin, Alert, Row, Card, Form, Icon, Input, Button } from 'antd';
import './style.css';
import AccountAction from '../AccountAction/';
import { UserContext } from '../../../contexts/User';

const FormItem = Form.Item;

class NewPasswordForm extends Component {

    state = {
        resultMessage: null
    }



    handleSubmit = this.handleSubmit.bind(this);
    async handleSubmit(updatePasswordFunc){
        const { match } = this.props;
        const { password_token } = match.params;
    
        this.props.form.validateFields( async (err, values) => {
          if (!err) {
              const response = await updatePasswordFunc(password_token, values.password);

              if(response.status === 200){
                  const message = `Your password has been updated. Please sign in again.`;
                  this.setState({resultMessage: message});
                }
          }

        });
    }

    render() {

        const { resultMessage } = this.state;

        return <UserContext.Consumer>
            {({state, actions}) => {
                return state.updatingPassword ? 
                    <AccountAction message='Saving Password...' />
                :
                    <Row style={{height: '100vh'}} type="flex" justify="center" align="middle" >
                        <Card className='new-password-card' title={'New Password'} style={{boxShadow: '0px 2px 5px #8888AA33', width: 300}} >
                            <Form onSubmit={(e) => { 
                                e.preventDefault(); 
                                return this.handleSubmit(actions.updatePassword)} 
                            } className="new-password-form">
                          { resultMessage ? <FormItem><Alert message={resultMessage} type="success" showIcon /></FormItem> : null}
                          { state.updatePasswordError ? <FormItem><Alert message={state.updatePasswordError} type="error" showIcon /></FormItem> : null}
                        {resultMessage ? null : this.renderPasswordField() }
                        {resultMessage ? null : this.renderConfirmField() }
                        {resultMessage ? this.renderReturn() : this.renderSubmit() }
                      </Form>
                        </Card>
                    </Row>
                }
            }
        </UserContext.Consumer>
    }

    renderPasswordField = () => {
        const { getFieldDecorator } = this.props.form;

        return <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please enter a new password' }],
                  })(
                    <Input key='NewPassword' name="password" type="password" id='password' prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="New Password" />
                  )}
                </FormItem>
    }

    renderConfirmField = () => {
        const { getFieldDecorator } = this.props.form;

        return  <FormItem>
          {getFieldDecorator('confirm', {
            rules: [{
              required: true, message: 'Please confirm your password',
            }, {
              validator: this.compareToFirstPassword,
            }],
          })(
            <Input key='ConfirmPassword' type="password" id='password' name='password_confirm' prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Confirm Password" />
          )}
        </FormItem>
    }

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
          callback(`Passwords don't match`);
        } else {
          callback();
        }
    }

    handleCancel = () => {
        const { history } = this.props;

        history.push('/');
    }

    renderReturn = () => {
        return <FormItem>
            <Button style={{boxShadow: '0px 0px', border: '0px'}} className="new-password-form-forgot" onClick={this.handleCancel} >Return to Sign In</Button>
        </FormItem>
    }

    renderSubmit = () => {
        const { loggingIn } = this.props;


        if(loggingIn) {
            return <div className="spinner"><Spin size='large' /></div>
        } else {
            return <FormItem>
                  <Button key="SavePassword" id='submit' type="primary" htmlType="submit" className="new-password-form-button">
                    Save Password
                  </Button>
                  <Button style={{boxShadow:'0px 0px', border: '0px'}} key="CancelNewPassword" id='cancel' className="new-password-form-forgot" onClick={this.handleCancel} >Cancel</Button>
            </FormItem>
        }
    }

}


const WrappedNewPasswordForm = Form.create()(NewPasswordForm);

export default WrappedNewPasswordForm;
