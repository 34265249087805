
import colors from 'lib/constants/resourceColors';

import Privileges from '../lib/constants/privileges';

const userFunc = (user) => {
    return {label: user.first_name + ' ' + user.last_name, value: user.id}
}

export const expenseForm = [
    {type: 'options', 
        ownerField: true, 
        createOnly: true, 
        key: 'user_id', 
        label: 'Employee', 
        defaultValue: (user) => user.id,
        optionsURL: '/lists/expenses/create_targets', 
        optionsMapper: userFunc},

    {static: true,
        key: 'employee',
        link: {url: '/users/:user_id', privileges: Privileges.READ_USERS},
        label: 'Employee'},

    '!description',
    '!$amount',

    {type: 'date', 
        static: true, 
        key: 'paid_at', 
        label: 'Paid Date', 
        readOnly: true},

    //'*status{approved,pending,rejected}=pending-w[WRITE_EXPENSES_STATUS]',

   {type: 'radio', 
        key: 'status', 
        defaultValue: 'pending', 
        options: [{label: 'Approved', value: 'approved'}, 
                  {label: 'Pending',  value:'pending'}, 
                  {label: 'Rejected', value: 'rejected'}], 
        writePrivileges: [Privileges.WRITE_EXPENSES_STATUS]},

   {type: 'longtext', 
        key: 'notes', 
        writePrivileges: [Privileges.WRITE_EXPENSES_NOTES], 
        showEmpty: false},

    {type: 'date',  
        key: 'expense_date', 
        writePrivileges: [Privileges.WRITE_EXPENSES_EXPENSE_DATE], 
        showEmpty: false},

    '~receiptImage(Receipt Photo or File){image_url}',
]

function expenseBadgeStyle(value, data){
    const { status, paid_at } = data;

    if(status === 'pending'){
        return {status: 'processing', text: 'Pending'}; 
    } else if (status === 'approved' && paid_at){
        if(new Date() < new Date(data.paid_at)){
            return {status: 'success', text: 'Paying'}; 
        } else {
            return {status: 'success', text: 'Paid'}; 
        }
    } else if (status === 'approved'){
        return {status:'success', text:'Approved'}; 
    } else if (status === 'rejected') {
        return {status: 'error', text: 'Rejected'}; 
    } else { 
        return null;
    }
}

export default {
    defaults: {
        name: 'Expense',
        color: colors.expense,
        formFields: expenseForm,
    },

    view: {
        mainImageWidth: '650px',
    },

    create: {
        urlFormatter:((expense) => `/expenses/${expense.expense_number}`),
    },
    
    search: {
        allowDateFilter: true,
        fields: ['id', 'expense_date', 'expense_number', 'description', 'amount', 'created_at', 'status', 'paid_at'], 
        dateFieldOptions: [{label: 'Submitted Date', value: 'created_at', description: 'submitted'},
                           {label: 'Expense Date', value: 'expense_date', description: 'occurring'},
                           {label: 'Paid Date', value: 'paid_at', description: 'paid'}],
        filterOptions: [
            {label: 'All', filters: null},
            {label: 'Approved (Not Paid Yet)', filters: {is_paid: false, status: 'approved'}}, 
            {label: 'Pending', filters: {status: 'pending'}},
            {label: 'Rejected', filters: {status: 'rejected'}},
            {label: 'Paid', filters: {is_paid: true}}
        ],
        //resourceFilters: (user ? {user: user.id} : null),
        defaultFilter: (currentUser) => { return {user: currentUser.id}},
        heading: 'Your Expenses',
        links: ['new'],
        searchBy: ['description', 'expense number', 'employee', 'notes', 'amount', 'approved, rejected or pending'],
        tableProps: {
            rowURL: '/expenses/:expense_number',
            columns:[
                {heading: 'Expense Number', accessor: 'expense_number', style: 'bold'},
                {heading: 'Description', type: 'multiple', sortField: 'expense_date',
                        rows: [{heading:'Description', accessor: 'description', maxWidth: '350px'}, 
                               {heading: 'Expense Date', type: 'date', dateFormat: 'MMM DD, YYYY', accessor: 'expense_date'}]},
                {heading: 'Amount', type: 'currency', accessor: 'amount'},
                {heading: 'Status', type: 'badge', accessor: 'status', badgePropsFunc: expenseBadgeStyle},
                {heading: 'Pay Date', placeholder: '—', type: 'date', dateFormat: 'MMM DD, YYYY',  value: 'paid_at', accessor: 'paid_at'}
            ],
        }
    },

        
}

