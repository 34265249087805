import React from 'react';
import './style.css';
import SideMenu from './SideMenu';
import ErrorBoundary from 'components/ErrorBoundary';
import Header from './Header';
//import getApplicationRoutes from '../../routes';
import { Layout } from 'antd';
import { Route, Switch} from "react-router-dom";
import getApplicationRoutes from 'routes/routes';
import { InterfaceProvider, InterfaceContext } from 'contexts/Interface';
import VersionHeader from './VersionHeader';

const { Content } = Layout;
const appRoutes = getApplicationRoutes();

function FilePreview(state, actions) {
        return <Layout style={{minHeight: '100vh'}}>
            <Content style={{ background: '#fff', padding: 24, margin: '16px', minHeight: 280}}>
                <Switch> {getApplicationRoutes()}</Switch>
            </Content>
        </Layout>
}

function AppView(state, actions, history, sideMenuWidth) {
        console.log("Render App View !");
        return <React.Fragment>
            <SideMenu history={history} trigger={null} collapsible collapsed={state.sidebarIsCollapsed} width={sideMenuWidth}/>
                <Layout style={{minHeight: '100vh'}}>
                    <VersionHeader />
                    <Header sideMenuCollapsed={state.sidebarIsCollapsed} toggleCollapse={actions.toggleCollapse}/>
                    <Content style={{ background: '#fff', padding: 24, margin: '16px', minHeight: 280}}>
                        <Switch>{appRoutes}</Switch> 
                    </Content>
                </Layout>
        </React.Fragment>
}


function View({history, sideMenuWidth, ...props}) {


    return <ErrorBoundary>
    <InterfaceProvider>
        <InterfaceContext.Consumer>
            {({state, actions}) => 
                <Switch>
                    <Route path='/preview' render={() => FilePreview(state, actions) } />
                    <Route path='/' render={() => AppView(state, actions, history, sideMenuWidth)} />
                </Switch>
            }
        </InterfaceContext.Consumer>
    </InterfaceProvider></ErrorBoundary>



}

export default React.memo(View);
