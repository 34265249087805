
import colors from 'lib/constants/resourceColors';
import formatPhoneNumber from 'lib/helpers/formatPhoneNumber';

export default {
    defaults: {
        name: 'Phone Log',
        color: colors.phoneLogs,
        actions: ['search'],
    },

    search: {
        heading: 'Phone Calls', 
        allowDateFilter: true,
        fields: ['id','station','station_name','class','time','duration','dialed_number','caller_id'],
        tableProps: {
            columns: [
                {heading: 'Station', valueFunc: (d => { return (d.station_name || d.station) }), placeholder: 'Unknown'},
                {heading: 'Type', accessor: 'class', placeholder: 'Unknown'},
                {heading: 'Date', accessor: 'time', type: 'date', dateFormat:`ddd MMM D, YYYY`, placeholder: 'Unknown'},
                {heading: 'Time', accessor: 'time', type: 'date', dateFormat:`hh:mm A`, placeholder: 'Unknown'},
                {heading: 'Duration', valueFunc: (d => {
                        const { duration } = d;
                        let durationString;
                        if(duration !== null && typeof duration !== 'undefined'){
                            const minutes = Math.floor(duration / 60);
                            const seconds = duration % 60;
                            durationString = `${minutes ? minutes + 'm' : ''} ${seconds ? seconds + 's' : ''}`; 
                        }
                        return durationString;
                    }), placeholder: 'Unknown'},
                    {heading: 'Phone Number', valueFunc: (d => {
                        return formatPhoneNumber(d.dialed_number) || d.dialed_number;
                    }), placeholder: 'Unknown'},
                {heading: 'Caller ID', accessor: 'caller_id', placeholder: 'Unknown'}
            ]
        }
    }
}
