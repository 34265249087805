import React, { Component } from 'react';
import api from '../api';
import localforage from 'localforage';


export const UserContext = React.createContext();


export class UserProvider extends Component {
    state = {
        state: {
            rehydrated: false,
            user: null,
            token: null,
            loggingIn: false,
            loggingOut: false,
            resetting: false,
            updatingPassword: false,
            error: null,
            resetError: null,
            updatePasswordError: null,
        },
    }

    setContextState = (newState) => {
        const { state } = this.state;
        let updatedState = Object.assign({}, state, newState);
        this.setState({state: updatedState});
    }


    logIn = async (email, password) => {
        this.setContextState({loggingIn: true, loggingOut: false, error: null}); 
        try {
            const response = await api.authenticate({email, password})
            if(response.status === 200) {
                const user = this.getUserFromResponse(response);
                this.setContextState({user, loggingIn: false, loggingOut: false, error: null});
            } else if (response.status === 401) {
                const error = 'Invalid Email or Password';
                this.setContextState({user: null, loggingIn: false, loggingOut: false, error});
            } else {
                const error = 'Failed to Log In';
                this.setContextState({user: null, loggingIn: false, loggingOut: false, error});
            }

            return response;
        } catch(e) {
            this.setContextState({loggingIn: false, loggingOut: false, error: e});
            return null;
        }
    }

    logOut = async () => {
        this.setContextState({loggingIn: false, loggingOut: true, error: null});
        try {
            const response = await api.logout();
            this.setContextState({loggingOut: false, loggingIn: false, user: null, error:null, token: null});
            return response;
        } catch (e) {
            this.setContextState({loggingOut: false, error: e});
            return null;
        }
    }

    resetPassword = async (email) => {
        this.setContextState({resetting: true, resetError: null, error: null});
        try {
            const response = await api.post('/send_password_reset', {email});
            this.setContextState({resetting: false, resetError: null});
            return response;
        } catch (e) {
            this.setContextState({resetting: false, resetError: e});
            return null;
        }
    }

    updatePassword = async (token, password) => {
        this.setContextState({updatingPassword: true, updatePasswordError: null});
        try {
            const response = await  api.post('/password_reset', {token, password});
            if(response.status === 200){
                this.setContextState({updatingPassword: false, updatePasswordError: null});
            } else if (response.status === 401) {
                this.setContextState({updatingPassword: false, updatePasswordError: 'You are not authorized.'});
            }
            return response;
        } catch (e) {
            this.setContextState({updatePassword: false, updatePasswordError: e});
            return null;
        }
    }
    
    componentDidMount() {
        this.hydrateStateWithLocalStorage();

        // add event listener to save state to localStorage
        // when user leaves/refreshes the page
        window.addEventListener(
          "beforeunload",
          this.saveStateToLocalStorage.bind(this)
        );

        let actions = { logIn: this.logIn, 
            logOut: this.logOut, 
            resetPassword: this.resetPassword, 
            updatePassword: this.updatePassword
        }

        this.setState({actions});
    }

    componentWillUnmount() {
        window.removeEventListener(
          "beforeunload",
          this.saveStateToLocalStorage.bind(this)
        );

        // saves if component has a chance to unmount
        this.saveStateToLocalStorage();
    }

    hydrateStateWithLocalStorage = async () => {

        localforage.getItem('user')
        .then(async (recoveredState) => {

            if(!recoveredState){
                recoveredState = {};
            }

            try {
                const response = await api.verify();
                if(response.status === 200) {
                    const user = this.getUserFromResponse(response);
                    recoveredState.user = user;
                    recoveredState.rehydrated = true;
                    this.setContextState(recoveredState);
                } else if (response.status === 204) {
                    //No user
                    recoveredState.user = null;
                    recoveredState.rehydrated = true;
                    this.setContextState(recoveredState);
                } else { 
                    const error = 'Failed to Authenticate';
                    recoveredState.user = null;
                    recoveredState.rehydrated = true;
                    recoveredState.error = error;
                    this.setContextState(recoveredState);
                }
            } catch (e) {
                let state = {};
                state.user = null;
                state.rehydrated = true;
                state.error = e;
                this.setContextState(state);
            }

        })
        .catch(e => {
            console.log("Error: ", e);
            this.setContextState({rehydrated: true, user: null, loggingIn: false, loggingOut: false, error: e});
        });
    }

    saveStateToLocalStorage = () => {
        const { state } = this.state;
        let saveState = {};
        saveState.user = state.user;
        saveState.token = state.token;
        
        localforage.setItem('user', saveState);
    }

    shouldComponentUpdate = () => {
        return true;
    }

    render(){

        return <UserContext.Provider value={this.state}>
            {this.props.children}
        </UserContext.Provider>
    }


    getUserFromResponse(response){
        let user = response.body;
        let privilegesMap = {};
        user.privileges.forEach(privilege => {
            privilegesMap[privilege] = true;
        });
        user.privileges = privilegesMap;
        return user;
    }

}

