import React, { Component } from 'react'
import { Button, Avatar, Icon, Dropdown, Menu } from 'antd';
import { UserContext } from 'contexts/User';
import versionNumber from 'version';


const userMenu = (actions) => {
  return <Menu>
    <Menu.Item>
      Profile
    </Menu.Item>
    <Menu.Item onClick={actions.logOut}>
        Log Out
    </Menu.Item>
  </Menu>
}

class UserMenu extends Component {

    render() {
        const marginSpace = {margin: '0px 8px'};

        return <UserContext.Consumer>
            {({actions, state}) => {
                let user = state.user;
                let menu = userMenu(actions);

                return <div style={{float: 'right'}}>
                    {`v${versionNumber}`}
                    <Dropdown overlay={menu}>
                        <Button style={{paddingRight: '0px', boxShadow: '0px 0px', border: '0px'}} className="ant-dropdown-link" href="#">
                            <Avatar src={user.avatar_url} style={marginSpace} icon="user" /> 
                                {user ? `${user.first_name} ${user.last_name}` : `Guest`}
                            <Icon style={marginSpace} type="down" />
                        </Button>
                    </Dropdown>
                </div>
            }}
        </UserContext.Consumer>
    }
}

export default UserMenu;

