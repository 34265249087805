export default function toTitleCase(str){
    if(!str) {
        return null;
    }

    if(typeof str !== 'string') {
        throw new Error(`${typeof str} ${str} provided to titleCase function is not a string`);
    }

    return str.replace(/_/g, ' ').replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}
