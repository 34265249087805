import React, { Component } from 'react';
import './style.css';
import Login from './Login';
import AccountAction from './AccountAction';
//import getRoutesForUser from '../../routes';
import { Layout } from 'antd';
//import { RouterContext, RouterProvider } from '../../contexts/Router';
import { UserContext, UserProvider } from '../../contexts/User';
import { BrowserRouter, Route, Switch} from "react-router-dom";
import NewPassword from './NewPassword';
import View from './view';
import { Spin } from 'antd';


class App extends Component {


    render() {

        return <UserProvider>
            <UserContext.Consumer>
            {({state, actions}) => {
                if(!state.rehydrated){
                    return <Spin style={{padding: '12px'}} />;
                }

                if(state.user) {

                    if(state.loggingOut) {
                        return this.renderLogout();
                    } else {
                        return this.renderApp(state.user);
                    }

                } else {
                    return <BrowserRouter>
                                <Switch>
                                    <Route path="/reset_password/:password_token" component={NewPassword} />
                                    <Route path="/" component={Login} />
                                </Switch>
                        </BrowserRouter>
                }
            }}
            </UserContext.Consumer>
        </UserProvider>
    }

    renderLogout() {
        return <AccountAction message='Logging Out...' />
    }

    renderLogin() {
        return <Login />
    }

    renderApp = (user) => {

        const sideMenuWidth = 200;
        return <Layout className="App">
                    <BrowserRouter>
                        <View sideMenuWidth={sideMenuWidth} {...this.props} {...this.state} />
                    </BrowserRouter>
            </Layout>
    }

}

export default App;
