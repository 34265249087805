import React, { Component } from 'react';
import Papa from 'papaparse';
import { Alert, Select,  Button } from 'antd';
import ErrorMessage from 'components/ErrorMessage';
import normalizeBoolean from 'lib/helpers/normalizeBoolean';
import parseFloatIgnoreCharacters from 'lib/helpers/parseFloatIgnoreCharacters';
import BackLink from 'components/BackLink';
import api from 'api';
// eslint-disable-next-line
const debug = require('debug')('app:BatchUploader:');
const Option = Select.Option;

// const propTypes = {
//     form : React.PropTypes.object.isRequired,
//     defaultTemplate: React.PropTypes.object,
//     onSubmit: React.PropTypes.func,
//     handleCancel : React.PropTypes.func,
//     label: React.PropTypes.string,
// };

const defaultProps = {
    defaultTemplate: {},
};

class BatchUploader extends Component {

    state = {
        numberOfRowsToSkip : 1,
        columnMappings: {},
        file: null,
        parsedRows: [],
        parsing: false,
        upserted: 0,
        uploading: false,
        errors: [],
    }

    render(){
        const { upserted, errors, file, parsing } = this.state;
        const { form } = this.props;

        if(!form) {
            return <ErrorMessage errors={['No form has been defined for CSV uploading for this resource.']} />

        }

        if(file === null){
            return <div>
                <BackLink />
                {parsing ? 
                    <span>Parsing...</span>
                    :
                    <div> 
                        <label key="sacf-key">Select a CSV File</label>,
                        <input key="csvinput-key" type="file" name="fileInput" required onChange={this.handleCSV} /> ,
                    </div> 
                }
                {this.upsertResultMessage(upserted, errors.length)}
                <ErrorMessage errors={errors} />
            </div>
        } else {
            return (
                <div>
                    <BackLink />
                    <p>Assign the columns from the CSV File to use for the following fields:</p>
                    <table>
                        <tbody>
                            {this.props.form.map(this.dropDownForKey)}
                            <tr>
                                <td style={{paddingRight: '12px'}}><label>Number of Rows to Skip</label></td>
                                <td><input style={{marginRight: '12px'}} type="number" value={this.state.numberOfRowsToSkip} 
                                        onChange={(e) => {this.setState({numberOfRowsToSkip: e.target.value})}} /></td>
                            </tr>
                        </tbody>
                    </table>
                    <div>                    
                        <Button style={{marginRight: '12px'}} onClick={this.handleCancel}>Cancel</Button>
                        <Button onClick={this.upload} type='primary'>Upload</Button>
                    </div>
                    {this.upsertResultMessage(upserted, errors.length)}
                    <ErrorMessage errors={errors} />
                </div>
            );

        }
    }


    upsertResultMessage = (upserted, failed) => {

        let node; 

        if(upserted && failed === 0) {
            node = <Alert 
                style={{marginTop: '12px'}}
                type='success'
                showIcon
                message={'Upload Successful'}
                description={`${upserted} uploads succeeded without errors.`}
            />
        } else if (upserted && failed) {
            node = <Alert
                style={{marginTop: '12px'}}
                type='error'
                showIcon
                message={'Some Uploads Failed'}
                description={`${failed} uploads encountered errors, ${upserted} uploads were successful.`}
            />
        } else if (upserted === 0 && failed) {
            node = <Alert 
                style={{marginTop: '12px'}}
                type='error'
                showIcon
                message={'Upload Failed'}
                description={`All ${failed} uploads failed.`}
            />
        } else if (upserted === 0 && failed === 0) {
            node = <Alert
                style={{marginTop: '12px'}}
                type='warning'
                showIcon
                message={'Empty'}
                description={`No items have been uploaded.`}
            />
        }

        return node;
    }

    dropDownForKey = (field, currentIndex) => {

        const key = field.key;
        const columnPreviews = this.state.parsedRows[0];

        let title = field.label;

        console.log("KEY VALUE ", field);
        return <tr key={`${field.key}_${field.label}_i${currentIndex}`}>
            <td style={{paddingRight: '12px', fontWeight: '500'}}>{title}</td>
            <td>
                <Select style={{width: 220}} 
                          key={`${currentIndex}_${key}`} 
                   allowClear={true}
                     onChange={(value) => this.assignColumnToIndex(key, value)}>
                    {Object.keys(columnPreviews).map((key, index) => {
                        const option = columnPreviews[key];
                        return <Option  key={option.value+'_'+index} value={index}>{option}</Option>
                    })}
                </Select>
            </td> 
        </tr>

        // return [<span>{title}</span>,
        // <DropDown 
        //     key={currentIndex}
        //     allowsNullSelection={true}
        //     selectedOption={selectedOption}
        //     showEmptySelection={true}
        //     options={columnPreviews}
        //     onChange={(e) => {
        //         this.assignColumnToIndex(key, e.target.value);
        //     }}/>, <br />];


    }

    handleCancel = () => {
        this.setState({file: null, parsedParts: []});
    }

    handleCSV = (e) => {
        const file = e.target.files[0];
        Papa.parse(file, {
            dynamicTyping: true,
            complete: (results) => {
                this.setState({file, parsedRows: results.data});
            }
        });
    }

    assignColumnToIndex = (key, index) => {
        let mappings = this.state.columnMappings;
        mappings[key] = index;
        this.setState({columnMappings: mappings});
        // this.setState(previousState => {
        //     let mappings = {...previousState.columnMappings};
        //     mappings[key] = index;
        //     return {columnMappings: mappings}
        // });
    }

    upload = () => {
        let columnKeys = Object.keys(this.state.columnMappings);
        console.log("Column Map ", this.state.columnMappings);
        let formattedData = [];
        this.state.parsedRows.forEach((row, index) => {
            if(index < this.state.numberOfRowsToSkip){
                return null;
            }
            if(row.length === 1 && !row[0]) {
                //see issue https://github.com/mholt/PapaParse/issues/604 about trailing empty newlines
                return null;
            }

            console.log("Parse row ", row);

            let entity = {...this.props.defaultTemplate}

            columnKeys.forEach((key) => {
                let columnIndexForKey = this.state.columnMappings[key];
                let value = row[columnIndexForKey];

                if(value === ""){
                    //don't insert empty strings
                    return;
                }    

                entity[key] = value;
            });
            
            formattedData.push(entity);
            return null;
        });
        
        //Upload to API
        this.handleSubmit(formattedData);
    }

    normalizeRow = (row) => {
        const { form } = this.props;

        form.forEach(column => {
            const key = column.key;

            switch(column.type) {
                case 'boolean':
                    if(row[key] !== null && typeof row[key] !== 'undefined'){
                        row[key] = normalizeBoolean(row[key]);
                    }
                    break;
                case 'float':
                    if(row[key] !== null && typeof row[key] !== 'undefined'){
                        row[key] = parseFloatIgnoreCharacters(row[key] || 0);
                    }
                    break;
                default:
                    break;
            }
        });
    }
    
    handleSubmit(data){
        debug("Upload ", data);

        data.forEach(row => {
            this.normalizeRow(row);
        });
        
        this.setState({upserted: 0, errors: [], uploading: true});
        api.upsert('/parts', data)
        .then(response => {

            debug("Result: ", response.body);
            const upserted = response.body.success || 0;
            const errors = response.body.errors || [];
            this.setState({upserted, errors, uploading: false});
        })
        .catch(errors => {
            this.setState({uploading: false});
        });
    }
}


//BatchUploader.propTypes = propTypes;
BatchUploader.defaultProps = defaultProps;

export default BatchUploader;    
