import React, { Component } from 'react';
import { UserContext } from 'contexts/User';
import { Redirect, Route } from 'react-router-dom';
import renderCondition from 'lib/helpers/renderCondition';
import NotFound from 'components/NotFound';


class PrivateRoute extends Component {

    render() {
        const { component: Component, alternate: AlternateComponent, customProps, privileges, autoRedirect, ...rest } = this.props;

        return <Route {...rest} render={props => 
          <UserContext.Consumer>
              {({state}) => {
                return state.user ? (
                        renderCondition(privileges, <Component currentUser={state.user} {...customProps} {...props} />, <NotFound {...props} />)
                    ) : (
                        AlternateComponent ? 
                            <AlternateComponent {...props} />
                        :
                            <Redirect to={{pathname: '/login', state: { from: props.location } }}/>
                    )
                }} 
            </UserContext.Consumer>
        } />;
    }
}

export default PrivateRoute;
