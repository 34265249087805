import colors from 'lib/constants/resourceColors';

export default {
    defaults: {
        name: 'Customer Purchase Order',
        formFields: [
            {key: 'customer_id', 
                displayKey: ['customer'],
                label: 'Customer',
                type: 'autocomplete', 
                suggestFormat: (c) => { 
                    let text = `${c.name} (${c.address || 'No Address'})`;
                    return {value: c.id, text} 
                },
                suggestUrl: '/customers', 
                suggestFields: ['id', 'name', 'address']},
            'po_number',
            {key: 'issued_date', type: 'date', label: 'Date Issued', static: true},
            'description',
            '$amount',
            '^poFile(PO File){file_url}',
        ],
    }
}
