import React from 'react';
import moment from 'moment';
import {
  DatePicker, Modal, Form, Input
} from 'antd';

const AffidavitForm = Form.create({ name: 'affidavit_form_in_modal' })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const {
        visible, onCancel, onCreate, form, quote, contractor
      } = this.props;

        return  <Modal
                  visible={visible}
                  title="Create Affidavit PDF"
                  okText="Create PDF"
                  onCancel={onCancel}
                  onOk={onCreate}
                >
          <Form layout="vertical">
            <span style={{paddingBottom: '5px', display:'block', marginBottom: '10px'}} >Enter the details for the Electrical Contractor:</span>
            <Form.Item style={{marginBottom:'5px'}} label="Contractor Name">
              {form.getFieldDecorator('ec_name', {initialValue: contractor.ec_name})(
                <Input />
              )}
            </Form.Item>
            <Form.Item style={{marginBottom:'5px'}} label="Address">
              {form.getFieldDecorator('ec_address', {initialValue: contractor.ec_address})(
                <Input />
              )}
            </Form.Item>
            <Form.Item style={{marginBottom:'5px'}} label="Address Line 2">
              {form.getFieldDecorator('ec_address2', {initialValue: contractor.ec_address2})(
                <Input />
              )}
            </Form.Item>
            <Form.Item style={{marginBottom:'5px'}} label="City">
              {form.getFieldDecorator('ec_city', {initialValue: contractor.ec_city})(
                <Input />
              )}
            </Form.Item>
            <Form.Item style={{marginBottom:'5px'}} label="State">
              {form.getFieldDecorator('ec_state', {initialValue:contractor.ec_state})(
                <Input />
              )}
            </Form.Item>
            <Form.Item style={{marginBottom:'5px'}} label="Zipcode">
              {form.getFieldDecorator('ec_zipcode', {initialValue: contractor.ec_zipcode})(
                <Input />
              )}
            </Form.Item>
            <span style={{paddingBottom: '5px', display:'block', marginBottom: '10px', marginTop: '25px'}} >Enter the property details:</span>
            <Form.Item style={{marginBottom:'5px'}} label="Site Name">
              {form.getFieldDecorator('p_name', {initialValue: quote.site_name})(
                <Input />
              )}
            </Form.Item>
            <Form.Item style={{marginBottom:'5px'}} label="Address">
              {form.getFieldDecorator('p_address', {initialValue: quote.address})(
                <Input />
              )}
            </Form.Item>
            <Form.Item style={{marginBottom:'5px'}} label="Address Line 2">
              {form.getFieldDecorator('p_address2', {initialValue: quote.address2})(
                <Input />
              )}
            </Form.Item>
            <Form.Item style={{marginBottom:'5px'}} label="City">
              {form.getFieldDecorator('p_city', {initialValue: quote.city})(
                <Input />
              )}
            </Form.Item>
            <Form.Item style={{marginBottom:'5px'}} label="State">
              {form.getFieldDecorator('p_state', {initialValue: quote.state})(
                <Input />
              )}
            </Form.Item>
            <Form.Item style={{marginBottom:'5px'}} label="Zipcode">
              {form.getFieldDecorator('p_zipcode', {initialValue: quote.zipcode})(
                <Input />
              )}
            </Form.Item>
            <span style={{paddingBottom: '5px', display:'block', marginBottom: '10px', marginTop: '25px'}} >Enter the date to display for the affidavit:</span>
            <Form.Item label="Affidavit Date">
                {form.getFieldDecorator('date-picker', {initialValue: moment() })(<DatePicker format='MM-DD-YYYY'/>)}
            </Form.Item>
          </Form>
        </Modal>
    }
  }
);

export default AffidavitForm
