import React, { Component } from 'react';
import './style.css';


class ErrorBoundary extends Component {
    state = {
        error: null,
        errorInfo: null,
    }

    componentDidCatch(error, info) { 

        this.setState({error, errorInfo: info});

        // log error out to an API call
        // let errorHeaders = {
        //   type: "Client Fatal Error",
        //   url: window.location.href,
        //   userId: window.userId,
        //   agent: window.navigator.userAgent,
        //   date: new Date(),
        //   msg: error.toString()
        // }

        // api.logError(error, info, errorHeaders);
    }

    render() {
        const { error, errorInfo} = this.state;


        if(error){
            
            let errorMessage = `${error}`;
            let errorStack = `${errorInfo.componentStack}`.split(/\r?\n/);
            console.log("Fatal Error: ", error);
            console.log("Error Info: ", errorInfo);
            return <div className='error-fatal'>
                <div className='error-container'>
                    <div className='error-title'>A FATAL ERROR HAS OCCURRED</div>
                    <div className='error-explain'>"{errorMessage}"{errorStack[1]}</div>
                    <div className='error-explain'>Please refresh the page, and try again.</div>
                </div>
            </div>
        }

        return this.props.children;
    }

}

export default ErrorBoundary;
