// eslint-disable-next-line
const StackTrace = require('stacktrace-js');
const debugRequest = require('debug')('app:API:Request:');
const debugResponse = require('debug')('app:API:Response:');

const ROOT_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';
const VERSION_URL = '/v1';

async function simulateLatency(stallTime = 600) {
  await new Promise(resolve => setTimeout(resolve, stallTime));
}

export default {

    logError(error, info, headers) {

        var callback = function(stackframes) {
            var stringifiedStack = stackframes.map(function(sf) {
                return sf.toString();
            }).join('\n');
            console.log("Error ", error);
            console.log(stringifiedStack);
        };

        var errback = function(err) { console.log(err.message); };

        StackTrace.fromError(error).then(callback).catch(errback);

    },

    async call(method, subURL, body, rootURL=ROOT_URL, versionURL=VERSION_URL){

        let headers = { 'Accept': 'application/json' };

        let postBody = null;
        if(body instanceof FormData){
            //posting form data, leave content-type undefined so browser sets multipart/form-data with boundary
            postBody = body;
        } else if (body && typeof body === 'object'){
            //posting a regular object
            postBody = JSON.stringify(body);
            headers['Content-Type'] = method === 'PATCH' ? 'application/merge-patch+json' : 'application/json';
        }

        debugRequest(`call: ${method} ${subURL}
               body: ${JSON.stringify(postBody)}`);

        if(!process.env.REACT_APP_API_URL){
            //if we don't have an API URL, then we are probably on localhost, so we need to simulate latency
            //await simulateLatency();
        }

        return fetch(rootURL + versionURL +  subURL, {
            method,
            headers,
            mode: 'cors',
            origin: null,
            credentials: 'include',
            body: postBody,
        })
        .then(function(res) {
            const { status } = res;
            debugResponse(`Status Code: ${status}`);


            let headers = {};
            res.headers.forEach((value, name) => {headers[name] = value});
            if(status === 200 || status === 201){
                return res.json()
                .then(jsonData => {
                    debugResponse(`Data: ${JSON.stringify(jsonData)}`);
                    return {success: true, headers, status, body: jsonData};
                });

            } else if (status === 204) {
                return {success: true, headers, status, body: null};
            } else if (status === 400) {
                return {success: false, headers, status,
                        errors: [{message: body}]};

            } else if(status === 403){
                return {success: false, headers, status, 
                        errors: [{message: "You are not authorized for this action."}]};

            } else if(status === 401){
                //authActions.verifyUser();
                return {success: false, headers, status, 
                        errors: [{message: "You are not authenticated."}]};

            } else if(status === 404){
                return {success: false, headers, status, 
                        errors: [{message: "The requested resource was not found."}]};

            } else if (status === 405) {
                return {success: false, headers, status,
                        errors: [{message: "This action can not be performed at this time."}]};
            } else if (status === 413) {
                return {success: false, headers, status,
                        errors: [{message: "The file you tried to upload was too large."}]};
            } else if (status === 409) {
                return res.json()
                .then(jsonData => {
                    return {success: false, headers, status, errors: `Conflict Error: ${jsonData}`};
                });
            } else if(status === 500) {
                return res.json()
                .then(jsonData => {
                    debugResponse(`Errors: ${JSON.stringify(jsonData)}`);
                    return {success: false, headers, status, errors: jsonData};
                });
            } else if(status === 503){
                return {success: false, headers, status, errors:[{message: "Connection Failed."}]};
            } else {
                return res.json()
                .then(jsonData => {
                    // debugResponse(`Errors: ${JSON.stringify(jsonData)}`);
                    // return {headers, status, errors: jsonData};
                    throw new Error(jsonData);
                });
            }
        })
        .catch(error => {
            return {status: 500, errors: [{message: `An error occurred: ${error}`}]};      
        });
    },


    get(subURL, params){
        let getURL = this.urlWithParams(params, subURL);
        if(getURL === undefined){
            throw new Error('Passed undefined url');
        }
        return this.call('GET', getURL);
    },

    post(subURL, body, params){
        let postURL = this.urlWithParams(params, subURL);
        return this.call('POST', postURL, body);
    },

    search(subURL, params){
        let getURL = subURL;
        const queryParams = this.getQueryParams(params);
        if(queryParams.length){
            getURL = subURL + (subURL.indexOf('?') === -1 ? '?' : '&') + queryParams;
        }

        return this.call('GET', getURL);
    },

    download(subURL, params) {
        let downloadURL = this.urlWithParams(params, subURL);
        if(downloadURL === undefined){
            throw new Error('Passed undefined url');
        }
        window.open(ROOT_URL + VERSION_URL + downloadURL);
    },

    rpc(method, params) {


        if(typeof this.rpc.callback_id === 'undefined') {
            this.rpc.callback_id = 0;
        }

        let idNumber = ++this.rpc.callback_id;

        let rpcParams = {
            jsonrpc: '2.0',
            method,
            id: idNumber
        };

        if(params){
            if(params instanceof FormData) {
                var object = {};
                params.forEach((value, key) => object[key] = value);
                rpcParams.params = object;
            } else {
                rpcParams.params = params;
            }
        }

        return this.post('/rpc', rpcParams);
    },

    create(subURL, body, params){
        return this.post(subURL, body, params);
    },

    read(subURL, params){
        return this.get(subURL, params);
    },

    update(subURL, body){
        return this.call('PATCH', subURL, body);
    },

    upsert(subURL, body){
        return this.call('PUT', subURL, body);
    },

    destroy(subURL){
        return this.call('DELETE', subURL);
    },

    getQueryParams(params){
        if(!params){
            return '';
        }

        const keys = Object.keys(params);
        let queries = [];
        keys.map(key => { 
            if(typeof params[key] !== undefined && params[key] !== null){
                let query; 
                if(typeof params[key] === 'object') {
                    query = this.getQueryParams(params[key]);
                } else { 
                    query = (encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
                }
                queries.push(query);
                return query;
            }

            return null; //TODO: need to skip this map function output instead of returning a ''
        }).filter(v => v);

        return queries.join('&');
    },

    urlWithParams(params, url){

        let newUrl = url;
        if(params){
            const queryParams = this.getQueryParams(params);
            if(queryParams.length){
                newUrl = url + (url.indexOf('?') === -1 ? '?' : '&') + queryParams;
            }
        }

        return newUrl;
    },

};
