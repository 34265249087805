import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Circles, Email, RedX, Clock } from './Icons';
import { DragSource } from 'react-dnd';
import moment from 'moment';
import styled from 'styled-components';

const standardHeight = 4.8; //was 3.6
const compressedHeight = 1.6;

const EventWrapper = styled.div`
    box-sizing: border-box;
    min-height: ${({slotHeight, compressed}) => `${(compressed ? compressedHeight : standardHeight * (slotHeight || 1)) + 0.3475 + 0.3475 - 0.3 }`}em;
    line-height: 1em;
    position: relative;
    overflow-x: visible;
    //width: ${props => props.isFirstVisibleDayOfWeek ? Math.min((props.days || 1), 7) * 100 : 100}%;
    //${props => props.isFirstVisibleDayOfWeek ? `border: 1px solid red;` : ''}
    margin: 0;
    &:last-of-type {
        margin-bottom: 24px;
    }
`;


//    ${props => !props.isFirstVisibleDayOfWeek ? `display: none;` : 'width: 100%;'}

const BaseSlot = styled.div`
//    width: ${props => props.isFirstVisibleDayOfWeek ? (100 / Math.min((props.days || 1), 7) - 5) : 100}%;

    ${props => props.updating ? 'opacity: 0.35;' : ''}
    background-color: transparent;
    border-bottom-left-radius: ${props => props.isFirstDay ? '0.5375em' : 0};
    border-top-left-radius: ${props => props.isFirstDay ? '0.5375em' : 0}; 
    border-bottom-right-radius: ${props => props.isLastDay ? '0.5375em' : 0};
    border-top-right-radius: ${props => props.isLastDay ? '0.5375em' : 0}; 

    border-top: 0.3475em solid transparent;
    border-bottom: 0.3475em solid transparent;
    ${({isFirstVisibleDayOfWeek}) => isFirstVisibleDayOfWeek ? 'border-left: 0.3475em solid transparent;' : ''}
    ${({isLastDay}) => isLastDay ? 'border-right: 0.3475em solid transparent;' : ''}

    box-sizing: border-box;
    cursor: move;
    font-family: sans-serif;
    line-height: 1em;
    height: ${({rowHeight, compressed}) => `${compressed ? compressedHeight : standardHeight * (rowHeight || 1)}`}em;
    margin: 0 0 0 0;
    margin-left: ${props => props.isFirstDay ? '.25em' : 0};  
    margin-right: ${props => props.isLastDay ? '.25em' : 0};
    overflow-x: visible;
    white-space: nowrap;
    z-index: ${props => props.isSelected ? '300' : props.index || '1'};

`;

const FilledSlot = BaseSlot.extend`
    box-shadow: 0px 3px 0px 0px hsla(0,0%,0%,.09);


    ${props => {
        if(!props.confirmed){
            return `
            `
        }
    }}

    ${({wrapsToNextWeek, compressed, slotHeight, rowHeight}) => wrapsToNextWeek ? `
        margin-right: -0.5em;
        border-top-right-radius: 0.1375em; 
        &:after {
            content: "";
            position: absolute;
            right: -0.5em;
            bottom: ${-0.1 + (compressed ? 0 : standardHeight * (slotHeight - rowHeight))}em;
            display: block;
            border-style: solid;
            border-color: gray transparent transparent transparent;
            border-width: 0.5em 0.5em 0 0;
            z-index: -1;
        }
    ` : ''}

    ${props => props.wrapsFromPreviousWeek ? `
        margin-left: -0.5em;
        padding-left: 0.25em;
        border-bottom-left-radius: 0.1375em; 
        &:before {
            content: "";
            position: absolute;
            left: -0.5em;
            top: -0.5em;
            display: block;
            border-style: solid;
            border-color: transparent transparent gray transparent;
            border-width: 0 0 0.5em 0.5em;
            z-index: -1;
        }
    ` : ''}
`;

const SkippedSlot = BaseSlot.extend`
    box-shadow: none;
    background-color: red;
    opacity: 0.25;
    border-top: 1px solid red;
    border-bottom: 1px solid red;
`;

const GenericSlot = FilledSlot.extend`
    background-color: ${({colors}) => (colors && colors.genericEventColor) || '#00acff'};
    color: ${({colors}) => (colors && colors.genericEventTextColor) || '#FFFFFF'};

    ${props => props.wrapsToNextWeek ? `
        &:after {
            border-color: #1b90c7 transparent transparent transparent;
        }
    ` : ''}

    ${props => props.wrapsFromPreviousWeek ? `
        &:before {
            border-color: transparent transparent #1b90c7 transparent;
        }
    ` : ''}
`;

const ConfirmedSlot = FilledSlot.extend`
    background-color: ${({colors}) => (colors && colors.confirmedColor) || '#44c94f'}; 
    color: ${({colors}) => (colors && colors.confirmedTextColor) || 'white'};

    ${props => props.wrapsToNextWeek ? `
        &:after {
            border-color: #36a53f transparent transparent transparent;
        }
    ` : ''}

    ${props => props.wrapsFromPreviousWeek ? `
        &:before {
            border-color: transparent transparent #36a53f transparent;
        }
    ` : ''}
`;

const PendingSlot = FilledSlot.extend`
    background-color: ${({colors}) => (colors && colors.pendingColor) || '#e4d231'}; 
    color: ${({colors}) => (colors && colors.pendingTextColor) || 'white'};
    border-bottom-left-radius: ${props => props.isFirstDay ? '3px' : 0};
    border-top-left-radius: ${props => props.isFirstDay ? '3px' : 0}; 
`;

const NeedActionSlot = FilledSlot.extend`
    background-color: ${({colors}) => (colors && colors.pendingColor) || '#e48231'}; 
    color: ${({colors}) => (colors && colors.pendingTextColor) || 'white'};
    border-top-right-radius: ${props => props.isFirstDay ? '3px' : 0}; 
`;

const InactiveSlot = FilledSlot.extend`
    background-color: ${({colors}) => (colors && colors.inactiveColor) || '#AAAACC'}; 
    color: ${({colors}) => (colors && colors.inactiveTextColor) || 'white'};
`;

const FailedPendingSlot = FilledSlot.extend`
    background-color: ${({colors}) => (colors && colors.unconfirmedColor) || '#FFF9F9'}; 
    border-bottom-left-radius: ${props => props.isFirstDay ? '3px' : 0};
    border-top-left-radius: ${props => props.isFirstDay ? '3px' : 0}; 
    color: ${({colors}) => (colors && colors.unconfirmedTextColor) || '#ff0039'};
`;

const UnconfirmedSlot = FilledSlot.extend`
    background-color: ${({colors}) => (colors && colors.unconfirmedColor) || '#ffd8d8'}; 
    color: ${({colors}) => (colors && colors.unconfirmedTextColor) || '#ff0009'};
    border-radius: 0;
`
const HolidaySlot = FilledSlot.extend`
    background-color: #c016f530;
    box-shadow: none;
    border-radius 0;
    margin-right: 0;
    margin-left: 0;
    padding: 0;
    color: purple;
    cursor: default;
`


const Label = styled.span`
    ${props => props.isFirstVisibleDayOfWeek ? '' : `visibility: hidden;`}
    background: none;
    position: relative;
    top: 0;
    display: block;
    color: inherit;
    font-size: 14px;
    width: ${props => Math.min(props.span || 1, 7) * 100}%;
    text-indent: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: none;
    z-index: 1;
`;

const SmallLine = styled.span`
    ${props => props.isFirstVisibleDayOfWeek ? '' : `visibility: hidden;`}
    background: none;
    position: relative;
    display: block;
    color: inherit;
    font-size: 12px;
    opacity: 0.75;
    overflow: hidden;
    z-index:1;
    text-overflow: ellipsis;
    text-indent: 0;
    user-select: none;
    width: ${props => Math.min(props.span || 1, 7) * 100}%;
    white-space: nowrap;
`;

const Time = styled.span`
    display: block;
    color: inherit;
    font-size: 12px;
    opacity: 0.75;
    z-index:1;
    overflow-x:visible;
    text-indent: 0;
    user-select: none;
`;


// React DnD
const eventSource = {

    canDrag(props, monitor) {
        const { immutableEvent } = props;
        if(immutableEvent.has('updating') && immutableEvent.get('updating') === true){
            return false;
        } else if (immutableEvent.get('eventStatus') === 'holiday') {
            return false;
        } else {
            return true;
        }
    },
    
    beginDrag({day, immutableEvent}) {

        return {
            id: immutableEvent.get('id') 
        };
    },
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

const propTypes = {
    day: PropTypes.object.isRequired,
    defaultStatus: PropTypes.string.isRequired,
    eventIcons: PropTypes.object,
    eventContainers: PropTypes.object,
    rowHeight: PropTypes.number,
    immutableEvent: PropTypes.object,
    onClick: PropTypes.func,
    onMouseOut: PropTypes.func,
    onMouseOver: PropTypes.func,
    wrapTitle: PropTypes.bool,
    text: PropTypes.string,
    isDragging: PropTypes.bool.isRequired,
    connectDragSource: PropTypes.func.isRequired
};

const defaultProps = {
    defaultStatus: 'generic',
    eventIcons: {
        generic: Circles,
        confirmed: Clock,
        pending: Email,
        pendingFailed: Email,
        unconfirmed: RedX,
    },
    eventContainers: {
        generic: GenericSlot,
        confirmed: ConfirmedSlot,
        pending: PendingSlot,
        pendingFailed: FailedPendingSlot,
        unconfirmed: UnconfirmedSlot,
        holiday: HolidaySlot,
        needsAction: NeedActionSlot, 
        inactive: InactiveSlot,
    },
    onClick: () => {},
    onMouseOut: () => {},
    onMouseOver: () => {},
}

class Event extends Component {

    constructor(props) {
        super(props);

        this.sharedArguments = [null, this, this.props.immutableEvent, this.props.day];
    }
    
    componentWillReceiveProps(nextProps) {
      this.sharedArguments = [null, this, nextProps.immutableEvent, nextProps.day];
    }

    handleClick = (e) => {
        const { immutableEvent, day } = this.props;

        if(immutableEvent.has('updating') && immutableEvent.get('updating') === true){
            //ignore event clicks while updating 
            return;
        }

        if(e.type === 'click') {
            if(this.props.onClick) {
                this.props.onClick(e, immutableEvent, day);
            }
        } else if(e.type === 'contextmenu') {
            console.log("Right click");
            if(this.props.onContextMenu){
                e.preventDefault();
                this.props.onContextMenu(e, immutableEvent, day);
                e.stopPropagation();
                return false;
            }
        }

        e.stopPropagation();
    }

    render() {
        const { defaultStatus, 
                connectDragSource, 
                compressed,
                eventContainers, 
                rowHeight,
                slotHeight,
                index,
                immutableEvent, 
                onMouseOut, 
                onMouseOver, 
                wrapTitle 
            } = this.props;

        // Return a placeholder element if there is no event data 
        if(!immutableEvent) {
            return <EventWrapper compressed={compressed} slotHeight={slotHeight}><BaseSlot compressed={compressed} /></EventWrapper>;
        }

        const isSelected = immutableEvent.get('isSelected');
        const isSkipped = immutableEvent.get('isSkipped');
        const isFirstDay = immutableEvent.get('isFirstDay');
        const isFirstVisibleDayOfWeek = immutableEvent.get('isFirstVisibleDayOfWeek');
        const isLastDay = immutableEvent.get('isLastDay');
        const wrapsToNextWeek = immutableEvent.get('wrapsToNextWeek');
        const wrapsFromPreviousWeek = immutableEvent.get('wrapsFromPreviousWeek');
        const eventStatus = immutableEvent.get('eventStatus');
        const title = immutableEvent.get('title');
        const days = immutableEvent.get('days');
        const visibleDaySpan = immutableEvent.get('visibleDaySpan');
        const updating = immutableEvent.get('updating');

        const showLabel = isFirstDay || (isFirstVisibleDayOfWeek && wrapTitle);
        const titleText = showLabel ? title : '&nbsp;';

        const Container = isSkipped ? SkippedSlot : eventContainers[eventStatus || defaultStatus]; 
        const LabelComponent = Label;


        let ContainerNode = 
                <EventWrapper compressed={compressed} index={index} isFirstVisibleDayOfWeek={isFirstVisibleDayOfWeek} days={days} slotHeight={slotHeight}>
                    <Container
                        updating={updating}
                        compressed={compressed}
                        days={days}
                        slotHeight={slotHeight}
                        innerRef={instance => connectDragSource(instance)}
                        isSelected={isSelected}
                        isSkipped={isSkipped}
                        isFirstDay={isFirstDay}
                        isFirstVisibleDayOfWeek={isFirstVisibleDayOfWeek}
                        isLastDay={isLastDay}
                        wrapsToNextWeek={wrapsToNextWeek}
                        wrapsFromPreviousWeek={wrapsFromPreviousWeek}
                        rowHeight={rowHeight}
                        onClick={this.handleClick}
                        onContextMenu={this.handleClick}
                        onMouseOut={onMouseOut.bind(...this.sharedArguments)}
                        onMouseOver={onMouseOver.bind(...this.sharedArguments)}>
                            <LabelComponent isFirstVisibleDayOfWeek={isFirstVisibleDayOfWeek} index={index} span={visibleDaySpan}>{titleText}</LabelComponent>
                            
                            {this.renderSublines()}
                            {this.renderTime()}
                            {showLabel ? this.renderLowerIcons() : null}
                    </Container>
                </EventWrapper>

        return ContainerNode;

    }

    renderSublines = () => {
        const { compressed, immutableEvent } = this.props;
        const isFirstVisibleDayOfWeek = immutableEvent.get('isFirstVisibleDayOfWeek');
        const visibleDaySpan = immutableEvent.get('visibleDaySpan');
        let subtitles = immutableEvent.get('subtitles');

        if(compressed){
            return null;
        }

        if(typeof subtitles === 'object') {
            if(subtitles.scheduled) {
                subtitles = subtitles.scheduled;
            }
        }
        
        if(subtitles.length) {
            const firstTitle = subtitles[0];
            return <SmallLine isFirstVisibleDayOfWeek={isFirstVisibleDayOfWeek} span={visibleDaySpan}>{firstTitle}</SmallLine>
        }
    }

    renderTime = () => {
        const { compressed, immutableEvent } = this.props;

        if(compressed) {
            return null;
        }

        if(immutableEvent.get('eventStatus') === 'holiday') {
            return null;
        }

        const TimeComponent = Time;
        const allDay = immutableEvent.get('allDay');
        const isFirstDay = immutableEvent.get('isFirstDay');
        const isLastDay = immutableEvent.get('isLastDay');
        const days = immutableEvent.get('days');

        if(isFirstDay){
            if(allDay || !immutableEvent.has('startDate')){
                return <TimeComponent>All Day</TimeComponent>
            } else {
                if(immutableEvent.has('startDate')){
                    let startTime = moment(immutableEvent.get('startDate'));
                    let endTime;
                    if(immutableEvent.has('endDate')){
                        endTime = moment(immutableEvent.get('endDate'));
                        const testFormat = 'MM-DD-YYYY hh:mm A';
                        const testDay = 'MM-DD-YYYY';
                        if( endTime.format(testDay) !== startTime.format(testDay) || 
                            endTime.format(testFormat) === startTime.format(testFormat)){
                            //events with shorter than a minute or events ending on different days don't need end time shown on first day
                            endTime = null;
                        }
                    }

                    let noStartMeridiem = endTime && startTime.format('A') === endTime.format('A');

                    let startFormat = startTime.minute() === 0 ? 
                                              `h${noStartMeridiem ? '' : ' A'}` 
                                            : `h:mm${noStartMeridiem ? '' : ' A'}`;

                    let endFormat = endTime && endTime.minute() === 0 ? 
                                              `${noStartMeridiem ? '' : ' '}–${noStartMeridiem ? '' : ' '}h A` 
                                            : `${noStartMeridiem ? '' : ' '}–${noStartMeridiem ? '' : ' '}h:mm A` 

                    return <TimeComponent>{startTime.format(startFormat)}{endTime ? endTime.format(endFormat) : ''}</TimeComponent>
                }
            }

        } else if (isLastDay && days > 1) {
                let endTime;
                let endFormat;
                if(immutableEvent.has('endDate')){
                    endTime = moment(immutableEvent.get('endDate'));
                    endFormat = endTime.minute() === 0 ? `h A` : `h:mm A`;
                }

                let endString; 
                if(allDay) {
                    //Until End of Day
                    endString = '';
                } else if (endTime) {
                    //Ends at specific time
                    endString = `Ends at ${endTime.format(endFormat)}`
                } else {
                    //Ends anytime
                    endString = '';
                }

                return <TimeComponent style={{float: 'right'}}>{endString}</TimeComponent>;

        } else {

        }

    }

    renderLowerIcons = () => {
        const { compressed, immutableEvent } = this.props;

        if(compressed) {
            return null;
        }

        if(immutableEvent.get('eventStatus') === 'holiday') {
            return null;
        }

        const iconData = immutableEvent.has('lowerIcons') ? immutableEvent.get('lowerIcons') : [];

        let imageStyle = {
            width: '18px',
            height: '18px',
            borderRadius: '3px',
            background: 'lightgray',
        };

        let icons = iconData.map(i => {
            if(i.imageURL) {
                return <img style={imageStyle} src={i.imageURL} alt={i.altText || ''} />
            } else {
                return <div style={imageStyle}>&nbsp;</div>
            }
        });

        return icons;
    }

    renderIcon = () => {
        const { immutableEvent, defaultStatus, eventIcons } = this.props;
        const eventStatus = immutableEvent.get('eventStatus');
        const isFirstDay = immutableEvent.get('isFirstDay');

        if(isFirstDay){
            const Icon =  eventIcons[eventStatus || defaultStatus];
            if(Icon) {
                return <Icon eventData={immutableEvent} /> 
            }
        }

        return null;
    }

    
}

Event.propTypes = propTypes;
Event.defaultProps = defaultProps;

export default DragSource('EVENT', eventSource, collect)(Event);
