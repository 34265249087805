import React from 'react';
import { Card, Button, Icon, DatePicker, TimePicker, Input, Checkbox, InputNumber, Radio, List, Upload, Select } from 'antd';
import Autocomplete from './AutoComplete';
import ActivityLogs from './ActivityLogs';
import Options from './Options';
import OneToMany from './OneToMany';
import Relational from './Relational';
import AccessControl from './AccessControl';
import LinkedEditor from './LinkedEditor';
import DistributionEditor from './DistributionEditor';
import DataTable from './DataTable';
import moment from 'moment';
import api from 'api';
import Privileges from 'lib/constants/privileges';
import ics from 'lib/helpers/ics';
import Autosuggest from './AutoSuggest';
import numeral from 'numeral';
//import ics from 'ics';


const { TextArea } = Input;
const RadioGroup = Radio.Group;

const staticPadding = '5px 12px';


let configs = {

    activity_logs: {
        showInCreateMode: false,
        readPrivileges: [Privileges.READ_ACTIVITY_LOGS],
        inputRender: (item, values, inputRefs, user) => <ActivityLogs user={user} activities={values[item.key]} parent_id={values.id} parent_type={item.parent_type} />
    },

    access_control: {
        showInCreateMode: false,
        readPrivileges: [Privileges.READ_ACCESS_RULES], 
        inputRender: (item, values) => <AccessControl
                rulesUrl={(object) => `/access_rules`}
                object_table={item.object_table}
                values={values}
        />
    },

    collaborators: {
        showInCreateMode: false,
        readPrivileges: [Privileges.READ_COLLABORATORS], 
        inputRender: (item, values, inputRefs, user) => <Relational 
                values={values}
                type='relations'
                user={user}
                writePrivileges={[Privileges.UPDATE_COLLABORATORS]}
                placeholder='Add a User...'
                submitLabel='Add'
                addRelation={(user_id, object) => api.create('/collaborators', {user_id, object_id: object.id})}
                deleteRelation={(collaboration_id, object) => api.destroy(`/collaborators/${collaboration_id}`)}
                sort={(a,b) => new Date(a.created_at) - new Date(b.created_at)}
                relationsUrl={(object) => `/collaborators?object_id=${object.id}&fields=id,type,first_name,last_name,avatar_url,user_id,created_at`}
                optionsUrl={(object) => `/lists/users`}
                optionTransform={(o) => { return {value: o.id, label: [o.first_name, o.last_name].join(' ')} }}
                relationTransform={(r) => { return {value: r.id, label: [r.first_name, r.last_name].join(' '), created_at: r.created_at} }} />
    },

    notifications: {
        showInCreateMode: false,
        readPrivileges: [Privileges.READ_NOTIFICATION_USERS], 
        inputRender: (item, values, inputRefs, user) => <Relational 
                values={values}
                type='relations'
                user={user}
                writePrivileges={[Privileges.UPDATE_NOTIFICATION_USERS]}
                placeholder='Add a User...'
                submitLabel='Add'
                addRelation={(user_id, object) => api.create('/notification_users', {user_id, object_id: object.id})}
                deleteRelation={(notification_id, object) => api.destroy(`/notification_users/${notification_id}`)}
                sort={(a,b) => new Date(a.created_at) - new Date(b.created_at)}
                relationsUrl={(object) => `/notification_users?object_id=${object.id}&fields=id,type,first_name,last_name,avatar_url,user_id,created_at`}
                optionsUrl={(object) => `/lists/users`}
                optionTransform={(o) => { return {value: o.id, label: [o.first_name, o.last_name].join(' ')} }}
                relationTransform={(r) => { return {value: r.id, label: [r.first_name, r.last_name].join(' '), created_at: r.created_at} }} />
    },
    autocomplete: {
        inputRender: (item, values) => { 
            return <Autocomplete 
                valueLabel={values ? (item.displayFunc ? item.displayFunc(values) : values[item.displayKey]) : null}
                suggestFormat={item.suggestFormat} 
                suggestFields={item.suggestFields} 
                suggestUrl={item.suggestUrl} 
                placeholder={item.placeholder} />
        }
    },

    autosuggest: {
        inputRender: (item, values) => {
            return <Autosuggest
            />
        }
    },

    hidden: {
        showInEditMode: false,
        showInCreateMode: false,
        inputRender: (item) => null,
    },

    text: { 
        inputRender: (item) => <Input placeholder={item.placeholder} />
    },

    address: { 
        inputRender: (item) => <Input placeholder={item.placeholder} />,
        staticRender: (staticValue, item) => <span style={{padding: staticPadding}}>{staticValue}</span>
    },

    array: { 
        inputRender: (item) => <Select placeholder={item.placeholder || 'Enter comma separated values'} mode="tags" tokenSeparators={[',']} />
    },

    url: { 
        inputRender: (item) => <Input placeholder={item.placeholder} />
    },

    boolean: { 
        decoration: { valuePropName: 'checked' },
        inputRender: (item) => <Checkbox style={{marginLeft: '12px'}} />,
        staticRender: (staticValue, item) => <span style={{padding: staticPadding}}>{staticValue ? 'Yes' : 'No'}</span>
    },

    currency : {
        actionRender: (item, values, inputRefs, user) => {
            if(item.calculations) {
                return item.calculations.map(c => {
                    const { name, onClick } = c
                    const clickAction = () => {
                        onClick(item, values, inputRefs, user)
                    }
                    return <Button onClick={clickAction}><Icon type="apple" />{name}</Button>;
                });
            } else {
                return null;
            }
        },
        inputRender: (item) => <InputNumber 
            style={{width: '200px'}} 
            precision={2}
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
            parser={value => value.replace(/\$\s?|(,*)/g, '')} />
        ,

        staticRender: (staticValue, item) => <span style={{padding: staticPadding}}>{staticValue ? (item.negativeAmount ?  
            `(${numeral(parseFloat(staticValue).toFixed(2)).format('$0,0.00')})` : 
            `${numeral(parseFloat(staticValue).toFixed(2)).format('$0,0.00')}`) : '-'}</span>
    },

    radio : { 
        inputRender: (item) => <RadioGroup style={{padding: '0px 12px'}} initialValue={item.defaultValue}>
                {item.options.map((option, index) => { 
                    return <Radio key={item.key+'_'+index+'_'+option.value} value={option.value}>
                            {option.label}
                        </Radio>
                })}
            </RadioGroup>
    },

    file : { 
        inputRender: (item, values, inputRefs, user, uploadAction, removeAction, fileList) => {
            let url;
            let uploadedLinks;
            let buttonText = 'Upload File';
            if(values) {
                url = values[item.urlKey];
                if(url) {
                    buttonText = 'Upload New File';
                    uploadedLinks = <span style={{display: 'inline-block'}}><Icon type='file' style={{marginRight: '12px'}} /><a style={{marginRight: '12px'}} href={url} target='_blank'>{item.viewUploadText || 'View Uploaded File'}</a></span>
                }
            }
            
            return <div>
                    {uploadedLinks}
                    <Upload fileList={fileList} 
                        onRemove={removeAction} 
                        multiple={item.allowMultipleFiles} 
                        beforeUpload={uploadAction}>
                        <Button><Icon type="upload" />{buttonText}</Button>
                    </Upload>
                    </div>
        },
        staticRender: (staticValue, item) => {
            let url = staticValue;
            if(url) {
                return <span style={{display: 'inline-block'}}><Icon type='file' style={{marginRight: '12px'}} /><a style={{marginRight: '12px'}} href={url} target='_blank'>{'View Uploaded File'}</a></span>
            } else {
                return <span style={{display: 'inline-block'}}><Icon type='file' style={{marginRight: '12px'}} />No File</span>
            }
        }
    },

    files: { 
        showInCreateMode: false,
        inputRender: (item, values, inputRefs, user) => <LinkedEditor 
            user={user}
            values={values} 
            createLabel='Upload a File' 
            list={true}
            dataUrl={'/attachments?parent_id=:id'}
            createUrl={(values) => { return '/attachments' }}
            deleteUrl={(values) => { return '/attachments/:id' }}
            deletePrivileges={[Privileges.DELETE_ATTACHMENTS]}
            injectedValues={(values) => { return { parent_id: values.id }}}
            formFields={[
                {key: 'description', required: true },
                {key: 'attachment', required: true, type: 'file', label:'Attached File'}
            ]}
            tableProps={{
                externalURL: true,
                columns: ['description', {type: 'date', dateFormat: 'MMM D, YYYY', label: 'Added Date', key: 'created_at'}, {type: 'file', heading: 'Link', key: 'url', label: 'Open File', newTab: true}],
            }}
            {...item} />
    },

    image : { 
        inputRender: (item, values, inputRefs, user, uploadAction, removeAction, fileList) => {
            let url;
            let uploadedLinks;
            let buttonText = 'Upload Image';
            if(values) {
                url = values[item.urlKey];
                if(url) {
                    buttonText = 'Upload New Image';
                    uploadedLinks = <span style={{display: 'block'}}>
                        <a href={url} rel="noopener noreferrer" target="_blank">

                            <object data={url} width="100%" style={{maxWidth: '300px'}}> 
                                <span>{url}</span>
                            </object>
                        </a>
                        </span>
                }
            }
            
            
            return <div><Upload fileList={fileList} 
                        onRemove={removeAction} 
                        multiple={item.allowMultipleFiles} 
                        beforeUpload={uploadAction} 
                        listType="picture"><Button style={{marginRight: '12px'}}><Icon type="upload" />{buttonText}</Button>                
                    </Upload>
                    {url ? <a href={url} rel="noopener noreferrer" target="_blank">Open File in New Tab</a> : null}
                {uploadedLinks}
                </div>
        }
    },

    date: { 
        actionRender: (item, values) => {
            if(!item || !values || !values[item.key]|| !item.icsFormat) {
                return null;
            }

            let clickAction = () => {
                let { title, description, location, start_date, end_date } = item.icsFormat(values);
                var cal = ics();
                cal.addEvent(title, description, location, start_date, end_date);
                cal.download();
            }

            return <Button onClick={clickAction}><Icon type="apple" />Add to iCalendar</Button>;
        },
        inputRender: (item, values) => {
            return <DatePicker style={{marginRight: '12px'}} format={item.dateFormat || "MM/DD/YYYY"} />
        },
        staticRender: (staticValue, item) => {
            if(!staticValue) {
                return <span style={{fontStyle: 'italic', color: '#666666', padding: staticPadding}}>{item.placeholder || 'No Date'}</span>
            } else {
                return <span style={{padding: staticPadding}}>
                    {moment(staticValue).format(item.dateFormat || 'MMM DD, YYYY')}
                </span>
            }

        }
    },

    time: { 
        inputRender: (item) => <TimePicker use12Hours format="h:mm a" />,
        staticRender: (staticValue, item) => {
            if(!staticValue) {
                return <span style={{fontStyle: 'italic', color: '#666666', padding: staticPadding}}>No Time</span>
            } else {
                return <span style={{padding: staticPadding}}>{moment(staticValue).format(item.timeFormat || 'h:mm a')}</span>
            }
        }
    },

    number: { 
        inputRender: (item) => <InputNumber style={{width: '200px'}} />
    },

    longtext: {
        inputRender: (item) => <TextArea placeholder={item.placeholder} autosize={{ minRows: 3 }} />
    },

    percentage : {
        inputRender: (item) => <InputNumber style={{width: '200px'}} min={0} step={0.01} formatter={value => `${parseInt((value || 0)*100)}%` } parser={value => parseFloat(value.replace('%', ''))/100 } />,
    },

    options: { 
        inputRender: (item, values, inputRefs) => <Options {...item} values={values} inputRefs={inputRefs} />
    },

    oneToMany: { 
        decoration: { valuePropName: 'selectedRowKeys' },
        inputRender: (item, values) => <OneToMany columns={item.columns} url={item.url} relations={item.relations(values)} groupColumns={item.groupColumns} groups={item.groupedData(values)} />
    },

    relations: { 
        showInCreateMode: false,
        inputRender: (item, values) => <Relational values={values} {...item} /> 
    },

    references: { 
        showInCreateMode: false,
        inputRender: (item, values, inputRefs, user) => <LinkedEditor user={user} values={values} {...item} />
    },

    distributions: {
        showInCreateMode: false,
        inputRender: (item, values, inputRefs, user) => <DistributionEditor user={user} values={values} {...item} />

    },

    datatable: {
        showInCreateMode: false,
        inputRender: (item, values, inputRefs, user) => <DataTable user={user} values={values} {...item} /> 
    }
}


Object.keys(configs).forEach(key => {
    let template = configs[key];

    if(template.staticRender === undefined) {
        template.staticRender = (staticValue, item) => <span style={{padding: staticPadding}}>{staticValue}</span>
    }

    if(template.decoration === undefined) {
        template.decoration = {};
    }

    if(template.showInCreateMode === undefined) {
        template.showInCreateMode = true;
    }

    if(template.showInEditMode === undefined) {
        template.showInEditMode = true;
    }

});


export default configs;



