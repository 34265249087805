import React, { Component } from 'react';
import Calendar from 'components/Calendar';
import api from 'api';
import { withRouter } from 'react-router'; 
import { InterfaceContext } from 'contexts/Interface';
import moment from 'moment';
import toTitleCase from 'lib/helpers/titleCase';

// eslint-disable-next-line
const debug = require('debug')('app:TimeOffCalendar:');

class TimeOffCalendar extends Component {

    state = {
        events: {},
    }

    render(){
        const { interfaceState } = this.props;

        return <div style={{height: '1000px'}}>
            <Calendar
                selectedMonth={interfaceState.inspectionSelectedMonth}
                selectedYear={interfaceState.inspectionSelectedYear}
                onMonthChange={this.handleMonthChange}
                onEventClick={this.handleEventClick}
                loadEvents={this.fetchEvents} 
                moveEventToNewDate={() => {}}
                scheduleEventOnDate={() => {}}
                unscheduleEvent={() => {}}
            />
        </div>
    }

    closeModal = () => {
        this.setState({timeModalVisible: false});
    }

    handleMonthChange = ( month, year) => {
        const { interfaceActions } = this.props;

        interfaceActions.setInterfaceState({inspectionSelectedMonth: month, inspectionSelectedYear: year});

    }

    handleEventClick = (event) => { 
        const { history } = this.props;

        history.push(`/time_off/${event.id}`);
    }


    fetchEvents = async (start_date, end_date) => {

        start_date = moment(start_date).format("MM-DD-YYYY h:mm A");
        end_date = moment(end_date).format("MM-DD-YYYY h:mm A");

        let events = [];
        try {
            let results = await  api.get('/employee_time_off', {date_field: 'start_date', date_field_end: 'end_date', start_date, end_date});
            const data = results.body;
            events = data.map(this.formatEvent(true));
        } catch (e) {
            throw new Error(`Couldn't fetch events.`);
        }

        let holidays = [];
        try { 
            let holiday_results = await api.get('/holidays', {date_field: 'holiday_date', start_date: start_date, end_date: end_date});
            if(holiday_results.success) {
                const holiday_data = holiday_results.body;
                holidays = holiday_data.map(this.formatHoliday());
            }
        } catch (e) {
            throw new Error("Couldn't fetch holidays");
        }

        return [].concat(events, holidays);
    }

    formatHoliday = () => {

        return (h) => {

            const description = `${h.name}`;

            const businessStatus = h.business_open ? 'Open for Business' : 'Closed';

            let event = {
                id: h.id,
                title: h.name,
                subtitles: {scheduled: [businessStatus], unscheduled: []},
                eventStatus: 'holiday',
                days: 1,
                allDay: true,
                skippedDays: [0,6],
                startDate: new Date(h.holiday_date),
                endDate: new Date(h.holiday_date),
            }

            return event;
        }
    }

    iconForTimeOffType = (type) => {

        let icon;
        switch(type) {
            case 'vacation':
                icon = '⛱️'
                break;
            case 'sick':
                icon = '🤮'
                break;
            case 'no_pay':
                icon = '❌'
                break;
            case 'out_with_pay':
                icon = '💸'
                break;
            case 'vacation_payout':
                icon = '💸⛱️'
                break;
            case 'sick_payout':
                icon = '💸🤮'
                break;
            case 'sick_delete':
                icon = '🖥️';
                break;
            case 'vacation_delete':
                icon = '🖥️';
                break;
            default:
                icon = '';
                break;
        }

        return icon;
    }

    formatEvent = (scheduled = true) => {

        return (d) => {
            let eventStatus = 'generic';

            let icon = this.iconForTimeOffType(d.type);
            const title = [d.first_name, d.last_name].join(' ');
            const description = `${d.type ? icon : ''} 
                                 ${d.type ? toTitleCase(d.type) : ''}                                                      
                                 ${d.hours ? `${d.hours}hrs` : ''}`;
            let event = {
                id: d.id,
                title,
                subtitles: {scheduled: [description], unscheduled: []},
                eventStatus,
                days: Math.ceil(d.hours / 8),
                lowerIcons: [{imageURL: d.avatar_url, altText: title}]
            }

            if(scheduled) {
                event.startDate = new Date(d.start_date);
                event.endDate = new Date(d.end_date);
            }

            return event;
        }

    }

}

export default props => ( <InterfaceContext.Consumer>
    {({state, actions}) => {
        let Component = withRouter(TimeOffCalendar);
        return <Component {...props} interfaceState={state} interfaceActions={actions} />;
    }}
    </InterfaceContext.Consumer>
);
