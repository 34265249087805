import React, { Component } from 'react';
import { Icon, Avatar, Button, Badge, Table } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import titleCase from 'lib/helpers/titleCase';
import bindURL from 'lib/helpers/bindURL';
import { withRouter } from 'react-router-dom';
import { userHasPrivileges } from 'lib/helpers/renderCondition';
import './style.css';


const defaultProps = {
    collapsed: false,
    columns: [],
    data: [],
    totalResults: 0,
    resultName: 'result',
    resultNamePlural: null,
    currentPage: 1,
    pageSize: 200,
    loading: true,
    expandedCell: null,
    showHeading: true,
    showColumnHeadings: true,
    size: 'middle',
    keyFunc: (item, index) => `${item.id}_${index}`,
    rowURL: null,
    indexOffset: 0,
}


class WrappedTable extends Component {

    state = {
        errors: null,
        selectedIndex: -1,
    }

    prepareColumnsForUser = (columns, user) => {

        return columns.filter(c => user ? userHasPrivileges(c.privileges || [], user) : true).map(c => {
            if(typeof c === 'string') {
                c = {accessor: c};
            }

            let dataIndex;
            if(Array.isArray(c.accessor) ||
                  c.type === 'dateRange' ||
                   c.type === 'timeDiff' ||
                    c.type === 'multiple') {
                //by specifying null you get the entire row object so you can pull values yourself
                dataIndex = null;
            } else {
                dataIndex = c.accessor;
            }

            let column = {
                title: c.heading || titleCase(c.accessor) || titleCase(c.key),
                dataIndex,
                key: c.accessor,
                width: c.cellWidth,
                sorter: c.sortable,
                sortField: c.sortField,
            }

            let render = this.renderFunc(c);
            if(render){
                column.render = render
            }

            return column;
        });
    }


    placeholderString = (placeholder) => {
        return <span style={{fontStyle: 'italic', color: '#c6c6c6'}}>{placeholder}</span>
    }

    renderFunc = (column) => {
        let type = column.type || 'text';

        switch(type) {
            case 'text':
                return (value, record) => { 
                    if(column.valueFunc) {
                        value = column.valueFunc(record, value);
                    }
                    if(Array.isArray(column.accessor)){
                        return this.nodeForLine(value, column);
                    } else {
                        let style = {whiteSpace: 'pre-wrap'};
                        if(column.style === 'bold') {
                            style.fontWeight = 700;
                        }

                        let copyButton;
                        if(column.showCopyToClipboard){
                            style.marginRight = '10px';
                            copyButton = <Button size='small' onClick={(e) => {
                                e.stopPropagation();
                                navigator.clipboard.writeText(value);
                            }}><Icon type={'copy'}/></Button>

                        }

                        return (value && value !== '') ? <React.Fragment><span style={style}>{value}</span>{copyButton}</React.Fragment> : this.placeholderString(column.placeholder);
                    }
                }
            case 'count':
                return (value, record) => { 
                    if(column.valueFunc) {
                        value = column.valueFunc(record, value);
                    }
                    if(Array.isArray(column.accessor)){
                        return this.nodeForLine(value, column);
                    } else {
                        let style = {marginRight: '6px'};
                        if(column.style === 'bold') {
                            style.fontWeight = 700;
                        }

                        let icon;
                        if(column.icon) {
                            icon = <Icon type={column.icon} />
                        }

                        if (value && value !== '' && value !== 0 && parseInt(value) !== 0) { 
                            return <React.Fragment><span style={style}>{value}</span>{icon}</React.Fragment> 
                        } else {
                            return this.placeholderString(column.placeholder);
                        }
                    }
                }
            case 'list':
                //generalize this
                return (value, record) => {
                    if(column.valueFunc) {
                        value = column.valueFunc(record, value);
                    }

                    if(!Array.isArray(value)){
                        throw new Error('Expected array of objects for list in table');
                    } else {
                        let strings = value.map(column.itemMapper)
                        return <React.Fragment>
                            {strings.length === 0 ? column.placeholder || null : null}
                            {strings.map(s => <div style={{marginBottom: '8px'}}>
                                <Avatar style={{marginRight: '8px'}} size='small' icon='user' />
                                <span>{s}</span>
                            </div>)}
                        </React.Fragment>
                    }
                }
            case 'date':
                return (v, row) => {
                    if(!row) {
                        return 'No data';
                    }

                    let value = row[column.key || column.accessor];
                    if(column.valueFunc) {
                        value = column.valueFunc(value, row);
                    }

                    return value ? moment(value).format(column.dateFormat || 'MMM DD, YYYY') 
                                 : this.placeholderString(column.placeholder); 
            }
            case 'dateRange':
                return (v, row) => {
                    let start = row[column.startDateAccessor];
                    let end = row[column.endDateAccessor];
                    let value;
                    if(start && end) {
                        start = moment(start);
                        end = moment(end);

                        if(start.format("MM DD YYYY") === end.format("MM DD YYYY")){
                            value = `${start.format("MMM DD, YYYY")} ${start.format("h:mm")} - ${end.format("h:mm A")}`;
                        } else {
                            value = `${start.format("MMM DD, YYYY h:mm A")} - ${end.format("MMM DD, YYYY h:mm A")}`;
                        }
                    } else {
                        value = column.placeholder || null ;
                    }

                    return value;
                }
            case 'workDiff':
                return (v, row) => {
                    let keys = column.keys; 
                    let start = row[keys.startDate];
                    let end = row[keys.endDate];
                    let include_breaks = row[keys.includeBreaks];
                    let break_length = row[keys.breakLength];
                    let value;

                    if(start && end) {
                        start = new Date(start);
                        let endStart = new Date(end);
                        endStart.setHours(start.getHours());
                        endStart.setMinutes(start.getMinutes());
                        endStart.setSeconds(start.getSeconds());
                        let hoursPass = parseFloat(moment(end).diff(endStart, 'hours', true).toFixed(1));
                        let daysPass = parseFloat(moment(end).diff(start, 'days')) + 1;
                        let breakTime = include_breaks ? break_length * daysPass : 0;

                        value = (hoursPass * daysPass) - breakTime;
                    } else {
                        value = 0;
                    }

                    if(value === 0 && column.placeholder) {
                        return column.placeholder;
                    }

                    return `${value} hours`; 
                }
            case 'timeDiff':
                return (v, row) => {
                    let unitType = column.unit || 'milliseconds';
                    let start = row[column.startDateAccessor];
                    let end = row[column.endDateAccessor];
                    let value;
                    if(start && end) {
                        value = parseFloat(moment(end).diff(start, unitType, true).toFixed(1));
                    } else {
                        value = 0;
                    }

                    if(value === 0 && column.placeholder) {
                        return column.placeholder;
                    }

                    let unit = column.unit || 'milliseconds';
                    return `${value} ${unit}`; 
                }
            case 'boolean':
                return (value, record) => {
                    if(column.valueFunc) {
                        bool = column.valueFunc(record, bool);
                    } 

                    let bool = record[column.key || column.accessor];
                    if(typeof bool === 'boolean') {
                        bool = bool ? 'Yes' : 'No';
                    }
                    return bool;
                }
            case 'image':
                return (value, record) => {
                    if(column.valueFunc) {
                        value = column.valueFunc(record, value);
                    }

                    let imageStyle = { 
                        verticalAlign: 'top',
                        maxWidth: column.imageWidth || column.imageHeight || '45px',
                        maxHeight: column.imageHeight || column.imageWidth || '45px',
                        background: '#F0F0F0',
                    }
                    if(value) {
                        return <img alt='' style={imageStyle} src={value} />
                    } else {
                        imageStyle.minWidth = imageStyle.maxWidth;
                        imageStyle.minHeight = imageStyle.maxHeight;
                        return <div style={imageStyle}>&nbsp;</div>
                    }
                }
            case 'file':
                return (v, row) => {
                    let value = row[column.key];
                    if(value) {
                        return <span>
                            <Icon type='file' style={{marginRight: '12px'}} />
                            <a onClick={(e) => {e.stopPropagation();}} href={value} target='_blank'>{column.label || "Open File"}</a>
                        </span>
                    } else {

                        return <span style={{color: 'lightgray', fontStyle: 'italic'}}>None</span>
                    }
                }
            case 'button':
                return (value, record) => <Button onClick={column.onClick(record)}>{column.icon? <Icon type={column.icon} /> : null}{column.label}</Button> 
            case 'currency':
                return (value, record) => {
                    if(column.valueFunc) {
                        value = column.valueFunc(record, value);
                    }

                    let money = numeral(value).format('$0,0.00');
                    if(column.negative){
                        money = `(${value})`;
                    }
                    return money;
                }
            case 'badge':
                //const badgeProps = (column.badgePropsFunc && column.badgePropsFunc(rowData)) || {};
                //value = null; //<Badge {...badgeProps} />
                return (value, record) => {
                    let val = value;
                    if(column.valueFunc) {
                        val = column.valueFunc(record, value);
                    }

                    const { badgePropsFunc } = column;
                    const badgeProps = (badgePropsFunc && badgePropsFunc(val, record)) || null; 
                    if(badgeProps){
                        return <Badge status={badgeProps.status} text={badgeProps.text} />
                    } else if (column.placeholder) {
                        return this.placeholderString(column.placeholder);
                    } else {
                        return null;
                    }
                }
            case 'multiple':
                return (value, record) => {
                    if(column.valueFunc) {
                        value = column.valueFunc(record, value);
                    }

                    return this.nodeForMultiLine(value, column);
                }
            default:
                return null;
        }
    }

    nodeForMultiLine = (data, column) => {

        return column.rows.map(row => {
            return this.nodeForLine(data, row);
        });
    }

    nodeForLine = (data, row) => {
        if(row.valueFunc) {
            return row.valueFunc(data);
        }

        let val;
        let descriptor = typeof row.accessor === 'string' ? row.accessor : (row.heading || 'data');
        let placeholder = row.placeholder || `No ${titleCase(descriptor)}`;
        if(Array.isArray(row.accessor)){
            let vals = row.accessor.map(key => {
                return data[key];
            });

            val = vals.filter(v => v).join(row.separator || ', ');
        } else {
            switch(row.type) {
                case 'date':
                    const dateFormat = row.dateFormat || 'MM-DD-YYYY';
                    const date = data[row.accessor];
                    if(date) {
                        val = moment(data[row.accessor]).format(dateFormat);
                    } else {
                        val = placeholder;
                    }
                    break;
                default:
                    val = data[row.accessor];
                    break;
            }
        }

        let style = {display: 'block'};
        switch(row.style) {
            case 'bold':
                style.fontWeight = '700';
                break;
            case 'subtext':
                style.fontStyle = 'italic';
                style.fontSize = '12px';
                break;
            default:
                break;
        }

        if(val === placeholder){
            style.fontStyle = 'italic';
            style.color = 'lightgray';
        }

        let prefix = '';
        if(row.label && val) {
            prefix = `${row.label}: `;
        }
        return <span style={style}>{prefix}{val}</span>
    }

    render(){
        const { id, collapsed, resultName, resultNamePlural, hideSinglePagePagination, locale, loading, rowKey, onChange, currentPage, size,
                showColumnHeadings, pageSize, totalResults, data, history, bordered, titleNode, title, user} = this.props;

        let columns = this.prepareColumnsForUser(this.props.columns, user);

        let pagination;

        if(!collapsed){ 
            pagination = {
                current: currentPage,
                defaultCurrent: currentPage,
                position: 'both',
                hideOnSinglePage: hideSinglePagePagination || false,
                pageSize,
            }

            if(totalResults === 'unknown') {
                pagination.simple = true;
            } else {
                pagination.total = totalResults;
                pagination.showTotal = (total, range) => { 
                    
                    let prefix;
                    let firstResultIndex = range[0];
                    let lastResultIndex = range[1];
                    console.log("Range and total ", range, total);
                    prefix = `Showing ${firstResultIndex}-${lastResultIndex} of ${totalResults}`;
                        
                    return `${prefix} ${totalResults === 1 ? resultName : resultNamePlural || `${resultName}s`}`;
                }
            }
        } else {

            pagination = false;
        }

        const t = titleNode ? titleNode : (title ? () => title :  null);

        let rowClass = collapsed ? 'collapsed-row' : '';
        let displayedColumns = collapsed ? [] : columns;
        let displayedData = collapsed ? [{}] : data;
        let rowFunction = collapsed ? null : this.onRow;

        return <Table 
            id={id}
            title={t}
            rowClassName={rowClass}
            size={size}
            style={{wordBreak: 'normal'}}
            locale={locale}
            bordered={bordered || titleNode || title ? true : false}
            rowKey={rowKey}
            loading={loading} 
            onChange={onChange} 
            pagination={pagination} 
            onHeaderRow={c => { 
                if(showColumnHeadings === false) {
                    return {style: {display: 'none'}}
                } 
            }}
            columns={displayedColumns} 
            dataSource={displayedData} 
            onRow={rowFunction}
        />
                
        
    }

    onRow = (record) => { 
        const { history } = this.props;

        let clickable = !!this.props.rowURL;
        if(this.props.rowValidate) {
            clickable = this.props.rowValidate(record);
        } 

        let style = {};

        if (clickable) {
            style.cursor = 'pointer';
        }

        if(this.props.rowColor) {
            let color = this.props.rowColor(record);
            if(color) {
                style.backgroundColor = color;
            }
        }
    
        return { 
            style,
            onClick: clickable ? this.onClickRow(record, history) : null
        }
    }

    onClickRow = (record, routerHistory) => {
        const { rowURL } = this.props;

        if(!rowURL){
            return null;
        } else {
            let url;
            if(typeof rowURL === 'string') {
                url = bindURL(rowURL, record); 
            } else if (typeof rowURL === 'function') {
                url = bindURL(rowURL(record), record);
            } else {
                return null;
            }

            return () => {
                routerHistory.push(url);
            }
        }
    }
}

WrappedTable.defaultProps = defaultProps;

export default withRouter(WrappedTable);
