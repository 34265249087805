import React, { Component } from 'react';
import { Icon, Layout } from 'antd';
import UserMenu from './UserMenu';
import './style.css';

const { Header } = Layout;

function HeaderBar({sideMenuCollapsed, toggleCollapse}){

    return <Header className='main' style={{padding: 0, paddingRight: '16px', background: '#fff'}}>
        <Icon
          className="trigger"
          type={sideMenuCollapsed ? 'menu-unfold' : 'menu-fold'}
          onClick={toggleCollapse}
        />
        <UserMenu />
    </Header>
}

export default HeaderBar;
