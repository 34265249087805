
export default function(quote){
    const items = quote.quote_items;
    const discount = quote.discount || 0;

    let vendorDiscounts = {};
    quote.vendor_discounts.forEach(vd => {vendorDiscounts[vd.vendor_id] = vd.discount;});
    const freightRate = quote.freight_rate;

    let summary = {
        partsBasePrice: 0,
        discountAmount: 0,
        partsTotal: 0,
        partsBaseCost: 0,
        partsCostDiscounted : 0,
        servicesTotal: 0,
        servicesCost: 0,
        totalVendorDiscounts : 0,
        totalCost: 0,
    };
    
    if(items){
        items.forEach((item, index) => {
            const { unit_price, unit_cost, quantity, discountable, type } = item;
            const vendor_id = item.vendor_id;

            const baseTotal = unit_price * quantity
            const itemDiscount = discountable ?  (baseTotal * discount) : 0;
            const discountedTotal = baseTotal - itemDiscount;

            const vendorDiscount = vendorDiscounts[vendor_id] || 0;
            const unit_costDiscount = vendorDiscount ? unit_cost * vendorDiscount : 0;

            if(type === 'part'){
                summary.partsBasePrice += baseTotal;
                summary.discountAmount += itemDiscount;
                summary.partsTotal += discountedTotal;
                summary.partsBaseCost +=  (quantity * unit_cost);
                summary.partsCostDiscounted += (quantity * (unit_cost - unit_costDiscount));
                summary.totalVendorDiscounts += (unit_costDiscount * quantity);
            } else if (type === 'service') {
                summary.servicesTotal += baseTotal;
                summary.servicesCost += (unit_cost * quantity);
            } else {
                throw new Error("Unknown product type!");
            }
        });
    }

    summary.partsAndServiceTotal = (summary.partsTotal + summary.servicesTotal);
    summary.freightCost = quote.include_freight ? (summary.partsBaseCost * freightRate) : 0;
    summary.totalCost = summary.partsCostDiscounted + summary.servicesCost + summary.freightCost;
    summary.subtotal = summary.partsAndServiceTotal + summary.freightCost;
    
    const { quoted_total } = quote;
    const taxableAmount = (quoted_total > 0 && !quote.show_unit_prices) ? parseFloat(quoted_total) : summary.subtotal;
    if(quote.include_tax){
        const taxInfo = calculateTax(taxableAmount, quote.tax_region);
        Object.assign(summary, taxInfo);
    }

    summary.grandTotal = (parseFloat(taxableAmount) + (parseFloat(summary.tax) || 0));

    return summary;
};

//this must match the calculations done server side in the database
//in 2019 the surcharge tax logic was removed because splitting a quote to smaller quotes < 5k was losing money
const calculateTax = function(taxableAmount, taxRegion){
    let taxInfo = {};
    taxInfo.surcharge = 0;
    taxInfo.taxableAmount = taxableAmount;

    if(!taxRegion){
        taxRegion = 'miami-dade';
    }

    switch(taxRegion.toLowerCase()){
        case "exempt":
            taxInfo.taxRate = 0.0;
            break;
        case "notax":
            taxInfo.taxRate = 0.0;
            break;
        case "miami-dade":
            // if(taxableAmount > 5000){
            //     taxInfo.taxRate = 0.06;
            //     taxInfo.surcharge = 50;
            // } else {
                taxInfo.taxRate = 0.07;
                //}
            break;
        case "broward":
            // if(taxableAmount > 5000){
            //     taxInfo.taxRate = 0.06;
            //     taxInfo.surcharge = 50;
            // } else {
                taxInfo.taxRate = 0.07;
            // }
            break;
        default:
            throw new Error("Unknown Tax Region ", taxRegion);
    }

    taxInfo.tax = (taxableAmount * taxInfo.taxRate) + taxInfo.surcharge;
    return taxInfo;
}
