import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// eslint-disable-next-line
const debug = require('debug')('app:ModalBackground:');

const propTypes = {
    onClick: PropTypes.func.isRequired
}

const Background = styled.div`
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
    display: block; 
    height: 100vh; 
    left: 0;
    overflow: auto; 
    position: fixed; 
    top: 0;
    width: 100vw; 
    z-index: 2; 
`

class ModalBackground extends Component {

    render(){
        const { onClick } = this.props;
        return <Background onClick={onClick}/>
    }
}

ModalBackground.propTypes = propTypes;

export default ModalBackground;

