import React, { useState, useEffect} from 'react';
import api from 'api';
import { Alert, Select,  Button } from 'antd';
import ErrorMessage from 'components/ErrorMessage';
import { withRouter } from 'react-router-dom';
import Papa from 'papaparse';
import './style.css';
const Option = Select.Option;


function QuoteImportCSV({history, ...props}) {
    const { type, columns, create_url } = props;
    const { quote_number, quote_id } = props.match.params;
    const [products, setProducts] = useState([]);

    const [file, setFile] = useState(null);
    const [parsedRows, setParsedRows] = useState([]);
    const [startRow, setStartRow] = useState(1);
    const [maxColumns, setMaxColumns] = useState(0);
    const [endRowIndex, setEndRowIndex] = useState(0);
    const [columnMappings, setColumnMappings] = useState({});
    const [indexMappings, setIndexMappings] = useState({});
    const [importMeta, setImportMeta] = useState({});


    useEffect(() => {
        if(!parsedRows.length) {
            return;
        }
        let newMeta = {};
        setEndRowIndex(parsedRows.length - 1);

        let longestRow = 0;
        parsedRows.forEach((row, index) => {
            if(row.length > longestRow) {
                longestRow = row.length;
            }
            const ignore = row.filter(e => e).length === 0 ? true : false; //null rows ignored

            newMeta[index] = {
                status: 'NONE',
                error: null,
                ignore,
            };
        });


        setMaxColumns(longestRow);
        setImportMeta(newMeta);
    }, [parsedRows]);

    useEffect(() => {
        setIndexMappings(() => {
            let newMappings = {};
            Object.keys(columnMappings).forEach(key => {
                let index = columnMappings[key];
                if(!newMappings[index]) {
                    newMappings[index] = [];
                }
                newMappings[index].push(key);
            });

            return newMappings;
        });
    }, [columnMappings]);

    const getProductData = () => {

        let data = [];

        parsedRows.forEach((r,index) => {
            const meta = importMeta[index];

            if(!meta) {
                return;
            }

            if(meta.ignore || 
                (index < startRow) || 
                (index > endRowIndex)){
                data.push(false);
                return;
            }

            let vals = {};
            Object.keys(columnMappings).forEach(key => {
                vals[key] = r[columnMappings[key]];
            });

            data.push({quote_id, ...vals});
        });


        return data;
    }

    const setStatusForRowIndex = (index, newStatus) => {
        setImportMeta(old => {
            //immutable js might make it faster
            let meta = {...old};
            meta[index] = {...meta[index]};
            meta[index].status = newStatus;
            return meta;
        });
    }

    const setErrorForRowIndex = (index, message) => {
        setImportMeta(old => {
            //immutable js might make it faster
            let meta = {...old};
            meta[index] = {...meta[index]};
            meta[index].error = message;
            return meta;
        });
    }

    const toggleIgnoreForRow = (index) => {
        setImportMeta(old => {
            //immutable js might make it faster
            let meta = {...old};
            meta[index] = {...meta[index]};
            meta[index].ignore = !meta[index].ignore;
            return meta;
        });
    }

    const handleSubmit = async () => {
        const data = getProductData();

        let index = -1;
        for (const row of data) {
            index++;

            if(!row) {
                setStatusForRowIndex(index, 'SKIP');
                continue;
            }

            setStatusForRowIndex(index, 'UPLOADING');
            setErrorForRowIndex(index, null);
            let result = await api.create(create_url, row);
            if(result.success) {
                setStatusForRowIndex(index, 'SUCCESS');
            } else {
                setStatusForRowIndex(index, 'FAILED');
                if(result.status === 409) {
                    setErrorForRowIndex(index, 'Conflict: Already Exists');
                } else {
                    if(Array.isArray(result.errors)) {
                        setErrorForRowIndex(index, result.errors[0].message);
                    } else if (typeof result.errors === 'string') {
                        setErrorForRowIndex(index, result.errors);
                    } else {
                        setErrorForRowIndex(index, 'Unknown Error');
                    }
                }
            }
        }
    }

    const handleCSV = (e) => {
        const file = e.target.files[0];
        Papa.parse(file, {
            dynamicTyping: true,
            complete: (results) => {
                if(!results.data.length) {
                    return;
                }
                setFile(file);
                let resultsClean = results.data.filter(row => (row.length === 1 && !row[0]) ? false : true)
                setParsedRows(resultsClean); //resolve issue with trailing null empty lines
            }
        });
    }

    const assignColumnToIndex = async (key, index) => {
        setColumnMappings(oldMappings => {
            let newMappings = {...oldMappings};
            newMappings[key] = index;
            return newMappings;
        });

    }

    const dropDownForColumn = (column, currentIndex) => {
        const { name, key } = column;
        let columnPreviews = parsedRows && parsedRows.length ? parsedRows[0] : [];

        return <tr key={`${key}_i${currentIndex}`}>
            <td style={{paddingRight: '12px', fontWeight: '500'}}>{name}</td>
            <td>
                <Select style={{width: 220}} 
                          key={`${currentIndex}_${key}`} 
                   allowClear={true}
                     onChange={(value) => assignColumnToIndex(key, value)}>
                    {columnPreviews.map((key, index) => {
                        const option = key ? `Column ${index+1} (${key}...)` : `Column ${index+1}`;
                        return <Option  key={option.value+'_'+index} value={index}>{option}</Option>
                    })}
                </Select>
            </td> 
        </tr>
    }


    const renderUploader = () => {

        if(file) {
            return <div><p>Assign the columns from the CSV File to use for the following fields:</p>
                    <table>
                        <tbody>
                            {columns.map((c,index) => dropDownForColumn(c, index))}
                            <tr>
                                <td style={{paddingRight: '12px'}}><label>Start Row</label></td>
                                <td><input style={{marginRight: '12px'}} type="number" value={startRow} 
                                        onChange={(e) => setStartRow(e.target.value)} /></td>
                            </tr>
                            <tr>
                                <td style={{paddingRight: '12px'}}><label>End Row</label></td>
                                <td><input style={{marginRight: '12px'}} type="number" value={endRowIndex} 
                                        onChange={(e) => setEndRowIndex(e.target.value)} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

        } else {
            return <div style={{marginBottom: '12px'}}> 
                <label style={{marginRight: '12px'}} >Select a CSV File:</label>
                <input key="csvinput-key" type="file" name="fileInput" required onChange={handleCSV} /> 
            </div> 
        }


    }

    const renderStatusForIndex = (index) => {
        const status = importMeta[index].status;

        let node;
        let style = {fontStyle: 'bold', minWidth: '20px', width: '20px'};
        switch(status) {
            case 'SUCCESS':
                style['color'] = 'green';
                node = <span style={style}>SUCCESS</span>
                break;
            case 'FAILED':
                style['color'] = 'red';
                node = <span style={style}>FAILED</span>
                break;
            case 'UPLOADING':
                style['color'] = 'blue';
                node = <span style={style}>UPLOADING</span>
                break;
            case 'SKIP':
                style['color'] = 'lightgray';
                node = <span style={style}>SKIPPED</span>
                break;
            default:
                node = <span style={style}>&nbsp;</span>
                break;

        }

        return node;
    }

    const renderErrorForIndex = (index) => {
        const error = importMeta[index].error;

        let style = {border: 'none', maxWidth: '300px', fontSize:'10px'};
        if(error) {
            return <td style={style}><span style={{color: 'red'}}>{error}</span></td>;
        } else {
            return <td style={style}> </td>;
        }
    }


    const renderTopHeaders = (numColumns) => {

        let headers = [<td>&nbsp;</td>];
        for(var i = 0; i < numColumns; i++){
            let names = indexMappings[i];
            if(names && names.length) {
                names.map(s => <span>{s}</span>);
                headers.push(<td style={{color: 'white', backgroundColor:'green', textAlign: 'center', fontStyle: 'bold'}}>{names}</td>);
            } else {
                headers.push(<td> </td>);
            }
        }

        //For status and error cells
        headers.push(<td></td>);
        headers.push(<td></td>);

        return headers;
    }

    const renderTopIndexes = (numColumns) => {

        let headers = [<td>&nbsp;</td>];
        for(var i = 0; i < numColumns; i++){
            headers.push(<td style={{textAlign: 'center', fontStyle: 'bold'}}>{i+1}</td>);
        }

        //For status and error cells
        headers.push(<td></td>);
        headers.push(<td></td>);

        return headers;
    }

    const renderCellForRowIndex = (index) => {

        let style = {border: 'none'};

        if(index === parseInt(startRow) || index === parseInt(endRowIndex)) {
            style.backgroundColor = 'green';
            style.color = 'white';
            style.fontStyle = 'bold';
        }

        let desc = index === parseInt(startRow) ? ':START' : index === parseInt(endRowIndex) ? ':END' : null;

        return <td style={style}>{index}<span>{desc}</span></td>
    }

    const renderCSV = () => {
        if(parsedRows && parsedRows.length) {
            return <React.Fragment>
                <span>Click to add or remove rows from the import.</span>
                <table className='csvit'>
                <thead>
                    <tr className='csvithead'>
                        {renderTopIndexes(maxColumns)}
                    </tr>
                    <tr className='csvithead'>
                        {renderTopHeaders(maxColumns)}
                    </tr>

                </thead>
                <tbody>
                    {parsedRows.map((r,rowIndex) => {
                        let meta = importMeta[rowIndex];

                        if(!meta) {
                            return null;
                        }

                        let rowClass = 'csvitd';
                        let cellClass = null;
                        let included = false;
                        if(!meta.ignore && 
                            rowIndex >= startRow && 
                            rowIndex <= endRowIndex) {
                            cellClass = 'csvincludedrow';
                            included = true;
                        } else {
                            cellClass = 'csvexcludedrow';
                        }

                        return <tr className={rowClass} onClick={() => toggleIgnoreForRow(rowIndex)}>
                            {renderCellForRowIndex(rowIndex)}
                            {r.map((c,index) => {
                                let cl = cellClass;
                                if(included && indexMappings[index] && indexMappings[index].length) {
                                    cl = 'csvincludedcolumn csvincludedcolumn_ol';

                                    if(rowIndex === parseInt(startRow)) {
                                        cl += ' csvincludedcolumn_start';
                                    }

                                    if(rowIndex === parseInt(endRowIndex)) {
                                        cl += ' csvincludedcolumn_end';
                                    }
                                }


                                return <td className={cl}>{c}</td>
                            })}
                            <td style={{width: '75px', border: 'none'}}>{renderStatusForIndex(rowIndex)}</td>
                            {renderErrorForIndex(rowIndex)}
                        </tr>;
                    }).filter(v => v)}
                </tbody>
            </table>
        </React.Fragment>

        } else {
            return null;
        }
    }

    return <div className='projectImport'>
        <h1>Import Parts & Services</h1>
        {renderUploader()}
        <div style={{marginBottom: '12px'}}>
            <Button style={{marginRight: '12px'}} onClick={e => { history.goBack() }}>Exit</Button>
            <Button onClick={handleSubmit}>Import</Button>
        </div>
        {renderCSV()}
    </div>
}

export default withRouter(QuoteImportCSV);
