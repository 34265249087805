import colors from 'lib/constants/resourceColors';
import moment from 'moment';

export const time_off_fields = [
    { static: true,
         key: 'employee' },

    { key: 'user_id',
     type: 'hidden' },

    {key: 'type', 
       required: true, 
       inputRef: 'type',
       type: 'options', 
       options: [
        {value: 'vacation', label: 'Vacation'},
        {value: 'sick', label: 'Sick Day'},
        {value: 'no_pay', label: 'No Pay'},
        {value: 'out_with_pay', label: 'Out with Pay'},
        {value: 'vacation_payout', label: 'Paid Out Vacation Time'},
        {value: 'vacation_delete', label: 'Delete Vacation Time'},
        {value: 'sick_payout', label: 'Paid Out Sick Time'},
        {value: 'sick_delete', label: 'Delete Sick Time'}]},

    {key: 'start_date',  
        required: true,
        placeholder: 'Select a Start Date', 
        type: 'date', 
        end_date_key: 'end_date', 
        time_key: 'start_time',
        condition: {key: 'type', values: ['vacation', 'sick', 'no_pay', 'out_with_pay']}},

    {key: 'start_time',  
        required: true,
        type: 'time',
        date_key: 'start_date',
        defaultValue: moment(new Date()).hour(8).minutes(0).seconds(0),
        condition: {key: 'type', values: ['vacation', 'sick', 'no_pay', 'out_with_pay']}},

    {key: 'end_date', 
        required: true,
        placeholder: 'Select the End Date', 
        type: 'date', 
        start_date_key:'start_date',
        time_key: 'end_time',
        condition: {key: 'type', values: ['vacation', 'sick', 'no_pay', 'out_with_pay']}},

    {key: 'end_time', 
        required: true,
        type: 'time',
        date_key: 'end_date',
        defaultValue: moment(new Date()).hour(17).minutes(0).seconds(0),
        condition: {key: 'type', values: ['vacation', 'sick', 'no_pay', 'out_with_pay']}},

    {key: 'hours', 
        label: 'Hours Used',
        required: true, 
        type: 'number'},

    {key: 'description', 
        required: true, 
        type: 'longtext'},
]; 

export default {
    defaults: {
        name: 'Employee Time Off',
        color: colors.timeOffEvent,
        namePlural: 'Employee Time Off',
        urlBase: '/time_off',
        dataUrlBase: '/employee_time_off',
        actions: ['view']
    },

    view: {
        title: (e) => `Time Off Entry`,
        formFields: time_off_fields,
        remarks: {
            header: (values) => values.type,
        },
    },
}
