let Privileges = {}

//NEW privileges must be added to the database
//
function addDataPrivileges(resourceName) {

    const rUpper = resourceName.toUpperCase().replace(/ /g, '_');
    const rLower = resourceName.toLowerCase().replace(/ /g, '_');

    Privileges[`DATA_READ_${rUpper}`] = `data_read_${rLower}`;
}

function addCRUDPrivileges(resourceName) {

    const rUpper = resourceName.toUpperCase().replace(/ /g, '_');
    const rLower = resourceName.toLowerCase().replace(/ /g, '_');

    Privileges[`SEARCH_${rUpper}`] = `search_${rLower}`;
    Privileges[`CREATE_${rUpper}`] = `create_${rLower}`;
    Privileges[`READ_${rUpper}`]   = `read_${rLower}`;
    Privileges[`UPDATE_${rUpper}`] = `update_${rLower}`;
    Privileges[`DELETE_${rUpper}`] = `delete_${rLower}`;
}

Privileges.ADMIN = 'superuser';
Privileges.UNLIMITED_PAGE_SIZE = 'unlimited_page_size';
addCRUDPrivileges('users');
addCRUDPrivileges('text_templates');
addCRUDPrivileges('receiving_slips');
addCRUDPrivileges('purchase_orders');
addCRUDPrivileges('app_logs');
addCRUDPrivileges('attachment_limits');
addCRUDPrivileges('phone_logs');
addCRUDPrivileges('phone_stations');

['part', 'service'].forEach(type => {
    const pluralType = type + 's';
    const upper = pluralType.toUpperCase();

    addCRUDPrivileges(pluralType);
    Privileges[`CREATE_BATCH_${upper}`] = `create_batch_${pluralType}`;
    Privileges[`UPDATE_BATCH_${upper}`] = `update_batch_${pluralType}`;
    Privileges[`DELETE_BATCH_${upper}`] = `delete_batch_${pluralType}`;
    Privileges[`READ_${upper}_COST`] = `read_${pluralType}_unit_cost`;
    Privileges[`READ_${upper}_PRICE`] = `read_${pluralType}_unit_price`;
    Privileges[`WRITE_${upper}_COST`] = `write_${pluralType}_unit_cost`;
    Privileges[`WRITE_${upper}_PRICE`] = `write_${pluralType}_unit_price`;
    Privileges[`READ_${upper}_BASE_COST`] = `read_${pluralType}_base_cost`;
    Privileges[`WRITE_${upper}_BASE_COST`] = `write_${pluralType}_base_cost`;
    Privileges[`SHOW_${upper}_PRICES_IN_SEARCH`] = `read_${type}_unit_prices_in_search`;
    Privileges[`SHOW_${upper}_COSTS_IN_SEARCH`] = `read_${type}_unit_costs_in_search`;
});

addCRUDPrivileges('customers');
Privileges.WRITE_CUSTOMERS_CUSTOMER_NUMBER = 'write_customers_customer_number';
Privileges.READ_CUSTOMERS_TOTAL_OUTSTANDING_PAYMENT_REQUEST_BALANCES = 'read_customers_total_outstanding_payment_request_balances';
Privileges.READ_CUSTOMERS_TOTAL_BALANCE_TO_FINISH = 'read_customers_total_balance_to_finish';
Privileges.FILE_READ_CUSTOMER_OPEN_BALANCES = 'file_read_customer_open_balances';
Privileges.FILE_READ_CUSTOMER_OPEN_BALANCES_SIMPLE = 'file_read_customer_open_balances_simple';

addCRUDPrivileges('sites');
addCRUDPrivileges('vendors');
addCRUDPrivileges('credit_holds');
addCRUDPrivileges('remarks');

Privileges.SEARCH_SENT_EMAILS = 'search_sent_emails';
Privileges.READ_SENT_EMAILS = 'read_sent_emails';

addCRUDPrivileges('expenses');
Privileges.SEARCH_EMPLOYEE_EXPENSES = 'search_employee_expenses';
Privileges.WRITE_EXPENSES_STATUS = 'write_expenses_status';
Privileges.WRITE_EXPENSES_EXPENSE_DATE = 'write_expenses_expense_date';
Privileges.WRITE_EXPENSES_NOTES = 'write_expenses_notes';
Privileges.REVIEW_PENDING_EXPENSES = 'review_pending_expenses';

addCRUDPrivileges('expense_payouts');

addCRUDPrivileges('quotes');
Privileges.SEARCH_EMPLOYEE_QUOTES = 'search_employee_quotes';
Privileges.VIEW_QUOTE_COSTS = 'view_quote_costs';
Privileges.VIEW_QUOTE_ITEM_COST = 'view_quote_item_cost';
Privileges.VIEW_QUOTE_ITEM_MARKUP = 'view_quote_item_markup';
Privileges.MODIFY_QUOTE_INCLUDE_TAX = 'write_quotes_include_tax';
Privileges.APPLY_QUOTE_DISCOUNTS = 'write_quotes_discount';
Privileges.APPLY_QUOTE_MARKUP = 'write_quotes_terms';
Privileges.APPLY_QUOTE_VENDORS_DISCOUNT = 'apply_vendor_discounts';
Privileges.EXPORT_VENDOR_PART_LIST = 'export_vendor_part_list';
Privileges.EDIT_QUOTE_TERMS = 'write_quote_terms';
Privileges.DIGITAL_SIGNATURE = 'accept_digital_signature';
Privileges.OPEN_QUOTE_PREVIEW = 'open_quote_preview';
Privileges.CREATE_QUOTE_PDF_FILE = 'create_quote_pdfs';

addCRUDPrivileges('roles');
addCRUDPrivileges('inspection_sites');
addCRUDPrivileges('inspections');
Privileges.WRITE_INSPECTIONS_COMPLETED = 'write_inspections_completed';

addCRUDPrivileges('inspection_cards');
addCRUDPrivileges('inspection_time_blocks');
addCRUDPrivileges('invoice_recipients');
addCRUDPrivileges('invoice_batches');

Privileges.TRANSFER_INVENTORY = 'transfer_inventory';
Privileges.SEARCH_INVENTORY = 'search_inventory';

addCRUDPrivileges('inventory_locations');
addCRUDPrivileges('access_rules');

addCRUDPrivileges('employee_time_off');
Privileges.CREATE_BATCH_EMPLOYEE_TIME_OFF = 'create_batch_employee_time_off';
Privileges.UPDATE_BATCH_EMPLOYEE_TIME_OFF = 'update_batch_employee_time_off';
Privileges.DELETE_BATCH_EMPLOYEE_TIME_OFF = 'delete_batch_employee_time_off';

Privileges.SEARCH_AVAILABLE_TIME_OFF = 'search_available_time_off';
Privileges.READ_AVAILABLE_TIME_OFF   = 'read_available_time_off';

Privileges.SEARCH_USED_TIME_OFF = 'search_used_time_off';
Privileges.READ_USED_TIME_OFF   = 'read_used_time_off';

Privileges.LIST_UPCOMING_INSPECTIONS = 'list_upcoming_inspections';

addCRUDPrivileges('sales_commissions');
addCRUDPrivileges('activities');
addCRUDPrivileges('attachments');
addCRUDPrivileges('tasks');
Privileges.SEARCH_EMPLOYEE_TASKS = 'search_employee_tasks';
Privileges.SEARCH_CAD_TASKS = 'search_cad_tasks';
Privileges.SEARCH_INSPECTION_TASKS = 'search_inspection_tasks';
Privileges.SEARCH_INSTALLATION_TASKS = 'search_installation_tasks';
Privileges.SEARCH_KNOX_TASKS = 'search_knox_tasks';
Privileges.SEARCH_PERMITTING_TASKS = 'search_permitting_tasks';
addCRUDPrivileges('task_types');
addCRUDPrivileges('projects');
Privileges.SEARCH_EMPLOYEE_PROJECTS = 'search_employee_projects';
Privileges.READ_PROJECTS_COMMISSION = 'view_project_commission';
Privileges.WRITE_PROJECTS_COMMISSION = 'write_project_commission';
Privileges.READ_PROJECTS_RETAINER = 'view_project_retainer';
Privileges.WRITE_PROJECTS_RETAINER = 'write_project_retainer';
Privileges.READ_PROJECTS_ORIGINAL_CONTRACT_SUM = 'view_project_original_contract_sum';
Privileges.WRITE_PROJECTS_ORIGINAL_CONTRACT_SUM = 'write_project_original_contract_sum';
Privileges.READ_EQUIPMENT_RELEASES_EQUIPMENT_RELEASED_BASE_COST = 'view_total_equipment_released_base_cost';
Privileges.READ_PROJECTS_CONTRACT_SUM_TO_DATE = 'view_project_contract_sum_to_date';
Privileges.READ_PROJECTS_TOTAL_EARNED_RETAINER = 'view_project_total_earned_retainer';
Privileges.READ_PROJECTS_TOTAL_PROJECT_BASE_COST = 'view_project_total_project_base_cost';
Privileges.READ_PROJECTS_TOTAL_EQUIPMENT_RELEASED_BASE_COST = 'view_project_total_equipment_released_base_cost';
Privileges.READ_PROJECTS_BALANCE_TO_FINISH = 'view_project_balance_to_finish';
Privileges.READ_PROJECTS_OUTSTANDING_PAYMENT_REQUESTS = 'view_project_outstanding_payment_requests';
Privileges.READ_PROJECTS_MATERIALS_HISTORY = 'view_project_materials_history';
Privileges.READ_PROJECTS_SERVICES_HISTORY = 'view_project_services_history';
Privileges.READ_PROJECTS_HISTORY = 'view_project_history';
Privileges.READ_PROJECTS_ATTACHMENTS = 'view_project_attachments';
Privileges.READ_PROJECTS_COMPANY_PURCHASE_ORDERS = 'view_project_company_purchase_orders';
Privileges.READ_PROJECTS_PICKING_REQUESTS = 'view_project_picking_requests';
Privileges.READ_PROJECTS_PAYMENT_REQUESTS = 'view_project_payment_requests';
Privileges.READ_PROJECT_RECEIVED_PAYMENTS = 'view_project_received_payments';
Privileges.LIST_CUSTOMER_PROJECTS = 'list_customer_projects';
Privileges.LIST_SITE_PROJECTS = 'list_site_projects';
Privileges.LIST_PROJECT_NAMES = 'list_project_names';
Privileges.LIST_OPEN_PROJECT_NAMES = 'list_open_project_names';
Privileges.FILE_READ_PROJECT_OPEN_BALANCES = 'file_read_project_open_balances';


addCRUDPrivileges('change_orders');
addCRUDPrivileges('equipment_releases');
addCRUDPrivileges('company_purchase_orders');
addCRUDPrivileges('customer_purchase_orders');
addCRUDPrivileges('attachments');
addCRUDPrivileges('notification_users');
addCRUDPrivileges('collaborators');
addCRUDPrivileges('default_quote_terms');
addCRUDPrivileges('payment_requests');
addCRUDPrivileges('transactions');

addCRUDPrivileges('invoices');
Privileges.FILE_READ_INVOICE_RECEIVED_PAYMENTS = 'file_read_invoice_received_payments';
Privileges.FILE_READ_SALES_TAX_REPORT = 'file_read_sales_tax_report';
Privileges.FILE_READ_SALES_TAX_ERROR_REPORT = 'file_read_sales_tax_error_report';

addCRUDPrivileges('invoice_payments');
addCRUDPrivileges('bad_debts');
addCRUDPrivileges('project_packing_slips');

Privileges.FILE_READ_INSPECTIONS = 'file_read_inspections';
Privileges.FILE_READ_UPCOMING_INSPECTIONS = 'file_read_upcoming_inspections';
Privileges.IMPORT_DATA_FROM_QUOTE = 'RPC_importDataFromQuote';

addCRUDPrivileges('holidays');

Privileges.REPORT_COMPANY = 'report_company';
Privileges.REPORT_WEEK_TIME_OFF = 'report_week_time_off';
Privileges.REPORT_PHONE_LOGS = 'report_phone_logs';
addDataPrivileges('phone_logs_weeks');
addDataPrivileges('phone_logs_months');
addDataPrivileges('phone_logs_days');
addDataPrivileges('approved_quotes');
addDataPrivileges('employee_time_off');
addDataPrivileges('employee_tasks');
addDataPrivileges('employee_quotes');
addDataPrivileges('employee_projects');
addDataPrivileges('employee_expenses');



module.exports = Privileges;

