
import colors from 'lib/constants/resourceColors';
import numeral from 'numeral';
import Privileges from '../lib/constants/privileges';

const vendorFunc = (vendor) => {
    return {label: vendor.name, value: vendor.name};
};

const form = [
    {createOnly: true, 
        key: 'product_number', 
        label: 'Part Number', 
        requiredOnCreate: true },

    {key: 'category', 
        label: 'Category'},

    {key: 'manufacturer', 
        label: 'Manufactured By'},

    {type: 'boolean', 
        key: 'discontinued'},

    {type: 'currency', 
        key: 'unit_price', 
        required: true,
        readPrivileges: [Privileges.READ_PARTS_PRICE], 
        writePrivileges: [Privileges.WRITE_PARTS_PRICE]},

    {type: 'boolean', 
        key: 'discountable',
    defaultValue: true},

    {type: 'currency', 
        key: 'unit_cost', 
        negativeAmount: true,
        required: true,
        label: 'Unit Cost', 
        readPrivileges: [Privileges.READ_PARTS_COST], 
        writePrivileges: [Privileges.WRITE_PARTS_COST]},

    {type: 'currency', 
        key: 'base_cost', 
        negativeAmount: true,
        required: true,
        label: 'Base Cost', 
        readPrivileges: [Privileges.READ_PARTS_BASE_COST], 
        writePrivileges: [Privileges.WRITE_PARTS_BASE_COST]},

    {type: 'options', 
        key: 'vendor', 
        label: 'Vendor', 
        link: {url: '/vendors/:vendor_id', privileges: Privileges.READ_VENDORS},
        optionsURL: `/lists/vendor_names`, 
        optionsMapper: vendorFunc},

    {key: 'upc_code', 
        label: 'UPC Code'},

    {type: 'url', 
        key:'image_url'},

    {type: 'longtext', 
        key: 'description'},
    '^referenceURL(Reference Sheet){reference_url}',
]

export default {
    defaults: {
        name: 'Part',
        color: colors.part,
        identifierParam: 'product_number',
        formFields: form,
        actions: ['search', 'create', 'view', 'batch'],
    },

    create: {
        urlFormatter:((part) => `/parts/${part.product_number.replace("/", "%2F")}`)
    },

    view: {
        title: (p) => p.product_number,
    },

    search: {
        fields: ['id','reference_url', 'product_number','category','description','unit_price','unit_cost','image_url','discontinued'],
        links: [
            'new',
            {url: '/parts/csv/upload', 
            title:'Upload CSV', 
            requiredPrivileges: [Privileges.CREATE_BATCH_PARTS]
            },
        ],
        tableProps: {
            rowURL: '/parts/:product_number',
            showHeading: false,
            columns: [
                {heading: 'Image', cellWidth: '130px', imageWidth: '130px', type: 'image', accessor: 'image_url'}, 
                {heading: 'Part Information', type:'multiple', rows: [
                    {heading: 'Part Number', style: 'bold', accessor:'product_number'},
                    {heading: 'Category', style: 'subtext', accessor:'category'},
                    {heading: 'Description', accessor: 'description'},
                    {heading: 'Values', type: 'multiple', directionStyle: 'horizontal', rows: [
                        {optional: true, enclosed: true, heading: 'Unit Price', type:'currency', accessor: 'unit_price'},
                        {optional: true, enclosed: true, heading: 'Unit Cost', negative:true, type:'currency', accessor:'unit_cost'},
                        {optional: true, enclosed: true, heading: 'Markup', 
                            valueFunc: (d => (typeof d.unit_cost === 'undefined' || typeof d.unit_price === 'undefined') ? 
                                        null :  numeral((d.unit_price - d.unit_cost) / d.unit_cost).format("0%"))},
                    ]}
                ]},
                {heading: 'Reference Sheet', label:'Reference Sheet', type:'file', key: 'reference_url'},
            
            ],
        }
    },

    batch: {
        defaultTemplate: {type: 'part'},
        form: [
            {key: 'product_number', label: 'Part Number', required: true},
            {key: 'description',    label: 'Description'}, 
            {key: 'manufacturer',   label: 'Manufacturer'}, 
            {key: 'vendor'  ,       label: 'Vendor'}, 
            {key: 'unit_price',     label: 'Retail Price / MSRP', type: 'float'}, 
            {key: 'unit_cost',      label: 'Unit Cost', type: 'float'}, 
            {key: 'base_cost',      label: 'Base Cost', type: 'float'}, 
            {key: 'image_url',      label: 'Image URL'}, 
            {key: 'category',       label: 'Category'}, 
            {key: 'discontinued',   label: 'Discontinued', type: 'boolean'}, 
            {key: 'upc_code',       label: 'UPC'}, 
            {key: 'discountable',   label: 'Allows Discounts'},
            {key: 'reference_url',  label: 'Reference Sheet URL'}, 
        ],
    },
}
