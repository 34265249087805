import React, { Component } from 'react';
import Calendar from 'components/Calendar';
import api from 'api';
import { withRouter } from 'react-router'; 
import { Modal } from 'antd';
import { InterfaceProvider, InterfaceContext } from 'contexts/Interface';
import * as resources from 'resources';
import Editor from 'components/Editor';
import setResourceDefaults from 'lib/helpers/setResourceDefaults';
import bindUrl from 'lib/helpers/bindURL';
import moment from 'moment';

// eslint-disable-next-line
const debug = require('debug')('app:InspectionCalendar:');

class InspectionCalendar extends Component {

    state = {
        events: {},
        timeModalVisible: false,
        timeBlockInModal: null,
    }

    timeblockResource = setResourceDefaults(resources['InspectionTimeBlock'])

    render(){
        const { interfaceState } = this.props;
        const { timeBlockInModal } = this.state;

        let site_name = timeBlockInModal ? timeBlockInModal.title : null;
        let dataUrl = '';
        if(timeBlockInModal) {
            dataUrl = bindUrl(this.timeblockResource.view.dataUrl, timeBlockInModal);
        }

        return <div style={{height: '1000px'}}>
            {this.state.timeModalVisible ? null : <h1>Inspection Calendar</h1>}
            <Modal title={site_name} visible={this.state.timeModalVisible} onCancel={this.closeModal} footer={null}>
                <Editor formActions={this.timeblockResource.defaults.formActions} 
                    embedded={true} 
                  formFields={this.timeblockResource.view.formFields} 
                     dataUrl={dataUrl} />
            </Modal>
            {this.state.timeModalVisible ? null :
            <Calendar
                selectedMonth={interfaceState.inspectionSelectedMonth}
                selectedYear={interfaceState.inspectionSelectedYear}
                onMonthChange={this.handleMonthChange}
                onEventClick={this.handleEventClick}
                onEventContextMenu={this.handleEventRightClick}
                onDockedEventClick={this.handleEventClick}
                loadEvents={this.fetchEvents} 
                moveEventToNewDate={this.handleTimeBlockMove}
                scheduleEventOnDate={this.handleScheduleNewEvent}
                unscheduleEvent={this.handleUnschedule}
                loadUnscheduledEvents={this.fetchUnscheduledEvents} 
                unscheduledEventsSort={(d1,d2) => d1.get('zipcode') - d2.get('zipcode')}
            />
            }
        </div>
    }

    closeModal = () => {
        this.setState({timeModalVisible: false});
    }

    handleMonthChange = ( month, year) => {
        const { interfaceActions } = this.props;

        interfaceActions.setInterfaceState({inspectionSelectedMonth: month, inspectionSelectedYear: year});

    }

    handleEventClick = (event) => { 
        const { history } = this.props;

        history.push(`/inspections/${event.inspection_id}`);
    }

    handleEventRightClick = (event) => {
        this.setState({timeModalVisible: true, timeBlockInModal: event});
    }


    handleUnschedule = (id) => {

        return this.handleTimeBlockMove(id, null, null);
    }

    handleScheduleNewEvent = (id, newStart, newEnd, event) => {


        let adjustedStart = new Date(newStart);
        let adjustedEnd = new Date(newEnd);

        //TODO: Do this without string comparison or hardcoded array
        if(event.subtitles[0] === '4 hour inspection') {
            adjustedEnd.setHours(12);
        }

        return this.handleTimeBlockMove(id, adjustedStart, adjustedEnd);


    }

    handleTimeBlockMove = (id, newStart, newEnd) => {

        return api.update(`/inspection_time_blocks/${id}`, {scheduled_date: newStart, scheduled_end_date: newEnd})
            .then(result => {
                if(result.status === 200 || result.status === 204) {
                    return true;
                } else {
                    return false;
                }
            })
            .catch(error => {
                return false;
            });
    }

    fetchUnscheduledEvents = async (startDate, endDate, sortOption) => {

        return api.get('/inspection_time_blocks', {is_scheduled: false, sort: 'location'}) 
        .then(results => {
            const data = results.body;

            //let events = {};
            let fetched = data.map(this.formatEvent(false));

                // fetched.forEach(d => {
                //     events[d.id] = d;
                // });

            return fetched;
        })
    }

    fetchEvents = async (start_date, end_date) => {

        start_date = moment(start_date).format("MM-DD-YYYY h:mm A");
        end_date = moment(end_date).format("MM-DD-YYYY h:mm A");

        let events = [];
        try {
            let results = await  api.get('/inspection_time_blocks', {is_scheduled: true, date_field: 'scheduled_date', date_field_end: 'scheduled_end_date', start_date, end_date});
            const data = results.body;
            events = data.map(this.formatEvent(true));
        } catch (e) {
            throw new Error(`Couldn't fetch events.`);
        }

        let holidays = [];
        try { 
            let holiday_results = await api.get('/holidays', {date_field: 'holiday_date', start_date: start_date, end_date: end_date});
            if(holiday_results.success) {
                const holiday_data = holiday_results.body;
                holidays = holiday_data.map(this.formatHoliday());
            }
        } catch (e) {
            throw new Error("Couldn't fetch holidays");
        }

        return [].concat(events, holidays);
    }

    formatHoliday = () => {

        return (h) => {

            const description = `${h.name}`;

            const businessStatus = h.business_open ? 'Open for Business' : 'Closed';

            let event = {
                id: h.id,
                //inspection_id: d.inspection_id,
                title: h.name,
                //badges: [pendingStatus],
                subtitles: {scheduled: [businessStatus], unscheduled: []},
                eventStatus: 'holiday',
                days: 1,
                allDay: true,
                startDate: new Date(h.holiday_date),
                endDate: new Date(h.holiday_date),
            }


            return event;
        }
    }

    iconForInspectionType = (type) => {

        let icon;
        switch(type) {
            case 'Fire Alarm':
                icon = '🔥'
                break;
            case 'Elevator':
                icon = '🛗'
                break;
            case 'AOR':
                icon = '🚑'
                break;
            case 'Smoke Test':
                icon = '💨'
                break;
            default:
                icon = '';
                break;
        }

        return icon;
    }

    formatEvent = (scheduled = true) => {

        return (d) => {
            let eventStatus = this.statusFromTimeBlock(d);

            let icon = this.iconForInspectionType(d.inspection_type);
            let lowerIcons = d.technicians.map(t => {
                let name = [t.first_name, t.last_name].filter(v => v).join(' ');
                return { imageURL: t.avatar_url, altText: name };
            });
            const address = [d.address, d.address2, d.zipcode].filter(f => f !== 'null' && f !== '' && f !== null).join(', ');
            const description = `${icon} 
                                 ${d.work_hours}hrs 
                                 ${d.is_ul ? ' UL' : ''}
                                 ${d.cash_on_delivery ? ' COD': ((parseFloat(d.billed_amount) + parseFloat(d.ul_billed_amount)) > 0 ? ' $' : '')}
                                 ${d.category === 'MDCPS' ? ' MDCPS' : ''}`;
            const pendingStatus = d.pending ? {bgColor: 'red', text: 'Pending'} : null;
            let event = {
                id: d.id,
                inspection_id: d.inspection_id,
                title: d.site_name,
                badges: [pendingStatus],
                subtitles: {scheduled: [description], unscheduled: [address, description]},
                defaultEndHour: d.work_hours <= 8 ? ((8 + d.work_hours) + (d.include_breaks ? parseInt(d.break_length) : 0)) : 17,
                eventStatus,
                days: Math.ceil(d.work_hours / 8),
                lowerIcons,
            }

            if(scheduled) {
                event.startDate = new Date(d.scheduled_date);
                event.endDate = new Date(d.scheduled_end_date);
            }

            return event;
        }

    }

    statusFromTimeBlock = (d) => {

        let eventStatus = null; 
        if(d.completed) {
            return 'inactive';
        }

        if(d.contact_status ==='confirmed' && d.pending_documents) {
            return 'needsAction';
        }

        switch(d.contact_status) {
            case 'confirmed':
                eventStatus = 'confirmed';
                break;
            case 'waiting':
                eventStatus = 'pending';
                break;
            case 'unconfirmed':
                eventStatus = 'unconfirmed';
                break;
            default:
                eventStatus = 'unconfirmed';
                break;
        }

        return eventStatus;
    }
}

export default props => ( <InterfaceContext.Consumer>
    {({state, actions}) => {
        let Component = withRouter(InspectionCalendar);
        return <Component {...props} interfaceState={state} interfaceActions={actions} />;
    }}
    </InterfaceContext.Consumer>
);
