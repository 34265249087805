export default function(url, data) {

    const matches = url.match(/:(\D\w+)/g);
    let finalURL = url;

    if(matches){
        const replacements = matches.map(s => { return {param: s, value: data[s.substring(1)]} } );
        replacements.forEach(r => {
            let encodedValue = r.value ? r.value.replace("/", "%2F") : r.value;
            finalURL = finalURL.replace(r.param, encodedValue);
        });
    }

    return finalURL;
}
