import React, { Component } from 'react';
import { Icon, Checkbox, Select, Radio, DatePicker, Button, Input } from 'antd';
import ErrorMessage from 'components/ErrorMessage';
import Table from 'components/Table';
import debounce from 'lodash/debounce';
import { withRouter, useLocation } from 'react-router-dom';
import { UserContext } from 'contexts/User';
import renderCondition from 'lib/helpers/renderCondition';
import api from 'api';
//import DatePicker from 'react-datepicker';
import moment from 'moment';
import queryString from 'query-string';
//import 'react-datepicker/dist/react-datepicker.css';
import bindUrl from 'lib/helpers/bindURL';

const { RangePicker } = DatePicker;
const { Option } = Select;
const RadioGroup = Radio.Group;

const ROOT_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';


// eslint-disable-next-line
const debug = require('debug')('app:Search:');

// const propTypes = {
//     cacheKey: PropTypes.string,
//     dataUrl: PropTypes.string,
//     fields: PropTypes.array,
//     defaultFilter: PropTypes.object,
//     heading: PropTypes.string,
//     cell: PropTypes.element,
//     links: PropTypes.array,
//     tableProps: PropTypes.object, 
//     expandedTableCell: PropTypes.element,
//     currentUser: PropTypes.object,
// };

// const inputStyle = css({
//     width: '100%',
// });

const defaultProps = {
    allowDateFilter: false,
    links: null
};

class Search extends Component {

    state = {
        query: '',
        loading: true,
        error: null,
        results: [],
        totalPages: 0,
        totalResults: 0,
        searchedTerm: '',
        currentPage: 1,
        pageSize: 0,
        sorter: {},
        search_by_date: false,
        selected_date_search_field: null,
        selected_date_range_type: null,
        toggleSelections: [],
        start_date: null,
        end_date: null,
        date_range_type: "all",
        date_field: null,
        date_field_desc: null,
    }

    userCacheKey = () => {
        const { currentUser, cacheKey } = this.props;
        return cacheKey ? `${cacheKey}_${currentUser.id}` : null;
    }

    componentWillMount(){
        let cacheKey = this.userCacheKey();
        let cachedState = cacheKey ? JSON.parse(sessionStorage.getItem(cacheKey)) : null;

        let queryParam = queryString.parse(this.props.location.search);

        if(cachedState){
            this.setState(cachedState, () => {
                const { query, currentPage, pageSize, sorter } = this.state;
                this.searchResource(query, currentPage, pageSize, sorter)
            });
            sessionStorage.setItem(cacheKey, null);

            if(queryParam.search) {
                this.setState({query: queryParam.search}, () => {
                    this.searchResource(this.state.query);
                });
            }
        } else {
            if(queryParam.search) {
                this.setState({query: queryParam.search}, () => {
                    this.searchResource(this.state.query);
                });
            } else {
                this.searchResource(this.state.query);
            }
        }
    }

    clicked = (e) => {
        this.cacheState();
    }

    render(){
        const { heading, searchBy } = this.props;
        const { query } = this.state;

        let placeholder = searchBy.length ? `Search by ${searchBy.join(', ')}` : 'Search';

        return <div>
            <h1 id={'heading'}>{heading}</h1>
            {this.props.children}
            {this.linkButtons(this.state)}
                <div onClick={this.clicked}>
                    {this.filterOptions()}
                    {this.renderToggles()}
                    {this.dateRange()}
                    <Input 
                        style={{marginBottom: '12px'}} 
                        id='searchbox'
                        onFocus={this.searchFocus}
                        placeholder={placeholder}
                        prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />} 
                        value={query}
                        onChange={this.handleSearchInput}
                    />
                    {this.renderResults()}
                </div>
        </div>
    }

    searchFocus = (e) => {
        setTimeout(e.target.setSelectionRange(0, e.target.value.length), 1);
    }

    dateRangeTypes = () => {
        let types = {
            'All Time' : 'all', 
            'Custom Range' : 'custom', 
            'Last 24 Hours' : 'last_24_hours',
            'Last 7 Days' : 'last_7_days',
            'Last 30 Days' : 'last_30_days',
            'Last 90 Days' : 'last_90_days',
            'Last 365 Days' : 'last_365_days',
            'Yesterday' : 'yesterday',
            'Last Week' : 'last_week', 
            'Last Month' : 'last_month', 
            'Last Quarter' : 'last_quarter', 
            'Last Year' : 'last_year', 
            'Today' : 'today', 
            'This Week' : 'this_week', 
            'This Month' : 'this_month', 
            'This Quarter' : 'this_quarter', 
            'This Year' : 'this_year'
        }

        return types;
    }

    dateFieldOptions = () => {
        const { dateFieldOptions } = this.props;

        if(!dateFieldOptions){
            return null;
        }

        let options = {};
        dateFieldOptions.forEach((f) => {
            options[f.value] = f;
        });

        return options;
    }

    onChangeFilter = (e) => {
        const { filterOptions } = this.props;

        let value = e.target.value;
        let filters;
        filterOptions.forEach(filter => {
            if(filters){
                return;
            }


            if(filter.label === value) {
                filters = filter.filters;
            }

        });

        let newState = {selected_filter_option: value};
        this.setState(newState, this.refreshSearch);
    };

    onChangeToggles = (checkedValues) => {
        const { toggleSelections } = this.state;

        let values = [...checkedValues];
        this.setState({toggleSelections: values}, this.refreshSearch);
        this.cacheState();
    }

    filterOptions = () => {
        const { filterOptions } = this.props;

        if(filterOptions) {
            let firstItem = filterOptions[0];

            if(firstItem.options) {
                return filterOptions.map(f => this.renderfilterOptionsWithLabel(f.options, f.label));
            } else {
                return this.renderfilterOptionsWithLabel(filterOptions, 'Show');
            }
        }
    }

    renderfilterOptionsWithLabel = (filterOptions, label) => {
        const { selected_filter_option } = this.state;
        
        if(filterOptions){
            let defaultFilterIndex = 0;
            filterOptions.forEach((f, index) => {
                if(f.default) {
                    defaultFilterIndex = index;
                }
            });

            let selectedOption = selected_filter_option || filterOptions[defaultFilterIndex].label;

            return <div>
                <span style={{marginRight: 8}}>{label}:</span>
                <RadioGroup buttonStyle='solid' style={{marginBottom: '12px'}} onChange={this.onChangeFilter} name='filter_options' value={selectedOption}>
                    {filterOptions.map((option, index) => {
                    let style = selectedOption === option.label ? {fontWeight: 600} : null;
                    return <Radio style={style} id={'filterOption' + index} key={'f_radio_' + index} value={option.label}>
                            {option.label}
                        </Radio>
                    })} 
                </RadioGroup>
            </div>

        }

        return null;
    }



    renderToggles = () => {
        const { toggleOptions } = this.props;
        const { toggleSelections } = this.state;

        if(toggleOptions) {
            const options = toggleOptions.map((option, index) => {
                return {label: option.label, value: option.key};
            })

            return <div style={{marginBottom: '12px'}}><Checkbox.Group options={options} value={toggleSelections} onChange={this.onChangeToggles} /></div>
        }
    }


    dateRange = () => {
        const { start_date, end_date, search_by_date, 
                date_range_type, selected_date_search_field, selected_date_range_type } = this.state;
        const { allowDateFilter } = this.props;

        if(!allowDateFilter){
            return null;
        }

        let searchFields = null;
        let dateFieldOptions = null;
        if(this.props.dateFieldOptions){
            dateFieldOptions = this.dateFieldOptions();
            searchFields = Object.keys(dateFieldOptions);
        }
        const rangeTypes = this.dateRangeTypes();

        const startMoment = typeof start_date === 'string' ? moment(start_date) : start_date;
        const endMoment = typeof end_date === 'string' ? moment(end_date) : end_date;


        let filterByText;
        if(search_by_date && searchFields) {
            filterByText = 'Filter by';
        } else if (search_by_date) {
            filterByText = 'Filter by Date within';
        } else {
            filterByText = 'Filter by Date';
        }

        return <div style={{marginBottom: '12px'}}>
            <Checkbox id={'filter-by-date-checkbox'} checked={search_by_date} onChange={this.handleChangeSearchDates}>{filterByText}</Checkbox>
                {(search_by_date && searchFields) ? 
                    <Select value={selected_date_search_field} 
                            id={'date-type-selector'}
                            defaultActiveFirstOption={true}
                            style={{marginRight: 8, width: 180}}
                         onChange={this.handleChangeDateField}>
                         {Object.keys(dateFieldOptions).map((key, index) => {
                            const field = dateFieldOptions[key];
                            const { value, label } = field;
                            return <Option key={key+'odsfsd'} value={value}>{label}</Option>
                            })}
                    </Select>
                : null}
                {search_by_date ? 
                    <React.Fragment>
                        {searchFields ? <span style={{marginRight: 8}}>within</span> : null}
                        <Select value={selected_date_range_type || 'All Time'}
                                id={'date-range-selector'}
                                style={{marginRight: 8, width: 150}}
                                placeholder='Date Range'
                                defaultActiveFirstOption={true}
                                onChange={this.handleChangeDateRangeType}>
                            {Object.keys(rangeTypes).map((key) => {
                                const value = rangeTypes[key];
                                return <Option key={value+key} value={value}>{key}</Option>
                            })}
                        </Select>
                    </React.Fragment>
                : null}
               
                {(search_by_date && date_range_type === 'custom') ?
			        <RangePicker id={'date-range-picker'} format='MMM DD, YYYY' defaultValue={[startMoment, endMoment]} onChange={this.handleChangeDateRange} />
                : null}

        </div>
    }

    handleChangeDateField = (value) => {
        const fields = this.dateFieldOptions()
        const searchField = fields[value];
        this.setState({selected_date_search_field: value, date_field: value, date_field_desc: searchField.description}, this.refreshSearch);
    }

    handleChangeDateRangeType = (value) => {

        this.setState({selected_date_range_type: value, date_range_type: value}, this.refreshSearch);
    }

    handleChangeSearchDates = (e) => {
        debug("Check ", e.target.checked);
        this.setState({search_by_date: e.target.checked}, this.refreshSearch);
    }

	handleChangeDateRange = (dates) => {
        let start_date = dates[0].toISOString();
        let end_date = dates[1].toISOString();
        // start_date.setHour(0);
        // start_date.setMinute(0);
        // start_date.setSecond(0);
        // end_date.setHour(23);
        // end_date.setMinute(59);
        // end_date.setSecond(59);


		this.setState({start_date, end_date}, this.refreshSearch);
	}

    linkButtons = (state) => {
        const { history, links } = this.props;
        const { start_date } = this.state;

        if(!links){
            return null;
        }

        debugger;
        
        const style = {marginBottom: '12px', marginRight: '12px'};
        const buttons = links.map((link, index) => {
            let element 
            if(link.url) {
                element = <Button id={`${link.title}`}  
                                    icon={link.icon}
                                    key={`${link.title}_${index}`}
                                    style={style}
                                    onClick={() => {history.push(link.url)}}>
                                    {link.title}
                                </Button>
            } else if (link.downloadURL) {
                element = <Button id={`${link.title}`}  
                                icon='download'
                                key={`${link.title}_${index}`}
                                style={style}
                                onClick={(v) => {
                                    let url = typeof link.downloadURL === 'function' ? link.downloadURL(this.state) : link.downloadURL;
                                    window.open(`${ROOT_URL}/v1${url}`);
                                }}>
                                {link.title}
                            </Button>

            }


            return renderCondition(link.requiredPrivileges || [], element);
        });

        return buttons;
    }

    renderResults = () => {
        const {loading, results, start_date, end_date, date_range_type, currentPage, pageSize, totalResults, error} = this.state;
        
        if (error){
            return <ErrorMessage errors={error} />
        } else {
            return <React.Fragment>
                    {this.searchQueryMetaResult()}
                    {date_range_type === 'custom' && (!start_date && !end_date) ?
                        <span>Enter a valid date range.</span> 
                    :
                    <UserContext.Consumer>
                        {({state}) => {
                            return <Table
                                id={'results-table'}
                                onChange={this.handleTableChange}
                                {...this.props.tableProps}
                                rowKey={(record) => `r_${record.id}`}
                                currentPage={currentPage}
                                loading={loading}
                                pageSize={pageSize || results.length}
                                totalResults={totalResults || results.length}
                                data={results}
                                user={state.user}/>
                        }}
                    </UserContext.Consumer>
                    }
                </React.Fragment>
        }
    }

    handleTableChange = (pagination, filters, sorter) => {
        const { current } = pagination;

        //nextPageIndex = Math.max(Math.min(nextPageIndex, totalPages-1), 0);
        let page = current;
        if(JSON.stringify(sorter) !== JSON.stringify(this.state.sorter || {})) {
            page = 1;
        }
        this.searchResource(this.state.query, page, undefined, sorter);
        window.scrollTo(0, 0)

        this.cacheState();
    }


    searchQueryMetaResult = () => {
        const { loading, totalResults, searchedTerm } = this.state;
        if(loading) {
            return null;
        }

        if(totalResults <= 0){
            return <span style={{marginBottom: '12px', display: 'block'}}>
                {`No results found matching `}<span style={{fontWeight: 'bold'}}>{searchedTerm}</span>{'.'}
            </span>
        }
        
        if(searchedTerm && searchedTerm !== ''){
            return <span style={{marginBottom: '12px', display: 'block'}}>
                {`Found ${totalResults} result${totalResults === 1 ? '' : 's'} matching `}<span style={{fontWeight: 'bold'}}>{searchedTerm}</span>{'.'}
            </span>
        } else {
            return null;
        }
    }

    handleSearchInput = (e) => {
        this.setState({query: e.target.value, currentPage: 1});
        this.searchQuery(e.target.value);
    }

    searchQuery = debounce((value) => {
        this.searchResource(value, 1);
    }, 1250)

    refreshSearch = () => {
        this.searchResource(this.state.query, 1);
    }

    cacheState = () => {
        let cacheKey = this.userCacheKey();
        let copy = Object.assign({}, this.state);
        copy.results = [];
        sessionStorage.setItem(cacheKey, JSON.stringify(copy));
    }

    searchResource = (query, page=1, per_page=200, sorter) => {
        const { dataUrl, defaultFilter, currentUser, toggleOptions } = this.props;
        const { filterOptions, dataTransform, fields } = this.props;
        const { toggleSelections, selected_filter_option, selected_filters, date_field, date_range_type, search_by_date, start_date, end_date } = this.state;
        const filters = defaultFilter(currentUser);


        //this.setState({loading: true, results: [], page: 0, searchedTerm: '', totalPages: 0, totalResults: 0, error: null});
        this.setState({loading: true, results: [], page: 1, searchedTerm: '', error: null});

        const fieldParams = (fields && fields.length ) ? fields.join(',') : null;


        let searchFilters = {};
        let filterConfig;
        if(!selected_filter_option && filterOptions) {
            //if no option selected default to first filters
            filterConfig = filterOptions[0].filters;
        } else if (filterOptions) {
            filterOptions.forEach((option, index) => {
                if(filterConfig) {
                    return;
                }

                if(option.label === selected_filter_option) {
                    filterConfig = filterOptions[index].filters;
                }
            });
        } else {
            filterConfig = {};
        }

        if(filterConfig) {
            Object.keys(filterConfig).forEach(key => {
                let filter = filterConfig[key];
                if(typeof filter === 'function') {
                    searchFilters[key] = filter(currentUser);
                } else {
                    searchFilters[key] = filter;
                }
            });
        }


        //For some reason adding the toggle filters into searchFilters causes them to persist
        let toggleFilters = {};
        if(toggleSelections.length && toggleOptions.length) {
            toggleOptions.forEach(option => {
                if(toggleSelections.indexOf(option.key) !== -1) { 
                    toggleFilters[option.param] = option.value(currentUser);
                }
            })
        }

        const dateFilters = {};
        if(search_by_date){
            if(date_field){
                dateFilters.date_field = date_field;
            }

            if(date_range_type === 'custom'){
                if(start_date){
                    dateFilters.start_date = start_date;
                } 

                if(end_date){
                    dateFilters.end_date = end_date;
                }
            } else {
                debug("Search period ", date_range_type);
                dateFilters.date_period = date_range_type;
            }
        }

        const sortOrder = {};
        if(sorter && sorter.column){
            sortOrder.sort_field = sorter.column.sortField;
            sortOrder.sort_direction = sorter.order;
        }
        
        let pageIndex = page-1;
        //this.cacheState();
        this.setState({currentPage: page});
        const pageCount = per_page || 30;
        api.search(dataUrl, {query, fields: fieldParams, ...filters, ...searchFilters, ...toggleFilters, page: pageIndex, per_page: pageCount, ...dateFilters, ...sortOrder})
        .then(({headers, status, errors, body}) => {
            if(errors){
                throw errors;
            } else {
                const searchedTerm = headers['x-search-term'] || '';
                if(searchedTerm !== this.state.query.trim()){
                    //stale result
                    debug(`Ignoring result that arrived too late for search term ${searchedTerm}, current query is ${this.state.query}`);
                    return;
                } 

                const totalPages = parseInt(headers['x-total-pages']);
                const totalResults = parseInt(headers['x-total-results']);
                const currentPage = parseInt(headers['x-current-page'])+1;
                const pageSize = parseInt(headers['x-page-size']);

                if(dataTransform){
                    let mapper = dataTransform;
                    if(typeof dataTransform === 'string') {
                        //create function for dataTransform by access key in data using string value 
                        mapper = (data) => data[dataTransform]
                    }
                    body = body.map(mapper);
                }

                this.setState({searchedTerm, 
                               currentPage,
                               totalPages, 
                               pageSize,
                               sorter,
                               totalResults, 
                               loading: false, 
                               results: body, 
                               error: null});
                this.cacheState();
            }
        })
        .catch(error => {
            this.setState({loading: false, results: [], pageSize: 0, currentPage: 1, searchedTerm: '', totalPages: 0, totalResults: 0, error});
            this.cacheState();
        });


    }
}


Search.defaultProps = defaultProps;

export default withRouter(Search);
