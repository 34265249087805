import colors from 'lib/constants/resourceColors';
import Privileges from '../lib/constants/privileges';
import api from 'api';

const contactFunc = (contact) => {

    return {label: `${contact.name}${contact.email ? `, ${contact.email}` : ''}${contact.title ? ` (${contact.title})` : ''}`, 
            value: contact.id};

}


const siteFunc = (site) => {
    return {label: site.name, value: site.id}
}

const customerFunc = (customer) => {
    return {label: customer.name, value: customer.id}
}

const form = [
    {type: 'options', 
        //showDetails: true,
        required: true, 
        displayKey: 'site_name',
        createOnly: true,
        key: 'site_id', 
        label: 'Site Name', 
        link: {url: '/sites/:site_id', privileges: Privileges.READ_SITES},
        displayLabel: 'Name',
        optionsURL: `/lists/site_names`, 
        optionsMapper: siteFunc, 
        exportValuesToKeys: {billing_customer_name: 'defaultBillingId'}},

    {type: 'boolean', 
        key: 'active', 
        defaultValue: 'true',
        label: 'Active'},

    {type: 'address', 
        key:'address', 
        static: true, 
        maplink: true,
        displayOnly: true},

    {type: 'options',
        defaultValue: 'Standard',
        key: 'category',
        label: 'Category',
        options: [
            {value: 'Standard', label: 'Standard'},
            {value: 'MDCPS', label: 'MDCPS'},
        ],
    },

    {type: 'options', 
        defaultValueKey: 'defaultBillingId', 
        displayKey: 'billing_customer_name',
        key: 'billing_customer_id', 
        label: 'Billing Customer', 
        link: {url: '/customers/:billing_customer_id', privileges: Privileges.READ_CUSTOMERS},
        optionsURL: `/lists/customer_names`, 
        optionsMapper: customerFunc},

    {type: 'options',
        //showDetails: true,
        key: 'email_contact_id',
        label: 'Inspection Contact',
        optionsURL: (values, formState) => { 
            let billing_customer_id = (formState && formState.billing_customer_id) || (values && values.billing_customer_id) || null;
            let site_id = (formState && formState.site_id) || (values && values.site_id) || null;
            
            if(billing_customer_id && site_id) { 
                return `/lists/inspection_contacts?customer_id=${billing_customer_id}&site_id=${site_id}`;
            } else {
                return '';
            }
        },
        optionsMapper: contactFunc},

    {type: 'array',
        key: 'email_cc',
        label: 'Inspection CC',
        placeholder: 'Enter comma separated email addresses'},

    {type: 'radio', 
        required: true, 
        key: 'inspection_type', 
        defaultValue: 'Fire Alarm', 
        label: 'Inspection Type', 
        options: [{label: 'Fire Alarm', value: 'Fire Alarm'}, 
            {label: 'Smoke Test', value:'Smoke Test'},
            {label: 'AOR', value:'AOR'},
            {label: 'Elevator', value: 'Elevator'},
        ]},

    {type: 'radio', 
        required: true, 
        key: 'frequency', 
        defaultValue: 'yearly', 
        label: 'Frequency', 
        options: [{label: 'Yearly', value: 'yearly'}, 
                  {label: 'Semi-Annual', value:'semi annual'},
                  {label: 'Quarterly', value:'quarterly'}]},

    {type: 'currency', 
        required: true, 
        defaultValue: 0,
        key: 'billed_amount'},

    {type: 'boolean', 
        key: 'cash_on_delivery', 
        label: 'COD'},
        
    {type: 'boolean', 
        key: 'requires_po_number', 
        label: 'Requires PO#'},

    {type: 'boolean', 
        key: 'overtime'},

    {type: 'boolean', 
        static: true,
        key: 'is_ul', 
        label: 'UL Site'},

    {type: 'currency', 
        label: 'UL Billed Amount',
        required: true,
        defaultValue: 0,
        key: 'ul_billed_amount'},

    {type: 'boolean', 
        key: 'unit_access'},

    {type: 'longtext',
        key: 'notes', 
        placeholder: 'Enter any special instructions a technician should know for inspections...',
        label: 'Inspection Notes'},

    {key: 'work_hours', 
        required: true, 
        type: 'number'},

    {type: 'date', 
        required:true, 
        key: 'next_inspection_due_date', 
        label: 'Next Inspection Due'},

]

export default {
    defaults: {
        name: 'Inspection Card',
        color: colors.inspectionCard,
        formFields: form,
    },

    view: {
        title: (t) => `${t.site_name} Inspection Card`,
        remarks: {
            header: (values) => values.site_name,
        },
        formProps: {
            editPrivileges: [Privileges.UPDATE_INSPECTION_CARDS],
            deletePrivileges: [Privileges.DELETE_INSPECTION_CARDS]
        },
        formActions: [
            {   label: 'View Active Inspection',
                icon: 'schedule',
                validate: ({values}) => {
                    return !!values['next_inspection_id'];
                },
                method: async ({values, history}) => { 
                    const inspection_id = values['next_inspection_id'];
                    if(inspection_id){
                        history.push(`/inspections/${inspection_id}`);
                    }
                }
            },
            {   label: 'View Billing Customer',
                icon: 'user',
                validate: ({values}) => {
                    return !!values['billing_customer_id'];
                },
                method: async ({values, history}) => { 
                    const billing_customer_id = values['billing_customer_id'];
                    if(billing_customer_id){
                        history.push(`/customers/${billing_customer_id}`);
                    }
                }
            },
            {   label: 'View Site',
                icon: 'shop',
                validate: ({values}) => {
                    return !!values['site_id'];
                },
                method: async ({values, history}) => { 
                    const site_id = values['site_id'];
                    if(site_id){
                        history.push(`/sites/${site_id}`);
                    }
                }
            },
            {   label: 'Activate Next Inspection Now',
                icon: 'plus',
                validate: ({values}) => {
                    return !values['next_inspection_id'];
                },
                method: async ({values, history}) => { 
                    api.rpc('createNextInspectionForCard', {inspection_card_id: values.id})
                    .then(({body}) => { 
                        const { error, result } = body;

                        if(error) {
                            console.log("Failed to create inspection: ", error.message);
                        } else {
                            history.push(`/inspections/${result.inspection_id}`);
                        }
                    });
                }
            },
        ]
    },

    search: {
        allowDateFilter: true,
        fields: ['id', 'is_ul', 'active', 'inspection_type', 'frequency', 'billing_customer_name', 'address', 'address2', 'city', 'state', 'zipcode', 'site_name', 
                 'work_hours', 'last_inspection_date', 'next_inspection_due_date', 'created_at'], 
        dateFieldOptions: [{enableFutureDates: true, label: 'Next Inspection Due', value: 'next_inspection_due_date', description: 'with next inspection due'},
                           {label: 'Last Inspection Date', value: 'last_inspection_date', description: 'with the last inspection performed'},
                           ],
        filterOptions: [
            {label: 'All', filters: null},
            {label: 'Active', filters: {active: true}},
            {label: 'Inactive', filters: {active: false}},
        ],
        links: ['new'],
        tableProps: {
            rowURL: '/inspection_cards/:id',
            columns: [
                {heading: 'Address', type:'multiple', rows: [
                     {style: 'bold', accessor:'site_name'},
                     {heading: 'Address', separator: ', ', style: 'subtext', accessor:['address', 'address2', 'city', 'state', 'zipcode']}
                    ]
                },
                {heading: 'Customer', accessor: 'billing_customer_name'},
                {heading: 'UL', accessor: 'is_ul', type: 'boolean'},
                {heading: 'Type', accessor: 'inspection_type'},
                {heading: 'Last Inspection', accessor: 'last_inspection_date', type: 'date', dateFormat: 'MMM DD, YYYY'},
                {heading: 'Next Inspection Due', accessor: 'next_inspection_due_date', type: 'date', dateFormat: 'MMM DD, YYYY'},
                {heading: 'Active', accessor: 'active', type: 'boolean'},
            ]
        },
    }, 
    
}

