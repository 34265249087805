import React, { useState, useEffect } from 'react';
import api from 'api';
import './style.css';
import { withRouter } from 'react-router';
import BarChart from './BarChart';


function Dashboard() {

    const columns = ['m12','m11','m10','m9','m8','m7','m6','m5','m4','m3','m2','m1','current'];
    const sales = ['Carlos', 'Eddie', 'Abraham', 'Arley', 'Enrique', 'Rick', 'Henry'];
    return <div>

        <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
            <BarChart unit={'dollars'} 
                     title={'Projects'} 
                   dataURL={'/data/employee_projects'} 
               topValueRow={'#COUNT'}
           formatTopValues={false}
            topValueSuffix={'projects'}
                columnKeys={columns}/></div>

        <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
            <BarChart unit={'dollars'} 
                     title={'Sales Quotes'} 
                   dataURL={'/data/employee_quotes'} 
                   showSum={true}
           includeRowMatch={sales}
                columnKeys={columns}/></div>

        <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
            <BarChart unit={'dollars'} 
                     title={'Approved Sales Quotes'} 
                   dataURL={'/data/approved_quotes'} 
                   showSum={true}
           includeRowMatch={sales}
                columnKeys={columns}/></div>

        <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
            <BarChart 
                     title={'Approved Sales Quotes Count'} 
                   dataURL={'/data/approved_quotes_count'} 
                   showSum={true}
            topValueSuffix={'quotes'}
           includeRowMatch={sales}
                columnKeys={columns}/></div>

        <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
            <BarChart unit={'dollars'} 
                     title={'Service Quotes'} 
                   dataURL={'/data/employee_quotes'} 
                   showSum={true}
           excludeRowMatch={sales}
                columnKeys={columns}/></div>

        <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
            <BarChart unit={'dollars'} 
                     title={'Approved Service Quotes'} 
                   dataURL={'/data/approved_quotes'} 
                   showSum={true}
           excludeRowMatch={sales}
                columnKeys={columns}/></div>

        <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
            <BarChart 
                     title={'Approved Service Quotes Count'} 
                   dataURL={'/data/approved_quotes_count'} 
                   showSum={true}
            topValueSuffix={'quotes'}
           excludeRowMatch={sales}
                columnKeys={columns}/></div>

        <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
            <BarChart unit={'dollars'} 
                     title={'Employee Expenses'} 
                   dataURL={'/data/employee_expenses'} 
                   showSum={true}
                columnKeys={columns}/></div>

        <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
            <BarChart unit={'hours'} 
                     title={'Employee Time Off'} 
                   dataURL={'/data/employee_time_off'} 
                   showSum={true}
            topValueSuffix={'hrs'}
                columnKeys={columns}/></div>

        <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
            <BarChart unit={'tasks'} 
                     title={'Employee Tasks'} 
                   dataURL={'/data/employee_tasks'} 
                   stacked={false}
                columnKeys={columns}/></div>

    </div>

}

export default withRouter(Dashboard);

        // <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
        //     <BarChart unit={'Dollars'} 
        //              title={'Projects'} 
        //               data={projects} 
        //           labelKey={'salesperson'} 
        //         columnKeys={columns}/></div>

        // <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
        //     <BarChart unit={'Dollars'} title={'Sales Quotes'} data={salesQuotes} labelKey={'salesperson'} columnKeys={columns}/></div>

        // <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
        //     <BarChart unit={'Dollars'} title={'Approved Sales Quotes'} data={salesApprovedQuotes} labelKey={'salesperson'} columnKeys={columns}/></div>

        // <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
        //     <BarChart unit={'Dollars'} title={'Service Quotes'} data={quotes} labelKey={'salesperson'} columnKeys={columns}/></div>

        // <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
        //     <BarChart unit={'Dollars'} title={'Approved Service Quotes'} data={approvedQuotes} labelKey={'salesperson'} columnKeys={columns}/></div>

        // <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
        //     <BarChart unit={'Dollars'} title={'Employee Expenses'} data={expenses} labelKey={'employee'} columnKeys={columns}/></div>

        // <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
        //     <BarChart unit={'Hours'} title={'Employee Time Off'} data={timeOff} labelKey={'employee'} columnKeys={columns}/></div>

        // <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
        //     <BarChart title={'Tasks'} stacked={false} data={tasks} labelKey={'type'} columnKeys={columns}/></div>
