
import colors from 'lib/constants/resourceColors';
const form = [
    {key:'target_identifier', type: 'hidden', required: true, defaultValue:'DEFAULT_QUOTE_TERMS'},
    {key: 'user_id', 
     displayKey: 'user',
        label: 'Applies to User',
        type: 'autocomplete', 
        suggestFormat: (c) => { return {value: c.id, text: `${c.first_name} ${c.last_name}`} },
        suggestUrl: '/users?employed=true', 
        suggestFields: ['id', 'first_name', 'last_name']},
    {key: 'role_id', 
     displayKey: 'role',
        label: 'Applies to Role',
        type: 'autocomplete', 
        suggestFormat: (c) => { return {value: c.id, text: `${c.name}`} },
        suggestUrl: '/roles', 
        suggestFields: ['id', 'name']},
    {key: 'template_id', 
     displayKey: 'template_name',
        label: 'Default Template',
        type: 'autocomplete', 
        suggestFormat: (t) => { return {value: t.id, text: `${t.name}`} },
        suggestUrl: '/text_templates', 
        suggestFields: ['id', 'name']},
]


export default { 
    defaults: {
        name: 'Default Quote Term',
        color: colors.defaultQuoteTerms,
        dataUrlBase: '/default_user_text_templates',
        formFields: form,

    },

    search: {
        defaultFilter: () => { return { target_identifier: 'DEFAULT_QUOTE_TERMS' }},
        links: ['new'],
        searchBy: ['name', 'user', 'role', 'identifier'],
        tableProps: {
            rowURL: '/default_quote_terms/:id',
            columns: [
                {heading: 'User', accessor: 'user'},
                {heading: 'Email', accessor: 'user_email'},
                {heading: 'Role', accessor: 'role'},
                {heading: 'Quote Terms Template', accessor: 'template_name'}, 
            ],
            showHeading: false,
        }
    }




};
