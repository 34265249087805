import colors from 'lib/constants/resourceColors';
import numeral from 'numeral';
import Privileges from '../lib/constants/privileges';

const form = [
    {createOnly: true, 
        requiredOnCreate: true, 
        key: 'product_number', 
        label: 'Service Number' },

    {type: 'boolean', 
        key: 'discontinued'},
        
    {key: 'category'},

    {type: 'currency', 
        key: 'unit_price', 
        required: true,
        label: 'Hourly Rate', 
        readPrivileges: [Privileges.READ_SERVICES_PRICE], 
        writePrivileges: [Privileges.WRITE_SERVICES_PRICE]},

    {type: 'currency', 
        key: 'unit_cost', 
        negativeAmount: true,
        required: true,
        label: 'Hourly Cost', 
        readPrivileges: [Privileges.READ_SERVICES_COST], 
        writePrivileges: [Privileges.WRITE_SERVICES_COST]},

    {type: 'currency', 
        key: 'base_cost', 
        negativeAmount: true,
        required: true,
        label: 'Base Cost', 
        readPrivileges: [Privileges.READ_SERVICES_BASE_COST], 
        writePrivileges: [Privileges.WRITE_SERVICES_BASE_COST]},

    {type: 'longtext', 
        key: 'description'},

    {type: 'url', 
        key:'image_url'},
]

export default {
    defaults: {
        name: 'Service',
        color: colors.service,
        formFields: form,
        actions: ['search', 'create', 'view', 'batch'],
    },

    view: {
        title: (p) => p.product_number,
    },

    create: {
        urlFormatter:((service) => `/services/${service.product_number.replace("/", "%2F")}`)
    },

    batch: {
        defaultTemplate: {type: 'service'},
        form: [
            {key: 'product_number', label: 'Service Number', required: true},
            {key: 'description',    label: 'Description'}, 
            {key: 'unit_price',     label: 'Hourly Rate', type: 'float'}, 
            {key: 'unit_cost',      label: 'Hourly Cost', type: 'float'}, 
            {key: 'base_cost',      label: 'Hourly Base Cost', type: 'float'}, 
            {key: 'image_url',      label: 'Image URL'}, 
            {key: 'category',       label: 'Category'}, 
            {key: 'discontinued',   label: 'Discontinued', type: 'boolean'}, 
        ],
    },
    
    search: {
        fields: ['id','product_number','category','description','unit_price','unit_cost','image_url','discontinued'],
        links: [
            'new',
            {url: '/services/csv/upload', 
            title:'Upload CSV', 
            requiredPrivileges: [Privileges.CREATE_BATCH_SERVICES]
            },
        ],
        tableProps: {
            rowURL: '/services/:product_number',
            showHeading: false,
            columns: [
                {optional: true, heading: 'Image', cellWidth: '130px', imageWidth: '130px', type: 'image', accessor: 'image_url'}, 
                {heading: 'Service Information', type:'multiple', rows: [
                    {heading: 'Service Number', style: 'bold', accessor:'product_number'},
                    {optional: true, heading: 'Category', style: 'subtext', accessor:'category'},
                    {heading: 'Description', accessor: 'description'},
                    {heading: 'Values', type:'multiple', directionStyle: 'horizontal', rows: [
                        {enclosed: true, optional: true, heading: 'Unit Price', type:'currency', accessor: 'unit_price'},
                        {enclosed: true, optional: true, heading: 'Unit Cost', negative:true, type:'currency', accessor:'unit_cost'},
                        {enclosed: true, optional: true, heading: 'Markup', 
                        valueFunc: (d => (typeof d.unit_cost === 'undefined' || typeof d.unit_price === 'undefined') ? 
                                    null :  numeral((d.unit_price - d.unit_cost) / d.unit_cost).format("0%"))},
                    ]}
                ]}
            
            ],
        }
    },
    
};
