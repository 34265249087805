import React, { useState, useEffect } from 'react';
import api from 'api';
import { withRouter } from 'react-router';
import BarChart from '../Dashboard/BarChart';


function ReportPhoneLogs() {

    const phoneLoggers = ['alex','esther', 'mayte', 'wendy', 'isa', 'voicemail', 'ft desk'];


    const columns = ['m12','m11','m10','m9','m8','m7','m6','m5','m4','m3','m2','m1','current'];
    return <div>
        
        <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
            <BarChart title={'Incoming Phone Calls This Week'} 
                    dataURL={'/data/phone_logs_days'} 
            topValueSuffix={'calls'}
                   showSum={true}
           includeRowMatch={phoneLoggers}
                columnKeys={['sat','sun','mon','tue','wed','thu','fri']}/></div>

        <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
            <BarChart title={'Incoming Phone Calls Past 8 Weeks'} 
                    dataURL={'/data/phone_logs_weeks'} 
            topValueSuffix={'calls'}
                   showSum={true}
           includeRowMatch={phoneLoggers}
                columnKeys={['w7','w6','w5','w4','w3','w2','w1','current']}/></div>


        <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
            <BarChart title={'Incoming Phone Calls Past Year'} 
                    dataURL={'/data/phone_logs_months'} 
            topValueSuffix={'calls'}
                   showSum={true}
           includeRowMatch={phoneLoggers}
                columnKeys={columns}/></div>

        <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
            <BarChart title={'Outgoing Phone Calls This Week'} 
                    dataURL={'/data/phone_logs_days_outgoing'} 
            topValueSuffix={'calls'}
                        showSum={true}
           includeRowMatch={phoneLoggers}
                columnKeys={['sat','sun','mon','tue','wed','thu','fri']}/></div>

        <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
            <BarChart title={'Outgoing Phone Calls Past 8 Weeks'} 
                    dataURL={'/data/phone_logs_weeks_outgoing'} 
            topValueSuffix={'calls'}
                   showSum={true}
           includeRowMatch={phoneLoggers}
                columnKeys={['w7','w6','w5','w4','w3','w2','w1','current']}/></div>


        <div style={{margin: 'auto', marginBottom: '50px', maxWidth: '1150px'}}>
            <BarChart title={'Outgoing Phone Calls Past Year'} 
                    dataURL={'/data/phone_logs_months_outgoing'} 
            topValueSuffix={'calls'}
                   showSum={true}
           includeRowMatch={phoneLoggers}
                columnKeys={columns}/></div>


    </div>

}

export default withRouter(ReportPhoneLogs);

