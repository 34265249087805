export default function invoiceBadgeStyle(value, data) {
    const { invoice_status, voided } = data;

    debugger;
    if (voided || invoice_status === 'voided') {
        return {status: 'error', text: 'Void'};
    } else if(invoice_status === 'open'){
        return {status: 'processing', text: 'Open'}; 
    } else if (invoice_status === 'closed'){
        return {status:'success', text:'Closed'}; 
    } else if (invoice_status === 'partial') {
        return {status: 'processing', text: 'Partial'}; 
    } else if (invoice_status === 'overpaid') {
        return {status: 'error', text: 'Overpaid'}; 
    } else {
        return null;
    }
}
