import React from 'react';
import numeral from 'numeral';
const debug = require('debug')('app:MaskedInput:');

class MaskedInput extends React.Component {

    constructor(props){		
        super(props);

        this.onBlur = this.onBlur.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.maskValue = this.maskValue.bind(this);
        this.focus = this.focus.bind(this);
        this.blur = this.blur.bind(this);
        this.state = this.initialState();
        this.submitValue = this.submitValue.bind(this);
        this.unformat = this.unformat.bind(this);
    }

    initialState() {
        const { inputScale, initialValue } = this.props;

        return {
            value: (!initialValue && initialValue !== 0) ? "" :  this.maskValue(initialValue),
        };
    }

    componentWillReceiveProps(nextProps, nextState){
        if(nextProps.append !== this.props.append){
            const { value } = this.state;
            let maskedValue = this.maskValue(value, nextProps.append);
            this.setState({value: maskedValue});
        }
    }

    render(){
        const {name, integerOnly, placeholder, onUnmaskedValueChange, onMaskedValueChange, onBlur, onKeyUp, maskFormat, inputScale, initialValue, minimumValue, maximumValue, highlightFocus, showZeroAsEmptyString, append, readOnly, ...props } = this.props;

        return <input 
                   name={name}
              inputMode='numeric'
                pattern={integerOnly ? '[0-9]*' : null}
                    min={integerOnly ? 0 : null}
              className='numberInput'
                    ref={'inputRef'}
            placeholder={this.props.placeholder || ""}
                  value={this.state.value} 
                onKeyUp={this.onKeyUp}
                 onBlur={this.onBlur}
               readOnly={readOnly ? 'readonly' : null}
               onChange={this.handleChange}
                   {...props}/>
    }

    focus() {
        const input = this.refs['inputRef'];

        if(input){
            input.focus();
        }
    }

    blur() {
        const input = this.refs['inputRef'];

        if(input){
            input.blur();
        }
    }

    handleChange(e){
        const value = e.target.value;
        this.setState({value});
        
        if(this.props.onUnmaskedValueChange){
            let cleanValue = this.unformat(value);
            this.props.onUnmaskedValueChange(cleanValue); 
        }
    }

    maskValue(value, append=this.props.append){
        const { showZeroAsEmptyString, maskFormat, inputScale } = this.props;

        const cleanValue = this.unformat(value) * inputScale;

        if(showZeroAsEmptyString && cleanValue === 0){
            return '';
        }

        let maskedValue = numeral(cleanValue).format(maskFormat);

        if(append){
            maskedValue += append;
        }
        
        return maskedValue;
    }

    onKeyUp(e){
        const { value } = this.state;

        const cleanValue = this.unformat(value);
        if(this.props.onKeyUp){
            this.props.onKeyUp(e, cleanValue);
        } 

        if(e.keyCode === 13){
            this.blur();
        }
    }

    onBlur(e){
        const { value } = this.state;
        let newValue = this.submitValue(value);

        if(this.props.onBlur){
            const cleanValue = this.unformat(newValue); 
            this.props.onBlur(e, cleanValue);
        }
    }

    submitValue(value){
        const { minimumValue, maximumValue, inputScale, showZeroAsEmptyString } = this.props;

        let finalValue = this.unformat(value);

        if(typeof minimumValue === 'number'){
            finalValue = Math.max(finalValue, minimumValue);
        }

        if(typeof maximumValue === 'number'){
            finalValue= Math.min(finalValue, maximumValue);
        }

        let newValue;
        if((!finalValue || finalValue === 0) && showZeroAsEmptyString){
            newValue = '';
        } else {
            newValue = this.maskValue(finalValue);
        }

        this.setState({value: newValue});
        return newValue;
    }

    unformat(maskedValue){
        //const { inputScale } = this.props;
        let value = maskedValue.toString();

        const { inputScale } = this.props;
        const digits = value.match(/-?(\.|\d)/g) || ['0']

        return Number(digits.join('')).toFixed(2) / inputScale;
        //return numeral().unformat(maskedValue);
    }
}


// MaskedInput.propTypes = {
//     name: PropTypes.string,
//     integerOnly: PropTypes.bool,
//     placeholder: PropTypes.string,
//     onUnmaskedValueChange: PropTypes.func,
//     onMaskedValueChange: PropTypes.func,
//     onBlur: PropTypes.func,
//     onKeyUp: PropTypes.func,
//     maskFormat: PropTypes.string,
//     inputScale: PropTypes.number,
//     initialValue: PropTypes.number,
//     minimumValue: PropTypes.number,
//     maximumValue: PropTypes.number,
//     highlightFocus: PropTypes.bool,
//     showZeroAsEmptyString: PropTypes.bool,
//     append: PropTypes.string,
//     readOnly: PropTypes.bool,
// };


MaskedInput.defaultProps = {
    maskFormat: "$0,0.00",
    integerOnly: false,
    append: null,
    highlightFocus: true,
    inputScale: 1,
    showZeroAsEmptyString: false,
    initialValue: null,
    readOnly: false,
};

export default MaskedInput;
