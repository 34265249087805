import colors from 'lib/constants/resourceColors';
import parseUpdates from 'lib/helpers/parseUpdatedValues';

const object_type_suburl = function(type) {
    return `${type}s`;
}

export default {
    defaults: {
        name: 'Remark',
        color: colors.remarks,
        actions: ['search'],
    },

    search: {
        allowDateFilter: true,
        fields: ['id', 'type', 'updated_values', 'object_id', 'object_type', 'header','body','first_name','last_name','avatar_url','remarked_at'],
        tableProps: {
            rowURL: (row) => {
                let sub = object_type_suburl(row.object_type);
                return `/${sub}/${row.object_id}`;
            },
            columns: [
                {heading: 'User',   valueFunc: (d => { return `${d.first_name} ${d.last_name}` })},
                {heading: 'Subject', type: 'multiple', rows: [
                    {accessor: 'header'},
                    {style: 'subtext', accessor: 'object_type'},
                ]},
                {heading: 'Remark', valueFunc: (d) => {
                    if(d.type === 'update') {
                        return parseUpdates(d.updated_values);
                    } else {
                        return d.body;
                    }
                }},
                {heading: 'Time',   type: 'multiple', rows: [
                    {accessor: 'remarked_at', type: 'date', dateFormat: 'MM/DD/YYYY'},
                    {accessor: 'remarked_at', type: 'date', dateFormat: 'h:mm:ss A'},
                ]}
            ]
        }
    }


}
