import React, { Component } from 'react';
import RemoteSelect from '../RemoteSelect';
import { Button, List, Select } from 'antd';

const Option = Select.Option;


const defaultProps = {
    editLabel: 'Edit Details',
    editableDetails: false,
};


class Options extends Component {

    handleChange = (value, onFormValueChange) => {
        const { onChange, onCoordinatedChange } = this.props;

        onChange(value);

        if(onCoordinatedChange) {
            onCoordinatedChange(value);
        }

        // if(onFormValueChange){
        //     onFormValueChange(id, value);
        // }
    }

    render() {
        const { values, inputRefs, options, optionsURL, key, optionsMapper, 
                showDetails, value } = this.props;

        let node;
        if(optionsURL){

            let url;
            if(typeof optionsURL === 'function') {
                url = optionsURL(values, inputRefs); 
            } else {
                url = optionsURL;
            }

            node = <RemoteSelect value={value} 
                          onChange={this.handleChange} 
                          url={url} 
                          dataTransform={optionsMapper} />

        } else {
            //local options
            node = <Select allowClear value={value} onChange={this.handleChange}>
                    {options.map(({value, label}) => {
                        return <Option key={`${key}_${value}`} value={value}>{label}</Option>
                    })}
                </Select>
        }
        
        
        if(showDetails && (value !== null && value !== undefined)) {
            return <List style={{marginTop: '6px', marginBottom: '6px'}} size='small' bordered header={node} footer={this.editButton()}>
                <List.Item style={{display: 'flex'}}>
                    <span style={{flex: '1', fontWeight: '600'}}>Email</span>
                    <span style={{flex: '2'}}>Bob@mail.com</span>
                </List.Item>
                <List.Item style={{display: 'flex'}}>
                    <span style={{flex: '1', fontWeight: '600'}}>Phone</span>
                    <span style={{flex: '2'}}>3053452876</span>
                </List.Item>
            </List>
        } else {
            return node;
        }
    }

    editButton = () => {
        const { value, editableDetails, editLabel } = this.props;

        if(editableDetails || value === undefined) {
            return null;
        }

        return <div style={{display: 'flex'}}>
            <Button size='small' style={{width: 'auto'}}>{editLabel}</Button>
            <div style={{padding: '0px 8px', width: '100%'}} />
        </div>
    }

}

Options.defaultProps = defaultProps;

export default Options;
