import colors from 'lib/constants/resourceColors';

const form = [
    '!name',
    'title',
    'company',
    'phone(Office Phone)',
    'mobile',
    'fax',
    'email',
    'type',
];

export default {
    defaults: {
        name: 'Contact',
        color: colors.contacts,
        formFields: form,
    },

    view: {
        title: (c) => `${c.name} Contact Information`,
    }
}

