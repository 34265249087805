
import colors from 'lib/constants/resourceColors';
import { time_off_fields } from 'resources/timeOffEvent';
//import moment from 'moment';
//import Privileges from 'lib/Privileges';


export default {
    defaults: {
        name: 'Employee Time Off',
        color: colors.employeeTimeOff,
        namePlural: 'Employee Time Off',
        dataUrlBase: '/used_time_off',
    },

    view: {
        title: (e) => `${e.employee} Time Off`,
        enableDelete: false,
        // statistics: {
        //     fetchData: (values) => {

        //     },
        //     layout: [
        //         { },
        //         { heading: (values) => { return 'Time Used (since ' + 
        //                 moment(new Date(new Date().getFullYear(), 0, 1)).format('MMM DD, YYYY') 
        //                 + ')' },
        //           privileges: [Privileges.READ_USED_TIME_OFF],
        //           stats: [
        //               { title: 'Vacation Used'
        //                   key: '

        //           ]
        //         }
                

        //     ],

        // },
        remarks: {
            header: (values) => `${values.employee} Time Off`,
        },
        formFields: [
            {key: 'time-off',
                type: 'references',
                primaryCreate: true,
                createLabel: 'New Time Off Entry',
                createUrl: '/employee_time_off',
                modalValuesTransform: values => { return {user_id: values.user_id}; },
                dataUrl: '/used_time_off/:user_id',
                dataTransform: (data) => { 
                    return data.time_off; 
                },
                formFields: time_off_fields,
                tableProps: {
                    rowURL: '/time_off/:id',
                    columns:[
                        {heading: 'Start Date', type: 'multiple', rows: [
                            {heading: 'Start Date', type: 'date', dateFormat:'MMM DD, YYYY', accessor: 'start_date', placeholder: 'N/A'},
                            {heading: 'Start Time', type: 'date', dateFormat:'h:mm A', accessor: 'start_date', placeholder: ' '}],
                        },
                        {heading: 'End Date', type: 'multiple', rows: [
                            {heading: 'End Date', type: 'date', dateFormat:'MMM DD, YYYY', accessor:'end_date', placeholder: 'N/A'},
                            {heading: 'End Time', type: 'date', dateFormat:'h:mm A', accessor:'end_date', placeholder: ' '}],
                        },
                        {heading: 'Hours Used', accessor: 'hours'},
                        {heading: 'Type', titleize: true, accessor: 'type'},
                        {heading: 'Description', maxWidth:200, accessor: 'description'}
                    ],
                }
            }
        ],
    },


    search: {
        heading: 'Employee Time Off',
        dataTransform: (user) => Object.assign(user, {full_name: [user.first_name, user.last_name].filter(v => v).join(' ')}),
        dataUrl: '/users',
        fields: ['id','first_name','last_name'],
        tableProps: {
            rowURL: '/employee_time_off/:id',
            columns: [{heading: 'Name', accessor: 'full_name', placeholder: 'No Name'}],
            showHeading: false,
        }
    },

    
}

