
import colors from 'lib/constants/resourceColors';
import Privileges from '../lib/constants/privileges';
import api from 'api';

export default {
    defaults: {
        name: 'Equipment Release',
        color: colors.equipmentReleases,
        formFields: [
            'description',
            {key: 'total_equipment_released_base_cost', type: 'currency', static: true, label: 'Total Released Base Costs',
                readPrivileges:[Privileges.READ_EQUIPMENT_RELEASES_EQUIPMENT_RELEASED_BASE_COST]},
            {key: 'materials',
                type: 'references',
                heading: 'Materials',
                reloadPageOnCreate: true,
                createLabel: 'Add Materials',
                dataTransform: 'materials',
                dataUrl: `/equipment_releases/:id?fields=materials`,
                createUrl: (values) => { return `/equipment_releases/${values.id}/materials` },
                deleteUrl: (values) => { return `/equipment_releases/${values.id}/materials/:id` },
                tableProps: { columns: [
                    'product_number', 
                    {heading: 'Vendor', accessor: 'vendor_name', placeholder: 'Unknown'},
                    'quantity', 
                    'source_from',
                    {heading: 'Released', accessor:'released', placeholder: 'None'},
                    {heading: 'Available', accessor:'available', placeholder: 'None'}
                ] },
                formFields: [
                    {key: 'product_id', 
                     displayKey: 'product_number',
                        label: 'Part Number',
                        type: 'autocomplete', 
                        suggestFormat: (c) => { return {value: c.id, text: c.product_number} },
                        suggestUrl: '/parts?exclude_discontinued=true', 
                        suggestFields: ['id', 'product_number']},
                    {key: 'quantity' },
                    {key: 'source_from', 
                        defaultValue: 'inventory',
                        required: true,
                        label: 'Source',
                        type: 'options',
                        options: [
                            {value: 'inventory', label: 'Inventory'},
                            {value: 'purchase_order', label: 'Purchase Order'},
                        ],
                    },
                ],
            },
            {key: 'services',
                type: 'references',
                heading: 'Services',
                createLabel: 'Add Services',
                dataTransform: 'services',
                dataUrl: `/equipment_releases/:id?fields=services`,
                createUrl: (values) => { return `/equipment_releases/${values.id}/services` },
                deleteUrl: (values) => { return `/equipment_releases/${values.id}/services/:id` },
                tableProps: { columns: ['product_number', {heading:'Hours', accessor:'quantity'}, 'description'] },
                formFields: [
                    {key: 'product_id', 
                     displayKey: 'product_number',
                        label: 'Service',
                        type: 'autocomplete', 
                        suggestFormat: (c) => { return {value: c.id, text: c.product_number} },
                        suggestUrl: '/services', 
                        suggestFields: ['id', 'product_number']},
                    {key: 'quantity', label: 'Hours'},
                ],
            },
            {key: 'company_purchase_orders',
                type: 'references',
                heading: 'Purchase Orders',
                createLabel: 'Add Purchase Order',
                createPrivileges: [Privileges.CREATE_PURCHASE_ORDERS],
                readPrivileges: [Privileges.READ_PURCHASE_ORDERS],
                dataTransform: 'company_purchase_orders',
                dataUrl: `/equipment_releases/:id?fields=company_purchase_orders`,
                createUrl: (values) => { return `/equipment_releases/${values.id}/company_purchase_orders` },
                deleteUrl: (values) => { return `/equipment_releases/${values.id}/company_purchase_orders/:id` },
                injectedValues: (values) => { return {project_id: values.project_id}},
                formFields: [
                    {key: 'vendor_id', 
                     displayKey: 'vendor_name',
                        label: 'Vendor',
                        type: 'autocomplete', 
                        suggestFormat: (c) => { return {value: c.id, text: c.name} },
                        suggestUrl: '/vendors', 
                        suggestFields: ['id', 'name']},
                    {key: 'description' },
                    {key: 'amount', type: 'currency'},
                    {key: 'file_url', type: 'file'},
                ],
                tableProps: {
                    rowURL: '/company_purchase_orders/:id',
                    columns: ['po_number', 
                        {type: 'date', key:'created_at'}, 
                        {accessor: 'vendor_name', heading: 'Vendor'}, 
                        {accessor: 'amount', type: 'currency'},
                        'status']
                },
            },
            {key: 'payment_requests',
                type: 'references',
                heading: 'Payment Requests',
                createLabel: 'New Payment Request',
                createPrivileges: [Privileges.CREATE_PAYMENT_REQUESTS],
                readPrivileges: [Privileges.READ_PAYMENT_REQUESTS],
                dataTransform: 'payment_requests',
                dataUrl: `/equipment_releases/:id?fields=payment_requests`,
                createUrl: (values) => { return `/payment_requests` },
                deleteUrl: (values) => { return `/payment_requests/:id` },
                injectedValues: (values) => { return {release_id: values.id}},
                formFields: [
                    {key: 'description' },
                ],
                tableProps: {
                    rowURL: '/payment_requests/:id',
                    columns: [{type: 'date', key:'date', cellWidth: '150px'}, 
                        'ref_number',
                        'request_number', 
                        {type: 'currency', accessor: 'grand_total'},
                        'status'
                        ]
                },
            },
        ]
    },

    view: {
        title: (r) => `Equipment Release #${r.release_number}`,
        contextLinks: (release) => {
            if(release.project_id) {
                return {
                    label: 'For Project',
                    text: release.project_id,
                    url: `/projects/${release.project_id}`,
                    privilege: [Privileges.READ_PROJECTS]
                }
            } else {
                return null;
            }
        },
        formActions: [
            { label: 'Import Parts', 
             method: async ({values, form, history}) => {
                    try {
                        history.push(`/equipment_releases/${values.id}/import/parts`);
                    } catch (e) {
                        throw new Error(e);
                    }
                 },
            },
            { label: 'Import Services', 
             method: async ({values, form, history}) => {
                    try {
                        history.push(`/equipment_releases/${values.id}/import/services`);
                    } catch (e) {
                        throw new Error(e);
                    }
                 },
            },
            { label: 'Generate Purchase Orders',
                validate: ({values}) => {
                    return values['company_purchase_orders'].length === 0;
                },
                method: async({values, reload, success, error}) => {
                    const { id } = values;

                    let result;
                    try {
                        result = await api.rpc('createEquipmentReleasePurchaseOrders', {release_id: id});
                    } catch (e) {
                        throw new Error(e);
                    }

                    if(result.success) {
                        success({
                            title: 'Success', 
                            content: 'Purchase Orders created for vendors',
                            okText : 'Ok',
                        });
                    } else {
                        let error = result.errors.error;
                        console.log("Got error ", result);
                        error({
                            title: 'Error', 
                            content: error.message,
                            okText : 'Ok',
                        });
                    }

                    reload();

                },
            },
            { label: 'Generate Payment Request', 
               icon: 'file-text', 
              privileges: [Privileges.CREATE_PAYMENT_REQUESTS],
              method: async ({values, parameters, reload, loading, success, error}) => {
                    let equipment_release_id = values.id;

                    loading('Generating');
                    let result;
                    try {
                        result = await api.rpc('generatePaymentRequestFromRelease', {equipment_release_id})
                    } catch (e) {
                        throw new Error(e);
                    }
                    
                    if(result.success) {
                        success({
                            title: 'Success', 
                            content: 'A new payment request was generated from this equipment release.',
                            okText : 'Ok',
                        });
                    } else {
                        let error = result.errors.error;
                        console.log("Got error ", result);
                        error({
                            title: 'Error', 
                            content: error.message,
                            okText : 'Ok',
                        });
                    }

                    reload();
                }
            },
            { label: 'Generate Packing Slip',
                method: ({values}) => {

                    console.log("Equip ", values);
                    api.create('/pdfs/packing_slips', { equipment_release_id: values.id, filename: `Packing Slip` })
                    .then(({errors, body, headers}) => {
                        window.open(body.url, '_blank');
                    })
                    .catch(errors => {
                    })
                }
            },
        ]
    }
}

