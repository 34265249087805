import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import { css } from 'glamor';
import { Input, InputNumber } from 'antd';
//import { AutoTextArea, MaskedInput } from '..';
import { INPUT_YELLOW, FOCUS_BLUE } from 'lib/constants/colors';
import numeral from 'numeral';
import Privileges from 'lib/constants/privileges';
import renderCondition from 'lib/helpers/renderCondition';
// eslint-disable-next-line
const debug = require('debug')('app:QuoteItem:');

/*
let style = css({
    width: '250px',
    minHeight: '50px',
    border: '1px dashed #DDDDDD',
    marginBottom: '10px',
    borderRadius: '4px',
    cursor: 'move',
});

const label = css({
    fontSize: '12px',
    color: '#A9A9A9',
    fontWeight: '400 !important',

});
*/

const field = css({
    fontFamily: 'Raleway',
    width: '100%',
    backgroundColor: '#FBFBFC !important',
    '@media(min-width: 680px)': {
        marginBottom: '0px !important',
    }
});

const yellowInput = css({
    backgroundColor: (INPUT_YELLOW + ' !important'),
});

const descriptionBox = css({
    resize: 'vertical',
    minHeight: '24px',

    '@media(min-width: 680px)': {
        minHeight: '24px !important',
        marginBottom: '0px !important',
    }

});

const productNumber = css({
    fontWeight: '800',
    display: 'inline-block',
    width: '60%',
    cursor: 'default',
    float: 'left', 
    '@media(min-width: 680px)': {
        float: 'none',
        width: '100%',
    }
});

const vendorName = css({
    display: 'inline-block',
    cursor: 'default',
    width: '60%',
    fontSize: '12px',
    fontStyle: 'italic',
    float: 'left', 
    '@media(min-width: 680px)': {
        display: 'inline-block',
        width: '100%',
    }
});

const removeLabel = css({
    backgroundColor: 'lightgray',
    color: 'white',
    cursor: 'pointer',
    borderRadius: '2px',
    fontWeight: '400',
    padding: '2px 6px',
    display: 'inline-block',
    fontSize: '12px',
    marginBottom: '12px',
    float: 'right',
    '@media(min-width: 680px)': {
        float: 'none',
    }
});

const unitCostDiv = css({
    fontSize: '14px',
    textAlign: 'right',
});

const productField = css({
    width: '100px',
    '@media(min-width: 680px)': {
        width: '100%',
    }
});

const smallOnly = css({
    '@media(min-width: 680px)': {
        display: 'none',
    }
});

const largeOnly = css({
    display: 'none',
    '@media(min-width: 680px)': {
        display: 'inline',
    }
});

const productRow = css({
    display: 'block',
    textAlign: 'right',
    '@media(min-width: 680px)': {
        width: '100%',
    }
});


const columnSize = (size) => {
    return css({
        '@media(min-width: 680px)': {
            flex: size,
            margin: '0 4px',
        }
    });
};

const productCellEven = css({
    backgroundColor: '#F6F6F9',
    padding: '12px 12px 12px 12px',
    cursor: 'move',
    borderTop: '1px solid #A9A9A9',
    display: 'block',
    '@media(min-width: 680px)': {
        flexDirection: 'row',
        display: 'flex',
    }
});


const productCellOdd = css({
    backgroundColor: 'transparent',
    padding: '12px 12px 12px 12px',
    cursor: 'move',
    borderTop: '1px solid #A9A9A9',
    display: 'block',
    '@media(min-width: 680px)': {
        flexDirection: 'row',
        display: 'flex',
    }
});


const defaultProps = {
    name: 'none',
};

const source = {
    beginDrag(props){
        return {
            id: props.id,
            index: props.index
        };
    },

    endDrag(props, monitor, component){
        if(!monitor.didDrop()){
            return;
        }

        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        const isDragging = false;
		props.moveItem(dragIndex, hoverIndex, isDragging);
    }
}

const target = {
    hover(props, monitor, component){
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        if(dragIndex === hoverIndex){
            return;
        }


		// Determine rectangle on screen
		const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

		// Get vertical middle
		const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

		// Determine mouse position
		const clientOffset = monitor.getClientOffset();

		// Get pixels to the top
		const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        
		// Only perform the move when the mouse has crossed half of the items height
		// When dragging downwards, only move when the cursor is below 50%
		// When dragging upwards, only move when the cursor is above 50%

		// Dragging downwards
		if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
		  return;
		}

		// Dragging upwards
		if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
		  return;
		}

		// Time to actually perform the action
		debug("Move item " + dragIndex + " to " + hoverIndex);
        const isDragging = true;
		props.moveItem(dragIndex, hoverIndex, isDragging);

		// Note: we're mutating the monitor item here!
		// Generally it's better to avoid mutations,
		// but it's good here for the sake of performance
		// to avoid expensive index searches.
		monitor.getItem().index = hoverIndex;

    },

}

function dragCollect(connect, monitor) {
   return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    };
} 

function dropCollect(connect, monitor){
    return {
        connectDropTarget: connect.dropTarget(),
    }
}


class QuoteItem extends Component {

    constructor(props){
        super(props);

        this.state = {
           overInput: false,
        }
    } 

    render(){
        const { index, id, name, item_type, untouched, description, quantity, 
                unit_price, handleRemove, onChangeField, onBlurField, 
                connectDropTarget, isDragging, connectDragSource, vendor } = this.props;

        //const total = quantity * unit_price;
        const opacity = isDragging ? 0:1;
        const highlight = isDragging ? {border: '2px dashed', borderColor: FOCUS_BLUE , backgroundColor: "#F0FAFF"} : null;

        const mainStyle = (index % 2) ? productCellEven : productCellOdd;
        const untouched_field = untouched ? yellowInput : {};

        let element = <div style={highlight}>
                <div id='quote_item' style={{opacity}} {...mainStyle}>
                    <div {...columnSize('0.5')}>
                        <span id='product_number' {...productNumber}
                              onMouseEnter={() => this.setState({overInput: true})}
                              onMouseLeave={() => this.setState({overInput: false})}>
                            {name}
                        </span>
                        <span id='vendor_name' {...vendorName}>{vendor}</span>
                        <span id='remove' {...removeLabel} onClick={handleRemove}>Remove</span>
                    </div>
                    <div {...columnSize('0.9')}>
                        <div >
                            <Input.TextArea {...field} 
                                          {...descriptionBox}  
                                          autoSize={{minRow: 3}}
                                          id='description'
                                          name='item-desc'
                                          onMouseEnter={() => this.setState({overInput: true})}
                                          onMouseLeave={() => this.setState({overInput: false})}
                                          value={description || ""} 
                                          onChange={e => onChangeField(e.target.value, id, 'description')}
                                          onKeyDownCapture={this.onKeyUpCapture} 
                                          onBlur={e => onBlurField(e.target.value, id, 'description')} />
                        </div>
                    </div>
                    <div {...columnSize('0.5')}>
                        <span {...productRow}>
                            <span {...smallOnly}>{item_type === 'part' ? 'Quantity:' : 'Hours:'} </span>
                            <input {...field} 
                                   {...productField} 
                                   {...untouched_field}
                                   id='quantity'
                                   value={quantity} 
                                   onMouseEnter={() => this.setState({overInput: true})}
                                   onMouseLeave={() => this.setState({overInput: false})}
                                   onChange={e => onChangeField(e.target.value, id, 'quantity')}
                                   type="text" 
                                   onKeyUpCapture={this.onKeyUpCapture} 
                                   onBlur={e => onBlurField(e.target.value, id, 'quantity')} />
                                   {this.hideQuantityCheckbox()}
                        </span>
                    </div>
                    <div {...columnSize('0.5')}>
                        <span {...productRow}>
                            <span {...smallOnly}>Unit Price: </span>
                            <InputNumber 
                                   style={{width: '100%'}}
                                   min={0}
                                   formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
                                   parser={this.currencyParser}                                   
                                   id='unit_price'
                                   onMouseEnter={() => this.setState({overInput: true})}
                                   onMouseLeave={() => this.setState({overInput: false})}
                                   defaultValue={unit_price} 
                                   onKeyUp={this.onKeyUpCapture} 
                                   onBlur={(e,b) => onBlurField(this.currencyParser(e.target.value), id, 'unit_price')} />
                            {this.unitCost()}
                        </span>
                    </div>
                    <div {...columnSize('0.5')} {...largeOnly}>
                        <input {...field} 
                               {...productField} 
                               id='total_price'
                               value={numeral((quantity || 0) * (unit_price || 0)).format('$0,0.00')} 
                               readOnly="readonly" 
                               type="text" />
                    </div>
                </div>
            </div>

        if(this.state.overInput){
            return element;
        } else {
            return connectDropTarget(connectDragSource(element));
        }
    }

    currencyParser = (value) => {
        return value.replace(/\$\s?|(,*)/g, '') 
    };

    hideQuantityCheckbox = () => {

        const { id, hide_quantity, onChangeField, item_type } = this.props;

        const hideQuantityStyle = css({
            textAlign: 'right',
            '@media(min-width: 680px)': {
                textAlign: 'left'
            }
        });

        if(item_type === 'service'){
            return <div {...hideQuantityStyle}>
                    <input id='hide_quantity' 
                         type='checkbox'
                        checked={hide_quantity}
                        onChange={e => onChangeField(e.target.checked, id, 'hide_quantity')}
                    /> Hide Estimate 
                </div>
        } else {
            return null;
        }

    }

    unitCost = () => {
        const {unit_price, unit_cost, item_type} = this.props;


        if(typeof unit_cost !== 'undefined'){
            const markupPercent = (unit_cost !== 0) ? numeral((unit_price - unit_cost) / unit_cost).format('0%') : 'N/A';
            const requiredPrivileges = item_type === "part" ? [Privileges.VIEW_PART_COST, Privileges.VIEW_QUOTE_ITEM_COST] 
                                                            : [Privileges.VIEW_SERVICE_COST, Privileges.VIEW_QUOTE_ITEM_COST]; 
            const append = item_type === "service" ? "/hr" : "";
            return renderCondition(
                    requiredPrivileges, 
                    <div {...unitCostDiv}>
                        <span><span style={{fontWeight: '800'}}>{item_type === 'part' ? 'Unit' : 'Hourly'} Cost: </span><span id='unit_cost'>{'('+numeral(unit_cost).format('$0,0.00') + append + ')'}</span></span>
                        <span style={{display: 'block'}}><span style={{fontWeight: '800'}}>Markup: </span><span id='markup'>{markupPercent}</span></span> 
                    </div>
                );

        } else {
            return null;
        }

    }
    
    onKeyUpCapture = (e) => {
       if(e.keyCode === 13 && e.keyCode === 9){
           e.target.blur();
       }
    }
}

QuoteItem.defaultProps = defaultProps;

export default DropTarget('QuoteItem', target, dropCollect)(DragSource('QuoteItem', source, dragCollect)(QuoteItem));
