import colors from 'lib/constants/resourceColors';
const form = [
    {key:'name'},
]

export default {
    defaults: {
        name: 'Task Type',
        color: colors.taskTypes,
        formFields: form,
    },

    view: {
        title: (t) => `${t.name} Task Type`,
    },

    search: {
        fields: ['id','name','updated_at'],
        links: ['new'],
        searchBy: ['name'],
        tableProps: {
            rowURL: '/task_types/:id',
            columns: [{heading: 'Name', accessor: 'name'}],
            showHeading: false,
        }
    },
};
