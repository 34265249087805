import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DockedEvent from './DockedEvent';
import { OrderedMap } from 'immutable';
import { DropTarget } from 'react-dnd';

// eslint-disable-next-line
const debug = require('debug')('app:EventsDock:');

const propTypes = {
    heading: PropTypes.string,
    immutableEvents: PropTypes.object,
    onEventClick: PropTypes.func,
    calendarHeight: PropTypes.number,
};

const defaultProps = {
    heading: 'Unscheduled',
    immutableEvents: OrderedMap(),
    calendarHeight: 0,
};

const Main = styled.div`
    border-bottom: 1px solid gray;
    display: flex;
    max-width: 216px;
    padding-top: 37px;
    height: 100%;
    max-height: ${props => `${props.calendarHeight}`}px;
    width: 200px;
    font-family: sans-serif;
    flex: 1;
    flex-direction: column;
    margin: 0em 0.5em;


    @media screen and (max-width: 450px) {
        background-color: blue;
        display: block;
    }

    @media print {
        display: none;
    }
`

const Title = styled.div`
    background-color: transparent;
    color: #878798;
    border-bottom: 1px solid #DDDDDD;
    justify-content: center;
    padding: 6px 6px;
    color: #878798;
    font-family: sans-serif;
    text-overflow: hidden;
    user-select: none;
    word-wrap: none;
`

const EventList = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
`
const boxTarget = {

	drop(props, monitor) {
        const item = monitor.getItem();
        const { eventDidDrop } = props;

        eventDidDrop(item);

		return { id: item.id }
	},
}

function collect(connect, monitor){
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
    }
};

class EventsDock extends Component {

    render(){
        const { calendarHeight, connectDropTarget, heading } = this.props;

        return <Main calendarHeight={calendarHeight} innerRef={instance => connectDropTarget(instance)}>
            <Title>{heading}</Title> 
            <EventList>
                {this.renderEvents()}
            </EventList>
        </Main>
    }

    renderEvents = () => {
        const { immutableEvents, onEventClick } = this.props;

        const eventComponents = [];
        immutableEvents.forEach((event, index) => {
            eventComponents.push(<DockedEvent 
                onClick={onEventClick}
                key={`${index}_de_${event.get('id')}`}
                id={event.get('id')}
                immutableEvent={event}
            />);
        });

        return eventComponents;

    }
}

EventsDock.propTypes = propTypes;
EventsDock.defaultProps = defaultProps;

export default DropTarget('EVENT', boxTarget, collect)(EventsDock);
