import Privileges from 'lib/constants/privileges';
import colors from 'lib/constants/resourceColors';
import { statusBadgeStyle } from './projects';

export default {
    defaults: {
        name: 'Project',
        color: colors.projects,
        appUrlBase: '/company/projects',
    }, 

    search: {
        heading: 'Employee Projects', 
        privileges: [Privileges.SEARCH_EMPLOYEE_PROJECTS],
        cacheKey: 'cache_search_company_projects',
        allowDateFilter: true,
        links: [
            {downloadURL: '/files/project_open_balances',
                title: 'Download Open Balances Report',
                requiredPrivileges: [Privileges.FILE_READ_PROJECT_OPEN_BALANCES, Privileges.READ_CUSTOMERS_TOTAL_OUTSTANDING_PAYMENT_REQUEST_BALANCES, Privileges.READ_CUSTOMERS_TOTAL_BALANCE_TO_FINISH]
            }
        ],
        fields: ['id', 'logs_last_activity_description', 'logs_last_activity_user', 'project_number', 'description', 'customer_name', 'site_address', 'site_address2', 'site_city', 'site_state', 'site_zipcode', 'creator', 'salesperson', 'created_at', 'updated_at', 'status', 'outstanding_payment_request_balance', 'balance_to_finish'],
        tableProps: {
            rowURL: '/projects/:id',
            columns: [
                {heading: 'Created By', accessor: 'creator'},
                {accessor: 'created_at', type: 'date', dateFormat: 'MMM DD, YYYY' },
                {heading: 'Project Number', accessor: 'project_number', cellWidth: '110px', style: 'bold'},
                {accessor: 'salesperson'},
                {heading: 'Description', type:'multiple', rows: [
                    {heading: 'Description', accessor:'description', style:'bold'},
                    {heading: 'Activity', style: 'subtext', accessor:'logs_last_activity_description'},
                    {heading: 'User', style: 'subtext', accessor: 'logs_last_activity_user'},
                ]},
                {accessor: 'customer_name'},
                {heading: 'Site Address', separator: ', ',accessor: ['site_address', 'site_address2', 'site_city', 'site_state', 'site_zipcode']},
                {heading: 'Open Balance', accessor: 'outstanding_payment_request_balance', type: 'currency', privileges: [Privileges.READ_PROJECTS_OUTSTANDING_PAYMENT_REQUESTS]},
                {heading: 'Pending to Request', accessor: 'balance_to_finish', type: 'currency', privileges: [Privileges.READ_PROJECTS_BALANCE_TO_FINISH]},
                {type:'badge', 
                    badgePropsFunc: statusBadgeStyle, 
                    heading: 'Status', 
                    accessor: 'status'},
            ]
        },
        toggleOptions: [
            {key: 'hide_closed_void', 
                label: 'Hide Closed/Voided Projects', 
                param: 'status', 
                defaultValue: (currentUser) => true, 
                value: (currentUser) => 'open' }
        ],
    }
}
