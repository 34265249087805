import titleCase from 'lib/helpers/titleCase';

/*
`
name
=customer_id
%commission
description
@address (Location)
/date (Issued) {MM/DD/YYYY}
*test {smoke_test|Smoke Test,fire_test|Fire Test}
_state {US_STATES}
:time {hh:mm: a}
$amount :0
$-cost
>vendor_url
#-intrusion(Intrusions) 
?is_ul :false
#incidents
+notes
`
!req text name
!req text identifier 
long description
auto customer_id
numb incidents
numn intrusion(Intrusions) 
perc commission
addr address(Location)
date date(Issued) {MM/DD/YYYY}
time time {hh:mm: a}
radi test {smoke_test|Smoke Test,fire_test|Fire Test}
sele state {US_STATES}
file document
curr amount :0
curn cost
link vendor_url
bool is_ul :false

!req text name
!req text identifier 
nocreate neg-number intrusion(Intrusions) 
longtext description
autocomplete customer_id
number incidents
neg-number intrusion(Intrusions) 
percent commission
address address(Location)
date date(Issued) {MM/DD/YYYY}
time time {hh:mm: a}
radio test {smoke_test|Smoke Test,fire_test|Fire Test}
select state {US_STATES}
file document
money amount :0
neg-money cost
link vendor_url
bool is_ul :false
*/

function fieldFromString2(originalString) {
    const keywords = {
        required: '!req',
        text: 'text',
        long: 'longtext',
        bool: 'boolean',
        curr: 'currency',
        curn: 'currency',
        perc: 'percentage',
        numb: 'number',
        numn: 'number',
        addr: 'address',
        link: 'url',
        hide: 'hidden',
        list: 'array',
        file: 'file',
        files: 'file',
        imag: 'image',
        radi: 'radio',
        date: 'date',
        time: 'time',
        sele: 'options',
        auto: 'autocomplete',
    };

    let string = originalString;
    let tokens = string.split(' ');


    let field = {};

    let currentToken = tokens[0];
    if(currentToken === keywords.required) {
        field.required = true;
        let newStartIndex = keywords.required.length + 1;
        string = string.substring(newStartIndex);
        currentToken = tokens[1];
    }

    let type = keywords[currentToken];
    if(type){
        let newStartIndex = currentToken.length + 1;
        string = string.substring(newStartIndex);
    } else {
        type = 'text';
    }


    let split = string.split(':');
    if(split.length === 2) {
        string = split[0];
        field.placeholder = split[1];
    }

    split = string.split('=');
    if(split.length === 2) {
        string = split[0];
        field.defaultValue = split[1];
    }

    if(type === 'number' || type === 'currency') {
        if(currentToken === 'curn' || currentToken === 'numn') {
            field.negativeAmount = true;
        }
    }

    if(type === 'file') {
        if(currentToken === 'files') {
            field.allowMultipleFiles = true;
        }
    }

    if(type === 'date' || type === 'time'){
        let formatRegExp = new RegExp(/\{([^)]+)\}/);
        let formatMatch = formatRegExp.exec(string);
        field.dateFormat = formatMatch ? formatMatch[1] : (type === 'date' ? 'MM/DD/YYYY' : 'h:mm a');
        string = formatMatch ? string.slice(0, formatMatch.index) : string;

        if(!field.placeholder) {
            field.placeholder = (type === 'date' ? 'No Date' : 'No Time');
        }
    }

    if(type === 'image' || type === 'file'){
        let formatRegExp = new RegExp(/\{([^)]+)\}/);
        let formatMatch = formatRegExp.exec(string);
        field.urlKey = formatMatch ? formatMatch[1] : null;
        string = formatMatch ? string.slice(0, formatMatch.index) : string;
    }

    if(type === 'radio' || type === 'options'){
        let formatRegExp = new RegExp(/\{([^)]+)\}/);
        let formatMatch = formatRegExp.exec(string);
        let options = formatMatch ? formatMatch[1] : null;
        string = formatMatch ? string.slice(0, formatMatch.index) : string;

        if(type === 'options' && options === 'US_STATES') {
            field.options = [
                {value: 'Alabama', label: 'Alabama'},
                {value: 'Alaska', label: 'Alaska'},
                {value: 'Arizona', label: 'Arizona'},
                {value: 'Arkansas', label: 'Arkansas'},
                {value: 'California', label: 'California'},
                {value: 'Colorado', label: 'Colorado'},
                {value: 'Connecticut', label: 'Connecticut'},
                {value: 'Delaware', label: 'Delaware'},
                {value: 'Florida', label: 'Florida'},
                {value: 'Georgia', label: 'Georgia'},
                {value: 'Hawaii', label: 'Hawaii'},
                {value: 'Idaho', label: 'Idaho'},
                {value: 'Illinois', label: 'Illinois'},
                {value: 'Indiana', label: 'Indiana'},
                {value: 'Iowa', label: 'Iowa'},
                {value: 'Kansas', label: 'Kansas'},
                {value: 'Kentucky', label: 'Kentucky'},
                {value: 'Louisiana', label: 'Louisiana'},
                {value: 'Maine', label: 'Maine'},
                {value: 'Maryland', label: 'Maryland'},
                {value: 'Massachusetts', label: 'Massachusetts'},
                {value: 'Michigan', label: 'Michigan'},
                {value: 'Minnesota', label: 'Minnesota'},
                {value: 'Mississippi', label: 'Mississippi'},
                {value: 'Missouri', label: 'Missouri'},
                {value: 'Montana', label: 'Montana'},
                {value: 'Nebraska', label: 'Nebraska'},
                {value: 'Nevada', label: 'Nevada'},
                {value: 'New Hampshire', label: 'New Hampshire'},
                {value: 'New Jersey', label: 'New Jersey'},
                {value: 'New Mexico', label: 'New Mexico'},
                {value: 'New York', label: 'New York'},
                {value: 'North Carolina', label: 'North Carolina'},
                {value: 'North Dakota', label: 'North Dakota'},
                {value: 'Ohio', label: 'Ohio'},
                {value: 'Oklahoma', label: 'Oklahoma'},
                {value: 'Oregon', label: 'Oregon'},
                {value: 'Pennsylvania', label: 'Pennsylvania'},
                {value: 'Rhode Island', label: 'Rhode Island'},
                {value: 'South Carolina', label: 'South Carolina'},
                {value: 'South Dakota', label: 'South Dakota'},
                {value: 'Tennessee', label: 'Tennessee'},
                {value: 'Texas', label: 'Texas'},
                {value: 'Utah', label: 'Utah'},
                {value: 'Vermont', label: 'Vermont'},
                {value: 'Virginia', label: 'Virginia'},
                {value: 'Washington', label: 'Washington'},
                {value: 'West Virginia', label: 'West Virginia'},
                {value: 'Wisconsin', label: 'Wisconsin'},
                {value: 'Wyoming', label: 'Wyoming'}
            ]
        } else {
            field.options = options.split(',').map(o => {
                let option = o.split('|');
                let value = option[0];
                let label = option.length === 2 ? option[1] : titleCase(option[0]);
                return {value, label};
            });
        }

        //else, unrecognized preset?
    }


    
    let titleRegExp = new RegExp(/\(([^)]+)\)/);
    let titleMatch = titleRegExp.exec(string);
    let label = titleMatch ? titleMatch[1] : titleCase(string);
    string = titleMatch ? string.slice(0, titleMatch.index) : string;

    field.key = string; 
    field.type = type; 
    field.label = label;

    if((type === 'image' || type === 'file') && !field.urlKey) {
        field.urlKey = field.key;
    }

    return field;


}

function fieldFromString(originalString) {

    let string = originalString;

    let firstChar = string.charAt(0);

    let field = {};

    if(firstChar === '!') {
        field.required = true;
        string = string.slice(1);
        firstChar = string.charAt(0);
    }

    const charTypes = {
        '$': 'currency',
        '?': 'boolean',
        '#': 'number',
        '@': 'address',
        '>': 'url',
        '+': 'longtext',
        '.': 'hidden',
        '&': 'array',
        '^': 'file',
        '~': 'image',
        '*': 'radio',
        '/': 'date',
        ':': 'time',
        '_': 'options',
        '%': 'percentage',
        '=': 'autocomplete',
    }
    let type = charTypes[firstChar] ? charTypes[firstChar] : 'text';


    if(type !== 'text') { 
        string = string.slice(1);
    }


    let split = string.split(':');
    if(split.length === 2) {
        string = split[0];
        field.placeholder = split[1];
    }

    split = string.split('=');
    if(split.length === 2) {
        string = split[0];
        field.defaultValue = split[1];
    }

    if(type === 'number' || type === 'currency') {
        if(string.charAt(0) === '-') {
            field.negativeAmount = true;
            string = string.slice(1);
        }
    }

    if(type === 'file') {
        if(string.charAt(0) === '^') {
            field.allowMultipleFiles = true;
            string = string.slice(1);
        }
    }

    if(type === 'date' || type === 'time'){
        let formatRegExp = new RegExp(/\{([^)]+)\}/);
        let formatMatch = formatRegExp.exec(string);
        field.dateFormat = formatMatch ? formatMatch[1] : (type === 'date' ? 'MM/DD/YYYY' : 'h:mm a');
        string = formatMatch ? string.slice(0, formatMatch.index) : string;

        if(!field.placeholder) {
            field.placeholder = (type === 'date' ? 'No Date' : 'No Time');
        }
    }

    if(type === 'image' || type === 'file'){
        let formatRegExp = new RegExp(/\{([^)]+)\}/);
        let formatMatch = formatRegExp.exec(string);
        field.urlKey = formatMatch ? formatMatch[1] : null;
        string = formatMatch ? string.slice(0, formatMatch.index) : string;
    }

    if(type === 'radio' || type === 'options'){
        let formatRegExp = new RegExp(/\{([^)]+)\}/);
        let formatMatch = formatRegExp.exec(string);
        let options = formatMatch ? formatMatch[1] : null;
        string = formatMatch ? string.slice(0, formatMatch.index) : string;

        if(type === 'options' && options === 'US_STATES') {
            field.options = [
                {value: 'Alabama', label: 'Alabama'},
                {value: 'Alaska', label: 'Alaska'},
                {value: 'Arizona', label: 'Arizona'},
                {value: 'Arkansas', label: 'Arkansas'},
                {value: 'California', label: 'California'},
                {value: 'Colorado', label: 'Colorado'},
                {value: 'Connecticut', label: 'Connecticut'},
                {value: 'Delaware', label: 'Delaware'},
                {value: 'Florida', label: 'Florida'},
                {value: 'Georgia', label: 'Georgia'},
                {value: 'Hawaii', label: 'Hawaii'},
                {value: 'Idaho', label: 'Idaho'},
                {value: 'Illinois', label: 'Illinois'},
                {value: 'Indiana', label: 'Indiana'},
                {value: 'Iowa', label: 'Iowa'},
                {value: 'Kansas', label: 'Kansas'},
                {value: 'Kentucky', label: 'Kentucky'},
                {value: 'Louisiana', label: 'Louisiana'},
                {value: 'Maine', label: 'Maine'},
                {value: 'Maryland', label: 'Maryland'},
                {value: 'Massachusetts', label: 'Massachusetts'},
                {value: 'Michigan', label: 'Michigan'},
                {value: 'Minnesota', label: 'Minnesota'},
                {value: 'Mississippi', label: 'Mississippi'},
                {value: 'Missouri', label: 'Missouri'},
                {value: 'Montana', label: 'Montana'},
                {value: 'Nebraska', label: 'Nebraska'},
                {value: 'Nevada', label: 'Nevada'},
                {value: 'New Hampshire', label: 'New Hampshire'},
                {value: 'New Jersey', label: 'New Jersey'},
                {value: 'New Mexico', label: 'New Mexico'},
                {value: 'New York', label: 'New York'},
                {value: 'North Carolina', label: 'North Carolina'},
                {value: 'North Dakota', label: 'North Dakota'},
                {value: 'Ohio', label: 'Ohio'},
                {value: 'Oklahoma', label: 'Oklahoma'},
                {value: 'Oregon', label: 'Oregon'},
                {value: 'Pennsylvania', label: 'Pennsylvania'},
                {value: 'Rhode Island', label: 'Rhode Island'},
                {value: 'South Carolina', label: 'South Carolina'},
                {value: 'South Dakota', label: 'South Dakota'},
                {value: 'Tennessee', label: 'Tennessee'},
                {value: 'Texas', label: 'Texas'},
                {value: 'Utah', label: 'Utah'},
                {value: 'Vermont', label: 'Vermont'},
                {value: 'Virginia', label: 'Virginia'},
                {value: 'Washington', label: 'Washington'},
                {value: 'West Virginia', label: 'West Virginia'},
                {value: 'Wisconsin', label: 'Wisconsin'},
                {value: 'Wyoming', label: 'Wyoming'}
            ]
        } else {
            field.options = options.split(',').map(o => {
                let option = o.split('|');
                let value = option[0];
                let label = option.length === 2 ? option[1] : titleCase(option[0]);
                return {value, label};
            });
        }

        //else, unrecognized preset?
    }


    
    let titleRegExp = new RegExp(/\(([^)]+)\)/);
    let titleMatch = titleRegExp.exec(string);
    let label = titleMatch ? titleMatch[1] : titleCase(string);
    string = titleMatch ? string.slice(0, titleMatch.index) : string;

    field.key = string; 
    field.type = type; 
    field.label = label;

    if((type === 'image' || type === 'file') && !field.urlKey) {
        field.urlKey = field.key;
    }

    return field;
}

function setFormFieldDefaults(formFields) {

    let fields = formFields.map(field => {
        if(typeof field == 'string') {
            return fieldFromString(field);
        }

        let formatted = Object.assign({}, field);
        if(!formatted.label) {
            formatted.label = titleCase(formatted.key);
        }

        if(!formatted.displayKey) {
            formatted.displayKey = [formatted.key];
        } else if (!Array.isArray(formatted.displayKey)) {
            formatted.displayKey = [formatted.displayKey];
        }

        if(!formatted.displayFunc) {
            formatted.displayFunc = (values) => {
                let firstKey = formatted.displayKey[0]; 
                let displayedValue = values[firstKey];

                if(formatted.displayKey.length > 1) {
                    let others = formatted.displayKey.slice(1);
                    let labels = others.map(k => values[k]).filter(v => v);
                    labels.join(', ');
                    displayedValue = `${displayedValue} (${labels})`;
                }

                return displayedValue;
            }
        }

        if(!formatted.type) {
            formatted.type = 'text';
        }

        if(formatted.link) {
            if(typeof formatted.link === 'string') {
                formatted.link = { url: formatted.link, privileges: [] }
            } else if (typeof formatted.link === 'object') {
                if(typeof formatted.link.privileges === 'string') {
                    formatted.link.privileges = [formatted.link.privileges];
                }
            }
        }

        if(formatted.type === 'references') {
            const { columns } = formatted.tableProps;
            let c = columns.map(c => {
                if(typeof c === 'string') {
                    return {accessor: c, heading: titleCase(c)}
                }
            });

        }

        return formatted;
    });

    return fields;
}

export default setFormFieldDefaults;
