
import colors from 'lib/constants/resourceColors';
import React from 'react';
import Privileges from '../lib/constants/privileges';

const form = [
    {key: 'customer_name', createOnly: true},
    {key: 'customer_address', createOnly: true},
    {key: 'site_name', createOnly: true},
    {key: 'site_address', createOnly: true},
    {key: 'print_pages', type: 'boolean', defaultValue: true},
    {key: 'email', label: 'Email Address'},
    {type: 'array',
        key: 'email_cc',
        label: 'Email CC',
        placeholder: 'Enter comma separated email addresses'},
];

export default {
    defaults: {
        name: 'Invoice Recipient',
        color: colors.invoiceRecipients,
        formFields: form,
    },

    view: {
        remarks: {
            header: (values) => { return `${values.site_name} (${values.customer_name})` },
        }
    },

    search: {
        fields: ['id', 'customer_name', 'site_name', 'customer_address', 'site_address', 'print_pages', 'email', 'email_cc', 'created_at'],
        searchBy: ['primary email contact', 'customer name', 'customer address', 'site name', 'site address', 'CC email addresses'],
        links: [
            'new',
        ],
        tableProps: {
            rowURL: '/invoice_recipients/:id',
            columns: [
                {accessor: 'customer_name'},
                {accessor: 'customer_address'},
                {accessor: 'site_name'},
                {accessor: 'site_address'},
                {accessor: 'print_pages', type: 'boolean'},
                {accessor: 'email', label: 'Email Address'},
                {accessor: 'email_cc', type: 'list', label: 'Email CC', itemMapper: value => value},
                {accessor: 'created_at', label: 'Created at', type: 'date'},
            ]
        }
    }
}

