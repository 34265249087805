import React from 'react';
import { Icon } from 'antd';
import titleCase from '../../../../lib/helpers/titleCase';

export default (props) => {
    const {type, title} = props;

    const titleText = title || titleCase(type);
    return <span><Icon type={type} /><span>{titleText}</span></span>

}
