import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DropTarget } from 'react-dnd';

const Main = styled.div`
    box-sizing: border-box;
    background: ${props => (props.inactive && '#BBBBCC') || 
                                 (props.isEvenRow && props.isWeekend && '#DFE5EF') ||
                                 (props.today && '#d7f1ff') || 
                                 (props.isHoliday && '#F7DFFF') || 
                                 (props.isWeekend && '#e6ebf0') ||
                                 (props.isEvenRow && '#FCFCFF') || 
                                 '#FFFFFF'};
    border-bottom: 1px solid #DDDDDD;
    ${props => {
        if(props.showRightBorder) {
            return 'box-shadow: inset -1px 0px 0px 0px #DDDDDD;' 
        }
    }}

    box-shadow: inset 1px 0px 0px 0px #DDDDDD; 
    color: black;
    position: relative;
    cursor: pointer;
    display: ${props => props.hideWeekends && props.isWeekend ? 'none' : 'flex'};
    flex: 0 1 auto;
    overflow-x: visible;
    justify-content: center;
    width: ${props => props.hideWeekends ? (props.isWeekend ? 0 : '20%') : '14.28571428%'};
    min-height: ${props => props.compressed ? '65px' : '150px'};


    ${props => {
        if(props.inactive){
            return `
            background: repeating-linear-gradient(
              -45deg,
              #BBBBCC,
              #BBBBCC 10px,
              #BFBFCF 10px,
              #BFBFCF 20px
            ); 
            background-attachment: fixed;
            background-position: center;

            color: #888899;
            box-shadow: inset 0px 9px 5px -5px hsla(0,0%,0%,.15);
            `
        }
    }}

    
    ${props => props.isOver ? 'box-shadow: inset 0px 0px 10px 0px #00ACFF' : ''}

`


const Content = styled.div`
    box-sizing: border-box;
    position: relative;
    width: 100%;
`

const DateHeader = styled.div`
    box-sizing: border-box;
    font-family: sans-serif;
    line-height: 1.15em;
    padding: .25em .5em;
    text-align: ${props => props.dateAlign === 'left' ? 'left' : 'right'};
    color: #888899;
    user-select: none;
`

const MonthText = styled.span`
`

const boxTarget = {
	drop(props, monitor) {
        const item = monitor.getItem();
        const { handleNewEvent, day } = props;

        handleNewEvent(item, day);

		return { id: props.id, day }
	},
}

function collect(connect, monitor){
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
    }
};

class Day extends Component {

    render () {
        const { hideWeekends, compressed, monthName, dateAlign, children, isOver, canDrop, 
                connectDropTarget, index, day, isHoliday, isToday, onClick } = this.props;
        const { weekDay } = day;

        const evenRow = (index >= 7 && index < 14) || (index >= 21 && index < 28) || (index >= 35);
        const isWeekend = (weekDay === 0) || (weekDay === 6); 
        const isOnRightEdge = ((index+1) % 7 === 0);
        //const isOnLeftEdge = ((index % 7) === 0);

        const Container = Main;
        const ContentComponent = Content;
        const DateHeaderComponent = DateHeader;
        return (
            <Container
                innerRef={instance => connectDropTarget(instance)}
                compressed={compressed}
                isOver={isOver}
                canDrop={canDrop}
                onClick={onClick.bind(null, this, day)}
                inactive={day.siblingMonth}
                isEvenRow={evenRow}
                isWeekend={isWeekend}
                hideWeekends={hideWeekends}
                isHoliday={isHoliday}
                showRightBorder={!isOnRightEdge}
                today={isToday}>
                <ContentComponent>
                    <DateHeaderComponent dateAlign={dateAlign}>
                        {day.day === 1 ? <MonthText>{monthName}</MonthText> : ''} {day.day}
                    </DateHeaderComponent>
                    {children}
                </ContentComponent>
            </Container>
        );
    }

}

Day.propTypes = {
    day: PropTypes.object.isRequired,
    handleNewEvent: PropTypes.func,
    weekDay: PropTypes.number,
    styledComponents: PropTypes.object,
    isToday: PropTypes.bool,
    onClick: PropTypes.func,
};

Day.defaultProps = {
    onClick: () => {},
    styledComponents: {},
    isToday: false,
}

export default DropTarget('EVENT', boxTarget, collect)(Day);
