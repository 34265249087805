import colors from 'lib/constants/resourceColors';
import Privileges from '../lib/constants/privileges';
import api from 'api';

export function paymentRequestBadgeStyle(value, data) {
    const { status, voided } = data;

    debugger;
    if (voided || status === 'voided') {
        return {status: 'error', text: 'Void'};
    } else if(status === 'open'){
        return {status: 'processing', text: 'Open'}; 
    } else if (status === 'closed'){
        return {status:'success', text:'Closed'}; 
    } else if (status === 'partial') {
        return {status: 'processing', text: 'Partial'}; 
    } else if (status === 'overpaid') {
        return {status: 'error', text: 'Overpaid'}; 
    } else {
        return null;
    }
}

export default {
    defaults: {
        name: 'Payment Request',
        color: colors.paymentRequests,
        formFields: [
            {label:'Ref#', key:'ref_number', static: true},
            {label:'Payment Request#', key:'request_number', static: true},
            {key: 'project_description',
                readOnly: true,
                static: true,
                label: 'Project',
                link: {url:'/projects/:project_id', privileges: Privileges.READ_PROJECTS}},
            {label: 'Equipment Release#', 
                key:'release_number', 
                static: true, 
                link: {url:'/equipment_releases/:release_id', privileges: Privileges.READ_EQUIPMENT_RELEASES}},
            {label: 'Equipment Release', key:'release_description', static: true},
            {label: 'PO Number', key:'po_number'},
            {key: 'customer_name',
                readOnly: true,
                static: true,
                label: 'Customer',
                link: {url:'/customers/:customer_id', privileges: Privileges.READ_CUSTOMERS}},
            {key: 'salesperson_id', 
             displayKey: 'salesperson_name',
                link: {url:'/users/:salesperson_id', privileges: Privileges.READ_USERS},
                label: 'Salesperson',
                type: 'autocomplete', 
                suggestFormat: (c) => { return {value: c.id, text: `${c.first_name} ${c.last_name}`} },
                suggestUrl: '/users?employed=true', 
                suggestFields: ['id', 'first_name', 'last_name']},
            '/date(Date)',
            'description', 
            {key: 'tax_region',
                //showInCreateMode: false,
                type: 'options',
                defaultValue: 'miami-dade',
                options: [
                    {value: 'miami-dade', label: 'Miami Dade'},
                    {value: 'broward', label: 'Broward'},
                    {value: 'exempt', label: 'Tax Exempt'},
                    {value: 'notax', label: 'No Tax'},
                ],
            },
            {static: true, key: 'subtotal', type: 'currency'},
            {static: true, key: 'tax_total', label: 'Tax', type: 'currency'},
            {static: true, key: 'grand_total', type: 'currency'},
            {static: true, key: 'open_balance', type: 'currency'},
            // {key: 'grand_total', 
            //     type: 'currency',
            //     calculations: [
            //         { name: 'Remove Tax',
            //           onClick: (item, values, inputRefs, user) => {
            //               const currentPrice = inputRefs[item.key];
            //               console.log(`Refs `, inputRefs, item, values);
            //               console.log(`Change price ${currentPrice}`);
            //           }
            //         }
            //     ]
            // },
            
            {static: true, key: 'status'},
            '_payment_term{COD|COD,DUE_ON_RECEIPT|Due On Receipt,NET30|Net 30,NET60|Net 60,NET90|Net 90,PROJECT|Project}',

            {type: 'boolean', key: 'voided'}, 
            {key: 'parts',
                type: 'references',
                heading: 'Items',
                createLabel: 'Add Item',
                reloadPageOnCreate: true,
                dataTransform: 'items',
                dataUrl: `/payment_requests/:id?fields=items`,
                createUrl: (values) => { return `/payment_requests/${values.id}/items` },
                deleteUrl: (values) => { return `/payment_requests/${values.id}/items/:id` },
                editorActions: [ 
                    { label: 'Set Unit Prices to Zero', 
                       icon: 'minus-circle', 
                    method: async ({values, parameters, reload, loading, success, error}) => {
                            let payment_request_id = values.id;

                            loading('Updating');
                            let result;
                            try {
                                result = await api.rpc('zeroPaymentRequestItemUnitPrices', {payment_request_id})
                            } catch (e) {
                                throw new Error(e);
                            }
                            
                            if(result.success) {
                                success({
                                    title: 'Success', 
                                    content: 'Unit Prices have been cleared.',
                                    okText : 'Ok',
                                });
                            } else {
                                error({
                                    title: 'Error', 
                                    content: `Couldn't clear unit prices.`,
                                    okText : 'Ok',
                                });
                            }

                            reload(true);
                        }
                    }
                ],
                formTemplateSearch: {
                    displayKey: 'product_number',
                    placeholder: 'Search Product Number',
                    suggestFormat: ({product_number, description, unit_price}) => { return {
                        value: {
                            product_number,
                            description,
                            unit_price
                        },
                        text: product_number
                    } },
                    suggestUrl: '/products', 
                    suggestFields: ['id', 'product_number', 'product_number', 'description', 'unit_price'],
                    fieldMappings: {
                        'product_number': 'product_number',
                        'description': 'description',
                        'unit_price': 'price',
                    }
                },
                // formTemplates: {
                //     sample: {
                //         name: 'Sample Template',
                //         values: {
                //             priority: 3,
                //             description: 'It worked',
                //         }
                //     }
                // },
                formFields: [
                    {key: 'product_number'},
                    {key: 'description', type: 'longtext'},
                    {key: 'price', 
                        type: 'currency', 
                        label: 'Unit Price',
                        inputRef: 'price',
                    },
                    {key: 'quantity', type: 'number'},
                    {key: 'taxable', type: 'boolean', defaultValue: true},
                ],
                tableProps: { 
                    rowURL: '/payment_request_items/:id',
                    columns: [
                    {heading: 'Row', accessor:'position', type:'number', cellWidth: '10px'},
                    {accessor: 'product_number', cellWidth: '250px'},
                    {accessor: 'description'},
                    {accessor: 'price',  type:'currency', heading: 'Unit Price', cellWidth: '150px'},
                    {accessor:'quantity', type:'number', cellWidth: '50px'},
                    {accessor: 'taxable', type: 'boolean', cellWidth: '25px'},
                ] },
            },
            {key: 'invoices',
                type: 'references',
                heading: 'Invoices',
                createLabel: 'Add New Blank Invoice',
                createPrivileges: [Privileges.CREATE_INVOICES],
                readPrivileges: [Privileges.READ_INVOICES],
                dataTransform: 'invoices',
                dataUrl: `/payment_requests/:id?fields=invoices`,
                createUrl: (values) => { return `/invoices` },
                deleteUrl: (values) => { return `/invoices/:id` },
                instantCreate: true,
                instantCreateObject: (values) => { return {payment_request_id: values.id}},
                tableProps: { 
                    rowURL: '/invoices/:id',
                    columns: [
                    {accessor: 'date', type: 'date'},
                    {accessor: 'invoice_number', maxWidth: '100px'},
                    {accessor: 'grand_total', type: 'currency'},
                    // {type:'badge', 
                    //     badgePropsFunc: statusBadgeStyle, 
                    //     heading: 'Status', 
                    //     accessor: 'status', cellWidth: '160px'},
                ] },
            },
        ],
    },

    view: {
        title: (p) => `Payment Request (#${p.ref_number})`,
        formActions: [
            {   label: 'Print Payment Request',
                icon: 'printer-o',
                method: async ({values, history, inputValues}) => {
                    console.log("Got values ", values, inputValues);
                    let pdfData = {
                        proforma: true,
                        po_number: values.po_number,
                        salesperson: values.salesperson_name,
                        project_description: values.project_description,
                        bill_name: values.customer_name,
                        bill_address: values.customer_address,
                        bill_address2: values.customer_address2,
                        bill_city: values.customer_city,
                        bill_state: values.customer_state,
                        bill_zipcode: values.customer_zipcode,
                        ship_name: values.customer_name,
                        ship_address: values.customer_address,
                        ship_address2: values.customer_address2,
                        ship_city: values.customer_city,
                        ship_state: values.customer_state,
                        ship_zipcode: values.customer_zipcode,
                        payment_term: values.payment_term,
                        property_name: values.site_name,
                        shorten_descriptions: values.shorten_descriptions, 
                        show_unit_prices: false, 
                        payment_request_items: values.items, 
                        invoice_number: values.ref_number, 
                        amount_due: values.grand_total,
                        payments_made: values.invoices_total,
                        balance_due: values.open_balance,
                        parts_price_subtotal: values.subtotal,
                        services_price_subtotal: 0,
                        tax_due: values.tax_total,
                        freight: 0,
                        created_at: values.created_at,
                        date: values.date
                    };

                    const filename = 'payment_request'

                    let response;
                    try {
                        response = await api.create('/pdfs/payment_requests', {pdfData, filename});
                        console.log("Got response ", response);
                        window.open(response.body.url, '_blank');
                    } catch (e) {
                        console.log(`Got error ${e}`);
                        throw new Error(e);
                    }

                }
            },
            { label: 'Generate Invoice with this Payment Request', 
               icon: 'file-text', 
         submitText: 'Generate',
         privileges: [Privileges.CREATE_INVOICES],
            method: async ({values, parameters, reload, loading, success, error}) => {
                    let payment_request_id = values.id;

                    loading('Generating');
                    let result;
                    try {
                        result = await api.rpc('generateInvoice', {payment_request_id})
                    } catch (e) {
                        throw new Error(e);
                    }
                    
                    if(result.success) {
                        success({
                            title: 'Success', 
                            content: 'A new invoice was generated from this payment request.',
                            okText : 'Ok',
                        });
                    } else {
                        let error = result.errors.error;
                        console.log("Got error ", result);
                        error({
                            title: 'Error', 
                            content: error.message,
                            okText : 'Ok',
                        });
                    }

                    reload();
                }
            }
        ]
    },
    search: {
        allowDateFilter: true,
        heading: 'Payment Requests',
        links: ['new'],
        fields: ['id','ref_number', 'request_number','description', 'grand_total','voided', 'date', 'status', 'customer_name', 'project_description', 'salesperson_name', 'salesperson_avatar', 'release_description', 'release_number', 'created_at','updated_at'],
        filterOptions: [
             {label: 'All', filters: { voided: false }}, 
             {label: 'Open', filters: { status: 'open' }}, 
             {label: 'Closed', filters: { status: 'closed' }}, 
             {label: 'Partial', filters: { status: 'partial' }}, 
             {label: 'Void', filters: { voided: true }},
        ],
        searchBy: ['reference number', 'project name', 'customer name', 'grand total'],
        tableProps: {
            rowURL: '/payment_requests/:id',
            columns: [
                {heading: 'Created', cellWidth: '100px', dateFormat: 'MMM DD, YYYY', type:'date', accessor: 'created_at'},
                {heading: 'Ref #', accessor: 'ref_number', cellWidth: '110px', style: 'bold'},
                {heading: 'Req #', accessor: 'request_number', cellWidth: '80px', style: 'bold'},
                {heading: 'Project', accessor: 'project_description'},
                {heading: 'Customer', accessor: 'customer_name'},
                {type: 'currency', accessor: 'grand_total'},
                {heading: 'Status', type: 'badge', accessor: 'status', badgePropsFunc: paymentRequestBadgeStyle},
                {heading: 'Salesperson', accessor: 'salesperson_name'},
            ]
        }
    }
}
