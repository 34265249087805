import { quoteApprovedStyle } from 'resources/quotes';
import colors from 'lib/constants/resourceColors';
import Privileges from 'lib/constants/privileges';

export default {
    defaults: {
        name: 'Quote',
        color: colors.quotes,
        identifierParam: 'quote_number',
        appUrlBase: '/company/quotes',
    }, 

    search: {
        heading: 'Employee Quotes', 
        privileges: [Privileges.SEARCH_EMPLOYEE_QUOTES],
        cacheKey: 'cache_search_company_quotes',
        allowDateFilter: true,
        fields: ['id','quote_number','description','sender_name','approved_at','created_at','updated_at'],
        tableProps: {
            rowURL: '/quotes/:quote_number',
            columns: [
                {heading: 'Quote Number', accessor: 'quote_number', cellWidth: '110px', style: 'bold'},
                {heading: 'Owner', cellWidth: '180px', accessor: 'sender_name'},
                {heading: 'Description', accessor: 'description'},
                {heading: 'Status', cellWidth: '110px', type: 'badge', accessor: 'approved_at', badgePropsFunc: quoteApprovedStyle, placeholder: 'Unknown'},
                {heading: 'Created', cellWidth: '100px', dateFormat: 'MMM DD, YYYY', type:'date', accessor: 'created_at'}
            ]
        }
    }
}
