import colors from 'lib/constants/resourceColors';
import formatPhoneNumber from 'lib/helpers/formatPhoneNumber';

const form = [
    '!name',
    'account_number',
    '#payment_terms',
    '!&identifiers',
    '>website',
    {key: 'contacts',
        type: 'references',
        heading: 'Contacts',
        createLabel: 'Add New Contact',
        primaryCreate: false,
        createUrl: (values) => { return `/vendors/${values.id}/contacts` },
        dataUrl: '/vendors/:id/contacts',
        formFields: [
                {key: 'name', 
                    required: true,
                },
                {key: 'title', 
                },
                {key: 'phone',label: 'Office Phone',
                },
                {key: 'mobile', 
                },
                {key: 'fax', 
                },
                {key: 'email', 
                },

        ],
        tableProps: {
            rowURL: '/contacts/:id',
            columns: [
                {heading: 'Name',
                    accessor: 'name',
                },
                {heading: 'Title',
                    accessor: 'title',
                },
                {heading: 'Office',
                    accessor: 'phone',
                    valueFunc: ((d,v) => {
                        return formatPhoneNumber(v) || v;
                    })
                },
                {heading: 'Mobile',
                    accessor: 'mobile',
                    valueFunc: ((d,v) => {
                        return formatPhoneNumber(v) || v;
                    })
                },
                {heading: 'Fax',
                    accessor: 'fax',
                    valueFunc: ((d,v) => {
                        return formatPhoneNumber(v) || v;
                    })
                },
                {heading: 'Email',
                    accessor: 'email',
                },
            ]
        }
    },
]
// const form = [
//     {key: 'name', required: true},
//     {key: 'account_number'},
//     {type: 'number', key: 'payment_terms'},
//     {type: 'array', key: 'identifiers'},
//     {type: 'url', key: 'website'},
//     {key: 'contacts',
//         type: 'references',
//         heading: 'Contacts',
//         createLabel: 'Add New Contact',
//         primaryCreate: false,
//         createUrl: (values) => { return `/vendors/${values.id}/contacts` },
//         dataUrl: '/vendors/:id/contacts',
//         formFields: [
//                 {key: 'name', 
//                     required: true,
//                 },
//                 {key: 'title', 
//                 },
//                 {key: 'phone',label: 'Office Phone',
//                 },
//                 {key: 'mobile', 
//                 },
//                 {key: 'fax', 
//                 },
//                 {key: 'email', 
//                 },

//         ],
//         tableProps: {
//             rowURL: '/contacts/:id',
//             columns: [
//                 {heading: 'Name',
//                     accessor: 'name',
//                 },
//                 {heading: 'Title',
//                     accessor: 'title',
//                 },
//                 {heading: 'Office',
//                     accessor: 'phone',
//                     valueFunc: ((d,v) => {
//                         return formatPhoneNumber(v) || v;
//                     })
//                 },
//                 {heading: 'Mobile',
//                     accessor: 'mobile',
//                     valueFunc: ((d,v) => {
//                         return formatPhoneNumber(v) || v;
//                     })
//                 },
//                 {heading: 'Fax',
//                     accessor: 'fax',
//                     valueFunc: ((d,v) => {
//                         return formatPhoneNumber(v) || v;
//                     })
//                 },
//                 {heading: 'Email',
//                     accessor: 'email',
//                 },
//             ]
//         }
//     },
// ]

export default {
    defaults: {
        name: 'Vendor',
        color: colors.vendor,
        formFields: form,
    },

    view: {
        title: (v) => v.name,
    },

    search: {
        fields: ['id','name', 'account_number'],
        links: ['new'],
        searchBy: ['name'],
        tableProps: {
            rowURL: '/vendors/:id',
            columns: [{heading: 'Name', accessor: 'name'}, {heading: 'Account Number', accessor: 'account_number'}],
        }
    },
}
