import methods from './methods';
// eslint-disable-next-line
const debug = require('debug')('app:AuthAPI:');

export default {

    authenticate({email, password}){
        return methods.post('/login', {email, password});
    },

    verify(){
        return methods.get('/verify');
    },

    logout(){
        return methods.get('/logout');
    },

    register(details){
        return methods.post('/register', details);
    },

}
