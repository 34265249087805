import React, { Component } from 'react';
import { Spin, Alert, Row, Card, Form, Icon, Input, Button } from 'antd';
import './style.css';
import { UserContext } from 'contexts/User';

const FormItem = Form.Item;

class LoginForm extends Component {

  submitFunc = (actions) => {
    return async (e) => {
        e.preventDefault();
        this.props.form.validateFields( async (err, values) => {
          if (!err) {
            console.log('Received values of form: ', values);
            actions.logIn(values.email, values.password);
          }
        })
    }
  }

  render() {

    const { loggingIn, error } = this.props;
    const { getFieldDecorator } = this.props.form;

    const title = loggingIn ? 'Signing In...' : 'Sign In';
    return (
        <UserContext.Consumer>
            {({actions, state}) => {
                const handleSubmit = this.submitFunc(actions);
                return <Row style={{height: '100vh'}} type="flex" justify="center" align="middle" >
                <Card className='login-card' title={title} style={{boxShadow: '0px 2px 5px #8888AA33', width: 300}} >
              <Form onSubmit={handleSubmit} className="login-form">
                  { error ? <FormItem><Alert message={error} type="error" showIcon /></FormItem> : null}
                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Please input your email address' }],
                  })(
                    <Input id='email' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email Address" />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your password' }],
                  })(
                    <Input id='password' prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                  )}
                </FormItem>
                {this.renderSubmit()}
              </Form>
                </Card>
                </Row>
            }}
        </UserContext.Consumer>
    );
  }

    renderSubmit = () => {
        const { loggingIn, onForgot } = this.props;


        if(loggingIn) {
            return <div className="spinner"><Spin size='large' /></div>
        } else {
            return <FormItem>
                  <Button id='Sign In' type="primary" htmlType="submit" className="login-form-button">
                    Sign In
                  </Button>
                {/*getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true,
                  })(
                    <Checkbox id='remember_me'>Remember Me</Checkbox>
                    )*/}
                  <Button style={{border: '0px', boxShadow: '0px 0px'}} id='forgot_password' className="login-form-forgot" onClick={onForgot}>Forgot Password</Button>
            </FormItem>
        }
    }

    togglePasswordReset = () => {
        this.setState({showPasswordReset: !this.state.showPasswordReset});
    }
}


const WrappedLoginForm = Form.create()(LoginForm);

export default WrappedLoginForm;
