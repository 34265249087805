import React, { Component } from 'react';
import { Button, Modal, Form } from 'antd';
import { withRouter } from 'react-router';
import Controls from './Controls';
import moment from 'moment';
//import FormItemDecorators from './formItemDecorators';
//import * as FormItemComponents from './formItemComponents';
import renderCondition from 'lib/helpers/renderCondition';
import titleCase from 'lib/helpers/titleCase';
import api from 'api';
import './style.css';
import FormProvider from 'contexts/Form';
import FormItemConfigs from './formItems';
import isEmptyObject from 'lib/helpers/isEmptyObject';
import { UserContext } from 'contexts/User';
import { userHasPrivileges } from 'lib/helpers/renderCondition';
import bindURL from 'lib/helpers/bindURL';
import appleMapURL from 'lib/helpers/appleMapURL';
import googleMapURL from 'lib/helpers/googleMapURL';
const Success = Modal.success;
const Error = Modal.error;


const FormItem = Form.Item;


const defaultProps = {
    createMode: false,
    showCancel: true,
    showEditButtons: true,
    readOnly: false,
    accessControlList: false,
    editPrivileges: [],
    deletePrivileges: [],
    doubleConfirmDelete: false,
    deleteEnabled: true,
    deleteText: 'Delete',
    cancelText: 'Cancel',
    modalForm: false,
    showModalInstantly: false,
    modalIcon: null,
    modalSubmitText: 'Create',
    modalButtonLabel: 'New',
    modalValues: {},
    formActions: [],
};


class WrappedForm extends Component {


    formContext = React.createContext();

    state = {
        deleting: false,
        deletePending: false,
        deleteConfirms: 0,

        rowErrors: {},
        modalVisible: false,
        inputRefs: {},
        inputFiles: {},

        submitting: false,
    }


    componentDidMount(){
        const { showModalInstantly, form, fields, location } = this.props;
        const { setFieldsValue } = this.props.form;
        let values = this.modalForm ? this.props.modalValues : this.props.values;

        let newState = {};

        if(showModalInstantly) {
            newState.modalVisible = true;
        }

        if(fields) {
            fields.forEach(row => {
                //state must be initialized for row types that depend on state
                if(row.type === 'date'){
                    let rawDate = values ? values[row.key] : null;
                    if(rawDate){
                        newState[row.key] = moment(rawDate);
                    }
                } else if(row.type === 'options'){
                    newState[row.key] = values ? values[row.key] : undefined;

                    if(typeof newState[row.key] === 'undefined' && row.defaultValue) {
                        newState[row.key] = row.defaultValue;
                    }

                } else if (row.type === 'time'){
                    let timeValue = row.defaultValue || null; 
                    if(row.date_key){
                        let dateValue = values ? values[row.date_key] : null;
                        if(dateValue){
                            dateValue = moment(dateValue);
                            timeValue = moment(new Date()); 
                            timeValue.hour(dateValue.hour());
                            timeValue.minute(dateValue.minute());
                            timeValue.second(dateValue.second());
                        }
                    } else {
                        if(values && values[row.key]){
                            timeValue = values[row.key];
                        }
                    } 
                    newState[row.key] = timeValue;

                } else if (row.type === 'references'){
                    newState[row.key] = { loading: true, data: null, errors: null};
                    
                    if(row.url) {
                        api.get(row.url)
                        .then(response => {
                            if(response.errors){
                                throw response.errors;
                            } else {
                                let newState = {};
                                newState[row.key] = { loading: false, data: response.body, errors: null};
                                this.setState(newState);
                            }
                        })
                        .catch(errors => {
                            let newState = {};
                            newState[row.key] = {loading: false, data: null, errors};
                            this.setState(newState);
                        });
                    }

                }
            });
        }

        this.setState(newState);


        if(fields) {
            fields.forEach(item => {
                let formItemDef = FormItemConfigs[item.type || 'text'];

                if(formItemDef.getInitialValue) {
                    let field = {};
                    field[item.key] = formItemDef.getInitialValue(values);
                    setFieldsValue(field);
                }
            });
        }

    }

    normalizeValues = (values, initialValues, user) => {
        const { createMode, selectableOwner, fields } = this.props;

        
        let normalValues = Object.assign({}, values);
        fields.forEach(field => {

            if(field.static || (field.createOnly && !createMode) ) {
                delete normalValues[field.key];
                return;
            }

            if(createMode && !selectableOwner && field.ownerField) {
                normalValues[field.key] = user.id;
            }

            let oldValue = createMode ? null : initialValues[field.key];
            let newValue = normalValues[field.key];


            switch (field.type) {
                case 'date': 
                    let date = newValue ; 

                    if(date){
                        const time_key = field.time_key;
                        if(time_key){
                            const time = normalValues[time_key];
                            date.hour(time.hour());
                            date.minute(time.minute());
                        }

                        normalValues[field.key] = date.toDate().toISOString(true);

                        if(oldValue) {
                            const originalDate = new Date(oldValue);
                            const newDate = new Date(date);
                            if(newDate.getTime() === originalDate.getTime()) {
                                delete normalValues[field.key];
                            }
                        }

                    } else {
                        //no date specified 
                        if(oldValue !== null && oldValue !== undefined) {
                            //if there was an initial value and the new value is null, we know the existing value is being deleted
                            normalValues[field.key] = null;
                        } else {
                            //null value is removed, since no change was made or can be verified
                            delete normalValues[field.key];
                        }
                    }
                    break;
                case 'autocomplete':
                    if(newValue === undefined || newValue === null || newValue === '') {
                        if(oldValue !== null && oldValue !== undefined) {
                            //if there was an initial value and the new value is null, we know the existing value is being deleted
                            normalValues[field.key] = null;
                        } else {
                            //null value is removed, since no change was made or can be verified
                            delete normalValues[field.key];
                        }
                    } else if (newValue === oldValue) {
                        //no change made
                        delete normalValues[field.key];
                    }
                    break;
                case 'boolean':
                    if(newValue === null || 
                       typeof newValue === 'undefined') {
                        delete normalValues[field.key];
                    }
                    break;
                case 'file':
                case 'image':
                    //TODO: Add multi file upload support
                    let allFiles = this.state.inputFiles[field.key] || [];
                    let firstFile = allFiles[0];
                    if(firstFile){ 
                        normalValues[field.key] = field.allowMultipleFiles ? allFiles : firstFile;
                    }
                    break;
                case 'references':
                case 'activity_logs':
                case 'relations':
                    delete normalValues[field.key];
                    break;
                case 'hidden':
                    normalValues[field.key] = initialValues ? initialValues[field.accessor || field.key] : field.defaultValue;
                    break;
                case 'currency':
                    if(typeof newValue === 'undefined' || newValue === null) {
                        normalValues[field.key] = field.defaultValue || 0;
                    }
                    break;
                case 'time':
                    if(field.date_key){
                        delete normalValues[field.key]
                    }
                    break;
                case 'oneToMany':
                    console.log("Values ", normalValues[field.key]);
                    if(!createMode) {
                        //One to many can only be established during creates for now
                        delete normalValues[field.key]
                    }

                    break;
                case 'options':
                    if (typeof newValue === 'undefined' || newValue === null || newValue === '')  {
                        normalValues[field.key] = null;
                    }
                    break;
                default:
                    if(newValue === null || newValue === '') {
                        if(oldValue !== null && oldValue !== undefined) {
                            //if there was an initial value and the new value is null, we know the existing value is being deleted
                            normalValues[field.key] = null;
                        } else {
                            //null value is removed, since no change was made or can be verified
                            delete normalValues[field.key];
                        }
                    } else if (typeof newValue === 'undefined') {
                        delete normalValues[field.key];
                    } else if (newValue === oldValue) {
                        //no need to send values that haven't changed
                        delete normalValues[field.key];
                    }
                    break;
            }
        });

        return normalValues;
    }

    submitForUser = (user) => {

        return (e) => {
            const { values, modalValues, modalForm, form, onSubmit, onCancel } = this.props;
            e.preventDefault();

            form.validateFields( async (err, formValues) => {

                if(!err) {
                    //adjust values
                    formValues = this.normalizeValues(formValues, values, user);


                    let response;
                    try {
                        this.setState({submitting: true});
                        response = await onSubmit(formValues);
                        this.setState({submitting: false});
                        if(!response) {
                            throw new Error('Failed to submit');
                        }
                        if(modalForm){
                            this.hideModal();
                            form.resetFields();
                        }
                    } catch (e) {
                        console.error("Thrown error ", e);
                        return;
                        //throw e;
                    }
                    
                    let success = true;
                    if(response.status === 200){
                        console.log("Successful submit");
                    } else if (response.status === 201){
                        console.log("Created Resource");
                    } else if ( response.status === 204){
                        console.log("Submit with no content response");
                    } else {
                        success = false;
                        console.error("Fail with resposne ", response);
                    }

                    if(success) {
                        this.setState({inputFiles: {}});
                    }
                } else {
                    console.error("Got validation err ", err);

                }
            });
        }
    }

    revert = (e) => {
        this.props.form.resetFields();
        this.setState({inputRefs: {}});
    }

    showModal = () => {
        this.setState({modalVisible: true});
    }

    hideModal = () => {
        const { onCancel } = this.props;
        this.setState({modalVisible: false}, () => {
            if(onCancel){
                onCancel();
            }
        });
    }

    render(){
        const { showModalInstantly, modalSubmitText, modalDisabled, modalIcon, modalButtonPrimary, modalButtonLabel, modalForm } = this.props;
        const { submitting } = this.state;


        if(modalForm) {
            const { modalVisible } = this.state;
            return <UserContext.Consumer>
                {({state}) => {
                    const onSubmit = this.submitForUser(state.user);
                    return <React.Fragment>
                    {showModalInstantly ? null : 
                        <Button disabled={modalDisabled} style={{marginRight: '12px'}} icon={modalIcon} type={modalButtonPrimary ? 'primary' : null} onClick={this.showModal}>{modalButtonLabel}</Button>}
                        <Modal visible={modalVisible}
                            title={modalButtonLabel}
                            okText={modalSubmitText}
                            onCancel={this.hideModal}
                            onOk={onSubmit}
                            confirmLoading={submitting}
                            >
                            <FormProvider context={this.formContext}>{this.renderForm()}</FormProvider>
                        </Modal>
                    </React.Fragment>
                }}
            </UserContext.Consumer>
        } else {
            return <FormProvider context={this.formContext}>{this.renderForm()}</FormProvider>;
        }

    }
    
    renderForm(){
        const { embedded, modalForm, fields } = this.props;
    
        let className = (embedded || modalForm) ? 'modal-form' : null;
        let topButtons = this.topButtons();
        let lowerButtons = this.lowerButtons();

        const controlsLayout = {
            labelCol: {
                xs: { span: 0 },
                sm: { span: 0 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 14, offset: 0},
            },
        };

        
        return <UserContext.Consumer>
            {({state}) => {
                let user = state.user;
                const onSubmit = this.submitForUser(state.user);
                return <Form  className={className} onValuesChange={this.handleValueChange} onSubmit={onSubmit}>
                {topButtons ? <FormItem {...controlsLayout} className='form-item-buttons' >{topButtons}</FormItem> : null}
                {fields.map((item, index, array) => this.renderFormItem(item, index, array, user)).filter(v => v)}
                {lowerButtons ? <FormItem {...controlsLayout} className='form-item-buttons' >{lowerButtons}</FormItem> : null}
            </Form>
            }}
        </UserContext.Consumer>
    }

    deleteButton = () => {
        const { deletePrivileges, deleteEnabled } = this.props;

        if(deleteEnabled) {
            const controlsLayout = {
              labelCol: {
                xs: { span: 0 },
                sm: { span: 0, },
              },
            };

            const deleteStyle = { float: 'left' };

            return renderCondition(deletePrivileges, <FormItem className='form-item-delete-button' {...controlsLayout}>
                    <Button danger onClick={this.handleDelete} style={deleteStyle}>Delete</Button>
            </FormItem>);
        } else {
            return null;
        }

    }

    handleDelete = async () => {
        const { onDelete } = this.props;


        try {
            await onDelete();
        } catch (e) {
            console.error("Thrown error ", e);
        }
    }

    topButtons = () => {
        const { values, accessControlList, formActions, formTemplates, formTemplateSearch, deletePrivileges, editPrivileges, showEditButtons, deleteEnabled, createMode, form, readOnly } = this.props;
        const FormContextConsumer = this.formContext.Consumer;

        if(createMode && formActions.length === 0 && Object.keys(formTemplates || {}).length === 0 && !formTemplateSearch){
            return null;
        }

        return <FormContextConsumer>
            {({state, actions}) => {

                const revertAction = (e) => {
                    this.revert(e);
                    actions.resetFormState();
                }

                return <React.Fragment>
                    <Controls onRevert={revertAction} 
                             values={values}
                             renderAccessControl={accessControlList}
                             showEditButtons={(!createMode && !readOnly) && showEditButtons}
                             showTemplates={(createMode && !readOnly)}
                             renderDelete={createMode ? false : deleteEnabled} 
                             onDelete={this.handleDelete}
                             editPrivileges={editPrivileges}
                             deletePrivileges={deletePrivileges}
                             onFormAction={this.handleFormAction}
                             onTemplateApply={this.applyTemplate}
                             formActions={formActions}
                             formTemplates={formTemplates}
                             formTemplateSearch={formTemplateSearch}
                             changesMade={form.isFieldsTouched()} 
                            /> 
                </React.Fragment>

            }}
        </FormContextConsumer>
    }

    lowerButtons = () => {
        const { values, editPrivileges, createMode, modalForm, form } = this.props;
        const FormContextConsumer = this.formContext.Consumer;

        if(!createMode || modalForm) {
            return null;
        }

        return <FormContextConsumer>
            {({state, actions}) => {

                const revertAction = (e) => {
                    this.revert(e);
                    actions.resetFormState();
                }

                return <React.Fragment>
                    <Controls onRevert={revertAction} 
                              values={values}
                              renderDelete={false} 
                              renderAccessControl={false}
                              editPrivileges={editPrivileges}
                              onFormAction={this.handleFormAction}
                              changesMade={form.isFieldsTouched()} 
                              createMode={createMode} /> 
                </React.Fragment>

            }}
        </FormContextConsumer>

    }

    applyTemplate = (template) => {
        const { setFieldsValue } = this.props.form;

        this.revert();
        setFieldsValue(template.values);
    }

    handleFormAction = (method, parameters) => {
        const { history, form, values, onReload, onActionStart, onActionEnd } = this.props;

        const resource = {
            values,
            parameters,
            formState: this.state,
            form,
            loading: onActionStart,
            finished: onActionEnd,
            reload: onReload,
            success: Modal.success,
            error: Modal.error,
            history,
        }

        method(resource);
    }


    renderFormItem = (item, index, array, user) => {
        const { location, modalForm, embedded, values, createMode, selectableOwner } = this.props; 
        const { inputRefs } = this.state;
        const type = item.type || 'text';
        const formItemDef = FormItemConfigs[type];

        if(!user) {
            return null;
        }

        if(createMode){

            if(!selectableOwner && item.ownerField) {
                return null;
            }

            if(item.static) {
                return null;
            }

            if(item.showInCreateMode !== undefined && item.showInCreateMode === false) {
                return null;
            }

            if(formItemDef.showInCreateMode === false) {
                return null;
            }
        } else {

            if(item.showInEditMode !== undefined && item.showInEditMode === false) {
                return null;
            }

            if(formItemDef.showInEditMode === false) {
                return null;
            }

            if(item.disableRender && item.disableRender(values)) {
                return null;
            }
        }

        if(item.readPrivileges) {
            if(userHasPrivileges(item.readPrivileges, user) === false){
                return null;
            }
        }


        let normalLayout = {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 4, },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 10, offset: 0},
          },
        };

        const modalLayout = {
          labelCol: {
            xs: { span: 0 },
            sm: { span: 0, },
          },
          wrapperCol: {
            xs: { span: 98 },
            sm: { span: 34 },
          },
        };

        let formItemLayout = (embedded || modalForm) ? modalLayout : normalLayout;

        
        let label = item.label || titleCase(item.key);

        if(item.type === 'references') {
            formItemLayout.labelCol = {};
            formItemLayout.wrapperCol = {};
            label = null;
        }

        let initialValue;
        let pushedFormValues = (location.state && location.state.pushedFormValues) || {};
        //TODO: Maybe clean these conditionals up...
        if(values) {
            if(item.type === 'time') {
                if(createMode) {
                    initialValue = values[item.key] || (createMode ? item.defaultValue || null : null);
                } else {
                    if(item.date_key && values[item.date_key]) {
                        initialValue = moment(values[item.date_key]);
                    } else if (values[item.key]) {
                        initialValue = values[item.key];
                    } else {
                        initialValue = item.defaultValue || null;
                    }
                }
            } else if(item.type === 'array') {
                //TODO: postgres sends [null] arrays, so we need to check for this case, perhaps fix this server side
                const array_values = values[item.key];
                if(array_values.length === 1 && array_values[0] === null) {
                    initialValue = createMode ? (item.defaultValue || undefined) : undefined;
                } else {
                    initialValue = values[item.key] || (createMode ? item.defaultValue || undefined : undefined);
                }
            } else {
                initialValue = values[item.key] || (createMode ? item.defaultValue || null : null);
            }
        } else {
            if(item.type === 'array') {
                initialValue = pushedFormValues[item.key] || item.defaultValue || undefined;
            } else {
                initialValue = pushedFormValues[item.key] || item.defaultValue || null;
            }
        }

        if(initialValue && type === 'date') {
            initialValue = moment(initialValue);
        }

        let required = createMode ? item.requiredOnCreate || item.required || false : item.required || false;
        let defaultDecoration = {
            initialValue,
            rules: [
                {required}
            ]
        }

        let decoration = Object.assign({}, formItemDef.decoration, defaultDecoration);

        let className = item.type !== 'references' && item.type !== 'files' ? 'form-item' : null;

        let marginStyle = (index+1 === array.length) ? {marginBottom: '24px'} : null;

        const { condition } = item;
        let passCondition = true;
        if(condition){
            if(typeof condition === 'function') {
                passCondition = condition(values, inputRefs);
            } else if (typeof condition === 'object') {
                //check valid values for condition to be true using inputRefs or values if no inputRefs are assigned (so condition can be set at load time)
                if(condition.values){
                    //multiple valid values
                    passCondition = (inputRefs && condition.values.includes(inputRefs[condition.key])) || 
                                    (isEmptyObject(inputRefs) && values && condition.values.includes(values[condition.key]));
                } else if (condition.value) {
                    //single value
                    passCondition = (inputRefs && condition.value === (inputRefs[condition.key])) || 
                                    (isEmptyObject(inputRefs) && values && condition.value === (values[condition.key]));
                }
            }
        }

        if(!passCondition) {
            return null;
        }

        let writePermission = true;
        if(item.writePrivileges) {
            writePermission = userHasPrivileges(item.writePrivileges, user)
        }

        //fix in create mode
        if(!createMode && item.link && values[item.key]) {
            if(userHasPrivileges(item.link.privileges, user)) {
                const url = bindURL(item.link.url, values);
                label = <a href={url}>{label}</a>
            }
        }

        if(!createMode && item.maplink) {
            let components = item.maplink.components || ['address', 'address2,', 'city', 'state', 'zipcode'];
            let c = components.map(c => values[c]);
            if (c[0]) {
                const google_url = googleMapURL(c[0], c[1], c[2], c[3], c[4])
                const apple_url = appleMapURL(c[0], c[1], c[2], c[3], c[4])
                label = <span>{label} <a href={google_url}>🌎</a> <a href={apple_url}>🗺️</a></span>
            }
        }

        return <FormItem className={className} 
                                style={marginStyle}
                                prompt={'Prompt'}
                                colon={false} 
                                extra={item.hint} 
                                key={'form_item_'+index+'_'+item.key} 
                                {...formItemLayout} 
                                label={label}>
                    {this.renderFormItemComponent(item, type, decoration, writePermission, user)}
        </FormItem>
    }

    renderFormItemComponent = (item, type, decoration, writePermission, user) => {
        const { getFieldDecorator } = this.props.form;
        const { createMode, values } = this.props; 
        const formItemDef = FormItemConfigs[type];
        const { inputRefs } = this.state;
        if(!item.key) {
            throw new Error("Form item is missing key name");
        }

        if(item.inputRef) {
            console.log(`Input ref ${item.inputRef}`);
            item.onCoordinatedChange = (value) => {
                console.log(`Changing ${value}`);
                this.setState(previousState => {
                    let newRefs = Object.assign({}, previousState.inputRefs);
                    newRefs[item.inputRef] = value;
                    console.log("State change ");
                    return {inputRefs: newRefs}
                });
            }
        }


        if(item.static || (!createMode && item.createOnly) || !writePermission) {
            let staticValue = values ? values[item.key] : '';

            if(staticValue && item.displayKey) {
                staticValue = values[item.displayKey] || staticValue; 
            }

            if(item.type === 'address') {
                const { address, address2, city, state, zipcode } = values;
                staticValue = [address, address2, city, state, zipcode].filter(v => v).join(', ');
            }

            if(item.computeValue) {
                staticValue = item.computeValue(values);
            }

            return formItemDef.staticRender(staticValue, item);
        } else {
            const { inputRender, actionRender } = formItemDef;
            if(item.type === 'image' || item.type === 'file') {
                const { inputFiles } = this.state;
                return getFieldDecorator(item.key, decoration)(inputRender(item, values, inputRefs, user, this.preUploadFunction(item), this.removeFileFunction(item), inputFiles[item.key]))
            } else {
                return <React.Fragment>{getFieldDecorator(item.key, decoration)(inputRender(item, values, inputRefs, user))}{actionRender ? actionRender(item, values, inputRefs, user) : null}</React.Fragment>
            }
        }

    }

    removeFileFunction = (item) => {

        return  (file) => {
            this.setState(previousState => {
                let newRefs = Object.assign({}, previousState.inputFiles);
                let keyFiles = newRefs[item.key] || [];
                if(item.allowMultipleFiles) {
                    let index = keyFiles.indexOf(file);
                    const newList = keyFiles.slice();
                    newRefs[item.key] = newList.splice(index,1);
                } else{
                    delete newRefs[item.key];
                }

                return {inputFiles: newRefs}
            });
        }

    }

    preUploadFunction = (item) => {

        return (file) => {
            this.setState(previousState => {
                let newRefs = Object.assign({}, previousState.inputFiles);
                let keyFiles = newRefs[item.key] || [];
                console.log("File ", file);
                if(item.allowMultipleFiles) {
                    newRefs[item.key] = [...keyFiles, file];
                } else{
                    newRefs[item.key] = [file];
                }
                return {inputFiles: newRefs}
            });

            return false;
        }
    }

}



WrappedForm.defaultProps = defaultProps;

const DecoratedForm = Form.create()(WrappedForm);

export default withRouter(DecoratedForm);
