import colors from 'lib/constants/resourceColors';

const form = [
    {label: 'Unique ID', key: 'name', createOnly: true},
    'display_name',
    {type: 'number', key: 'sales_tax_rate', createOnly: true},
    {type: 'number', key: 'sales_sur_tax_rate', createOnly: true},
    {type: 'number', key: 'sales_sur_tax_limit', createOnly: true},
    '?deprecated',
]

export default {
    defaults: {
        name: 'Tax Region',
        color: colors.taxRegion,
        formFields: form
    },

    search: {
        fields: ['id','name', 'display_name', 'deprecated', 'updated_at'],
        links: ['new'],
        searchBy: ['name', 'display name'],
        tableProps: {
            rowURL: '/tax_regions/:id',
            columns: [
                {heading: 'Display Name', accessor: 'display_name'},
                {heading: 'Unique ID', accessor: 'name'},
                {heading: 'Deprecated', accessor: 'deprecated', type: 'boolean'}
            ],
            showHeading: false,
        }
    },
};
