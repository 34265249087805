import colors from 'lib/constants/resourceColors';
export default {

    defaults: {
        name: 'Sent Email',
        color: colors.sentEmails,
        actions: ['search'],
    },

    search: {
        fields: ['reference_id', 'reference_name', 'email_type', 'subject', 'sent_to', 'sent_from', 'cc', 'created_at'],
        allowDateFilter: true,
        tableProps: {
            columns: [
                {heading: 'Reference', accessor: 'reference_name'},
                {heading: 'Type', accessor: 'email_type'},
                {heading: 'Date', type: 'date', dateFormat: 'MM/DD/YY h:mm A', accessor: 'created_at'},
                {heading: 'Email', type: 'multiple', rows: [
                    {heading: 'From', accessor: 'sent_from'},
                    {heading: 'To', accessor: 'sent_to'},
                    {heading: 'CC', accessor: 'cc'},
                    {heading: 'Subject', style:'bold', accessor: 'subject'}
                ]},
            ]
        }
    }
}
