import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import api from 'api';


function OneToMany(props) { 

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const { url, relations } = props;

        async function fetch() {
            let response;

            if(!relations) {
                setLoading(true);

                try {
                    response = await api.get(url)
                } catch (e) {
                    setError(e);
                }

                setLoading(false);
                if(response.errors) {
                    setData([]);
                    setError(response.errors);
                } else {
                    setData(response.body);
                    setError(null);
                }
            }

        };

        fetch();
    }, []);

    const keyFunc = (item) => {
        return item.id;
    }

    const groupKey = (item, index) => {
        return `${item.heading}_${index}`;
    }

    const { pagination = false, selectedRowKeys, onChange, columns, relations, groups, groupColumns } = props;

    const rowSelection = relations ? null : {
        selectedRowKeys,
        onChange,
    };

    let source = data || relations;

    if(groups) {
        return groups.map(group => {
            let title = () => group.heading;
            return <Table rowKey={groupKey} 
                    style={{marginBottom: '24px'}}
                    bordered={true}
                    title={title}
                    pagination={false}
                    rowSelection={rowSelection} 
                    columns={groupColumns} 
                    dataSource={group.relations} />
        });
    } else { 
        return <Table pagination={pagination} rowKey={keyFunc} rowSelection={rowSelection} columns={columns} dataSource={source} />
    }
}


export default OneToMany;
