import React, { useEffect, useState } from 'react';
import { Button, Avatar, Spin, Card, Icon} from 'antd';
import api from 'api';
import './style.css'; 
import moment from 'moment';
import { userHasPrivileges } from 'lib/helpers/renderCondition'; 
import Privileges from 'lib/constants/privileges';

function ActivityLogs({parent_id, parent_type, user}) {
    const [activityInput, setActivityInput] = useState('');
    const [activities, setActivities] = useState([]);
    const [inputError, setInputError] = useState(null);
    const [loadError, setLoadError] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchActivities = async (auto_refresh) => {
        setLoading(true);
        let params = {parent_id};
        if(auto_refresh){
            params.event = 'auto';
        }

        api.get('/activity_logs', params)
        .then(result => {
            setLoading(false);
            setSubmitting(false);
            if(result.error) {
                result.setLoadError(result.error);
            } else {
                setActivities(result.body);
                setLoadError(null);
            }
        })
    }

    useEffect(() => {
        let refreshTimer = setInterval(async () => await fetchActivities(true), 15000);

        return () => {
            clearInterval(refreshTimer);
        }
    });

    useEffect(() => {
        const fetchActivities = async () => {
            setLoading(true);
            api.get('/activity_logs', {parent_id})
            .then(result => {
                setLoading(false);
                if(result.error) {
                    result.setLoadError(result.error);
                } else {
                    setActivities(result.body);
                    setLoadError(null);
                }
            })
        }
        fetchActivities();
    }, [parent_id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);
        let description = activityInput;
        setActivityInput('');
        api.post('/activity_logs', {parent_type, parent_id, description})
        .then(result => {
            if(result.error) {
                setInputError(result.error);
                setSubmitting(false);
            } else {
                setInputError(null);
                fetchActivities();
            }
        });
    }

    const handleDelete = (id) => {

        return (e) => {
            e.preventDefault();
            setSubmitting(true);
            api.destroy(`/activity_logs/${id}`)
            .then(result => {
                if(result.error) {
                    setInputError(result.error);
                    setSubmitting(false);
                } else {
                    setInputError(null);
                    fetchActivities();
                }
            });
        }
    }

    const handleRefresh = (e) => {
        e.preventDefault();
        if(loading === false) {
            fetchActivities();
        }
    }

    const allowWrite = userHasPrivileges([Privileges.CREATE_ACTIVITY_LOGS], user);

    return <Card size='small' style={{width: '700px'}}>
            { inputError ? <span style={{color: 'red'}}>{inputError}</span> : null }
            <InputBar disabled={activityInput === ''} allowWrite={allowWrite} loading={loading} onSubmit={handleSubmit} onRefresh={handleRefresh} onChange={(e) => { console.log("Become ", e); setActivityInput(e)}} currentText={activityInput} />
            { loadError ? <span style={{color: 'red'}}>{loadError}</span> : null }
            <div className='logWindow'>
                {activities && activities.length ? activityList(activities, submitting, handleDelete, user) : emptyList(submitting)}
            </div>
        </Card>
}

function emptyList(submitting) {
    if(submitting) {
        return <div className='loadingLine'><Spin /></div>;
    } else {
        return <p>No Activity</p>
    }
}

function activityList(activities, submitting, onDelete, user) {
    let items = activities.sort((a,b) => a.created_at - b.created_at).map(a => {
        let username = a.user === null || typeof a.user === 'undefined' || a.user.trim() === '' ? 'Unknown' : a.user;
        let avatar = a.avatar;
        let time = moment(a.created_at).format('MMM DD, YYYY h:mm a');
        let showDelete = userHasPrivileges([Privileges.DELETE_ACTIVITY_LOGS], user);
        return <div className='activityLine'>
            <div className='logHeader'>
                <Avatar style={{marginRight: '6px'}} shape='square' size="small" icon="user" src={avatar} />
                <span style={{fontWeight: 'bold'}}>{username} <i>({time})</i></span>:
                {showDelete ? <a style={{float: 'right'}} onClick={onDelete(a.id)}>Delete</a> : null}
            </div>
            {a.description ? <p className='activityText'>{a.description}</p> : <p className='emptyText'>No Text</p>}</div>
    });
        
    if(submitting) { 
        items.unshift(<div className='loadingLine'><Spin /></div>);
    }

    return items;
}

function InputBar({disabled, allowWrite, onChange, loading, onRefresh, onSubmit, currentText}) {
    return <div className='inputBar'>
        {allowWrite ? <div><textarea className='inputBox' onChange={(e) => onChange(e.target.value)} value={currentText} /></div> : null }
        <Button loading={loading} icon='reload' style={{marginRight: '6px'}} onClick={onRefresh}>Refresh</Button>
        {allowWrite ? <Button disabled={disabled} onClick={onSubmit}>Submit</Button> : null }
    </div>
}

export default ActivityLogs;
