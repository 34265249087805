import React, { Component } from 'react';
import localforage from 'localforage';


export const InterfaceContext = React.createContext();


export class InterfaceProvider extends Component {
    state = {
        rehydrated: false,
        sidebarIsCollapsed: false,
    }
  
    toggleCollapse = () => {
        const { sidebarIsCollapsed } = this.state;

        this.setState({sidebarIsCollapsed: !sidebarIsCollapsed});
    }

    componentDidMount() {
        this.hydrateStateWithLocalStorage();

        // add event listener to save state to localStorage
        // when user leaves/refreshes the page
        window.addEventListener(
          "beforeunload",
          this.saveStateToLocalStorage.bind(this)
        );
    }

    componentWillUnmount() {
        window.removeEventListener(
          "beforeunload",
          this.saveStateToLocalStorage.bind(this)
        );

        // saves if component has a chance to unmount
        this.saveStateToLocalStorage();
    }

    hydrateStateWithLocalStorage = () => {
        localforage.getItem('interface')
        .then(recoveredState => {
            if(recoveredState){
                recoveredState.rehydrated = true;
                this.setState(recoveredState);
            }
        })
        .catch(e => {
            console.log("Error Recovering Interface State: ", e);
        });
    }

    saveStateToLocalStorage = () => {
        let saveState = {};
        Object.keys(this.state).forEach(key => {
            saveState[key] = this.state[key];
        });

        delete saveState.rehydrated;
        
        localforage.setItem('interface', saveState);
    }

    setInterfaceState = (stateChange) => {

        this.setState(stateChange);
    }

    render(){
        const actions = { setInterfaceState: this.setInterfaceState, toggleCollapse: this.toggleCollapse };

        return <InterfaceContext.Provider value={{state: this.state, actions}}>
            {this.props.children}
        </InterfaceContext.Provider>
    }

}

