import colors from 'lib/constants/resourceColors';
import Privileges from 'lib/constants/privileges';
import api from 'api';
import formatPhoneNumber from 'lib/helpers/formatPhoneNumber';

function statusBadgeStyle(value, data){
    const { status } = data;

    if(status === 'closed') {
        return {status: 'default', text: 'Closed'};

    } else if (status === 'open'){
        return {status: 'success', text: 'Open'}; 

    } else if (status === 'void') {
        return {status: 'error', text: 'Void'}; 
    } else { 
        return null;
    }
}

export const createActions = [
            {   label: 'Import Details from Billing Customer',
                icon: 'solution',
                method: async ({form}) => { 
                    console.log("Import") 
                    try { 
                        let id = form.getFieldValue('billing_customer_id');
                        if(!id) {
                            return;
                        }

                        let result = await api.get(`/customers/${id}`)
                        let customer = result.body;
                        console.log("Got customer", customer);
                        const { name, address, address2, city, state, zipcode } = customer;
                        form.setFieldsValue({name, address, address2, city, state, zipcode});
                    } catch (e) {
                        throw new Error(e);
                    }
                }
            }
        ];

export const form = [

    {key: 'site_number', label: 'Site Number', static: true, readOnly: true},


    {key: 'name', label: 'Site Name', required: true},

    {key: 'type', 
        defaultValue: 'Full Service',
        required: true,
        label: 'Site Type',
        type: 'options',
        options: [
            {value: 'Full Service', label: 'Full Service'},
            {value: 'Monitoring', label: 'Monitoring'},
            {value: 'Non_Contract', label: 'Non Contract'},
        ],
    },

    {key: 'parent_site_id', 
     displayKey: ['parent_site_name'],
        label: 'Parent Site',
        link: { url: '/sites/:parent_site_id', privileges: Privileges.READ_SITES },
        type: 'autocomplete', 
        suggestFormat: (c) => { 
            let text = `${c.name}`;
            let subtexts = [];
            if(c.address) {
                subtexts.push(c.address);
            } else {
                subtexts.push('(No Address)');
            }

            if(c.parent_site_name) {
                subtexts.push(c.parent_site_name);
            }
            return {value: c.id, text, subtexts} 
        },
        suggestUrl: '/sites', 
        suggestFields: ['id', 'name', 'address', 'parent_site_name']},

    {key: 'address', label: 'Address', maplink: true},
    {key: 'address2', label: 'Address Line 2'},
    {key: 'city', label: 'City'},
    {key: 'state', 
        defaultValue: 'Florida',
        label: 'State',
        type: 'options',
        options: [
            {value: 'Alabama', label: 'Alabama'},
            {value: 'Alaska', label: 'Alaska'},
            {value: 'Arizona', label: 'Arizona'},
            {value: 'Arkansas', label: 'Arkansas'},
            {value: 'California', label: 'California'},
            {value: 'Colorado', label: 'Colorado'},
            {value: 'Connecticut', label: 'Connecticut'},
            {value: 'Delaware', label: 'Delaware'},
            {value: 'Florida', label: 'Florida'},
            {value: 'Georgia', label: 'Georgia'},
            {value: 'Hawaii', label: 'Hawaii'},
            {value: 'Idaho', label: 'Idaho'},
            {value: 'Illinois', label: 'Illinois'},
            {value: 'Indiana', label: 'Indiana'},
            {value: 'Iowa', label: 'Iowa'},
            {value: 'Kansas', label: 'Kansas'},
            {value: 'Kentucky', label: 'Kentucky'},
            {value: 'Louisiana', label: 'Louisiana'},
            {value: 'Maine', label: 'Maine'},
            {value: 'Maryland', label: 'Maryland'},
            {value: 'Massachusetts', label: 'Massachusetts'},
            {value: 'Michigan', label: 'Michigan'},
            {value: 'Minnesota', label: 'Minnesota'},
            {value: 'Mississippi', label: 'Mississippi'},
            {value: 'Missouri', label: 'Missouri'},
            {value: 'Montana', label: 'Montana'},
            {value: 'Nebraska', label: 'Nebraska'},
            {value: 'Nevada', label: 'Nevada'},
            {value: 'New Hampshire', label: 'New Hampshire'},
            {value: 'New Jersey', label: 'New Jersey'},
            {value: 'New Mexico', label: 'New Mexico'},
            {value: 'New York', label: 'New York'},
            {value: 'North Carolina', label: 'North Carolina'},
            {value: 'North Dakota', label: 'North Dakota'},
            {value: 'Ohio', label: 'Ohio'},
            {value: 'Oklahoma', label: 'Oklahoma'},
            {value: 'Oregon', label: 'Oregon'},
            {value: 'Pennsylvania', label: 'Pennsylvania'},
            {value: 'Rhode Island', label: 'Rhode Island'},
            {value: 'South Carolina', label: 'South Carolina'},
            {value: 'South Dakota', label: 'South Dakota'},
            {value: 'Tennessee', label: 'Tennessee'},
            {value: 'Texas', label: 'Texas'},
            {value: 'Utah', label: 'Utah'},
            {value: 'Vermont', label: 'Vermont'},
            {value: 'Virginia', label: 'Virginia'},
            {value: 'Washington', label: 'Washington'},
            {value: 'West Virginia', label: 'West Virginia'},
            {value: 'Wisconsin', label: 'Wisconsin'},
            {value: 'Wyoming', label: 'Wyoming'}
        ],
    },
    {key: 'zipcode', label: 'Zipcode'},

    {key: 'billing_customer_id', 
     displayKey: ['customer_name'],
        label: 'Default Billing Customer',
        link: '/customers/:billing_customer_id',
        type: 'autocomplete', 
        suggestFormat: (c) => { 
            let text = `${c.name} (${c.address || 'No Address'})`;
            return {value: c.id, text} 
        },
        suggestUrl: '/customers', 
        suggestFields: ['id', 'name', 'address']},

    {key: 'monitoring_account', label: 'Monitoring Account#'},
    {key: 'nfpa_edition', label: 'NFPA 72 Edition'},
    {key: 'control_unit_model', label: 'Control Unit Model'},

    {key: 'is_ul', label: 'UL', type: 'boolean'},
    {key: 'ul_expiration', label: 'UL Expiration', type: 'date'},

    //{key: 'credit_hold', label: 'Credit Hold', type: 'boolean', static: true},

    {key: 'special_access', label: 'Special Access', type: 'boolean'},
    {key: 'access_notes', label: 'Access Notes', type: 'longtext'},
    {key: 'notes', label: 'Site Notes', type: 'longtext'},



    {key: 'contacts',
        type: 'references',
        heading: 'Site Contacts',
        createLabel: 'Add New Contact',
        primaryCreate: false,
        createUrl: (values) => { return `/sites/${values.id}/contacts` },
        dataUrl: '/sites/:id/contacts',
        formFields: [
                {key: 'name',  
                    required: true,
                },
                {key: 'title',  
                },
                {key: 'phone', label: 'Office Phone'
                },
                {key: 'mobile',  
                },
                {key: 'fax',  
                },
                {key: 'email',  
                },

        ],
        tableProps: {
             rowURL: '/contacts/:id',
            columns: [
                {heading: 'Name', 
                    accessor: 'name',
                },
                {heading: 'Title', 
                    accessor: 'title',
                },
                {heading: 'Office', 
                    accessor: 'phone',
                    valueFunc: ((d,v) => {
                        return formatPhoneNumber(v) || v;
                    })
                },
                {heading: 'Mobile', 
                    accessor: 'mobile',
                    valueFunc: ((d,v) => {
                        return formatPhoneNumber(v) || v;
                    })
                },
                {heading: 'Fax', 
                    accessor: 'fax',
                    valueFunc: ((d,v) => {
                        return formatPhoneNumber(v) || v;
                    })
                },
                {heading: 'Email', 
                    accessor: 'email',
                },
            ]
        }
    },

    {key: 'inspection_cards',
        type: 'references',
        heading: 'Inspection Cards, Active',
        dataUrl: '/inspection_cards?active=true&site_id=:id',
        tableProps: {
             rowURL: '/inspection_cards/:id',
            columns: [
                {heading: 'Due Date', 
                    type:'date',
                    dateFormat: 'MMM D, YYYY',
                    accessor: 'next_inspection_due_date',
                },
                {heading: 'Category', 
                    accessor: 'category',
                },
                {heading: 'Type', 
                    accessor: 'inspection_type',
                },
                {heading: 'Frequency', 
                    accessor: 'frequency',
                },
                {heading: 'Billed Amount', 
                    accessor: 'billed_amount',
                },
                {heading: 'UL Billed Amount', 
                    accessor: 'ul_billed_amount',
                },
                {heading: 'Work Hours', 
                    accessor: 'work_hours',
                },
            ]
        }
    },

    {key: 'site_projects',
        type: 'references',
        heading: `Site Projects`,
        readPrivileges: [Privileges.LIST_SITE_PROJECTS],
        dataUrl: '/lists/site_projects?site_id=:id',
        tableProps: {
            rowURL: '/projects/:id',
            columns: [
                {accessor: 'created_at', type: 'date', dateFormat: 'MMM DD, YYYY' },
                {heading: 'Project Number', accessor: 'project_number', cellWidth: '110px', style: 'bold'},
                {accessor: 'salesperson'},
                {heading: 'Description', type:'multiple', rows: [
                    {heading: 'Description', style:'bold', accessor:'description'},
                    {heading: 'Activity', style: 'subtext', accessor:'logs_last_activity_description'},
                    {heading: 'User', style: 'subtext', accessor: 'logs_last_activity_user'},
                ]},
                {accessor: 'customer_name'},
                {type:'badge', 
                    badgePropsFunc: statusBadgeStyle, 
                    heading: 'Status', 
                    accessor: 'status'},
                ]
        }
    },
]

export default {
    defaults: {
        name: 'Site',
        color: colors.site,
        formFields: form,
        actions: ['search', 'create', 'view', 'batch'],
    },

    create: {
        formActions: createActions 
    },

    view: {
        title: (s) => s.name,
        remarks: {
            header: (values) => values.name,
        },
        formActions: [
            {   label: 'Create New Inspection Card',
                icon: 'schedule',
                method: async ({values, form, history}) => { 
                    try { 
                        const site_id = values['id'];
                        const billing_customer_id = values['billing_customer_id'];
                        const pushedFormValues = { site_id, billing_customer_id };
                        history.push('/inspection_cards/new', { pushedFormValues });
                    } catch (e) {
                        throw new Error(e);
                    }
                }
            },
            {   label: 'View Billing Customer',
                icon: 'user',
                validate: ({values}) => {
                    return !!values['billing_customer_id'];
                },
                method: async ({values, history}) => { 
                    const billing_customer_id = values['billing_customer_id'];
                    if(billing_customer_id){
                        history.push(`/customers/${billing_customer_id}`);
                    }
                }
            },
            {   label: 'Open in Google Maps',
                icon: 'google',
                validate: ({values}) => {
                    return !!values['address'];
                },
                method: async ({values, history}) => { 
                    const { address, address2, city, state, zipcode } = values;

                    let slug = [address, address2, city, state, zipcode].filter(v => v).join('+').replace(/,/g, '').replace(/ /g, '+');

                    let url = `https://www.google.com/maps/place/${slug}`
                    window.open(url, '_blank');
                }
            },
            {   label: 'Open in Apple Maps',
                icon: 'apple',
                validate: ({values}) => {
                    return !!values['address'];
                },
                method: async ({values, history}) => { 
                    const { address, address2, city, state, zipcode } = values;

                    let slug = [address, address2, city, state, zipcode].filter(v => v).join('+').replace(/,/g, '').replace(/ /g, '+');

                    let url = `https://maps.apple.com/?address=${slug}`
                    window.open(url, '_blank');
                }
            },
        ],
    },

    search: {
        fields: ['id', 'name', 'address', 'address2', 'city', 'state', 'zipcode', 'parent_site_name'],
        links: ['new',
            {url: '/sites/csv/upload', 
            title:'Upload CSV', 
            requiredPrivileges: [Privileges.CREATE_BATCH_SITES]
            },
        ],
        searchBy: ['name', 'address', 'billing customer', 'monitoring account', 'notes'],
        tableProps: {
            rowURL: '/sites/:id', 
            columns: [{heading: 'Site Info', type:'multiple', rows: [
                     {heading: 'Name', style: 'bold', accessor:'name'},
                        {heading: 'Address', 
                            separator: ', ',
                            style: 'subtext', 
                            accessor:['address', 'address2', 'city', 'state', 'zipcode']},
                        {heading: 'Parent Site',
                            style: 'subtext',
                            accessor:'parent_site_name'}]
                    }],
            showHeading: false,
        }
    },

    batch: {
        form: [
            {key: 'id', label: 'Unique Id'},
            {key: 'owner_id', label: 'Owner Id'},
            {key: 'billing_customer_id', label: 'Billing Customer Id'},
            {key: 'name', label: 'Site Name'},
            {key: 'address', label: 'Address'},
            {key: 'address2', label: 'Address Line 2'},
            {key: 'city', label: 'City'},
            {key: 'state', label: 'State'},
            {key: 'zipcode', label: 'Zipcode'},
        ], 
    }

}
