import React, { Component } from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { css } from 'glamor';
// eslint-disable-next-line
const debug = require('debug')('app:QuoteItems:');

//const propTypes = {};
const defaultProps = {};

const header = css({
    display: 'none',
    margin: '0 10px',
    fontSize: '12px',
    color: '#A9A9A9',
    fontWeight: '400 !important',
    '@media(min-width: 680px)': {
        flexDirection: 'row',
        display: 'flex',
    }
});

const columnSize = (size) => {
    
    return css({
        '@media(min-width: 680px)': {
            flex: size,
            margin: '0 4px',
        }
    });

};

class QuoteItems extends Component {

    render(){
        return (
            <div> 
                <div {...header}>
                    <span {...columnSize('0.5')}>Part Number</span>
                    <span {...columnSize('0.9')}>Description</span>
                    <span {...columnSize('0.5')}>Quantity</span>
                    <span {...columnSize('0.5')}>Unit Price</span>
                    <span {...columnSize('0.5')}>Total Price</span>
                </div>
                {this.props.children}
            </div>
        );
    }
}

//QuoteItems.propTypes = propTypes;
QuoteItems.defaultProps = defaultProps;

export default DragDropContext(HTML5Backend)(QuoteItems);
