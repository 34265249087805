import React, { Component } from 'react';
import { Select, Spin } from 'antd';
import api from 'api';
import debounce from 'lodash/debounce';
import Autosuggest from 'react-autosuggest';

class Suggest extends Component {

    state = {
        suggestions: [],
    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.suggestProduct(value);
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    }

    getSuggestionValue = (suggestion) => {
        return this.props.value;
    }

    renderSuggestion = (suggestion, {query, isHighlighted}) => {
        return <span>{suggestion}</span>

    }

    renderSuggestionsContainer = ({ containerProps , children, query }) => {
      return (
        <div>
            {children}
        </div>
      );
    }

    renderInputComponent = inputProps => {

        return (<div>
            <input {...inputProps} />
        </div>);
    } 

    onSuggestionSelected = (event, { suggestionIndex, method }) => {
        this.handleProductSelect(suggestionIndex);
    }

    suggestProduct = debounce((input) => {

        if(input === '' || !input){
            //Don't suggest anything for empty strings
            this.setState({suggestions: []});
            return;
        }

        api.search('/products', {query: input, 
                              per_page: 16,
                                fields: 'id,product_number,description,discontinued'})
        .then(({headers, errors, body}) => {
            if(errors) {
                throw errors;
            } else {
                const searchedTerm = headers['x-search-term'];
                if(searchedTerm !== this.props.value.trim()){
                    //stale result
                    return;
                }

                //set state
                this.setState({suggestions: body});
            }
                
        })
        .catch(function(error){

        });

    }, 300);


    render() {
        const { onChange, value } = this.props;

        const inputProps = {
            placeholder: 'Add a Part Number',
            value, 
            id: 'product_selector',
            onChange,
            onBlur: this.onBlurSuggestion,
            onKeyDown: this.onKeyDown,
        };

        return <div>
                <Autosuggest
                suggestions={this.state.suggestions}
                alwaysRenderSuggestions={true}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                renderSuggestionsContainer={this.renderSuggestionsContainer}
                renderInputComponent={this.renderInputComponent}
                onSuggestionSelected={this.onSuggestionSelected}
                inputProps={inputProps} />
        </div>
    }
};

export default Suggest;
