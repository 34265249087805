import React from 'react';
//import * as cp from '../components';
import * as resources from 'resources';
import Search from 'components/Search';
import Create from 'components/Create';
import InspectionCalendar from 'components/InspectionCalendar';
import UpcomingInspections from 'components/UpcomingInspections';
import ConfirmInspections from 'components/ConfirmInspections';
import Welcome from 'components/Welcome';
import NotFound from 'components/NotFound';
import Privileges from 'lib/constants/privileges';
import BatchUploader from 'components/BatchUploader';
import Report from 'components/Report';
import ReportPhoneLogs from 'components/ReportPhoneLogs';
import Dashboard from 'components/Dashboard';
import QuotePreview from 'components/QuotePreview';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from 'routes/privateRoute';
import setResourceDefaults from 'lib/helpers/setResourceDefaults';
import TimeOffCalendar from 'components/TimeOffCalendar';
import ProductsImport from 'components/ProductsImport';
import QuoteImportCSV from 'components/Quote/Form/QuoteImportCSV';
import NewForm from 'components/NewForm';


function createRouteForResource(resourceConfig, index){
    const { create } = resourceConfig;

    if(!create){
        return null;
    }

    const key = `${create.routeKey}_${index}`;
    return <PrivateRoute exact path={create.appUrl} 
                          component={Create} 
                        customProps={create} 
                         privileges={create.privileges} 
                                key={key} />
}

function readRouteForResource(resourceConfig, index) {
    const { view } = resourceConfig;

    if(!view) {
        return null;
    }

    const key = `${view.routeKey}_${index}`;
    return <PrivateRoute exact path={view.appUrl} 
                            component={view.component} 
                            customProps={view} 
                            privileges={view.privileges} 
                            key={key} />
}

function searchRouteForResource(resourceConfig, index){
    const { search } = resourceConfig;

    if(!search) { 
        return null;
    }

    const key = `${search.routeKey}_${index}`;
    return <PrivateRoute exact path={search.appUrl} 
                            component={Search} 
                            customProps={search} 
                            privileges={search.privileges} 
                            key={key} />
}

function batchRouteForResource(resourceConfig, index){
    const { batch } = resourceConfig;

    if(!batch){
        return null;
    }

    // let batchProps = {
    //     columnsConfig: batch.form,
    //     defaultTemplate: batch.defaultTemplate
    // };

    const key = `${batch.routeKey}_${index}`;
    return <PrivateRoute exact path={batch.appUrl} 
                          component={BatchUploader} 
                        customProps={batch} 
                         privileges={batch.privileges} 
                                key={key} />

}

function getApplicationRoutes() { 


    return <Switch> 
        <PrivateRoute exact path="/"
            component={Welcome} />

        <PrivateRoute path="/inspections/upcoming"
                privileges={[Privileges.LIST_UPCOMING_INSPECTIONS]}
                component={UpcomingInspections} />

        <PrivateRoute path="/inspections/confirmations"
                privileges={[Privileges.READ_INSPECTIONS]}
                component={ConfirmInspections} />

        <PrivateRoute path="/inspections/schedule"
                component={InspectionCalendar} />

        <PrivateRoute path="/employee_time_off/calendar"
                component={TimeOffCalendar} />

        <PrivateRoute path="/preview/quote/:quote_number"
                component={QuotePreview}
                privileges={[Privileges.OPEN_QUOTE_PREVIEW, Privileges.READ_QUOTES]} />

        <PrivateRoute path="/quotes/:quote_id/import"
                component={QuoteImportCSV}
                customProps={{create_url: '/quote_items',
                    columns: [
                    {name: 'Part Number', key: 'product_number'},
                    {name: 'Quantity', key: 'quantity'},
                ]}}
                privileges={[Privileges.CREATE_QUOTES, Privileges.UPDATE_QUOTES]} />

        <PrivateRoute path="/equipment_releases/:release_id/import/parts"
                component={ProductsImport}
                customProps={{type: 'part'}}
                privileges={[Privileges.CREATE_EQUIPMENT_RELEASES, Privileges.UPDATE_EQUIPMENT_RELEASES]} />

        <PrivateRoute path="/equipment_releases/:release_id/import/services"
                component={ProductsImport}
                customProps={{type: 'service'}}
                privileges={[Privileges.CREATE_EQUIPMENT_RELEASES, Privileges.UPDATE_EQUIPMENT_RELEASES]} />

        <PrivateRoute path="/reports/current_week_time_off"
                 component={Report} /> 

         <PrivateRoute path="/dashboards/company"
             component={Dashboard} /> 

         <PrivateRoute path="/reports/phone_logs"
             component={ReportPhoneLogs} />

        {Object.keys(resources).map((r, index) => batchRouteForResource(setResourceDefaults(resources[r]), index)).filter(v => v)}
        {Object.keys(resources).map((r, index) => createRouteForResource(setResourceDefaults(resources[r]), index)).filter(v => v)}
        {Object.keys(resources).map((r, index) => readRouteForResource(setResourceDefaults(resources[r]), index)).filter(v => v)}
        {Object.keys(resources).map((r, index) => searchRouteForResource(setResourceDefaults(resources[r]), index)).filter(v => v)}

        <Route component={NotFound} />
    </Switch>
};

//for testing new forms
         // <PrivateRoute path="/projects/:id"
         //     component={NewForm} 
         //     customProps={setResourceDefaults(resources.Projects).view} 
         //     privileges={setResourceDefaults(resources.Projects).view.privileges} 
         //     key={'83924'} />
         


export default getApplicationRoutes;

        /*


        <PrivateRoute path="/part/:product_number" 
                 component={cp.Part}
                privileges={[Privileges.READ_PARTS]} />

        <PrivateRoute path="/expense/:expense_number"
                component={cp.Expense}
                privileges={[Privileges.READ_EXPENSES]} />

        <PrivateRoute path="/inspection/:id"
                component={cp.Inspection}
                privileges={[Privileges.READ_INSPECTIONS]} />

        <PrivateRoute path="/expense_payout/:batch_id"
                component={cp.ExpensePayout}
                privileges={[Privileges.READ_EXPENSE_PAYOUTS]} />

        <PrivateRoute path="/inspections/schedule"
                component={cp.InspectionSchedule} />

        <PrivateRoute path="/service/:product_number" 
                 component={cp.Service} 
                privileges={[Privileges.READ_SERVICES]} />

        <PrivateRoute path="/inventory_transfer"
                component={cp.InventoryTransfer}
                privileges={[Privileges.TRANSFER_INVENTORY]} />

        <PrivateRoute path="/quote/:quote_number" 
                 component={cp.Quote} 
                privileges={[Privileges.READ_QUOTES]} />

        <PrivateRoute path="/preview/quote/:quote_number" 
                 component={cp.QuotePreview} 
                privileges={[Privileges.OPEN_QUOTE_PREVIEW, Privileges.READ_QUOTES]} />

        <PrivateRoute path="/time_off/event/:id"
                privileges={[Privileges.READ_EMPLOYEE_TIME_OFF]}
                component={cp.TimeOffEvent} />

        <PrivateRoute path="/time_off/employee/:id"
                privileges={[Privileges.READ_EMPLOYEE_TIME_OFF]}
                component={cp.TimeOff} />

        <PrivateRoute path="/time_used_reports"
                privileges={[Privileges.SEARCH_USED_TIME_OFF]}
                component={cp.TimeUsedReport} />


        <PrivateRoute path="/new/parts" 
                 component={cp.PartsUploader}
                privileges={[Privileges.CREATE_BATCH_PARTS]} />

        <PrivateRoute path="/new/services" 
                 component={cp.ServicesUploader}
                privileges={[Privileges.CREATE_BATCH_SERVICES]} />
        */
