import { EST_PALE_GREEN , EST_PALE_RED } from 'lib/constants/colors';
import colors from 'lib/constants/resourceColors';
import Privileges from 'lib/constants/privileges';

function priorityStyle(value, data) {
    switch(value) {
        case 1:
            return {status: 'default', text: 'Low'};
        case 2: 
            return {status: 'warning', text: 'Medium'};
        case 3:
            return {status: 'error', text: 'High'};
        default:
            return {status: 'default', text: 'Unknown'};
    }
}

export default {
    defaults: {
        name: 'Task',
        color: colors.tasks,
        appUrlBase: '/company/tasks',
    }, 
    search: {
        heading: 'Employee Tasks', 
        cacheKey: 'cache_search_company_tasks',
        allowDateFilter: true,
        fields: ['id', 'attachment_count', 'task_type', 'completed_at', 'created_at', 'due_date', 'task_name', 'last_activity_description', 'last_activity_user', 'task_number', 'user', 'completed', 'description', 'parent_type', 'parent_description', 'priority'],
        filterOptions: [
            {label: 'CAD', filters: {task_type: 'CAD'}},
            {label: 'Inspection', filters: {task_type: 'INSPECTION'}},
            {label: 'Installation', filters: {task_type: 'INSTALLATION'}},
            {label: 'Permitting', filters: {task_type: 'PERMITTING'}},
        ],
        toggleOptions: [
            {key: 'hide_completed', 
                label: 'Hide Completed', 
                param: 'completed', 
                defaultValue: (currentUser) => true, 
                value: (currentUser) => false }
        ],
        links: ['new'],
        searchBy: ['description', 'assigned user', 'creator', 'task parent', 'task type', 'task name or number'], 
        tableProps: {
            rowURL: '/tasks/:id',
            rowColor: (task) => {
                let color = null;
                if(task.completed) {
                    color = EST_PALE_GREEN;
                } else if (!task.user) {
                    color = EST_PALE_RED; 
                }


                return color;
            },
            columns: [
                {heading: 'Task', accessor: 'task_number', cellWidth: '40px'},
                {type: 'date', accessor: 'created_at', cellWidth: '130px'}, 
                {heading: 'Project', placeholder: 'None', accessor: 'parent_description', cellWidth: '150px'}, 
                {heading: 'Description', type:'multiple', rows: [
                    {heading: 'Type', style:'subtext', accessor: 'task_type'},
                    {heading: 'Name', style: 'bold', accessor:'task_name'},
                    {heading: 'Parent', style: 'subtext', accessor: 'parent_description'},
                    {heading: 'Description', accessor:'description'},
                    {heading: 'Last Activity', accessor:'last_activity_description', style:'subtext'},
                    {heading: 'Last Activity User', accessor: 'last_activity_user', style:'subtext',}
                ]},
                {heading: 'Files', type: 'count', icon: 'file-text', accessor: 'attachment_count', cellWidth: '60px'},
                {heading: 'Assigned to', accessor: 'user', cellWidth: '120px'},
                {type: 'date', placeholder: 'None', accessor: 'due_date', cellWidth: '110px'}, 
                {heading: 'Completed', type: 'date', accessor: 'completed_at', cellWidth: '130px'}, 
                {type:'badge', 
                    badgePropsFunc: priorityStyle, 
                    heading: 'Priority', 
                    accessor: 'priority', cellWidth: '60px'},
            ] 
        }
    }
}
