import colors from 'lib/constants/resourceColors';
import Privileges from '../lib/constants/privileges';

const form = [
    {key: 'po_number', label: 'PO Number', static: true, link: {url: '/company_purchase_orders/:company_purchase_order_id', privileges: Privileges.READ_COMPANY_PURCHASE_ORDERS}},
    {key: 'product_number', label: 'Part Number', static: true, link: {url: '/parts/:product_number', privileges: Privileges.READ_PARTS}},
    {key: 'description', type: 'longtext'},
    {key: 'base_cost', type: 'currency'},
    {key: 'quantity', type: 'number'},
];

export default {
    defaults: {
        name: 'Purchase Order Part',
        urlBase: '/company_purchase_order_products',
        color: colors.companyPurchaseOrderParts,
        formFields: form,
        dataUrlBase: '/company_purchase_orders/parts'
    }
}
