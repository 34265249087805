import React, { useState, useEffect } from 'react';
import { Modal, Menu, Icon, Dropdown, Button, Select, Spin } from 'antd';
import ModalForm from 'components/Form';
import { withRouter } from 'react-router-dom'
import { userHasPrivileges } from 'lib/helpers/renderCondition';
import { UserContext } from 'contexts/User';
import Privileges from 'lib/constants/privileges';
import Autocomplete from '../AutoComplete';
import AccessControl from '../AccessControl';
import './style.css';
import api from 'api';
import debounce from 'lodash/debounce';

const { Option } = Select;
const Confirm = Modal.confirm;


function Controls(props) {
    const { history, editPrivileges, changesMade, onTemplateApply, createMode,
        onRevert, values, renderAccessControl = true, showTemplates, formTemplates, formTemplateSearch, 
        renderDelete, accessControlPrivileges, deletePrivileges, formActions, 
        showEditButtons, onDelete, onFormAction } = props;

    const [actionInModal, setActionInModal] = useState(null);
    const [templateInSearch, setTemplateInSearch] = useState(null);
    const [fetchingTemplate, setFetchingTemplate ] = useState(false);
    const [accessControlOpen, setAccessControlOpen] = useState(false);
    const [submittingParameters, setSubmittingParameters] = useState(false);
    const [ dataSource, setDataSource ] = useState([]);
    const [submission, setSubmission] = useState({});

    useEffect(() => {
        if(actionInModal) {
            onFormAction(actionInModal.method, submission);
            setActionInModal(null);
            setSubmittingParameters(true);
        }

    }, [submission]);

    const handleTemplateClick = (e,s) => {

        let template = formTemplates[e.key];
        onTemplateApply(template);
    }

    const handleMenuClick = (e,s) => {
        if(e.key === 'delete') {
            Confirm({
                title: 'Delete',
                content: 'Are you sure you want to delete this?',
                okText: 'Delete',
                okType: 'danger',
                onOk() {
                    onDelete();
                },
            });
        } else if (e.key === 'access_control') {
            setAccessControlOpen(true);

        } else {
            let index = parseInt(e.key);
            let menuOption = formActions[index];
            let method = menuOption.method;
            if(menuOption.parametersForm) {
                setActionInModal(menuOption);

            } else if(menuOption.confirm) { 
                const { title, getContent, content, okText, okType } = menuOption.confirm;

                let confirmContent = getContent ? getContent(values) : content;

                Confirm({
                    title, 
                    content: confirmContent || 'Are you sure you want to do this?',
                    okText,
                    okType,
                    onOk() {
                        onFormAction(method);
                    }
                });
            } else {
                onFormAction(method);
            }
        }
    }

    const closeAccessControl = () => {
        setAccessControlOpen(false);
    }

    const cancelModalAction = () => {
        setActionInModal(null);
    }

    const submitModalAction = (parameters) => {
        setSubmission(parameters);

        return true;
    }

    const renderCreateOptions = () => {
        return <div className='create-controls'>
                <div style={{display: 'block', width: '100%'}} />
                <Button style={{marginRight: '8px', flexBasis: 'auto'}} size='default' type='normal' onClick={history.goBack}>Cancel</Button>
                <Button style={{flexBasis: 'auto'}} size='default' type='primary' htmlType='submit'>Create</Button>
            </div>
    }

    const renderSaveOptions = () => {
        let style = {display: 'flex'};
        return <UserContext.Consumer>
            {({state}) => { 
                return <div className='form-controls'>
                    <div style={style}>
                        {renderActionsDropdown(state.user)}
                        {renderTemplatesAutocomplete(state.user)}
                        {renderTemplatesDropdown(state.user)}
                        <div style={{display: 'block', width: '100%'}} />
                        {showEditButtons ? renderEditButtons(state.user) : null}
                    </div>
                </div>
            }}
        </UserContext.Consumer>
    }

    const renderActionsDropdown = (user) => {
        let menu = renderActionsMenu(user);
        if(!menu || menu.props.children.length === 0) {
            return null;
        }


        return <React.Fragment>
            {renderActionsParameterModal()}
            {renderAccessControlModal()}
            <Dropdown overlay={menu}>
              <Button style={{marginRight: '12px'}}>
                Actions <Icon type="down" />
              </Button>
            </Dropdown>
        </React.Fragment>
    }

    const renderTemplatesAutocomplete = (user) => {

        if(!formTemplateSearch || Object.keys(formTemplateSearch).length == 0 || !showTemplates) {
            //templates are only for creating
            return null;
        }

        const {suggestFormat, suggestFields, suggestUrl, placeholder, displayFunc, displayKey, fieldMappings} = formTemplateSearch

        const onChange = (value, option) => {
            console.log(`APPLY `, value, option);
            let template = { values: {}};
            for (const [key, field] of Object.entries(fieldMappings)) {
                template.values[field] = option.props.rawvalue[key];
            }
            setTemplateInSearch(value)
            onTemplateApply(template);
        }

        const onSearch = debounce((searchText) => {
            search(searchText);
        }, 300);

        const search = (searchText) => {
            if(!searchText || searchText === '') {
                return;
            }

            setFetchingTemplate(true);
            api.get(suggestUrl, {query: searchText, per_page: 5, fields: suggestFields.join(',')})
            .then(result => {
                setFetchingTemplate(false);
                const searchedTerm = result.headers['x-search-term'] || '';
                if(searchedTerm !== searchText){
                    //stale result
                    return;
                } 
                let completions = result.body.map(o => {
                    return suggestFormat(o);
                });
                setDataSource(completions);
            })
            .catch(error => {
                setFetchingTemplate(false);
            })
        }

        let content;
        if(dataSource.length) {
            content = dataSource.map(d => {
                let subtexts;
                if(d.subtexts) {
                    subtexts = d.subtexts.map(subtext => {
                        return <div style={{fontSize: '12px', lineHeight: '16px', fontStyle: 'italic'}}>{subtext}</div>
                    });
                }
                return <Option rawvalue={d.value} key={d.text}>{d.text}{subtexts}</Option>
            })
        } else if (templateInSearch) {
            let label = `${templateInSearch}`
            content = <Option key={templateInSearch}>{label}</Option> 
        } else {
            content = null;
        }

        return <React.Fragment>
            <Select
                allowClear={true}
                showSearch={true}
                showArrow={false}
                placeholder={placeholder}
                notFoundContent={fetchingTemplate ? <Spin style={{marginLeft: '12px'}} size="small" /> : null}
                filterOption={false}
                onSearch={onSearch}
                onSelect={onChange}
                style={{ minWidth: '250px', width: '100%' }}>
                {content}
            </Select>
        </React.Fragment>
    }

    const renderTemplatesDropdown = (user) => {

        if(!showTemplates) {
            //templates are only for creating
            return null;
        }

        let menu = renderTemplatesMenu(user);
        if(!menu || menu.props.children.length === 0) {
            return null;
        }
        return <React.Fragment>
            <Dropdown overlay={menu}>
              <Button>
                Templates <Icon type="down" />
              </Button>
            </Dropdown>
        </React.Fragment>
    }


    const renderActionsParameterModal = () => {
        let visible = (actionInModal !== null && typeof actionInModal !== 'undefined');

        if(!visible) {
            return null;
        }

        return <ModalForm 
                values={{}}
                showEditButtons={false}
                deleteEnabled={false}
                modalForm={true}
                showModalInstantly={true}
                modalSubmitText={actionInModal.submitText}
                onSubmit={submitModalAction}
                onCancel={cancelModalAction}
                fields={actionInModal.parametersForm} 
                />
    }

    const renderAccessControlModal = () => {

        return <Modal 
            cancelButtonProps={{style: {display: 'none'} }}  
            title='Access Control' 
            visible={accessControlOpen} 
            onOk={closeAccessControl}>
            <AccessControl values={values} object_table={'projects'} />
        </Modal>

    }

    const renderTemplatesMenu = (user) => {
        let nodes = Object.keys(formTemplates).map(key => {
            let t = formTemplates[key];
            return <Menu.Item key={key}><span>{t.name}</span></Menu.Item>;
        });
        if(nodes.length) {
            return <Menu key='template_menu' onClick={handleTemplateClick}>
                {nodes}
            </Menu>
        } else {
            return null;
        }
    }

    const renderActionsMenu = (user) => {

        if((!renderDelete && !renderAccessControl) && formActions.length === 0){
            //no actions to show
            return null;
        }

        let menuNodes = formActions.map((action, index) => {
            let icon = action.icon ? <Icon type={action.icon} /> : null;
            const disabled = action.validate && !action.validate({values}) ? true : false;
            let node = <Menu.Item disabled={disabled} key={index}>{icon}{action.label}</Menu.Item>

            if(action.visible && (!values || !action.visible({values}))) {
                node = null;
            }

            if(action.privileges && !userHasPrivileges(action.privileges, user)) {
                node = null;
            }

            return node;
        }).filter(n => n);

        let deleteOption = <Menu.Item key='delete'><Icon style={{color: 'red'}} type="delete" /><span style={{color: 'red'}}>Delete</span></Menu.Item>
        let accessControlOption= <Menu.Item key='access_control'><Icon type="lock" /><span>Access Control</span></Menu.Item>
        let accessControlPrivileges = [Privileges.READ_ACCESS_RULES, Privileges.UPDATE_ACCESS_RULES];

        let userCanDelete = renderDelete && userHasPrivileges(deletePrivileges, user);
        let userCanAccessControl = renderAccessControl && userHasPrivileges(accessControlPrivileges, user);

        let renderedNodes = [];
        if(menuNodes.length) {
            renderedNodes.push(menuNodes);
        }

        if((menuNodes.length && (userCanDelete || userCanAccessControl))) {
            renderedNodes.push(<Menu.Divider />);
        }

        if(userCanAccessControl) {
            renderedNodes.push(accessControlOption);
        }

        if(userCanDelete) {
            renderedNodes.push(deleteOption);
        }

        if(renderedNodes.length) {
            return <Menu key='action_menu' onClick={handleMenuClick}>
                {renderedNodes}
            </Menu>
        } else {
            return null;
        }
    }

    const renderEditButtons = (user) => {

        if(userHasPrivileges(editPrivileges, user)) {
            return <React.Fragment>
                <Button disabled={!changesMade} style={{marginRight: '8px', flexBasis: 'auto'}} size='default' type='normal' onClick={onRevert}>Undo</Button>
                <Button disabled={!changesMade} style={{flexBasis: 'auto'}}size='default' type='primary' htmlType='submit'>Save Changes</Button>
            </React.Fragment>
        } else {
            return null;
        }
    }

    if(createMode) {
        return renderCreateOptions();
    } else {
        return renderSaveOptions();
    }
}


export default withRouter(Controls);
