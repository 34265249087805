import React, { Component } from 'react';
import { List, Spin, Select, Button } from 'antd';
import './style.css'
import api from 'api';
import { Map } from 'immutable';
import { userHasPrivileges } from 'lib/helpers/renderCondition'; 

const Option = Select.Option;


const defaultProps = {
    emptyText: 'No Relations',
    submitLabel: 'Add',
    writePrivileges: [],
}

class Relational extends Component {

    state = {
        loading: false,
        loadingRelations: false,
        input: '',
        selectedOption: null,
        sort: undefined,
        options: [],
        relations: Map(),
        relationStates: Map(),
        error: null,
    }

    componentDidMount() {

        this.fetchOptions();
        this.loadExistingRelations();
    }

    loadExistingRelations = () => {
        const { relationTransform, values, relationsUrl } = this.props;

        const setInitialRelationStates = (data) => {
            let relations = data.map(relationTransform).map(r => [r.value, r]); 
            let relationStates = relations.map(r => [ r[0], Map({pending: false, removing: false}) ]); 
            this.setState({relations: Map(relations), 
                           relationStates: Map(relationStates), 
                           loadingRelations: false, 
                           error: null});

        }

        if(relationsUrl) {
            let url = typeof relationsUrl === 'function' ? relationsUrl(values) : relationsUrl;

            this.setState({loadingRelations: true});
            api.get(url)
            .then(({status, body}) => {
                if(status === 200){
                    setInitialRelationStates(body);
                } else {
                    this.setState({relations: Map(), relationStates: Map(), loadingRelations: false, error: body});
                }
            }).catch(e => {
                throw new Error(e);
            });

        } else {
            const { getRelations, values } = this.props;
            let data = getRelations(values);
            setInitialRelationStates(data);
        }
    }

    fetchOptions = () => {
        const { values, optionTransform, optionsUrl } = this.props; 

        let url = typeof optionsUrl === 'function' ? optionsUrl(values) : optionsUrl;

        this.setState({loading: true, options: []}, () => {
            api.get(url)
            .then(results => {
                if(results.status === 200){
                    let options = results.body.map(optionTransform);
                    this.setState({loading: false, options});
                } else {
                    this.setState({options: [], loading: false, error: results.body});
                }
            }).catch(e => {
                throw new Error(e);
            });
        });
    }

    render() {
        const { emptyText, sort } = this.props;
        const { relations, loadingRelations } = this.state;

        let searchBar = this.renderSearchBar();

        const listData = relations.toArray().sort(sort);

        return <List 
                style={{margin: '4px 0px'}}
                bordered 
                footer={searchBar} 
                size='small'
                loading={loadingRelations}
                locale={{emptyText}}
                >
                {listData.map((item, index) => { 
                    return <List.Item key={`l_${index}`} className='relation-row' actions={this.actionsForItem(item)}>
                                <List.Item.Meta className={this.classForItem(item)} title={item.label} />
                           </List.Item>
                })}
                </List>
    }

    classForItem = (item) => {
        let itemState = this.state.relationStates.get(item.value);

        if(itemState.get('removing')){
            return 'removing-relation';
        } else if (itemState.get('pending')) {
            return 'pending-relation';
        } else {
            return null;
        }
    }

    renderSearchBar = () => {
        const { submitLabel, placeholder, user, writePrivileges } = this.props;
        const { options, relations, loading, selectedOption } = this.state;

        if(!userHasPrivileges(writePrivileges, user)) {
            return null;
        }

        return <div className='search-row'>
                <Select
                    allowClear
                    style={{marginRight: '8px' }}
                    optionFilterProp='keyword'
                    showSearch
                    placeholder={placeholder}
                    notFoundContent={loading ? <Spin size="small" /> : null} 
                    value={selectedOption ? selectedOption.value : undefined}
                    onChange={this.handleSelect}>
                    {options.map(option => {
                        const { value, label } = option;
                        const disabled = relations.has(value);
                        return <Option key={value + '_option'} disabled={disabled} keyword={label} value={value}>{label}</Option>
                    })}
                </Select>
                <Button type='primary' 
                       style={{flexBasis: 'auto'}}
                    disabled={selectedOption === null}
                     onClick={this.handleAdd}>
                    {submitLabel}
                </Button>
            </div>
    }

    handleSelect = (value) => {

        this.setState(previousState => {
            let selectedOption = null;
            previousState.options.forEach(o => {
                if(selectedOption){
                    return;
                }

                if(o.value === value) {
                    selectedOption = o;
                }
            });

            return {selectedOption};
        });
    }

    actionsForItem = (item) => {
        const { relationStates } = this.state;

        let itemState = relationStates.get(item.value);

        if(itemState.get('removing')){
            return [<Spin size='small' />];
        } else if (itemState.get('pending')) {
            return [<Spin size='small' />];
        } else {
            const removeAction = () => this.handleRemove(item);
            return [<Button className='remove-link' onClick={removeAction}>Remove</Button>];
        }  
    }

    handleRemove = (item) => {
        const { values, deleteRelation } = this.props;
        const { relations, relationStates } = this.state;

        let newRelations = this.state.relationStates.setIn([item.value, 'removing'], true);

        this.setState({selectedOption: null, relationStates: newRelations} , () => {

            let role_id = item.value;
            deleteRelation(role_id, values)
            .then(result => {

                if(result.status === 200 || result.status === 204) {
                    let committedRelations = relations.delete(role_id); 
                    let newRelationStates = relationStates.delete(role_id);
                    this.setState({relations: committedRelations, relationStates: newRelationStates});
                } else {
                    let newRelationStates = relationStates.setIn([role_id, 'removing'], false);
                    this.setState({relationStates: newRelationStates});
                }

                this.fetchOptions();
            })
            .catch(e => {
                throw new Error(e);
            });
        });
    }

    handleAdd = () => {
        const { values, addRelation } = this.props; 
        const { selectedOption, relations, relationStates } = this.state;

        let previousAdded = relations.has(selectedOption.value);

        let newRelations = relations.set(selectedOption.value, selectedOption);
        let newRelationStates = relationStates.setIn([selectedOption.value, 'pending'], 
                                                     Map({pending: true, removing: false}));
        let newAddition = selectedOption;

        this.setState({selectedOption: null, relations: newRelations, relationStates: newRelationStates}, () => {

            addRelation(newAddition.value, values)
            .then(result => {
                if(result.status === 200 || result.status === 204 || result.status === 201) {
                    let relationStates = this.state.relationStates.setIn([newAddition.value, 'pending'], false);
                    this.setState({relationStates});
                } else {
                    if(previousAdded) {
                        //incase this option was previously added, and this is a duplicate add, don't remove it from the list
                        let relationStates = this.state.relationStates.setIn([newAddition.value, 'pending'], false);
                        this.setState({relationStates});
                    } else { 
                        let relations = this.state.relations.delete(newAddition.value);
                        let relationStates = this.state.relationStates.delete(newAddition.value);
                        this.setState({relations, relationStates});
                    }
                }

                this.fetchOptions();
            })
            .catch(e => {
                throw new Error(e);
            });
        });
    }


}

Relational.defaultProps = defaultProps;

export default Relational;
