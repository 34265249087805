import React from 'react';
import { Menu, Layout } from 'antd';
import options from './options';
import MenuLink from './MenuLink';
import Sub from './Sub';
import './style.css';
import { userHasPrivileges, userHasAnyPrivileges } from 'lib/helpers/renderCondition';
import { UserContext } from 'contexts/User';
import { Link } from 'react-router-dom';

const { Sider } = Layout;


function View({loading, error, width, ...other}){

    return <UserContext.Consumer>
        {({state}) => {
                return <Sider className="SideMenu" width={width} {...other}>
                    <Link to={'/'}><img style={{margin: '10px 0px 10px 20px', borderRadius: '75px', border: '2px solid white', maxWidth: '150px'}} src='https://est-app-files.s3.us-east-2.amazonaws.com/estLogo600.png' /></Link>
                    <Menu
                        theme="dark"
                        mode="inline"
                        style={{height: '100%', borderRight: 0}}>
                        {options.map((option, index) => {

                            // let visibleFeatures = 0;
                            // option.features.forEach(f => {
                            //     if(userHasPrivileges([f.role], state.user)) {
                            //         visibleFeatures++;
                            //     }
                            // });

                            if(options.features && options.features.length === 1) {
                                let feature = option.features[0];
                                let roles = Array.isArray(feature.role) ? feature.role : [feature.role];

                                if( (feature.roleReq === 'any' && userHasAnyPrivileges(roles, state.user)) || 
                                userHasPrivileges(roles, state.user)) { 
                                    return <MenuLink iconType={option.iconType} 
                                                key={`${feature.route}-${feature.title}`} 
                                                to={feature.route} 
                                                label={feature.title} />
                                } else {
                                    return null;
                                }

                                    
                            } else {
                                return <Sub user={state.user} key={option.heading + '_' + index} {...option} />
                            }
                        })}
                    </Menu>
                </Sider>
        }}
    </UserContext.Consumer>
}


export default View;
