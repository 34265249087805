
import colors from 'lib/constants/resourceColors';
import moment from 'moment';
import Privileges from 'lib/constants/privileges';
import api from 'api';

const form = [

    {key: 'scheduled_date',  
        required: true,
        label: 'Start Date', 
        placeholder: 'Select a Start Date', 
        type: 'date', 
        end_date_key: 'scheduled_end_date', 
        time_key: 'start_time',
    },

    {key: 'scheduled_end_date', 
        required: true,
        label: 'End Date', 
        placeholder: 'Select the End Date', 
        type: 'date', 
        start_date_key:'scheduled_date',
        time_key: 'end_time',
    },

    {key: 'start_time',  
        required: true,
        label: 'Start Time', 
        type: 'time',
        date_key: 'scheduled_date',
        defaultValue: moment(new Date()).hour(8).minutes(0).seconds(0),
    },

    {key: 'end_time', 
        required: true,
        label: 'End Time', 
        type: 'time',
        date_key: 'scheduled_end_date',
        defaultValue: moment(new Date()).hour(17).minutes(0).seconds(0),
    },

    {key: 'include_breaks',
        label: 'Includes Break',
        type: 'boolean',
    },

    {key: 'technicians',
        type: 'relations',
        label: 'Technicians',
        placeholder: 'Add a Technician...',
        submitLabel: 'Assign',
        addRelation: (user_id, time_block) => api.create('/inspection_workers', {user_id, inspection_time_block_id: time_block.id}),
            deleteRelation: (user_id, time_block) => api.destroy(`/inspection_workers/time_blocks/${time_block.id}/workers/${user_id}`),
        relationsUrl: (time_block) => `/inspection_workers?inspection_time_block_id=${time_block.id}`,
        optionsUrl: (time_block) => `/lists/inspection_workers_available?inspection_time_block_id=${time_block.id}`,
        getRelations: (block) => { return block.technicians },
        optionTransform: (o) => { 
            return {value: o.id, label: [o.first_name, o.last_name].join(' ')} 
        }, 
        relationTransform: (r) => { 
            return {value: r.user_id, label: [r.first_name, r.last_name].join(' ')} 
        } , 
    },

]

export default {
    defaults: {
        name: 'Inspection Time Block',
        colors: colors.inspectionTimeBlock,
        formFields: form,
        actions: ['view'],
        formActions: [
            { label: 'Shift to 8:00 AM Start Time',
                icon: 'double-left',
                validate: ({values}) => {
                    return values.scheduled_date !== null && values.scheduled_date !== undefined; 
                },
                method: async ({values, reload}) => { 
                    let newTime = new Date(values.scheduled_date);
                    newTime.setHours(8);
                    try { 
                        await api.rpc(`moveBlockToStartTime`, {inspection_time_block_id: values.id, new_start_time: newTime})
                    } catch (e) {
                        throw new Error(e);
                    }
                    reload();
                }
            },
            { label: 'Shift to 1:00 PM Start Time',
                icon: 'double-right',
                validate: ({values}) => {
                    return values.scheduled_date !== null && values.scheduled_date !== undefined; 
                },
                method: async ({values, reload}) => { 
                    let newTime = new Date(values.scheduled_date);
                    newTime.setHours(13);
                console.log("Shift to ", values.id, newTime);
                    try { 
                        await api.rpc(`moveBlockToStartTime`, {inspection_time_block_id: values.id, new_start_time: newTime})
                    } catch (e) {
                        throw new Error(e);
                    }
                    reload();
                }
            },
        ],
    },
    
    view: {
        title: (t) => `Time`,
        formProps: {
            editPrivileges: [Privileges.UPDATE_INSPECTION_TIME_BLOCKS],
            deletePrivileges: [Privileges.DELETE_INSPECTION_TIME_BLOCKS],
        }
    },
}
