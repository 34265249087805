import React, { Component } from 'react';
import { Prompt } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Spin } from 'antd';
import Form from 'components/Form';
import BackLink from 'components/BackLink';
import ErrorMessage from 'components/ErrorMessage';
import toTitleCase from 'lib/helpers/titleCase';
import api from 'api';
// eslint-disable-next-line
const debug = require('debug')('app:Create:');

// const propTypes = {
//     resourceName: PropTypes.string.isRequired, //singular name for resource (no plural)
//     urlFormatter: PropTypes.func, //if not specified, a default url of /resourceName/newObject.id is used
//     createMethod: PropTypes.func.isRequired, //api method to use when creating the resource
//     form: PropTypes.element, //if no form element is supplied, createMethod is called immediately with null values
//     formFields: PropTypes.object, //the fields to create for the generic form object
// };

class Create extends Component {
    
    state = {
        creating: false,
        created: false,
        errors: null,
    };

     componentDidMount(){
         const { instantCreate } = this.props;

         if(instantCreate){
             this.setState({creating: true}, ()=>{
                 this.handleSubmit({});
             });
         }
    }

    render(){
        const { creating, created } = this.state;
        const { formTemplates, formTemplateSearch, formActions, formFields, selectableOwner, title} = this.props;

        if(created) {
            return 'Created';
        }

        if(creating) {
            return <Spin tip='Creating' />
        }

        console.log("Create with ", formTemplates);
        return <div>
            <BackLink />
            <ErrorMessage errors={this.state.errors} />
            <h1 id={'heading'}>{title}</h1>
            <Prompt when={creating} message={'Your data is still submitting, if you exit now your data may not be saved.'} />
            <Form formActions={formActions} formTemplates={formTemplates} formTemplateSearch={formTemplateSearch} selectableOwner={selectableOwner} createMode={true} onSubmit={this.handleSubmit} fields={formFields} />
        </div>

    }

    handleCancel = (e) => {
        const { history } = this.props;
        e.preventDefault();
        history.goBack();
    }

    createMethod = (data) => {
        const { postUrl } = this.props;
        return api.create(postUrl, data);
    } 

    handleSubmit = (values) => {
        const { history, urlFormatter } = this.props;
        console.log("SUBMIT ", values);
        
        let data = new FormData();

        debug("Values ", values);
        for(var key in values){
            data.append(key, values[key]);
        }
        
        debug("Here is data ", data);
        this.setState({errors: null, creating: true});
        window.scrollTo(0, 0)
        return this.createMethod(data)
        .then(response => { 
            if(response.errors){
                this.setState({creating: false, errors: response.errors});
            } else {
                const object = response.body;
                this.setState({created: true, creating: false}, () => {
                    if(!urlFormatter) {
                        throw new Error('No redirect URL given for newly created object');
                    }
                    const redirectUrl = urlFormatter(object);
                    history.replace(redirectUrl);
                });
            }

            return response;
        })
        .catch(error => {
            this.setState({errors: error, creating: false});
            throw error;
        });
    }
}

export default withRouter(Create);
