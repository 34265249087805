import colors from 'lib/constants/resourceColors';

const userFunc = (user) => {
    return {label: user.first_name + ' ' + user.last_name, value: user.id}
}

export default {
    defaults: {
        name: 'Sales Commission',
        color: colors.salesCommissions,
        formFields: [
            {type: 'options',
                createOnly: true,
                key: 'user_id',
                label: 'Employee',
                optionsURL: '/lists/users',
                optionsMapper: userFunc },
            {static: true,
                key: 'first_name',
                label: 'Employee' },
            {key: 'project_commission',
                type: 'percentage'},
        ],
    },
    search: {
        fields: ['id', 'first_name', 'last_name', 'project_commission'],
        dataTransform: (user) => Object.assign(user, {full_name: [user.first_name, user.last_name].filter(v => v).join(' ')}),
        links: ['new'],
        tableProps: {
            rowURL: '/sales_commissions/:id',
            keyFunc: (item, index) => `${item.full_name}_${index}`,
            columns: [{heading: 'Name', accessor: 'full_name', placeholder:'No Name'}, {heading: 'Project Commission', accessor: 'project_commission'}],
        }
    }
}


