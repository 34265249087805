import React from 'react';
import { Alert } from 'antd';


export default ({errors, ...other}) => {
    if(!errors){
        return null;
    } else if (Array.isArray(errors)) {
        return <div>{errors.map((e, index) => { 
            let text = typeof e === 'object' ? e.message : e;
            return <Alert id='error_message' 
                        key={'Error' + index} 
                        type="error" 
                        showIcon 
                        message={text} />
        })}
        </div>
    } else {
        return <Alert id='error_message' type="error" showIcon message={errors} />
    }
}
