import React, { useState, useEffect } from 'react';
import { Spin, Dropdown, Menu, Button, Icon } from 'antd';
import Table from 'components/Table';
import api from 'api';
import bindUrl from 'lib/helpers/bindURL';
import './style.css';
import { userHasPrivileges } from 'lib/helpers/renderCondition';

function DataTable(props) { 
    const { values, headers, dynamicStyles, data } = props;

    let hd = headers(values);
    let d = data(values);
    return <table className='dt_border'>
    <thead>
        <tr>
            {hd.map(h => <th className='dt_border dt_header'>{h}</th>)}
        </tr>
    </thead>
    <tbody>
        {d.map(row => {
            let styles;
            if(dynamicStyles) {
                styles = dynamicStyles(row, d);
            }

            return <tr>
                {row.map((dd, index) => {
                    let style = styles ? styles[index] : null;
                    return <td style={style} className='dt_border dt_cell'>{dd}</td>
                })}
            </tr>
        })}
    </tbody>
        </table>

}

export default DataTable;
