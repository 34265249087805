import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RightArrow, LeftArrow } from './Icons';
import moment from 'moment';

const propTypes = {
    handleNext: PropTypes.func,
    handlePrevious: PropTypes.func,
    handleToday: PropTypes.func,
    handleCompressEvents: PropTypes.func,
    handleHideDock: PropTypes.func,
    month: PropTypes.number.isRequired,
    months: PropTypes.array,
    year: PropTypes.number.isRequired,
    compressEvents: PropTypes.bool,
    hideDock: PropTypes.bool,
};
const defaultProps = {
    months: moment.months(),
    compressEvents: false
};

const Main = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: bottom;
    width: 100%;

`

const Navigation = styled.div`
    height: 100%;
    display: flex;

    @media print {
        display: none;
    }
`

const Month = styled.span`
    font-weight: 800;
`

const Button = styled.button`
    background-color: #FFFFFF;
    border: 1px solid lightgray;
    border-radius: 0.5em;
    color: ${({selected}) => selected ? '#9595BB' : '#FFFFFF'};
    cursor: pointer;
    font-size: 14px;
    margin-right: 0.2em;
    outline: none;
    padding: 0.25em 0.5em;
    position: relative;
    user-select: none;

    ${({selected}) => {
        if(!selected){
            return `
                &:hover{
                }

                &:active{
                    background-color: blue;
                }
            `
        }
    }}
`

const LeftSegmentButton = Button.extend`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
`

const RightSegmentButton = Button.extend`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
`

const MiddleSegmentButton = Button.extend`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-left: 0;
    margin-right: 0;
`

const Heading = styled.span`
    color: #747484;
    font-family: sans-serif;
    font-size: 36px;
    flex-grow: 1;
`

class Controls extends Component {

    render(){
        const {  month, months, year, handleNext, handleToday, handlePrevious, handleCompressEvents, compressEvents, hideDock, handleHideDock } = this.props;

        return <Main>
            <Heading>
                <Month>{months[month]}</Month> {year}
            </Heading>
            <Navigation>
            <span style={{marginRight: '24px'}}>
                <input type='checkbox' value={compressEvents} onChange={handleCompressEvents} /> Compressed
            </span>
            <input type='checkbox' value={hideDock} onChange={handleHideDock} /> Hide Unscheduled
            <LeftSegmentButton onClick={handlePrevious}><LeftArrow color={'lightgray'}/></LeftSegmentButton> 
            <MiddleSegmentButton onClick={handleToday} selected={true}>Today</MiddleSegmentButton>
            <RightSegmentButton onClick={handleNext}><RightArrow color={'lightgray'} /></RightSegmentButton>
            </Navigation>
        </Main>
    }
}

Controls.propTypes = propTypes;
Controls.defaultProps = defaultProps;

export default Controls;
