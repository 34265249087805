
import colors from 'lib/constants/resourceColors';
const form = [
    {key: 'description', required: true, label: 'Main Location Name'},
    {key: 'sublocation', label: 'Sub Location Name'}
]

export default {
    defaults: {
        name: 'Inventory Location',
        color: colors.inventoryLocations,
        formFields: form,
    },

    view: {
        title: (i) => `${i.name}, ${i.bin}`,
    },

    search: {
        fields: ['description', 'sublocation', 'id'],
        tableProps: {
            keyFunc: (item, index) => `${item.id}_${index}`,
            columns:[
                {heading: 'Name', accessor: 'description'},
                {heading: 'Bin', accessor: 'sublocation'},
            ],
        },
        links: ['new'],
    }

}
