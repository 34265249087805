
import colors from 'lib/constants/resourceColors';
import Quote from 'components/Quote';

export function quoteApprovedStyle(value, data){
    const { approved_at } = data;

    if(approved_at){
        return {status: 'success', text: 'Approved'};
    } else {
        return null;
    }
}

export default {
    defaults: {
        name: 'Quote',
        colors: colors.quotes,
        identifierParam: 'quote_number',
    },

    view: {
        title: (q) => `Quote ${q.quote_number}`,
        component: Quote,
        formActions: [ 
            {   label: 'Generate Affidavit',
                icon: 'file-pdf',
                inputValues: [
                    {label: 'Address', key: 'address'},
                    {label: 'Address Line 2', key: 'address2'},
                    {label: 'City', key: 'city'},
                    {label: 'State', key: 'state'},
                    {label: 'Zipcode', key: 'zipcode'},
                ],
                method: async ({values, history, inputValues}) => {
                    const { address, address2, city, state, zipcode } = values;
                    let ca = inputValues;

                    // let data = {
                    //     property_name: values.site_name,
                    //     property_address: { address, address2, city, state, zipcode },
                    //     contractor_address: { ca.address, ca.address2, ca.city, ca.state, ca.zipcode},
                    //     shorten_descriptions: values.shorten_descriptions, 
                    //     show_unit_prices: values.show_unit_prices, 
                    //     quote_items: values.quote_items, 
                    //     quote_number: values.quote_number, 
                    //     quoted_at: values.quoted_at
                    // };
                        

                    let result;
                    try {
                        //result = await api.create('/pdfs/inspection_affidavits', data)
                    } catch (e) {
                        throw new Error(e);
                    }

                }
            },
        ]
    },

    create: {
        urlFormatter:((quote) => `/quotes/${quote.quote_number}`),
        instantCreate: true,
    },

    search: {
        allowDateFilter: true,
        heading: 'Your Quotes',
        fields: ['id','quote_number','description','sender_name','approved_at','created_at','updated_at'],
        links: ['new'],
        //resourceFilters: (user ? {user: user.id} : null),
        defaultFilter: (currentUser) => { return {user: currentUser.id} },
        searchBy: ['quote number', 'description', 'address', 'property', 'salesperson', 'contact info', 'emails', 'phone numbers'],
        tableProps: {
            rowURL: '/quotes/:quote_number',
            columns: [
                {heading: 'Quote Number', accessor: 'quote_number', cellWidth: '110px', style: 'bold'},
                {heading: 'Description', accessor: 'description'},
                {heading: 'Status', cellWidth: '110px', type: 'badge', accessor: 'approved_at', badgePropsFunc: quoteApprovedStyle, placeholder: ''},
                {heading: 'Created', cellWidth: '100px', dateFormat: 'MMM DD, YYYY', type:'date', accessor: 'created_at'}
            ]
        }
    }
}
