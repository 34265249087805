
import colors from 'lib/constants/resourceColors';
export default {
    defaults: {
        name: 'Holiday',
        color: colors.holidays,
        formFields: [ 
            '!name',
            'description',
            {required: true, type: 'date', key: 'holiday_date'},
            {key: 'business_open', type: 'boolean', defaultValue: true},
        ],

    },

    search: {
        fields: ['id', 'name', 'description', 'holiday_date', 'business_open'], 
        links: ['new'],
        tableProps: {
            rowURL: '/holidays/:id',
            columns: [
                {heading: 'Name', accessor: 'name', placeholder: 'None'},
                {heading: 'Date', accessor: 'holiday_date', type: 'date', cellWidth: '130px'},
                {heading: 'Open for Business', type: 'boolean', accessor: 'business_open', cellWidth: '50px'}, 
                {heading: 'Description', accessor: 'description'},
            ]
        },
    }
};
