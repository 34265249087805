import colors from 'lib/constants/resourceColors';
const form = [
    {key:'identifier', required: true},
    {key:'name'},
    {type: 'longtext', key:'text'},
]

export default {
    defaults: {
        name: 'Text Template',
        color: colors.textTemplate,
        formFields: form,
    },

    view: {
        title: (t) => t.identifier,
    },

    search: {
        fields: ['id','name','updated_at'],
        links: ['new'],
        searchBy: ['name', 'text'],
        tableProps: {
            rowURL: '/text_templates/:id',
            columns: [{heading: 'Name', accessor: 'name'}],
            showHeading: false,
        }
    },
};
