import React, { Component } from 'react';
import { Checkbox, Button, Input, Icon, Spin, Timeline } from 'antd';
import parseUpdatedValues from 'lib/helpers/parseUpdatedValues';
import ErrorMessage from 'components/ErrorMessage';
import api from 'api';
import renderCondition from 'lib/helpers/renderCondition';
import moment from 'moment';
import './style.css';
import Privileges from 'lib/constants/privileges';


const Item = Timeline.Item;
const { TextArea } = Input;


class Remarks extends Component {


    state = {
        loading: false,
        posting: false,
        postError: null,
        remarks: [],
        remarkInput: '',
        error: null,
        showEditHistory: false,
    }


    componentDidMount() {

        this.fetchRemarks();
    }

    fetchRemarks = () => {
        const { object_id } = this.props;

        this.setState({loading: true}, () => {
            api.get('/remarks', {view: 'related', parent_object_id: object_id})
            .then((result) => {
                if(result.status === 200) {
                    this.setState({loading: false, remarks: result.body});
                } else {
                    this.setState({loading: false, remarks: [], error: 'Failed to fetch remarks.'});
                }
            });
        });
    }

    onChangeShowEdit = (e) => {
        this.setState({showEditHistory: e.target.checked});
    }

    render() {
        const { object_id, title } = this.props;
        const { postError, posting, remarkInput, loading, error, remarks } = this.state;


        if(error) {
            return <ErrorMessage errors={error} />
        }

        let visibilityStyle = {};
        visibilityStyle.visibility = loading ? 'hidden' : 'visible';

        return renderCondition([Privileges.READ_REMARKS], <div className='remarks'>
            <h2 style={{display: 'inline-block', marginRight: '24px'}}>{title || 'History'}</h2> 
            <Checkbox onChange={this.onChangeShowEdit} checked={this.state.showEditHistory}>Show Edit History</Checkbox>
            {loading ? <Spin style={{marginBottom: '20px'}} tip='Loading History' /> : null}
            {postError ? ErrorMessage(postError) : null}
            <Timeline style={visibilityStyle}>
                {renderCondition([Privileges.CREATE_REMARKS], <Item style={{marginTop: '20px'}} dot={<Icon type="message" style={{marginTop: '7px', fontSize: '16px'}}/>}>
                    <TextArea value={remarkInput} onChange={this.updateRemarkInput} style={{maxWidth: '400px', marginRight: '10px'}} placeholder='Add a remark...' autosize />
                    <Button style={visibilityStyle} onClick={this.submitRemark} loading={posting}>Submit</Button>
                    </Item>
                )}
                {this.renderRemarks()}
            </Timeline>
        </div>);

    }

    updateRemarkInput = (e) => {
        console.log("Update ", e);
        this.setState({remarkInput: e.target.value});
    }

    submitRemark = () => {
        const { remarkInput } = this.state;
        const { user, header, object_type, object_id } = this.props;

        let body = {header, user_id: user.id, object_id, object_type, type: 'comment', body: remarkInput};

        this.setState({posting: true});
        api.create('/remarks', body)
        .then((result) => {
            console.log("Posted ", result);
            if(result.status === 201){
                this.setState({posting: false, remarkInput: '', postError: null});
                this.fetchRemarks();
            } else {
                this.setState({postError: result, posting: false});
            }
        });
    }


    renderRemarks() {
        const { showEditHistory, remarks } = this.state;

        return remarks.map(remark => {

            if(!showEditHistory && remark.type === 'update') {
                return null;
            }

            let icon = this.iconForRemarkType(remark.type);
            let message = this.messageFromRemark(remark);
            let color = this.colorForRemarkType(remark.type);

            return <Item color={color} dot={icon}>{message}</Item>
        }).filter(v => v);

    }

    messageFromRemark(remark) {
        const { remarks } = this.state;

        let nodes = [];

        if(remark.parent_object_id !== remark.object_id){
            let parentLine = <span style={{color: '#1890FF', fontStyle: 'italic', fontSize: '12px', display: 'block'}}>
                <span style={{fontSize: '16px'}}>⇠</span> {remark.header} {remark.object_type}:
            </span>

            nodes.push(parentLine);
        }

        if(remark.type === 'update') {
            nodes.push(parseUpdatedValues(remark.updated_values)); 
        } else {
            let text = <span style={{display: 'block'}}>{remark.body}</span>
            nodes.push(text);
        }


        let author = remark.autogenerated && (remark.first_name === null && remark.last_name === null) ? 
                     'System' : `${remark.first_name || 'Unknown'} ${remark.last_name || ''}`;

        let authorLine = <span style={{fontStyle: 'italic', fontSize: '12px', display: 'block'}}>
            <strong>{author}</strong> 
            {`, ${moment(remark.remarked_at).format('MM/DD/YYYY h:mm A')}`}
        </span>

        nodes.push(authorLine);

        return nodes;
    }

    colorForRemarkType(type) {

        let color = 'blue';
        switch(type) {
            case 'update':
                color='black';
                break;
            case 'completed':
                color='green';
                break;
            case 'sent_email':
                break;
            default:
                color = 'blue';
                break;
        }

        return color;

    }

    iconForRemarkType(type) {


        let style = {fontSize: '16px'};
        let icon;
        switch(type) {
            case 'create':
                icon = 'plus-circle';
                break;
            case 'update':
                icon = 'form';
                style.color = '#9999AA';
                style.fontSize = '14px';
                break;
            case 'comment':
                icon = 'message';
                break;
            case 'completed':
                icon = 'check-circle';
                break;
            case 'sent_email':
                icon = 'mail';
                style.fontSize = '14px';
                break;
            case 'delete':
                icon = 'close-square';
                break;
            default:
                return null;
        }

        return <Icon style={style} type={icon} />


    }


}


export default Remarks;
