import React, { Component } from 'react';
import { Modal, Form, DatePicker, Spin, Input, InputNumber, Select, Button } from 'antd';
import Badge from 'components/Label';
import MaskedInput from './MaskedInput';
import QuoteItem from './Item';
import QuoteItems from './ItemList';
import AffidavitForm from './AffidavitForm';
import { withRouter } from 'react-router-dom';
import { UserContext } from 'contexts/User';
import localforage from 'localforage';

//import { MaskedInput, DropDown, AutoSuggest } from '..';
import { css } from 'glamor';
import styled from 'styled-components';
import numeral from 'numeral';
import { row, columns } from 'glamor/ous';
import { quoteSummary } from 'lib/helpers';
import { Redirect } from 'react-router';
import { FOCUS_BLUE } from 'lib/constants/colors';
import api from 'api';
import toCSV from 'array-to-csv';
import Privileges from 'lib/constants/privileges';
import renderCondition from 'lib/helpers/renderCondition';
import moment from 'moment';
import Autosuggest from 'react-autosuggest';


// eslint-disable-next-line
const debug = require('debug')('app:QuoteForm:');

const confirm = Modal.confirm;

const marginRight = {marginRight: '12px'};


const taxRegions = [
    {label: 'Tax-Exempt', value: 'exempt'},
    {label: 'No Tax', value: 'notax'},
    {label: 'Miami Dade', value: 'miami-dade'},
    {label: 'Broward', value: 'broward'},
];


// const propTypes = {
//     quote_number : PropTypes.string,
// };
const defaultProps = {};

const textField = css({
    fontFamily: 'Raleway',
    width: '100%',
    backgroundColor: '#FBFBFC !important',
    marginBottom: '0px !important',
});

const InputDiv = styled.div`
    margin-bottom: 24px !important;
`

const readOnlyStylePropertyies = css({
    border: '1px solid #EEEEEE !important'
});

const checkbox = css({
    
});


const FieldLabel = styled.span`
    display: inline-block;
    font-size: 12px;
    margin-right: 12px; 
    margin-bottom: 0px !important;
    color: #A9A9A9;
    font-weight: 400 !important;
`

const SavingLabel = styled.span`
    font-size: 12px;
    color: ${FOCUS_BLUE};
`

const ErrorLabel = styled.span`
    font-size: 12px;
    color: red;
`

const tableValue = css({
    textAlign: 'right !important',
});
/*
const productTitle = css({
    fontWeight: '800',
    display: 'block',
});

const productField = css({
    width: '100px',
});

const productRow = css({
    display: 'block',
    textAlign: 'right',
});

const productCellEven = css({
    backgroundColor: '#F6F6F9',
    padding: '12px 12px',
});

const productCellOdd = css({
    backgroundColor: 'transparent',
    padding: '12px 12px',
});
*/

const section = css({
    marginBottom: '24px',
});

const addressSmall = css({
    flex: 1,
    marginRight: '5px !important',
});


class QuoteForm extends Component {


    state = {
        electricalContractor: {},
        redirectTo: null,
        loading: true,
        generatingPDF: false,
        suggestString: '',
        readonly: false,
        allow_delete: false,
        deleting: false,
        copying: false,
        copyLoading: false,
        deleteLoading: false,
        affidavitModalVisible: false,
        suggestions: [],
        pdfUrl: null,
        quote: {},
        quoteDiscountTemp: 0,
        quote_items: {},
        quote_item_temp_vals: {},
        quote_item_product_ids: {},
        fieldErrors: {},
        fieldsSaving: {},
        error: null,
    }

    componentWillMount(){
        localforage.getItem('latestAffidavitContractor')
        .then(async (contractor) => {
            this.setState({electricalContractor: (contractor || {})});
        });


        api.read('/quotes/' + this.props.quote_number)
        .then(response => {
            if(response.errors){
                this.setState({error: response.errors[0].message, loading: false});
            } else {
                const { body, headers } = response;
                let quote = body;
                let quote_items = {};
                let quote_item_product_ids = {};
                let readonly = !headers['x-allow-write'];
                let allow_delete = headers['x-allow-delete'];
                quote.quote_items.forEach(item => {
                    quote_items[item.id] = item;
                    quote_item_product_ids[item.product_id] = item.id;
                });
                let quote_item_temp_vals = JSON.parse(JSON.stringify(quote_items));
                delete quote.quote_items;
                this.setState({readonly, allow_delete, quote_item_temp_vals, loading:false, quote, quoteDiscountTemp: quote.discount, quote_items, quote_item_product_ids});
            }
        })
        .catch(error => {
            debug("Error Fetching quote: ", error.message);
            this.setState({loading: false, error: error.message});
        });
        //this.props.actions.getResource('quote', this.props.quote_number);
    }

    submitAffidavit = () => {
        const form = this.formRef.props.form;
        form.validateFields((err, form_values) => {
            if(err) {
                return;
            }

            const { ec_address, ec_address2, ec_city, ec_state, ec_zipcode, ec_name, 
                    p_address, p_address2, p_city, p_state, p_zipcode, p_name, date } = form_values;
            const { quote, quote_items } = this.state;
            const { show_unit_prices, shorten_descriptions, quote_number } = quote;

            let ec = {
                ec_name,
                ec_address,
                ec_address2,
                ec_city,
                ec_state,
                ec_zipcode,
            };

            this.setState({electricalContractor: ec});
            localforage.setItem('latestAffidavitContractor', ec);
            
            const pdf_values = {
                property_name: p_name,
                property_address: {address: p_address, address2: p_address2, city: p_city, state: p_state, zipcode: p_zipcode},
                contractor_name: ec_name,
                contractor_address: {address: ec_address, address2: ec_address2, city: ec_city, state: ec_state, zipcode: ec_zipcode},
                shorten_descriptions,
                show_unit_prices,
                quote_items, 
                quote_number, 
                quoted_at: date,
            };
            
            console.log("PDF Values ", pdf_values);

            api.create('/pdfs/inspection_affidavits', {data: pdf_values, filename: 'Affidavit.pdf'})
                .then(result => {
                    window.open(result.body.url);
                })
                .catch(error => {
                    confirm({
                        title: 'Error',
                        content: 'Unable to create Affidavit.',
                    });
                });

            form.resetFields();
            this.setState({affidavitModalVisible: false});
        });
    }

    onCancelAffidavit = () => {
        this.setState({affidavitModalVisible: false});
    }

    affidavitFormRef = (formRef) => {
        this.formRef = formRef;
    }

    openImportPartsToQuote = async () => {
        const { history } = this.props;
        const { quote } = this.state;

        try {
            history.push(`/quotes/${quote.id}/import`);
        } catch (e) {
            throw new Error(e);
        }
    }


    render(){
        const { history } = this.props;
        const { saveFieldValue } = this;
        const { readonly } = this.state;
        const { deleting, copying, allow_delete, redirectTo, quoteDiscountTemp, quote,
                fieldsSaving, fieldErrors, loading, error, } = this.state;
        const { quote_number } = quote;

        if(redirectTo){
            return <Redirect to={redirectTo} />
        }

        if(loading){
            return <div><Spin tip='Loading' /></div>
        }

        if(error){
            return <span>{error}</span>
        }

        if(deleting){
            if(this.state.deleteLoading){
                return <Spin tip='Deleting' />
            } else {
                return <DeleteCheck quote_number={quote_number}
                        cancel={e => {e.preventDefault(); this.setState({deleting: false})}}
                        submit={e => {
                            e.preventDefault(); 
                            this.setState({deleteLoading: true});
                            api.destroy('/quotes/' + quote_number)
                            .then(response => {
                                this.setState({deleteLoading: false});
                                if(response.errors){
                                    this.setState({error: response.errors[0].message});
                                } else {
                                    this.setState({redirectTo: '/quotes'});
                                }
                            })
                            .catch(error => {
                                debug(error.message);
                            });
                        }}
                    />
            }
        }


        if(copying){
            if(this.state.copyLoading){
                return <Spin tip='Copying' />
            } else {
                return <CopyForm
                        quote_number={quote_number}
                        cancel={e => {e.preventDefault(); this.setState({copying: false});}}
                          copy={(description, copy_project_details, copy_original_quote_terms) => {
                            const params = {source_id: this.state.quote.id,
                                            copy_project_details,
                                            copy_original_quote_terms};

                            this.setState({copyLoading: true});
                            api.create('/quotes/', {description}, params)
                            .then(response => {
                                this.setState({copyLoading: false});
                                if(response.errors){
                                    throw new Error(response.errors);
                                } else {
                                    const quote_number = response.body.quote_number;
                                    history.push(`/quotes`);
                                    history.push(`/quotes/${quote_number}`);
                                }
                            })
                            .catch(error => {
                                debug(error.message); 
                            });
                            
                        }}
                    />
            }
        }

        let quoteData = {...quote};
        quoteData.quote_items = Object.keys(this.state.quote_items).map(key => this.state.quote_items[key]);
        const summary = quoteSummary(quoteData);

        const alignRight = {textAlign: 'right'};
        

        return ( 
            <div key={'qtk_' + quote_number}>
            <h1 id={'heading'}>Quote: {quote_number}</h1> 
            <Button style={marginRight}  onClick={e => this.setState({copying: true})}>Copy</Button>
            {allow_delete ? renderCondition([Privileges.DELETE_QUOTES], <Button style={marginRight} onClick={e => this.setState({deleting: true})}>Delete</Button>) : null}
            <Button style={marginRight}  onClick={e => this.setState({affidavitModalVisible: true})}>Create Affidavit PDF</Button>
            <Button style={marginRight} onClick={e => this.openImportPartsToQuote()}>Import Parts From CSV</Button>
            {<AffidavitForm 
                quote={quote}
                contractor={this.state.electricalContractor}
                wrappedComponentRef={this.affidavitFormRef}
                visible={this.state.affidavitModalVisible} 
                onCancel={this.onCancelAffidavit} 
                onCreate={this.submitAffidavit} />}
            <UserContext.Consumer>
                {({state}) => this.authorLine(state.user)}
            </UserContext.Consumer>
            {this.approvedStatus()}
            {this.readStatus()}
            {this.approvedDetail()}
            <form>
                <div {...row} {...section}>
                    <div {...columns(12)}>
                    {[
                        {name: 'Description', property: 'description'}, 
                        //{name: 'Project Name', property: 'project_name'}, 
                    ].map((p, Index) => {
                        return <TextField 
                                        id={p.name}                        
                                        key={p.property}
                                    saving={fieldsSaving[p.property]}
                                     errors={fieldErrors[p.property]}
                                    readonly={readonly}
                                defaultValue={quote[p.property]} 
                                   saveValue={saveFieldValue} 
                                       title={p.name} 
                                 placeholder={p.placeholder}
                                        name={p.property} />
                    })}
                    </div>
                </div>
                <div {...row}>
                    <div {...columns(6)} {...section}>
                        {[
                            {name: 'Contact Name', property: 'contact_name'}, 
                            {name: 'Contact Email', property: 'contact_email'}, 
                            {name: 'Contact Phone', property: 'contact_phone'}, 
                            {name: 'Contact Company', property: 'contact_company'}, 
                        ].map((p, Index) => {
                            return <TextField 
                                             id={p.name}                        
                                            key={p.property}
                                         saving={fieldsSaving[p.property]}
                                         errors={fieldErrors[p.property]}
                                        readonly={readonly}
                                    defaultValue={quote[p.property]} 
                                       saveValue={saveFieldValue} 
                                           title={p.name} 
                                     placeholder={p.placeholder}
                                            name={p.property} />
                        })}
                        </div>
                        <div {...columns(6)} {...section}>
                            {[
                                {name: 'Property Name', property: 'property_name'}, 
                                {name: 'Address', property: 'address'}, 
                                {name: 'Address Line 2', property: 'address2', placeholder: 'Suite, Unit, Building, Floor, etc'}, 
                            ].map((p, Index) => {
                                return <TextField 
                                              id={p.name}                        
                                             key={p.property}
                                          saving={fieldsSaving[p.property]}
                                          errors={fieldErrors[p.property]}
                                        readonly={readonly}
                                    defaultValue={quote[p.property]} 
                                       saveValue={saveFieldValue} 
                                           title={p.name} 
                                     placeholder={p.placeholder}
                                            name={p.property} />
                            })}
                            <div {...css({ display: 'flex', marginRight: '-5px' })}>
                                {[
                                    {name: 'City', property: 'city'}, 
                                ].map((p, Index) => {
                                    return <TextField 
                                                   {...css(addressSmall, {flex:'1.55'})}
                                                     id={p.name}                        
                                                    key={p.property}
                                                 saving={fieldsSaving[p.property]}
                                                 errors={fieldErrors[p.property]}
                                                readonly={readonly}
                                            defaultValue={quote[p.property]} 
                                               saveValue={saveFieldValue} 
                                                   title={p.name} 
                                             placeholder={p.placeholder}
                                                    name={p.property} />
                                })}
                                {[
                                    {name: 'State', property: 'state'}, 
                                    {name: 'Zip Code', placeholder: 'Zip', property: 'zipcode'}, 
                                ].map((p, Index) => {
                                    return <TextField 
                                                   {...addressSmall}
                                                     id={p.name}                        
                                                    key={p.property}
                                                 saving={fieldsSaving[p.property]}
                                                 errors={fieldErrors[p.property]}
                                                readonly={readonly}
                                            defaultValue={quote[p.property]} 
                                               saveValue={saveFieldValue} 
                                                   title={p.name} 
                                             placeholder={p.placeholder}
                                                    name={p.property} />
                                })}
                            </div>
                        </div>
                </div>

                <div {...section}>
                    <h2>Parts & Labor</h2>
                    <QuoteItems>
                        {this.sortedQuoteItems()}
                    </QuoteItems>

                    {this.renderAutoSuggest()}
                </div>

                {renderCondition([Privileges.APPLY_QUOTE_VENDORS_DISCOUNT],
                    <div style={{float: 'right'}} {...section}>
                        <h2>Vendor Discounts</h2>
                        <table>
                            <tbody>
                                {quote.vendor_discounts.map((vd, index) => {
                                    return <tr key={vd.id + '_' + index}>
                                            <th>{vd.name || 'Unknown'}</th>
                                            <td style={alignRight}>
                                                <NumberField
                                                    minimum={0}
                                                    maximum={1}
                                                    format={'0%'}
                                                    inputScale={100}
                                                    style={{float: 'right', 
                                                            marginRight: '0px',
                                                            marginBottom: '0px !important',
                                                            textAlign: 'right !important',
                                                            clear: 'both', 
                                                            width: '80px'}}
                                                       {...addressSmall}
                                                         key={vd.name + '_discount_' + index}
                                                defaultValue={vd.discount} 
                                                   saveValue={(name, value, target) => {
                                                        return this.saveVendorDiscount(vd.id, value, target);
                                                    }}
                                                 placeholder={"Percent"}
                                                        name={vd.name + '_vendor_discount'} />
                                            </td>
                                            {renderCondition([Privileges.EXPORT_VENDOR_PART_LIST], 
                                                <td>
                                                <Button type='button' onClick={e => { 
                                                    this.downloadVendorCSV(vd.vendor_id, vd.name) 
                                                    }}>Parts CSV</Button>
                                                </td>
                                            )}
                                        </tr> 
                                })}
                            </tbody>
                        </table>

                    </div>
                )}


                <div {...section}>
                    <h2>Summary</h2>
                    <table>
                        <tbody>
                            {this.tableRowCurrency('Parts Base Price', summary.partsBasePrice)}
                            {renderCondition([Privileges.APPLY_QUOTE_DISCOUNTS],
                            <tr>
                                <th>Parts Discount%</th>
                                <td style={alignRight}>
                                    { readonly ? 
                                        <span 
                                        style={{float: 'right', 
                                                marginRight: '0px',
                                                marginBottom : '0px !important',
                                                textAlign: 'right !important',
                                                clear: 'both', 
                                            width: '80px'}}>
                                            {`${quoteDiscountTemp * 100}%`}
                                        </span>
                                        :
                                        <InputNumber 
                                            style={{float: 'right', 
                                                    marginRight: '0px',
                                                    marginBottom : '0px !important',
                                                    textAlign: 'right !important',
                                                    clear: 'both', 
                                                    width: '80px'}}
                                            min={0}
                                            max={1}
                                            disabled={readonly}
                                            formatter={value => `${value * 100}%`}
                                            parser={value => (value.replace('%', '')) / 100}
                                            value={quoteDiscountTemp}
                                            onKeyUp={e => {
                                                if(e.keyCode === 13) {
                                                    e.target.blur();
                                                }
                                            }}
                                            onChange={value => this.setState({quoteDiscountTemp: value})}
                                            
                                            onBlur={e => saveFieldValue('discount', quoteDiscountTemp)}
                                        />
                                    }
                                </td>
                            </tr>
                            )}
                            {renderCondition([Privileges.APPLY_QUOTE_DISCOUNTS], 
                                             this.tableRowCurrency('Discount Amount', summary.discountAmount, ")","("))}
                            {this.tableRowCurrency('Parts Total', summary.partsTotal)}
                            {this.tableRowCurrency('Services Total', summary.servicesTotal)}
                            <tr>
                                <th>Include Freight</th>
                                <td style={alignRight}>
                                <Checkbox 
                                    defaultValue={quote.include_freight} 
                                        readonly={readonly}
                                       saveValue={saveFieldValue} 
                                            name={'include_freight'} />
                                </td>
                            </tr>
                            {this.tableRowCurrency('Freight', summary.freightCost)}
                            {this.tableRowCurrency('Total', summary.subtotal)}
                            <tr>
                                <th>Quoted Total</th>
                                <td>
                                    <NumberField 
                                    format={'$0,0.00'}
                                    style={{float: 'right', 
                                            marginRight: '0px',
                                            marginBottom: '0px !important',
                                            textAlign: 'right !important',
                                            clear: 'both', 
                                            width: '100%'}}
                                       {...addressSmall}
                                         key={'quoted_total'}
                                    readonly={readonly}
                                defaultValue={quote.quoted_total} 
                                   saveValue={saveFieldValue} 
                                 placeholder={numeral(summary.subtotal).format('$0,0.00')}
                                        name={'quoted_total'} />
                                </td>
                            </tr>
                            <tr>
                                <th>Include Tax</th>
                                <td style={alignRight}>
                                
                                {renderCondition([Privileges.MODIFY_QUOTE_INCLUDE_TAX],
                                <Checkbox 
                                        {...tableValue}
                                    defaultValue={quote.include_tax} 
                                        readonly={readonly}
                                       saveValue={saveFieldValue} 
                                            name={'include_tax'} />
                                    ,
                                <input id={'include_tax'} type='checkbox' disabled checked={quote.include_tax} />
                                )}
                                </td>
                            </tr>
                            <tr>
                                <th>Tax Region</th>
                                <td style={alignRight}>
                                    {quote.include_tax ? this.taxRegionSelect() : '—'}
                                </td>
                            </tr>
                            {this.tableRowPercent('Tax Rate', quote.include_tax ? summary.taxRate : null)}
                            {this.tableRowCurrency('Surcharge', quote.include_tax ? summary.surcharge : null)}
                            {this.tableRowCurrency('Tax', quote.include_tax ? summary.tax : null)}
                            {this.tableRowCurrency('Grand Total', summary.grandTotal, (quote.include_tax ? '' : ' + TAX'))}
                        </tbody>
                    </table>

                </div>
                
                {renderCondition([Privileges.VIEW_QUOTE_COSTS], this.costBreakdown(summary))}

                <div {...section}>
                    <h2>Terms</h2>
                    <TextArea defaultValue={quote.terms || ""} 
                                      id={'terms'}
                                    name={'terms'}
                                readonly={readonly}
                                    saveValue={saveFieldValue}
                                      style={{width: '100%'}} /> 

                </div>

                {this.approvalSection()}

                <div {...section}>
                    <h2>PDF Options</h2>

                    <span style={{fontWeight: '800', marginRight: '12px'}}>Quoted Date:</span>
                    <DatePicker
                        allowClear={false}
                        format={'MMM DD, YYYY'}
                        placeholder={'Quoted Date'}
                        value={moment(quote.quoted_at)}
                        onChange={this.changeQuotedDate}
                    />
                    
                    <Checkbox title={'Shorten Part Descriptions'} 
                        defaultValue={quote['shorten_descriptions']} 
                            readonly={readonly}
                           saveValue={saveFieldValue} 
                                name={'shorten_descriptions'} />
                    <Checkbox title={'Show Unit Prices'} 
                        defaultValue={quote['show_unit_prices']} 
                            readonly={readonly}
                           saveValue={saveFieldValue} 
                               name={'show_unit_prices'}  />

                </div>
            </form>
            {this.pdfButtons()}
            </div>
        );
    }

    changeQuotedDate = (date) => {
        const { quote_number } = this.state.quote;
        const update = {quoted_at: date};

        if(date === null){
            return;
        }

        api.update('/quotes/' + quote_number, update)
        .then(response => {
            if(response.errors) {
                debug("Failed to change quoted_at");
            } else {
                debug("Changed date to ", date);
                this.setState(previousState => {
                    const quote = Object.assign({}, previousState.quote);
                    quote.quoted_at = date;
                    return { quote };
                });
            }
        });

    }

    taxRegionSelect = () => {
        const { quote } = this.state;
        const Option = Select.Option;

        const handleSelect = (value) => {
            this.saveFieldValue('tax_region', value);
        }

        return <Select value={quote.tax_region} onChange={handleSelect}>
            {taxRegions.map((t,index) => {
                return <Option key={`${t.label}_${index}_opt`} value={t.value} title={t.label}>{t.label}</Option>
            })}
        </Select>
        
        // return readonly ? 
        //     <span>{taxRegionNames[quote.tax_region]}</span> 
        //     :
        //     <DropDown 
        //         id='tax_region'
        //         options={taxRegions}
        //         useOptionsAsValues={true}
        //         selectedOption={quote.tax_region || 'miami-dade'}
        //         onChange={e => {
        //         this.saveFieldValue('tax_region', e.target.value, e.target);      
        //     }} 
        //     />; 
    }

    pdfButtons = () => {
        const { history } = this.props;
        const { pdfURL, generatingPDF } = this.state;

        if(!generatingPDF){
            return <div>
                {pdfURL ? <div style={{marginBottom: '12px', fontWeight: 800}}>To open the PDF file, <a target="_blank" href={pdfURL}>click here</a>.</div> : null}
                {renderCondition([Privileges.CREATE_QUOTE_PDF_FILE], <Button style={{marginRight: '12px'}} onClick={this.openDownload}>Download PDF</Button>)}
                {renderCondition([Privileges.OPEN_QUOTE_PREVIEW], 
                <Button onClick={() => history.push(`/preview/quote/${this.state.quote.quote_number}`) }>Open Preview</Button>)}
                </div>
        } else {
            return <span>Generating PDF...</span>
        }

    }

    approvalSection = () => {
        const { quote } = this.state;
    
        if(quote.approved_at){
            const line = {display: 'block'};
            return <div {...section}>
                <h2>Approval</h2>
                {quote.po_number ? 
                    <span style={line}>PO Number: {quote.po_number}</span> 
                    : 
                    <span style={line}>No PO Number given.</span>}
                <span style={line}>Approved by {quote.approved_by}</span>
                <img alt='' src={quote.signature_url} />
            </div>
        } else {
            return null;
        }

    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.suggestProduct(value, this.state.quote_item_product_ids);
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    }

    onSuggestionSelected = (event, { suggestionIndex, method }) => {
        this.handleProductSelect(suggestionIndex);
    }

    getSuggestionValue = (suggestion) => {
        return this.state.suggestString;
    }

    renderSuggestion = (suggestion, {query, isHighlighted}) => {
        let highlightColor;
        if(suggestion.alreadyIncluded){
            highlightColor = '#babfca';
        } else if (suggestion.discontinued){
            highlightColor = '#ff3300';
        } else {
            highlightColor = FOCUS_BLUE;
        }

        const highlightStyle = {
            backgroundColor: highlightColor, 
            color: 'white'
        };

        const divStyle = {
            padding: '3px 0 3px 12px',
            overflow: 'hidden',
            display: 'flex',
            maxHeight: '24px !important',
        };

        const style = isHighlighted ? Object.assign(divStyle, highlightStyle) : divStyle;

        const productNumberStyle = css({ 
            display: 'block',
            marginRight: '12px',
            marginBottom : '0px !important',
            width: 'auto',
            flex: 1.2,
        });

        const descriptionStyle = css({ 
            flex: 6,
            display: 'block',
            width: 'auto',
            marginBottom : '0px !important',
            userSelect: 'none',
            pointerEvents: 'none',
            '@media(min-width: 680px)': {
                verticalAlign: 'middle',
                display: 'inline',
                marginBottom: '0px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            }
        });

        if(suggestion.alreadyIncluded){
            const includedStyle =  {
                fontStyle: 'italic',
                fontWeight: '400',
                color: isHighlighted ? 'white' : 'lightgray',
            };

            const finalStyle = Object.assign(style, includedStyle);
            return <div style={finalStyle}>
                <span {...productNumberStyle}>{suggestion.product_number}</span>
                <Badge label='Added to Quote' type='gray' />
                <span {...descriptionStyle}>{suggestion.description}</span> 
            </div>
                
        } else if (suggestion.discontinued) {
            const discontinued = {
                fontStyle: 'italic',
                color: isHighlighted ? 'white' : '#ff3300',
            };

            const finalStyle = Object.assign(style, discontinued);
            return <div style={finalStyle}>
                <span {...productNumberStyle}>{suggestion.product_number}</span>
                <Badge label='Discontinued' type='red' />
                <span {...descriptionStyle}>{suggestion.description}</span> 
            </div>
        } else {
            return <div style={style}>
                <span {...productNumberStyle}>{suggestion.product_number}</span>
                <span {...descriptionStyle}>{suggestion.description}</span> 
            </div>
        }
    }

    onChangeSuggestion = (event, {newValue}) => { 
        this.setState({suggestString: newValue}); 
    }

    onKeyDown = (event) => {
        if (event.keyCode === 9) {
            var descriptions = document.getElementsByName("item-desc");
            var lastItem = descriptions.length - 1;
            if (lastItem >= 0) {
                descriptions[lastItem].focus();
            }
        }
    }

    renderInputComponent = inputProps => {

        const suggestInput = css({
            marginBottom: '0px !important',
            width: '100%',
        });

        const hasSuggestions = this.state.suggestions.length;
        
        const openInput =  hasSuggestions ? css({
            borderBottomLeftRadius: '0px !important',
            borderBottomRightRadius: '0px !important',
        }) : null;


        return (<div>
            <input {...openInput} {...suggestInput} {...inputProps} />
        </div>);
    } 

    invalidatePDF = () => {
        this.setState({pdfURL: null});
    }

    renderSuggestionsContainer = ({ containerProps , children, query }) => {
      const style = css({
            backgroundColor: '#FFFFFF',
            border: '1px solid lightgray',
            borderTop: 'none',
            boxShadow: '0px 1px 4px #A9A9B0',
            display: children ? 'block' : 'none',
            borderRadius: '0px 0px 4px 4px'
        });
      return (
        <div {...style} {... containerProps}>
            {children}
        </div>
      );
    }

    onBlurSuggestion = (event, { highlightedSuggestion }) => {
        this.setState({suggestString: ''});
    }

    renderAutoSuggest = () => {
        let suggestCell = {
            borderTop: '1px solid #A9A9A9',
            borderBottom: '1px solid #A9A9A9',
            padding: '24px 12px',
        };

        if(this.state.quote.approved_at){
            return null;
        }

        if(Object.keys(this.state.quote_items || {}).length % 2){
            suggestCell.backgroundColor = '#F6F6F9'; 
        } else {
            suggestCell.backgroundColor = '#FFFFFF'; 
        }

        const inputProps = {
            placeholder: 'Add a Part Number',
            value: this.state.suggestString,
            id: 'product_selector',
            onChange: this.onChangeSuggestion,
            onBlur: this.onBlurSuggestion,
            onKeyDown: this.onKeyDown,
        };

        return <div style={suggestCell}>
                <Autosuggest
                suggestions={this.state.suggestions}
                alwaysRenderSuggestions={true}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                renderSuggestionsContainer={this.renderSuggestionsContainer}
                renderInputComponent={this.renderInputComponent}
                onSuggestionSelected={this.onSuggestionSelected}
                inputProps={inputProps}
            /></div>

    }

            // <AutoSuggest
            //     id={'product_selector'}
            //     placeholder={"Add a Part Number"}
            //     style={suggestInput}
            //     onInputChange={input => {
            //         this.setState({suggestString: input});
            //         this.suggestProduct(input, this.state.quote_item_product_ids);
            //     }}
            //     onSelectSuggestion={this.handleProductSelect}
            //     suggestions={this.state.suggestions}
            //     suggestionCell={<ProductQuantityCell />}
            // />
    
    downloadVendorCSV = (vendor_id, vendor_name) => {
        const { quote, quote_items } = this.state;

        let quoteData = {...quote};
        quoteData.quote_items = Object.keys(this.state.quote_items).map(key => this.state.quote_items[key]);
        const summary = quoteSummary(quoteData);

        let total = summary.totalCost;
        // if(!quote.show_unit_prices && quote.quoted_total){
        //     total = this.currencyFormat(quote.quoted_total);
        // } else {
        //     total = this.currencyFormat(summary.grandTotal);
        //     if(!quote.include_tax){
        //         total += ' + TAX';
        //     }
        // }

        let data = [
                    [(vendor_name + ' Part List'), '','',''],
                    ['','','',''],
                    ['Sales Person: ', quote.sender_name || 'Unknown', '',''],
                    ['Quote Number: ', quote.quote_number, 'Description:' ,quote.description || 'No Description'],
                    ['','','',''],
                    ['Part Number', 'Quantity', 'Unit Cost', 'Description', 'Total Cost'],
                    ];

        
        Object.keys(quote_items).forEach((q_id, index) => {
            let item = quote_items[q_id];
            if(item.vendor_id === vendor_id){
                data.push([item.product_number, item.quantity, this.currencyFormat(item.base_cost), item.description, this.currencyFormat(item.quantity * item.base_cost)]);
            } else {
                console.log("Exclude from CSV: ", item.product_number, " Vendor: ", item.vendor_id, vendor_id);
            }
        });

        let csvFile = toCSV(data);

        let csvBlob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        csvBlob.lastModifiedDate = new Date();
        let name = quote.quote_number + '_' + vendor_name + '_Parts_List.csv';

        let csvURL = window.URL.createObjectURL(csvBlob);
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', name);
        
        // Append anchor to body.
        document.body.appendChild(tempLink);
        tempLink.click();

        // Remove anchor from body
        document.body.removeChild(tempLink);

        // var uploader = new Slingshot.Upload("csvUpload");

        // uploader.send(csvBlob, function(error, downloadUrl){
        //     if(error){
        //         console.error('Error uploading');
        //         alert(error);
        //     } else {
        //         window.location = downloadUrl;
        //     }
        // })
    }


    suggestProduct = (input, quote_items) => {

        if(input === '' || !input){
            //Don't suggest anything for empty strings
            this.setState({suggestions: []});
            return;
        }

        api.search('/products', {query: input, 
                              per_page: 16,
                                fields: 'id,product_number,description,discontinued'})
        .then(({headers, errors, body}) => {
            if(errors) {
                throw errors;
            } else {
                const searchedTerm = headers['x-search-term'];
                if(searchedTerm !== this.state.suggestString.trim()){
                    //stale result
                    //debug(`Ignoring result that arrived too late for search term ${searchedTerm}, current query is ${this.state.suggestString.trim()}`);
                    return;
                }

                if(quote_items){
                    body.forEach(suggestion => {
                        if(quote_items[suggestion.id]){
                            suggestion.alreadyIncluded = true;
                        }
                    });
                }

                //set state
                this.setState({suggestions: body});
            }
                
        })
        .catch(function(error){

        });

    }

    readStatus = () => {
        const { readonly } = this.state;

        if(readonly !== null && readonly === true){
            return <Badge label='Read Only' type='red' style={{marginBottom:'24px'}}/>
        }
    }

    approvedStatus = () => {
        const { quote } = this.state;

        if(quote && quote.approved_at){
            return <Badge label='Approved' type='green' style={{marginBottom:'24px'}} />
        }
    }

    approvedDetail = () => {
        const { quote } = this.state;
        const { approved_at, approved_by, po_number } = quote;

        const ApproveDiv = styled.div`
                display: block;
                font-size: 12px;
                color: green;
                background-color: #F0FFF0;
                margin-bottom: 24px;
                padding: 6px;
                border-radius: 4px;
                border: 1px solid #D6EED6;
        `;

        if(quote && approved_at){
            const strong = approved_by ? {fontWeight: '800'} : {};
            const line = {display: 'block'};
            return <ApproveDiv>
                <span style={line}>Approved by <span style={strong}>{approved_by || "an unspecified person "}</span> on {moment(approved_at).format('MMMM D, YYYY')} at {moment(approved_at).format('hh:mm A')}</span>
                {po_number ? 
                <span style={line}>With PO Number: <span style={strong}>{po_number}</span></span> : null}
            </ApproveDiv>;
        }
    }

    authorLine = (currentUser) => {
        const { quote } = this.state;
        const style = {
            display: 'block',
            marginBottom: '24px',
            color: '#202020',
        }

        const name = {
            fontWeight: '800',
        };
        
        let date = moment(quote.created_at).format('MMMM D, YYYY');
        let authorName = quote.user_id === currentUser.id ? `You` : quote.sender_name;
        return <span id={'created_by'} style={style}>
                    <span style={name}>{authorName}</span> created this quote on {date}.
                </span>
    }

    costBreakdown = (summary) => {
        return <div {...section}>
            <h2>Costs</h2>
            <table>
                <tbody>
                    {this.tableRowCurrency('Parts Base Cost', summary.partsBaseCost, ')', '(')}
                    {this.tableRowCurrency('Total Vendor Discounts', summary.totalVendorDiscounts)}
                    {this.tableRowCurrency('Service Cost', summary.servicesCost, ')', '(')}
                    {this.tableRowCurrency('Freight Cost', summary.freightCost, ')', '(')}
                    {this.tableRowCurrency('Total Cost', summary.totalCost, ')', '(')}
                </tbody>
            </table>
        </div>
    }

    sortedQuoteItems = () => {
        const { quote_items, quote_item_temp_vals } = this.state;
        let items = Object.keys(quote_items).map(key => quote_items[key]);
        items.sort((a,b) => a.position - b.position);

        return items.map((item, index) => {
            const name = item.product_number;
            const id = item.id;
            const temp_item = quote_item_temp_vals[item.id];
            if(temp_item){
                return <QuoteItem moveItem={this.moveItem} 
                                        id={id}
                                       key={name} //dragged quote items must have matching key when changing index! 
                                    vendor={item.vendor}
                                 item_type={item.type}
                                 untouched={item.untouched}
                             hide_quantity={item.hide_quantity}
                                  position={item.position}
                                  quantity={temp_item.quantity}
                              handleRemove={() => this.deleteQuoteItem(id)}
                             onChangeField={this.changeQuoteItemField}
                               onBlurField={this.blurQuoteItemField}
                                unit_price={temp_item.unit_price}
                                 unit_cost={temp_item.unit_cost}
                               description={temp_item.description}
                                      name={name} 
                                     index={index} />
            }
        }, this);
    }

    changeQuoteItemField = (value, item_id, field) => {
        let values = {}
        values[field] = value;
        values.untouched = false;
        const submit = field === 'hide_quantity' ? true : false;
        this.updateQuoteItem(item_id, values, submit);
    }

    blurQuoteItemField = (value, item_id, field) => {
        let values = {}

        if(field === 'quantity' || field === 'unit_price'){
            values[field] = Math.max(0, (value || 0));
        } else {
            values[field] = value;
        }

        values.untouched = false;

        if(field === 'quantity' && value <= 0){
            this.deleteQuoteItem(item_id);
        } else {
            this.updateQuoteItem(item_id, values, true);
        }
    }

    deleteQuoteItem = (item_id) => {

        this.invalidatePDF();

        let new_quote_items = {...this.state.quote_items};
        const deleted_item = new_quote_items[item_id];
        delete new_quote_items[item_id];

        let new_temp_quote_items = {...this.state.quote_items};
        delete new_temp_quote_items[item_id];

        const product_id = deleted_item.product_id;
        let new_item_product_ids = {...this.state.quote_item_product_ids};
        const deleted_product_id = new_item_product_ids[product_id];
        delete new_item_product_ids[product_id];

        this.setState({quote_items: new_quote_items, 
                      quote_item_temp_vals: new_temp_quote_items, 
                      quote_item_product_ids: new_item_product_ids});

        api.destroy('/quote_items/' + item_id)
        .then(response => {
            if(response.errors){
                let quote_items = {...this.state.quote_items};
                quote_items[item_id] = deleted_item;

                let quote_item_temp_vals = {...this.state.quote_item_temp_vals};
                quote_item_temp_vals[item_id] = {...deleted_item};

                let quote_item_product_ids = {...this.state.quote_item_product_ids};
                quote_item_product_ids[product_id] = deleted_product_id;

                this.setState({quote_items, quote_item_product_ids, quote_item_temp_vals});
            } else {
                return api.read('/quotes/' + this.state.quote.quote_number, {fields: 'vendor_discounts'})
                    .then(({errors, body}) => {
                        if(errors){
                            throw errors;
                        } else {
                            const quote = body;
                            this.setState(prevState => {
                                const prevQuote = prevState.quote;
                                const newQuote = Object.assign({}, prevQuote, quote);
                                return { quote: newQuote };
                            });
                        }
                    });
            };
        })
    }

    updateQuoteItem = (item_id, values, submit=false) => {
        const { quote_items, quote_item_temp_vals } = this.state;

        debug("Update quote item ", values);

        this.invalidatePDF();

        const item = quote_items[item_id];
        let updateRequired = false;
        let originalValues = {};
        const valueKeys = Object.keys(values);
        valueKeys.forEach(key => {
            if(item[key] !== values[key]){
                updateRequired = true;
                originalValues[key] = item[key];
            }
        });


        let new_item = Object.assign({...item}, values);
        let new_items = {...quote_item_temp_vals};
        new_items[item_id] = new_item;

        this.setState({quote_item_temp_vals: new_items});

        if(!submit || !updateRequired){
            return;
        }

        if(submit){
            api.update('/quote_items/' + item_id, values)
            .then(response => {
                if(response.errors){
                    debug("Update Quote Item: Error");
                    let reverted_items = {...quote_item_temp_vals}; 
                    reverted_items[item_id] = {...quote_items[item_id]};
                    this.setState({quote_item_temp_vals: reverted_items}); 
                } else {
                    this.setState({quote_items: new_items});
                };
            });
        }

    }

    openDownload = (e) => {
        e.preventDefault(); 
        const { quote } = this.state;
        const { quote_number } = quote;

        this.invalidatePDF();

        this.setState({generatingPDF: true});

        api.create('/pdfs/quotes', { quote_number, filename: `Quote_${quote_number}` })
        .then(({errors, body, headers}) => {
            this.setState({pdfURL: body.url, generatingPDF: false});
            if(errors){
                throw errors;
            } else {
                window.open(body.url, '_blank');
            }
        })
        .catch(errors => {
            debug(`Error opening quote pdf: ${JSON.stringify(errors)}`);
            this.setState({generatingPDF: false});
        })

    }

    tableRowCurrency = (title, value, append, prepend) => {
        return <tr>
                <th>{title}</th>
                <td id={title} {...tableValue}>{value !== null ? this.currencyFormat(value, append, prepend) : '—'}</td>
            </tr>
    }

    tableRowPercent = (title, value) => {
        return <tr>
                <th>{title}</th>
                <td id={title} {...tableValue}>{value !== null ? numeral(value).format('0.00%') : '—'}</td>
            </tr>
    }

    moveItem = (dragIndex, hoverIndex, isDragging) => {
        // const { quote_items } = this.state.quote;
        // //const dragItem = quote_items.sortOrder[dragIndex];
        // const newOrder = quote_items.sortOrder.slice();
        // debug("Old Order is ", newOrder);
        // newOrder.splice(hoverIndex, 0, newOrder.splice(dragIndex, 1)[0]);
        // debug("New Order is ", newOrder);
        /*
        this.setState(update(this.state, {
          quote_items: {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragItem]
            ]
          }
         */

        const { readonly } = this.state;


        if(readonly){
            return;
        }

        this.invalidatePDF();

        let quote_items  = Object.keys(this.state.quote_items).map(key => this.state.quote_items[key]);
        
        let original_positions = {};
        quote_items.forEach(item => original_positions[item.id] = item.position);

        quote_items.sort((a,b) => a.position - b.position);
        quote_items.splice(hoverIndex, 0, quote_items.splice(dragIndex, 1)[0]);
        let new_order_items = {};
        let reorder = [];
        quote_items.forEach((item, index) => { 
            let new_item = {...item};
            new_item.position = index;
            new_order_items[new_item.id] = new_item;
            reorder.push({id: new_item.id, position: new_item.position});
        });

        // Object.keys(quote_item_temp_vals).forEach((item, index) => {
        //     let new_item = {...item};
        //     new_item.position = index;
        //     new_order_temp_items[new_item.id] = new_item;
        // });

        this.setState({quote_items: new_order_items, 
                       quote_item_temp_vals: JSON.parse(JSON.stringify(new_order_items))});

        if(!isDragging){
            api.update('/quote_items/' , reorder)
            .then(response => {
                if(response.errors){
                    debug("Reorder Items: Failure");
                }
            });
        }


    }


    currencyFormat = (amount, append="", prepend="") => {
        if(!amount || amount === null){
            amount = 0;
        }

        const moneyFormat = '$0,0.00';
        return prepend + numeral(amount).format(moneyFormat) + append;
    }


    handleProductSelect = (index, event) => {
        if(event){
            event.preventDefault();
        }
        const { suggestions, quote } = this.state;
        const product = suggestions[index];
        const product_number = product.product_number; 
        const quote_id = quote.id;
        const quantity = 1;//;event ? (event.target.value || 1) : 1;
        const quote_number = quote.quote_number;

        if(product.discontinued || product.alreadyIncluded){
            return;
        }

        let previous_items_state;
        this.setState(previousState => {
            let updatedSuggestions = previousState.suggestions.slice();
            updatedSuggestions[index].alreadyIncluded = true;

            let new_quote_item_product_ids = Object.assign({}, previousState.quote_item_product_ids);
            new_quote_item_product_ids[product.id] = product_number;
            return { suggestions : updatedSuggestions, 
           quote_item_product_ids: new_quote_item_product_ids };
        });
       

        api.create('/quote_items', {product_number, quote_id, quantity})
        .then(({errors, body}) => {
            if(errors){
                this.setState({quote_item_product_ids: previous_items_state});
                throw errors;
            } else {
                const quote_item = body;
                let new_quote_items = {...this.state.quote_items};
                new_quote_items[quote_item.id] = quote_item;

                let new_temp_quote_items = {...this.state.quote_items};
                new_temp_quote_items[quote_item.id] = quote_item;

                let new_item_product_ids = Object.assign({...this.state.quote_item_product_ids});
                new_item_product_ids[quote_item.product_id] = quote_item.id;

                let prevScroll = document.body.scrollHeight - document.body.scrollTop;
                debug("Scroll Y", window.scrollY);
                debug("Window is ", window);
                debug("Window body is ", document.body.scrollHeight);
                debug("prev scroll is ", prevScroll);
                this.setState({quote_items: new_quote_items, quote_item_temp_vals: new_temp_quote_items, quote_item_product_ids: new_item_product_ids}, () => {
                    debug("Scroll Y", window.scrollY);
                    debug("New height is ", document.body.scrollHeight);
                    const scrollDiff = document.body.scrollHeight - prevScroll;
                    document.body.scrollTop = 0;
                    window.scrollTo(0, window.scrollY + scrollDiff);
                });

                //refetch so suggestions can reflect latest quote state
                this.onSuggestionsFetchRequested(this.state.suggestString);

                return api.read('/quotes/' + quote_number, {fields: 'vendor_discounts'}); 
            }
        })
        .then(({errors, body}) => {
            if(errors){
                throw errors;
            } else {
                const quote = body;
                this.setState(prevState => {
                    const prevQuote = prevState.quote;
                    const newQuote = Object.assign({}, prevQuote, quote);
                    return { quote: newQuote };
                });
            }
        })
        .catch(error => {
            debug("Error adding product: ", error.message);
            this.setState(previousState => {
                let updatedSuggestions = previousState.suggestions.slice();
                updatedSuggestions[index].alreadyIncluded = false;
                return { suggestions : updatedSuggestions };
            });
        });

    }

    saveVendorDiscount = (id, value, node) => {
        const { vendor_discounts } = this.state.quote;

        this.invalidatePDF();

        let update = { discount: value };
        let originalDiscountValue = 0;
        let originalDiscounts = vendor_discounts.map(vd => {
            if(vd.id === id){
                originalDiscountValue = vd.discount;
            }

            return Object.assign({}, vd);
        });

        let newDiscounts = vendor_discounts.map(vd => {
            if(vd.id === id){
                return Object.assign({}, vd, {discount: value});
            } else {
                return Object.assign({}, vd);
            }
        });


        this.setState(function(previousState, props){
            const updatedQuote = {...previousState.quote};
            updatedQuote.vendor_discounts = newDiscounts;

            return { quote: updatedQuote };
        });


        return api.update('/vendor_discounts/' + id, update)
        .then(response => {
            if(response.errors){
                throw new Error({message: 'You are not authorized to edit this value.'});
            }
        })
        .catch(error => {
            this.setState(function(previousState, props){
                const updatedQuote = {...previousState.quote};
                updatedQuote.vendor_discounts = originalDiscounts;

                return { quote: updatedQuote };
            });
            node.value = numeral(originalDiscountValue).format('0%');
        });
    }

    saveFieldValue = (name, value, node) => {
        const { quote_number } = this.props;
        const { quote } = this.state;

        this.invalidatePDF();

        const originalValue = quote[name];
        this.setState(function(previousState, props){
            const updatedQuote = {...previousState.quote};
            updatedQuote[name] = value;
            const fieldErrors = {...previousState.fieldErrors};
            fieldErrors[name] = null; 
            const fieldsSaving = {...previousState.fieldsSaving};
            fieldsSaving[name] = true;

            return { quote: updatedQuote, fieldErrors, fieldsSaving};
        });

        let update = {};
        update[name] = value;
        console.log("Update ", update);
        return api.update('/quotes/' + quote_number, update)
        .then(response => {
            debug(response);
            if(response.status === 403){
                throw new Error('You are not authorized to edit this value.');
            }

            if(response.status !== 204){
                const errorMessage = (response.errors && response.errors[0] && response.errors[0].message) || 'Failed';
                throw new Error(errorMessage);
            }

            console.log("Saved ", this.state.fieldsSaving);
            this.setState(function(previousState, props){
                const fieldsSaving = {...previousState.fieldsSaving};
                fieldsSaving[name] = false;

                return { fieldsSaving };
            });

            return true;
        })
        .catch(errors => {
            debug("Revert ", name, errors.message);
            this.setState(function(previousState, props){
                //most inputs are uncontrolled components, so this may not be necessary or expected
                const revertedQuote = {...previousState.quote};
                revertedQuote[name] = originalValue;
                ////////////

                const fieldErrors = {...previousState.fieldErrors};
                fieldErrors[name] = 'Failed to Save'; 
                const fieldsSaving = {...previousState.fieldsSaving};
                fieldsSaving[name] = false;

                console.log("Reverted to ", originalValue, name, revertedQuote[name]);
                return { quote: revertedQuote, fieldErrors, fieldsSaving};
            });

            if(node) {
                if(node.type === 'checkbox'){
                    node.checked = originalValue;
                } else {
                    node.value = originalValue;
                }
            }

            return false;
        });
    }
}



const Checkbox = ({saveValue, defaultValue, readonly, title, name, placeholder}) => {
    const marginBottom = css({marginBottom: '0px !important'});
    return (
        <div>
            <input name={name} 
                   id={name}
                defaultChecked={defaultValue}
                disabled={readonly}
                {...checkbox}
                {...marginBottom}
                 onChange={e => {
                    saveValue(name, e.target.checked, e.target)
                    .then(response => {})
                    .catch(error => {
                        debug("SAVE ERROR");
                    });
                 }}
                   type="checkbox" 
            />
            <FieldLabel>{title}</FieldLabel>
        </div>
    );
};

const TextField = ({id, saving, errors, saveValue, readonly, defaultValue, title, name, placeholder, ...otherProps}) => {

    let readonlyStyle = readonly ? readOnlyStylePropertyies : {}
    return (
        <InputDiv {...otherProps}>
            <FieldLabel>{title}</FieldLabel>
            {saving === true ? <SavingLabel>Saving...</SavingLabel> : null}
                <input id={id} 
                     name={name} 
                     {...textField}
                     {...readonlyStyle}
                     readOnly={readonly}
                defaultValue={defaultValue}
                     onBlur={e => {
                        if(readonly){
                             return;
                        }

                        saveValue(name, e.target.value, e.target)
                        .then(success => {
                            if(!success){
                                debug("SAVE FAILED");
                            } else {
                                debug("SAVE SUCCESS");
                            }
                        })
                        .catch(error => {
                            debug("SAVE ERROR");
                        });
                     }}
                     onKeyUpCapture={e => {
                        if(e.keyCode === 13){
                            e.target.blur();
                        }
                     }}
                       type="text" 
                placeholder={placeholder || title} />
            {errors ? <ErrorLabel>{errors}</ErrorLabel> : null}
        </InputDiv>
    );
};

const NumberField = ({inputScale, append, minimum, maximum, readonly, format, saveValue, defaultValue, title, name, placeholder, ...otherProps}) => {

    let readonlyStyle = readonly ? readOnlyStylePropertyies : {}
    const marginBottom = css({marginBottom: '0px !important'});

    if(readonly) {
        return <span 
        style={{float: 'right', 
                marginRight: '0px',
                marginBottom : '0px !important',
                textAlign: 'right !important',
                clear: 'both', 
            }}>
            {`${numeral(defaultValue).format(format)}`}
        </span>
    }

    return (
        <div {...otherProps}>
            <FieldLabel>{title}</FieldLabel>
                <MaskedInput name={name} 
                            id={name}
                      readOnly={readonly}
                   maskFormat={format}
                   inputScale={inputScale}
                       append={append}
                       {...readonlyStyle}
                 minimumValue={minimum}
                 maximumValue={maximum}
                 initialValue={defaultValue}
                    {...textField}
                    {...marginBottom}
                    style={{textAlign: 'right'}}
                     onBlur={(e, value) => {
                        if(readonly){
                            return;
                        }

                        saveValue(name, value, e.target)
                        .then(response => {
                            debug("SAVE SUCCESS");
                        })
                        .catch(error => {
                            debug("SAVE ERROR");
                        });
                     }}
                     onKeyUpCapture={e => {
                        if(e.keyCode === 13){
                            e.target.blur();
                        }
                     }}
                       type="text" 
                placeholder={placeholder || title} />
        </div>
    );
};

const TextArea = ({saveValue, readonly, name, ...otherProps}) => {

    return (
        <Input.TextArea
            autoSize={{minRows: 3}}
            disabled={readonly}
            {...otherProps}
             onBlur={e => {
                if(readonly){
                    return;
                }
                saveValue(name, e.target.value, e.target)
                .then(response => {
                    debug("SAVE SUCCESS");
                })
                .catch(error => {
                    debug("SAVE ERROR");
                });
             }}
             onKeyUpCapture={e => {
                if(e.keyCode === 13){
                    //Don't blur, terms may require enter key
                    //e.target.blur();
                }
             }}
        />
    );
}

const DeleteCheck = ({quote_number, cancel, submit}) => {
    const messageStyle = {display: 'block', marginBottom: '12px'};
    return <div>
        <h1>Delete Quote {quote_number}</h1>
        <div>
            <span style={messageStyle}>Are you sure you want to delete quote {quote_number}?</span>
            <div>
                <Button id="cancelDelete" style={marginRight} onClick={cancel}>Cancel</Button>
                <Button id="confirmDelete" onClick={submit}>Delete</Button>
            </div>
        </div>
    </div>
}

const CopyForm = (({quote_number, cancel, copy}) => {
    const blockInputStyle = { display: 'block' };
    return <div>
        <h1>Copying Quote {quote_number}</h1>
        <form>
        <label> New Description</label>
        <input style={blockInputStyle} required id="copyDescription" name="description" type='text' placeholder='Description' />
        <div><input style={marginRight} id="copyDetailsCheckbox" name="copyDetails" type='checkbox' /> Copy Address and Contact Info</div>
        <div><input style={marginRight} id="copyTermsCheckbox" name="copyTerms" type='checkbox' /> Copy Quote Terms</div>
        <div>
            <Button id="cancelCopy" style={marginRight} type="button" onClick={cancel}>Cancel</Button>
            <Button id="confirmCopy" type="submit" onClick={e => {
                e.preventDefault();
                const { description, copyDetails, copyTerms } = e.target.form;
                debug("d and c ", description.value, copyDetails.checked);
                copy(description.value, copyDetails.checked, copyTerms.checked); 
            }}>Copy</Button>
        </div>
        </form>
    </div>
});

//QuoteForm.propTypes = propTypes;
QuoteForm.defaultProps = defaultProps;
// QuoteForm.contextTypes = { 
//      router: PropTypes.object
// };

export default withRouter(QuoteForm);


