import colors from 'lib/constants/resourceColors';

export default {
    defaults: {
        name: 'Credit Hold',
        color: colors.creditHolds,
    },

    search: {
        fields: ['customer_id', 'site_id', 'created_at', 'customer_name', 'site_name', 
                 'site_address', 'site_address2', 'site_city', 'site_state', 'site_zipcode', 'site_parent'],
        links: ['new'],
        tableProps: {
            columns: [
                {heading: 'Customer', accessor: 'customer_name'},
                {heading: 'Site', accessor: 'site_name', placeholder: 'All Sites'},
                {heading: 'Created', accessor: 'created_at', type: 'date'},
            ]
        },
    }
};
