import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Spin, Button, Radio } from 'antd';
import BackLink from 'components/BackLink';
import ErrorMessage from 'components/ErrorMessage';
import Table from 'components/Table';
//import { Table, StatLine } from '..';
import api from '../../api';


function  UpcomingInspectionsReport() {
    const [errors, setErrors] = useState(null);
    const [activating, setActivating] = useState(false);
    const [inspections, setInspections] = useState([]);
    const [loading, setLoading] = useState(false);
    const [months, setMonths] = useState(1);


    useEffect(() => {
        setLoading(true);
        api.read('/lists/upcoming_inspections', { months })
        .then(({headers, errors, body}) => {
            if(errors){
                throw errors;
            } else {
                setLoading(false);
                setErrors(null);
                setInspections(body);
            }
        })
        .catch(errors => {
            setLoading(false);
            setInspections(null);
            let newErrors = (errors || []).concat(errors);
            setErrors(newErrors);
        });

    }, [months]);

    const onChange = (e) => {
        let value = e.target.value;
        setMonths(value);
    };

    const handleActivate = () => {
        
        setActivating(true);
        api.rpc('createUpcomingInspections', { months } )
        .then(({ success, errors }) => {
            if(success) {
                setInspections([]);
                setActivating(false);
            } else {
                setActivating(false);
                setErrors(errors);
            }
        });
    }


    if(loading){
        return <Spin tip='Loading' />
    } else if (activating) {
        return <Spin tip='Activating' />
    } else if (errors) {
            return <ErrorMessage errors={errors} />
    } else {
        return <div>
            <BackLink />
            <h1>Upcoming Inspections</h1>
            <Button style={{marginBottom: '12px'}} disabled={inspections.length <= 0} onClick={handleActivate}>Activate Upcoming Inspections</Button>
            <Radio.Group style={{display: 'block', marginBottom: '12px'}} onChange={onChange} value={months}>
                <Radio value={1}>1 Month</Radio>
                <Radio value={2}>2 Months</Radio>
                <Radio value={3}>3 Months</Radio>
            </Radio.Group>
            <Table
                columns={[
                    {style: 'bold', accessor: 'site_name'},
                    {heading: 'Address', 
                    separator: ', ',
                    accessor:['address', 'address2', 'city', 'state', 'zipcode']},
                    {accessor: 'inspection_type'},
                    {accessor: 'work_hours'},
                    {accessor: 'next_inspection_due_date', heading: 'Due Date', type: 'date', dateFormat: 'ddd, MMM DD, YYYY'},
                ]}
                data={inspections}
                locale={{emptyText: `No New Upcoming Inspections in ${months} month${months === 1 ? '' : 's'} period.`}}
                loading={loading}
            />

        </div>
    }


}


export default withRouter(UpcomingInspectionsReport);
