import React from 'react';
import titleCase from 'lib/helpers/titleCase';

export default function(updates){
    if(!updates){
        return;
    }

    if(updates.length === 0) {
        return ['Unknown update.'];
    }

    return updates.map((update) => {
        let newVal = update.new;
        let oldVal = update.old;

        if(typeof newVal === 'boolean') {
            newVal = newVal ? 'true' : 'false';
        }

        if(typeof oldVal === 'boolean') {
            oldVal = oldVal ? 'true' : 'false';
        }

        if(typeof newVal === 'object') {
            newVal = JSON.stringify(newVal);

            if(newVal === '[]' || newVal === '{}') {
                newVal = 'empty value';
            }
        }

        if(typeof oldVal === 'object') {
            oldVal = JSON.stringify(oldVal);

            if(oldVal === '[]' || oldVal === '{}'){
                oldVal = 'empty';
            }
        }

        return <span style={{display: 'block'}}>Changed <strong>{titleCase(update.key)}</strong> to <strong>{newVal}</strong> (<i>was {oldVal}</i>).</span>
    });
}
