
import colors from 'lib/constants/resourceColors';
import api from 'api';
import { Modal } from 'antd';
const Success = Modal.success;
const ErrorModal = Modal.error;
const Info = Modal.info;

const form = [
    {key: 'description'},
    {key: 'created_at', static: true, type: 'date', dateFormat: 'dddd, MMMM DD, YYYY'},
    {type: 'file',
       key: 'batchFile',
        urlKey: 'pdf_url',
        label: 'Contract Invoices PDF'},
    {type: 'file',
        key: 'statementsFile',
        urlKey: 'statements_url',
        label: 'Statements PDF'},
    {type: 'file',
       key: 'serviceFile',
        urlKey: 'service_url',
        label: 'Service Invoices PDF'},
    {key: 'email_body', type: 'longtext', showInCreateMode: false},
    {key: 'invoices',
        type: 'references',
        style: 'compact',
        heading: 'Invoices for Email',
        dataUrl: '/invoice_batches/:id/invoices?has_email=true&is_statement=false',
        tableProps: {
            pageSize: 800,
            rowURL: '/invoice_recipients/:recipient_id',
            columns: [
                {accessor: 'email_sent', label: 'Sent Email', type: 'boolean'},
                {accessor: 'invoice_number'},
                {heading: 'Customer Name and Address', type: 'multiple', rows: [
                    {style: 'bold', accessor: 'customer_name'},
                    {accessor: 'customer_address'},
                ]},
                {heading: 'Site Name and Address', type: 'multiple', rows: [
                    {style: 'bold', accessor: 'site_name'},
                    {accessor: 'site_address'},
                ]},
                {accessor: 'email'},
                {accessor: 'email_cc'},
            ]
        }
    },
    {key: 'invoices',
        type: 'references',
        style: 'compact',
        heading: 'Statements for Email',
        dataUrl: '/invoice_batches/:id/invoices?has_email=true&is_statement=true',
        tableProps: {
            pageSize: 800,
            rowURL: '/invoice_recipients/:recipient_id',
            columns: [
                {accessor: 'email_sent', label: 'Sent Email', type: 'boolean'},
                {heading: 'Customer Name and Address', type: 'multiple', rows: [
                    {style: 'bold', accessor: 'customer_name'},
                    {accessor: 'customer_address'},
                ]},
                {accessor: 'email'},
                {accessor: 'email_cc'},
            ]
        }
    },
    {key: 'invoices',
        type: 'references',
        style: 'compact',
        heading: 'Invoices for Printing',
        dataUrl: '/invoice_batches/:id/invoices?print_pages=true&is_statement=false',
        tableProps: {
            pageSize: 800,
            rowURL: '/invoice_recipients/:recipient_id',
            columns: [
                {accessor: 'invoice_number'},
                {heading: 'Customer Name and Address', type: 'multiple', rows: [
                    {style: 'bold', accessor: 'customer_name'},
                    {accessor: 'customer_address'},
                ]},
                {heading: 'Site Name and Address', type: 'multiple', rows: [
                    {style: 'bold', accessor: 'site_name'},
                    {accessor: 'site_address'},
                ]},
            ]
        }
    },
    {key: 'invoices',
        type: 'references',
        style: 'compact',
        heading: 'Statements for Printing',
        dataUrl: '/invoice_batches/:id/invoices?print_pages=true&is_statement=true',
        tableProps: {
            pageSize: 800,
            rowURL: '/invoice_recipients/:recipient_id',
            columns: [
                {heading: 'Customer Name and Address', type: 'multiple', rows: [
                    {style: 'bold', accessor: 'customer_name'},
                    {accessor: 'customer_address'},
                ]},
            ]
        }
    },

];

export default {
    defaults: {
        name: 'Invoice Batch',
        color: colors.invoiceBatches,
        namePlural: 'Invoice Batches',
        formFields: form,
    },

    view: {
        formActions: [
            { label: 'Send Invoice Batch Emails',
               icon: 'mail',
             method: async({values, form, history}) => {
                 api.rpc('sendInvoiceBatchEmails', {batch_id: values.id})
                 .then(result => {
                    if(result.success) {
                        Success({
                            title: 'Sending Emails', 
                            content: 'The emails will be sent.',
                            okText : 'Ok',
                        });
                    } else {
                        let error = result.errors.error;
                        console.log("Got error ", result);
                        ErrorModal({
                            title: 'Error', 
                            content: error.message,
                            okText : 'Ok',
                        });
                    }
                 });
             }},
            { label: 'Print Invoice Batch',
               icon: 'printer',
             method: async({values, form, history}) => {
                Info({
                    title: 'Creating Printable Batch', 
                    content: 'The PDF will open in a new window. DO NOT BLOCK POPUPS.',
                    okText : 'Ok',
                });

                 api.download(`/files/invoice_batches/${values.id}`)

                 // api.rpc('getPrintableBatchPDF', {batch_id: values.id})
                 //    .then(result => {
                 //        const { body } = result;
                 //        if(result.success) {

                 //            let base64data = new Buffer.from(body.result.data).toString('base64');                
                 //            let pdfWindow = window.open("")
                 //            pdfWindow.document.write(`<iframe width='100%' height='100%' src='data:application/pdf;base64,${encodeURI(base64data)}'></iframe>`);
                 //        } else {
                 //            let error = body.errors.error;
                 //            ErrorModal({
                 //                title: 'Error', 
                 //                content: error.message,
                 //                okText : 'Ok',
                 //            });
                 //        }
                 //    });
                }},
        ]
    },


    search: {
        fields: ['id', 'created_at', 'description', 'invoice_pages', 'statement_pages', 'emails_sent'],
        links: ['new'],
        tableProps: {
            rowURL: '/invoice_batches/:id',
            columns: [
                {accessor: 'created_at' , type: 'date'},
                {accessor: 'description'},
                {accessor: 'invoice_pages', type: 'number'},
                {accessor: 'statement_pages', type: 'number'},
            ],
        }
    }
}




