import React, { useState, useEffect} from 'react';
import api from 'api';
import { Button } from 'antd';
import ErrorMessage from 'components/ErrorMessage';
import { withRouter } from 'react-router-dom';
import './style.css';


function ProductsImport({history, ...props}) {

    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [quantities, setQuantities] = useState({});
    const [sources, setSources] = useState({});
    const { release_id } = props.match.params;
    const { type } = props;

    useEffect(() => {
        let response;

        async function fetchParts() {
            setLoading(true);

            try {
                response = await api.rpc('getEquipmentReleaseImports', {release_id, type});
            } catch (e) {
                setError(e);
            }

            setLoading(false);
            if(response.errors){
                setProducts([]);
                setQuantities({});
                setError(response.errors.error.message);
            } else {
                setProducts(response.body.result);
                let newVals = {};
                response.body.result.forEach(p => {
                    newVals[p.id] = 0;
                });

                setError(null);
            }

        }

        fetchParts();
    }, [release_id]);

    useEffect(() => {

        setQuantities(currentQuantities => {
            products.forEach(p => {
                currentQuantities[p.product_id] = 0;
            })

            return currentQuantities;
        });

        setSources(currentSources => {
            products.forEach(p => {
                currentSources[p.product_id] = 'purchase_order';
            })

            return currentSources;
        });

    }, [products]);

    const handleChange = (event, product_id) => {
        const newVal = event.target.value;
        setQuantities(currentQuantities => {
            let newQuantities = Object.assign({}, currentQuantities);
            newQuantities[product_id] = newVal;
            return newQuantities;
        });
    }

    const handleSelect = (event, product_id) => {
        const newVal = event.target.value;
        setSources(currentSources => {
            let newSources = Object.assign({}, currentSources);
            newSources[product_id] = newVal;
            return newSources;
        });
    }

    const handleSubmit = async () => {

        let data = products.map(p => {
            return {
                product_id: p.product_id,
                quantity: quantities[p.product_id],
                source: sources[p.product_id],
            };
        });;

        try {
            await api.rpc('importEquipmentReleaseProducts', {release_id, products: data});
        } catch (e) {
            setError(e);
        }

        history.goBack();
    }

    const setReleaseAll = async () => {

        let newQuantities = {};
        products.forEach(p => {
            newQuantities[p.product_id] = p.product_available;
        });

        setQuantities(newQuantities);
    }


    return <div className='projectImport'>
        <h1>Import {type === 'part' ? 'Parts' : 'Services'}</h1>
        <ErrorMessage errors={error} />
        <Button onClick={setReleaseAll} style={{marginBottom: '12px'}}>Set Max Quantities</Button>
        <table className='importTable'>
            <thead>
                <tr className='headerRow'>
                    <th colspan="1">Part</th>
                    <th colspan="1">{type === 'part' ? 'Quantity' : 'Hours'}</th>
                    {type === 'part' ? <th colspan="1">Source</th> : null}
                    <th colspan="1">Released</th>
                    <th colspan="1">To Be Released</th>
                </tr>
            </thead>
            <tbody>
                {products.map((p, index) => {
                    return <tr className='productRow' key={`${index}_${p.product_id}`}>
                        <td>{p.product_number}</td>
                        <td><input 
                                type='number'
                                value={quantities[p.product_id]} 
                                onChange={e => handleChange(e, p.product_id)} /></td>
                        {type === 'part' ? <td><select name="source" value={sources[p.product_id]} onChange={e => handleSelect(e, p.product_id)}> 
                                <option value="purchase_order">Purchase Order</option>
                                <option value="inventory">Inventory</option>
                            </select>
                        </td> : null}
                        <td>{p.product_released}</td>
                        <td>{p.product_available}</td>
                    </tr>
                })}
            </tbody>
        </table>
        <Button style={{marginRight: '12px'}} onClick={e => { history.goBack() }}>Cancel</Button>
        <Button onClick={handleSubmit}>Import</Button>
    </div>
}

export default withRouter(ProductsImport);
