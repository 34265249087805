import React, { Component } from 'react';
import { Button, Select, Modal } from 'antd';
import api from 'api';
import ErrorMessage from 'components/ErrorMessage';

const Option = Select.Option;


class SearchSelect extends Component { 

    state = {

        value: undefined,
        searching: false,
        modalVisible: false,
        data: [],
        error: null,

    }

    render() {
        const { addLabel } = this.props;
        const { value, modalVisible } = this.state;

        return <React.Fragment>
        <Button icon='plus' style={{marginRight: '12px'}} onClick={this.showAddExistingModal}>{addLabel}</Button>
        <Modal visible={modalVisible}
                title={addLabel}
                okText={'Add'}
                onCancel={this.hideAddExistingModal}
                onOk={this.handleAddExisting}>
                <ErrorMessage errors={this.state.error} />
                <Select 
                    showSearch
                    value={value}
                    placeholder={'Search'}
                    style={{width: '100%'}}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={this.handleSearch}
                    onChange={this.handleChange}
                    notFoundContent={null}>
                    {this.renderOptions()}
                </Select>
            </Modal>
        </React.Fragment>

    }

    renderOptions = () => {
        const { optionValue, optionContent } = this.props;
        const { data } = this.state;

        return data.map(option => {
            const value = optionValue(option);
            return <Option key={value} value={value}>{optionContent ? optionContent(option) : 'Option'}</Option>
        });
    }

    handleChange = (value) => {
        this.setState({value});
    }

    handleSearch = (value) => {
        const { searchUrl, searchFields } = this.props;
        
        this.setState({searching: true}, () => {
            api.get(searchUrl, {query: value, fields: searchFields})
            .then(results => {
                if(results.status === 200) {
                    this.setState({searching: false, data: results.body});
                } else {
                    this.setState({searching: false, data: [], error: 'Failed to search'});
                }
            })
            .catch(e => {
                console.log("Error");
                this.setState({data:[], searching: false, error: e});
            })
        });
    }

    handleAddExisting = () => {
        const { value } = this.state;
        const { onAdd } = this.props;

        onAdd(value)
        .then(result => {
            this.hideAddExistingModal();
        });
    }

    showAddExistingModal = () => {
        this.setState({modalVisible: true});
    }

    hideAddExistingModal = () => {
        this.setState({modalVisible: false});
    }

}

export default SearchSelect;
