
import colors from 'lib/constants/resourceColors';

export default {
    defaults: {
        name: 'Inventory',
        color: colors.inventory,
        namePlural: 'Inventory',
        actions: ['search'],
    },

    search: {
        fields: ['image_url', 'description', 'product_id','product_number','available','total_on_hand','available_on_order'],
        tableProps: {
            keyFunc: (item, index) => `${item.product_id}_${index}`,
            //expandedCell: <cp.InventoryCell />,
            columns:[
                {heading: 'Image', type:'image', accessor: 'image_url'},
                {heading: 'Product Number', accessor: 'product_number'},
                {heading: 'Available', accessor: 'available'},
                {heading: 'On Hand', accessor: 'total_on_hand'},
                {heading: 'On Order (Available)', accessor: 'available_on_order'}
            ]
        }
    }
}
