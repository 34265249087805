import Privileges from '../lib/constants/privileges';
import colors from 'lib/constants/resourceColors';

const form = [
    {key:'transaction_number', static: true},
    {key: 'customer_id',
     displayKey: ['customer_name'],
        label: 'Customer',
        createOnly: true,
        type: 'autocomplete',
        link: { url: '/customers/:customer_id', privileges: Privileges.READ_CUSTOMERS },
        suggestFormat: (c) => {
            let text = `${c.name}`;
            return {value: c.id, text}
        },
        suggestUrl: '/customers',
        suggestFields: ['id', 'name']},
    {type: 'currency',
        createOnly: true,
        key: 'amount',
        required: true},
    'check_number',
    {key: 'last_digits', label: 'Last 4 CC Digits '},
    'memo',
    {label: 'Payments Credited', key: 'payments_sum', static: true, readOnly: true, type: 'currency'},
    {label: 'Remaining Credit', key: 'available_credit', static: true, readOnly: true, type: 'currency'},
    '^file(File){file_url}',
    '?voided',
]

export default {
    defaults: {
        name: 'Transaction',
        color: colors.transaction,
        //identifierParam: 'transaction_number',
        formFields: form,
    },
    search: {
        allowDateFilter: true,
        heading: 'Inbound Customer Transactions',
        searchBy: ['transaction number', 'customer name', 'customer number', 'check number', 'memo', 'amount', 'last four credit card digits'],
        fields: ['id','transaction_number', 'amount','customer_name', 'voided', 'created_at','updated_at'],
        links: [
            'new',
        ],
        tableProps: {
            rowURL: '/transactions/:id',
            columns: [
                {heading: 'Posted', cellWidth: '65px', dateFormat: 'MMM DD, YYYY', type:'date', accessor: 'created_at'},
                {cellWidth: '150px', accessor: 'customer_name'},
                {heading: 'Transaction #', accessor: 'transaction_number', cellWidth: '110px', style: 'bold'},
                {heading: 'Amount', accessor: 'amount', type: 'currency', cellWidth: '110px'},
            ]
        }
    },
    view: {
        title: (t) => `Transaction ${t.transaction_number}`,
    },
}
