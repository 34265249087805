export default function(input) {
    if(typeof input === 'boolean'){
        return input;
    }

    if(typeof input !== 'string' && typeof input !== 'number'){
        throw new Error(`Undefined type for conversion to boolean: ${typeof input}`);
    }

    if(input === '' || input === 0){
        return false;
    } else if (input === 1){
        return true;
    } else {
        throw new Error(`Undefined conversion to boolean for input ${input}`);
    }

}
