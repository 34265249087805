// eslint-disable-next-line
import React, { Component } from 'react';
import api from 'api';
import ErrorMessage from 'components/ErrorMessage';
import { Spin } from 'antd';
import DropDown from './DropDown';
import styled from 'styled-components';
// eslint-disable-next-line
const debug = require('debug')('app:AccessRulesConfig:');


const EmptyText = styled.span`
    font-style: italic;
    color: darkgray;
`

const Section = styled.div`
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 24px;
`

const Parent = styled.div`
    display: table;
    margin-bottom: 12px;
`

const Header = styled.div`
    display: table-row;
    font-weight: 800;
`

const UserHeader = Header.extend`
    background-color: #89e2FF;
    color: #3D82A8;
`

const RoleHeader = Header.extend`
    background-color: #FFE989;
    color: #868603;
`

const Row = styled.div`
    display: table-row;
`

const Cell = styled.div`
    display: table-cell;
    padding: 6px;
`

const UserRow = Row.extend`
    background-color: #ECF7FF;
`

const UserOddRow = Row.extend`
    background-color: #D8EBF9;
`

const RoleRow = Row.extend`
    background-color: #FCFFD4;
`


class AccessRulesConfig extends Component {

    state = {
        loading_rules: true,
        loading_targets: true,
        rules : {},
        targets: { users: [], roles: [] },
        error: null,
    };


    componentDidMount(){
        const { user_id, role_id } = this.props;
        let query;
        if(user_id){
            query = {user: user_id};
        } else if (role_id){
            query = {role: role_id};
        } else {
            throw new Error('Must specify either user_id or role_id for AccessRules component');
        }

        api.read('/access_rules', query)
        .then(response => {
            if(response.errors){
                this.setState({loading_rules: false, error: 'Failed to load'});
            } else {
                const rules = response.body;
                //sketch
                let data = {}
                rules.forEach(rule => {
                    if(!data[rule.object_table]){
                        data[rule.object_table] = {};
                    }

                    const typeMap = data[rule.object_table];
                    if(rule.target_id){
                        typeMap[rule.target_id] = rule;
                    } else if (rule.target_role_id){
                        typeMap[rule.target_role_id] = rule;
                    }
                });

                this.setState({loading_rules: false, rules: data});
            }
        });

        let targets = {};
        api.read('/lists/users', {fields: 'id,first_name,last_name,email'})
        .then(({errors, body}) => {
            if(errors) { throw errors }; 
            targets.users = body;
            
            api.read('/roles', {fields: 'id,name,user_id,is_template'})
            .then(({errors, body}) => {
                if(errors) { throw errors };
                targets.roles = body;

                this.setState({loading_targets: false, targets});
            });
        })
        .catch(errors => {
            this.setState({loading_targets: false, error: errors});
        });
    }

    render(){
        const { loading_rules, loading_targets, error } = this.state;

        if(loading_rules || loading_targets){
            return <Spin tip='Loading' />
        }

        if(error){
            return <ErrorMessage errors={error} />; 
        }

        const contents = [{type: 'expenses', name: 'Expenses'}, {type: 'projects', name: 'Projects'}, {type: 'quotes', name: 'Quotes'}, {type: 'tasks', name: 'Tasks'}];
        return <div>
            
            {contents.map((content_type, index) => {
                return <Section id={content_type.type + '_section'} key={content_type.type + '_' + index}>
                    <h4>{content_type.name}</h4>

                    <Parent>
                        <RoleHeader>
                            <Cell>Role</Cell>
                            <Cell>Create</Cell>
                            <Cell>Read</Cell>
                            <Cell>Write</Cell>
                            <Cell>Delete</Cell>
                        </RoleHeader>

                        {this.state.targets.roles.map((role, index) => {
                            if(role.user_id || role.is_template){
                                return null;
                            }
                            const type = content_type.type;
                            const content_rules = this.state.rules[type];
                            const rule = content_rules ? content_rules[role.id] || {} : {};
                            return <RoleAccessRow key={type + '_' + role.id + '_' + index} 
                                                 subject_id={this.props.user_id}
                                                 subject_role_id={this.props.role_id}
                                                 role={role} 
                                                 rule={rule}
                                                 index={index} 
                                                 content_type={content_type.type}
                                                 change={updateRule.bind(this)} />; 
                        })}
                    </Parent>

                    <Parent>
                        <UserHeader>
                            <Cell>User</Cell>
                            <Cell>Email</Cell>
                            <Cell>Create</Cell>
                            <Cell>Read</Cell>
                            <Cell>Write</Cell>
                            <Cell>Delete</Cell>
                        </UserHeader>
                            
                        {this.state.targets.users.map((user, index) => {
                            const type = content_type.type;
                            const content_rules = this.state.rules[type];
                            const rule = content_rules ? content_rules[user.id] || {} : {};
                            return <UserAccessRow key={type + '_' + user.id + '_' + index} 
                                                 subject_id={this.props.user_id}
                                                 subject_role_id={this.props.role_id}
                                                 user={user} 
                                                 rule={rule}
                                                 index={index} 
                                                 content_type={content_type.type}
                                                 change={updateRule.bind(this)} />; 
                        })}
                    </Parent>

                </Section>
            })}

        </div>
    }
}

const UserAccessRow = ({subject_id, subject_role_id, user, rule, index, change, content_type}) => {

    let target_id = user.id;
    const RowComponent = index % 2 ? UserRow : UserOddRow;

    return <RowComponent>
        <Cell>{user.first_name || user.last_name ? user.first_name + ' ' + user.last_name : <EmptyText>No Name</EmptyText>}</Cell>
        <Cell>{user.email || <EmptyText>No Email</EmptyText>}</Cell>
        
        <Cell><DropDownSelect onChange={e => change(rule.id, subject_id, subject_role_id, target_id, null, content_type, 'create', e.target.value)} 
                                               selectedOption={rule.create} /></Cell>

        <Cell><DropDownSelect onChange={e => change(rule.id, subject_id, subject_role_id, target_id, null, content_type, 'read', e.target.value)} 
                                               selectedOption={rule.read} /></Cell>

        <Cell><DropDownSelect onChange={e => change(rule.id, subject_id, subject_role_id, target_id, null, content_type, 'write', e.target.value)} 
                                               selectedOption={rule.write} /></Cell>

        <Cell><DropDownSelect onChange={e => change(rule.id, subject_id, subject_role_id, target_id, null, content_type, 'delete', e.target.value)} 
                                               selectedOption={rule.delete} /></Cell>
    </RowComponent>
};

const RoleAccessRow = ({subject_id, subject_role_id, role, rule, index, change, content_type}) => {

    let target_role_id = role.id;

    return <RoleRow>
        <Cell>{role.name || <EmptyText>Untitled</EmptyText>}</Cell>


        <Cell><DropDownSelect onChange={e => change(rule.id, subject_id, subject_role_id, null, target_role_id, content_type, 'create', e.target.value)} 
                                               selectedOption={rule.create} /></Cell>

        <Cell><DropDownSelect onChange={e => change(rule.id, subject_id, subject_role_id, null, target_role_id, content_type, 'read', e.target.value)} 
                                               selectedOption={rule.read} /></Cell>

        <Cell><DropDownSelect onChange={e => change(rule.id, subject_id, subject_role_id, null, target_role_id, content_type, 'write', e.target.value)} 
                                               selectedOption={rule.write} /></Cell>

        <Cell><DropDownSelect onChange={e => change(rule.id, subject_id, subject_role_id, null, target_role_id, content_type, 'delete', e.target.value)} 
                                               selectedOption={rule.delete} /></Cell>
    </RoleRow>
};

const updateRule = function(id, subject_id, subject_role_id, target_id, target_role_id, content_type, access_type, value){

    if(value === 'true'){ 
        value = true;
    } else if (value === 'false'){
        value = false;
    } else {
        value = null;
    }

    let data = {};
    data[access_type] = value;
    if(target_id && target_role_id){
        throw new Error("Specify target_id or target_role_id, not both");
    }
    const target_key = target_id || target_role_id;

    if(target_id) { 
        data.target_id = target_id 
    } else { 
        data.target_role_id = target_role_id 
    }

    let new_rules = {...this.state.rules};
    if(!new_rules[content_type]){
        new_rules[content_type] = {};
    }

    const content_rules = this.state.rules[content_type];
    const originalValue = content_rules ? content_rules[target_key] ? this.state.rules[content_type][target_key][access_type] : null 
                                        : null;

    if(!new_rules[content_type][target_key]){
        let rule = {type: content_type};
        if(subject_id){
            rule.subject_id = subject_id;
        } else if (subject_role_id){
            rule.subject_role_id = subject_role_id;
        } else {
            throw new Error('Must specify either subject_id or subject_role_id for access rule');
        }

        if(target_id) { 
            rule.target_id = target_id 
        } else { 
            rule.target_role_id = target_role_id 
        }
        new_rules[content_type][target_key] = rule;
    }

    new_rules[content_type][target_key][access_type] = value;

    let endpoint;
    if(subject_id){
        endpoint = `/access_rules/types/${content_type}/subjects/${subject_id}`;
    } else if (subject_role_id) {
        endpoint = `/access_rules/types/${content_type}/roles/${subject_role_id}`;
    } else {
        throw new Error("Must specify either a subject or subject_role_id to alter rules for");
    }

    if(data.target_id){
        endpoint += `/targets/${target_id}`;
    } else {
        endpoint += `/roles/${target_role_id}`;
    }
    
    this.setState({rules: new_rules});
    api.upsert(endpoint, data)
    .then(({errors, body}) => {
        if(errors){
            throw errors;
        } else {
            console.log("Updated ", body);
        }
    }) 
    .catch(error => {
        console.log("Failed to update access control: ", error);
        let reverted_rules = {...this.state.rules};
        reverted_rules[content_type][target_key][access_type] = originalValue;
        console.log("Revert rules ", reverted_rules);
        this.setState({rules: reverted_rules});
    });

};

const DropDownSelect = (props) => {

    const options = [{value: true, label: 'Allow'}, {value: false, label: 'Deny'}];
    return <DropDown
                options={options}
                useOptionsAsValues={true}
                allowsNullSelection={true}
                showEmptySelection={true}
                {...props}
            />
};

export default AccessRulesConfig;
