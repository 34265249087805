
module.exports = {
      attachmentLimits: null,
      badDebts: '#FF1F99',
      changeOrders: null,
      companyExpenses: '#FA22C2',
      companyProjects: '#B1FB00',
      companyPurchaseOrderDist: '#546bb5',
      companyPurchaseOrders: '#89A1FB',
      companyPurchaseOrderParts: '#8AA5FB',
      companyQuotes: '#A09923',
      companyTasks: '#9776a1',
      contacts: null,
      creditHolds: null,
      customerPurchaseOrders: null,
      customers: '#746cbb',
      defaultQuoteTerms: null,
      employeeTimeOff: null,
      equipmentReleases: '#b3906d',
      expense: '#a82a47',
      expensePayout: null,
      globalTasks: '#9cafa0',
      holidays: null,
      index: null,
      inspection: '#c4d631',
      inspectionCard: '#d6a208',
      inspectionTimeBlock: null,
      inventory: null,
      inventoryLocations: null,
      invoices: '#506702',
      invoiceBatches: null,
      invoiceItems: '#adca4a',
      invoicePayments: '#bdcb4a',
      invoiceRecipients: null,
      part: '#607d8b',
      transactions: '#239944',
      paymentRequests: '#744370',
      paymentRequestItems: '#bca3de',
      phoneLogs: null,
      phoneStation: null,
      projects: '#1ee0d7',
      projectProducts:'#1fe0f7',
      projectPackingSlips:'#5ae0da',
      quotes: '#A09923',
      receivingSlip: '#34B39A',
      receivingSlipDistribution: '#396081',
      remarks: null,
      role: null,
      salesCommissions: null,
      sentEmails: null,
      service: '#8b606a',
      site: '#bf144b',
      tasks: '#9cafa0',
      tasks_cad: '#9cafa0',
      tasks_inspection: '#9cafa0',
      tasks_installation: '#9cafa0',
      tasks_knox: '#9cafa0',
      tasks_permitting: '#9cafa0',
      taskTypes: null,
      taxRegions: '#78329A',
      textTemplate: null,
      timeOffEvent: null,
      user: null,
      vendor: '#966A98',
};
