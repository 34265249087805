import Privileges from '../lib/constants/privileges';
import colors from 'lib/constants/resourceColors';

const form = [
    {key: 'customer_name',
        readOnly: true,
        static: true,
        label: 'Customer',
        link: {url:'/customers/:customer_id', privileges: Privileges.READ_CUSTOMERS}},
    {key: 'invoice_id',
     displayKey: ['invoice_number'],
        label: 'Invoice #',
        createOnly: true,
        type: 'autocomplete',
        link: { url: '/invoices/:invoice_id', privileges: Privileges.READ_INVOICES },
        suggestFormat: (i) => {
            let text = `${i.invoice_number} (${i.customer_name}) ${i.description ? `(${i.description})` : ''}`;
            return {value: i.id, text}
        },
        suggestUrl: '/invoices',
        suggestFields: ['id', 'invoice_number', 'description', 'customer_name']},
    {type: 'currency',
        createOnly: true,
        key: 'amount',
        required: true},
    'memo'
]

export default {
    defaults: {
        name: 'Bad Debt',
        color: colors.badDebts,
        formFields: form,
    },
    search: {
        fields: ['id', 'customer_name', 'invoice_number', 'amount', 'memo'],
        links: [
            'new',
        ],
        tableProps: {
            rowURL: '/bad_debts/:id',
            columns: [
                {accessor: 'customer_name', cellWidth: '300px'},
                {accessor: 'invoice_number', cellWidth: '100px'},
                {accessor: 'amount', type: 'currency', cellWidth: '150px'},
                {accessor: 'memo'},
            ]
        }
    }
}
