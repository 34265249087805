
import colors from 'lib/constants/resourceColors';
import Privileges from 'lib/constants/privileges';
const form = [
    {key: 'identifier', 
        hint: 'This is the codename given to the phone station', 
        required: true},
    {key: 'name'},
]

export default {
    defaults: {
        name: 'Phone Station',
        color: colors.phoneStation,
        formFields: form,
    },

    view: {
        title: (p) => `${p.identifier} (${p.name})`,
        formProps: {
            editPrivileges: [Privileges.UPDATE_PHONE_STATIONS],
            deletePrivileges: [Privileges.DELETE_PHONE_STATIONS],
        }
    },

    search: {
        fields: ['id', 'name', 'identifier'],
        links: ['new'],
        tableProps: {
            rowURL: '/phone_stations/:id',
            columns: [{heading: 'Station', cellWidth: '25%', style: 'bold', accessor: 'identifier'}, {heading: 'Assigned Name', accessor:'name'}],
        }
    },
};
