import titleCase from 'lib/helpers/titleCase';
import Editor from 'components/Editor';
import bindUrl from 'lib/helpers/bindURL';
import setFormFieldDefaults from 'lib/helpers/setFormFieldDefaults';
import Privileges from 'lib/constants/privileges';


function setDefault(values, targetKey, defaultValue) {
    if(defaultValue === undefined || typeof defaultValue === 'undefined'){
        throw new Error(`Default value unspecified for key ${targetKey}, got ${defaultValue}`);
    }

    if(values[targetKey] === undefined){
        values[targetKey] = defaultValue;
    }
}

function setResourceDefaults(spec) {

    let config = Object.assign({}, spec);
    const { defaults } = config;

    if(!defaults.name){
        throw new Error('Config objects must define a name for a resource');
    }


    setDefault(defaults, 'key', defaults.name);
    setDefault(defaults, 'namePlural', `${defaults.name}s`);
    setDefault(defaults, 'color', null);
    const defaultUrl = '/' + defaults.namePlural.toLowerCase().replace(/ /g, '_');
    setDefault(defaults, 'urlBase', defaultUrl);
    setDefault(defaults, 'appUrlBase', defaults.urlBase);
    setDefault(defaults, 'identifierParam', 'id');
    setDefault(defaults, 'appUrlIdentifierParam', defaults.identifierParam);
    setDefault(defaults, 'dataUrlBase', defaults.urlBase);
    setDefault(defaults, 'dataUrlIdentifierParam', defaults.identifierParam);
    setDefault(defaults, 'actions', ['search', 'create', 'view']);
    setDefault(defaults, 'formFields', []);
    defaults.formFields = setFormFieldDefaults(defaults.formFields);
    setDefault(defaults, 'formActions', []);
    setDefault(defaults, 'formTemplates', {});
    setDefault(defaults, 'formTemplateSearch', {});



    if(!defaults.privileges) {
        defaults.privileges = {};
    }

    let formattedName = typeof defaults.privileges === 'string' ? 
                        defaults.privileges.toUpperCase().replace(/ /g, '_')
                        : 
                        defaults.namePlural.toUpperCase().replace(/ /g, '_');

    //If a privileges object has been specified for defaults, use that, if not make an empty object.
    let privileges = typeof defaults.privileges === 'object' ? 
                            Object.assign({}, defaults.privileges) : {};

    setDefault(privileges, 'search', [Privileges[`SEARCH_${formattedName}`]]);
    setDefault(privileges, 'create', [Privileges[`CREATE_${formattedName}`]]);
    setDefault(privileges, 'read', [Privileges[`READ_${formattedName}`]]);
    setDefault(privileges, 'update', [Privileges[`UPDATE_${formattedName}`]]);
    setDefault(privileges, 'delete', [Privileges[`DELETE_${formattedName}`]]);
    if(defaults.actions.includes('batch')) {
        setDefault(privileges, 'batch', [Privileges[`CREATE_BATCH_${formattedName}`]]);
    }

    defaults.privileges = privileges;

    if(defaults.actions.includes('view')){
        if(!config.view){
            config.view = {};
        }

        let view = config.view;
        setDefault(view, 'title', (o) => defaults.name);
        setDefault(view, 'color', defaults.color);
        setDefault(view, 'component', Editor);
        setDefault(view, 'routeKey', `view_${defaults.key}`);
        setDefault(view, 'appUrl', `${defaults.appUrlBase}/:${defaults.appUrlIdentifierParam}`);
        setDefault(view, 'dataUrl', `${defaults.dataUrlBase}/:${defaults.dataUrlIdentifierParam}`);
        setDefault(view, 'accessControl', false);
        setDefault(view, 'formFields', defaults.formFields);
        view.formFields = setFormFieldDefaults(view.formFields);
        setDefault(view, 'formActions', defaults.formActions);
        setDefault(view, 'formTemplates', defaults.formTemplates);
        setDefault(view, 'formTemplateSearch', defaults.formTemplateSearch);
        setDefault(view, 'privileges', defaults.privileges.read);
        setDefault(view, 'enableDelete', true);
        setDefault(view, 'deletePrivileges', defaults.privileges.delete);
        setDefault(view, 'editPrivileges', defaults.privileges.update);
        setDefault(view, 'mainImageURLKey', 'image_url');
        setDefault(view, 'mainImageWidth', '200px');

        let defaultHeader = (values) => { return values.name || 
                                                values.site_name || 
                                                (values.first_name && values.last_name ? `${values.first_name} ${values.last_name}` : null) || 
                                                values.product_number || 
                                                values.id || 
                                                'Unknown' };
        if(!view.remarks) {
            view.remarks = {
                object_id_key: 'id',
                object_type: defaults.name.toLowerCase(),
                header: defaultHeader,
            }
        } else {
            let remarks = view.remarks;
            setDefault(remarks, 'object_id_key', 'id');
            setDefault(remarks, 'object_type', defaults.name.toLowerCase());
            setDefault(remarks, 'header', defaultHeader);
        }

    } else {
        delete config.view;
    }

    if(defaults.actions.includes('create')){
        if(!config.create){
            config.create = {};
        }

        let create = config.create;
        let view = config.view;
        setDefault(create, 'title', `New ${titleCase(defaults.name)}`);
        setDefault(create, 'routeKey', `create_${defaults.key}`);
        setDefault(create, 'appUrl', `${defaults.appUrlBase}/new`);
        setDefault(create, 'postUrl', `${defaults.dataUrlBase}`);
        setDefault(create, 'instantCreate', false);
        setDefault(create, 'redirectUrl', view.appUrl); 
        setDefault(create, 'urlFormatter', (newObject) => bindUrl(view.appUrl, newObject));
        setDefault(create, 'privileges', defaults.privileges.create);
        setDefault(create, 'formFields', defaults.formFields);
        create.formFields = setFormFieldDefaults(create.formFields);
        setDefault(create, 'formActions', defaults.formActions);
        setDefault(create, 'formTemplates', defaults.formTemplates);
        setDefault(create, 'formTemplateSearch', defaults.formTemplateSearch);
    } else {
        delete config.create;
    }


    if(defaults.actions.includes('search')){
        if(!config.search){
            config.search = {};
        }

        let search = config.search;
        let create = config.create;
        setDefault(search, 'fields', []);
        setDefault(search, 'defaultFilter', () => {});
        setDefault(search, 'heading', titleCase(defaults.namePlural));
        setDefault(search, 'searchBy', []);
        setDefault(search, 'cacheKey', `cached_search_${defaults.name.toLowerCase()}`);
        setDefault(search, 'routeKey', `search_${defaults.key}`);
        setDefault(search, 'appUrl', `${defaults.appUrlBase}`); 
        setDefault(search, 'dataUrl', `${defaults.dataUrlBase}`);
        setDefault(search, 'privileges', defaults.privileges.search);

        if(search.links) {
            search.links = search.links.map(link => {
                if(typeof link === 'string') {
                    switch(link) {
                        case 'new':
                            if(!defaults.actions.includes('create')){
                                throw new Error('Create action not allowed on resource.');
                            }

                            return {
                                url: create.appUrl,
                                icon: 'plus',
                                title: `New ${titleCase(defaults.name)}`,
                                requiredPrivileges: create.privileges
                            };
                        default:
                            return null;
                    }
                } else {
                    return link;
                }
            }).filter(v => v);
        }

        if(search.tableProps) {
            const { tableProps, fields } = search;

            setDefault(tableProps, 'showHeading', true);
            if(tableProps.columns) { 
                tableProps.columns = tableProps.columns.map(column => {
                    //set sortable property and sortField by default on columns
                    if(typeof column === 'string') {
                        //parse shorthand notation
                        column = {accessor: column}
                    }
                    let accessor = null;
                    if(column.type === 'multiple') {
                        let firstRow = column.rows[0];
                        if(firstRow) {
                            //TODO: Refact into general extraction function
                            if(firstRow.type === 'dateRange') {
                                accessor = column.startDateAccessor;
                            } else {
                                accessor = firstRow.accessor;
                            }
                        }
                    } else if (column.type === 'dateRange') {
                        accessor = column.startDateAccessor;
                    } else {
                        accessor = column.accessor;
                    }

                    if(Array.isArray(accessor)) {
                        //some accessor's can be arrays, 
                        //in this case use the first by default
                        accessor = accessor[0];
                    }

                    let defaultColumn = Object.assign({}, column);
                    if(fields.includes(accessor)) {
                        setDefault(defaultColumn, 'sortable', true);
                        setDefault(defaultColumn, 'sortField', accessor);
                    } else {
                        setDefault(defaultColumn, 'sortable', false);
                        setDefault(defaultColumn, 'sortField', null);
                    }

                    setDefault(defaultColumn, 'privileges', []);

                    return defaultColumn;
                });
            }
        }
    } else {
        delete config.search;
    }

    if(defaults.actions.includes('batch')){
        if(!config.batch){
            config.batch = {};
        }

        let batch = config.batch;
        setDefault(batch, 'routeKey', `batch_${defaults.key}`);
        setDefault(batch, 'appUrl', `${defaults.appUrlBase}/csv/upload`);
        setDefault(batch, 'dataUrl', `${defaults.dataUrlBase}`);
        setDefault(batch, 'privileges', defaults.privileges.batch);
    } else { 
        delete config.batch;
    }

    return config;
};

export default setResourceDefaults;
