import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Controls from './Controls';
import { Calendar } from 'calendar-base';
import { fromJS, Map, OrderedMap } from 'immutable';
import BaseCalendar from './BaseCalendar';
import EventsDock from './EventsDock';
import moment from 'moment'
import styled from 'styled-components';
import countCertainDays from '../helpers/countCertainDays';

// eslint-disable-next-line
const debug = require('debug')('app:Scheduler:');

const propTypes = {
    loadEvents: PropTypes.func,
    loadUnscheduledEvents: PropTypes.func,
    unscheduledEventsSort: PropTypes.func,
    weekStart: PropTypes.number,
    eventModal: PropTypes.node,
};
const defaultProps = {
    weekStart: 0,
    defaultEventStartHour: 8,
    defaultEventEndHour: 17,
    loadEvents: null,
    loadUnscheduledEvents: null,
    unscheduledEventsSort: null,
    eventModal: null,
};

const CalendarContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex: 2;
`
const DockContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;

    @media print {
        display: none;
    }
`


function immutableEventsSort(event1, event2) {
    // let event1Status = event1.get('eventStatus');
    // let event2Status = event2.get('eventStatus');

    // if(event1Status === 'holiday' && event2Status !== 'holiday') {
    //     return -1;
    // } else if (event2Status === 'holiday' && event1Status !== 'holiday') {
    //     return 1;
    // }

    let event1_startDate = event1.has('pendingStartDate') ? event1.get('pendingStartDate') : event1.get('startDate');
    let event2_startDate = event2.has('pendingStartDate') ? event2.get('pendingStartDate') : event2.get('startDate');

    return event1_startDate - event2_startDate;
}

class Scheduler extends Component {


    constructor(props) {
        super(props);

        this.calendar = new Calendar({weekStart: props.weekStart, siblingMonths: true, });

        const currentDate = moment(new Date());

        this.state = {
            today: currentDate,
            loading: false,
            selectedMonth: currentDate.month(),
            selectedYear: currentDate.year(),
            events: OrderedMap(),
            unscheduledEvents: OrderedMap(),
            eventDelta: Map(),
            modalIsOpen: false,
            modalEventId: null,
            modalDay: null,
            compressEvents: false,
            calendarHeight: 0,
        }
    }

    componentDidMount() {
        const selectedMonth = this.getSelectedMonth();
        const selectedYear = this.getSelectedYear();

        this.loadEventsForMonthInYear(selectedMonth, selectedYear);
    }

    getSelectedMonth = () => {
        if(this.props.selectedMonth !== null && this.props.selectedMonth !== undefined) {
            return this.props.selectedMonth;
        } else {
            return this.state.selectedMonth;
        }
    }

    getSelectedYear = () => {
        if(this.props.selectedYear !== null && this.props.selectedYear !== undefined) {
            return this.props.selectedYear;
        } else {
            return this.state.selectedYear;
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        const month = this.getSelectedMonth();
        const year = this.getSelectedYear();
        //const prevMonth = prevProps.selectedMonth || prevState.selectedMonth;
        //const prevYear = prevProps.selectedYear || prevState.selectedYear;
        const prevMonth = prevProps.selectedMonth !== null && prevProps.selectedMonth !== undefined ? prevProps.selectedMonth : prevState.selectedMonth;
        const prevYear = prevProps.selectedYear !== null && prevProps.selectedYear !== undefined ? prevProps.selectedYear : prevState.selectedYear;
        
        if (prevMonth !== month || prevYear !== year) {
            this.loadEventsForMonthInYear(month, year);
        }
    }

    loadEventsForMonthInYear = async (month, year) => {
        const { loadUnscheduledEvents, loadEvents, unscheduledEventsSort } = this.props;

        let days = this.calendar.getCalendar(year, month);

        let startDay = days[0];
        let endDay = days[days.length-1];
        let startDate = new Date(Date.UTC(startDay.year, startDay.month, startDay.day,0,0,0));
        let endDate = new Date(Date.UTC(endDay.year, endDay.month, endDay.day,23,59,59));

        let promises = [];
        if(loadEvents){
            promises.push(loadEvents(startDate, endDate));
        } else {
            promises.push([]); 
        }

        if(loadUnscheduledEvents){
            promises.push(loadUnscheduledEvents(startDate, endDate));
        } else {
            promises.push([]);
        }

        this.setState({loading: true});
        Promise.all(promises)
        .then(resultsArray => {
            let fetchedEvents = resultsArray[0] 
            let fetchedUnscheduledEvents = resultsArray[1];

            //Error checks
            if(Array.isArray(fetchedEvents) === false){
                throw new Error("Expected fetchedEvents to be an Array of events, received ", typeof fetchedEvents);
            }

            if(Array.isArray(fetchedUnscheduledEvents) === false){
                throw new Error("Expected fetchedUnscheduledEvents to be an Array of events, received ", typeof fetchedUnscheduledEvents);
            }


            //separate the scheduled events
            let scheduledEvents = fetchedEvents.filter(e => e.startDate !== undefined || typeof e.startDate !== 'undefined')

            //separate the unscheduled events and combine with any fetched unscheduled events
            let unscheduledEvents = fetchedEvents.filter(e => e.startDate === undefined)
            unscheduledEvents = unscheduledEvents.concat(fetchedUnscheduledEvents || []);

            scheduledEvents.sort((d1,d2) => { 
                // if(d1.eventStatus === 'holiday' && d2.eventStatus !== 'holiday') {
                //     return -1;
                // } else if (d1.eventStatus !== 'holiday' && d2.eventStatus === 'holiday') {
                //     return 1;
                // }
                
                return d1.startDate - d2.startDate
            });

            let newEvents = OrderedMap(scheduledEvents.map(e => [e.id, Map(e)]));
            let newUnscheduledEvents = OrderedMap(unscheduledEvents.map(e => [e.id, Map(e)]));

            if(unscheduledEventsSort){
                newUnscheduledEvents = newUnscheduledEvents.sort(unscheduledEventsSort);
            }
            
            this.setState({loading: false, events: newEvents, unscheduledEvents: newUnscheduledEvents});
        });
    }

    render(){
        const { weekStart, hideWeekends, eventModal } = this.props;
        const { loading, compressEvents, events, unscheduledEvents, today, modalIsOpen, modalEventId, modalDay, hideDock } = this.state;
        const selectedMonth = this.getSelectedMonth();
        const selectedYear = this.getSelectedYear();

        const elementRef = (element) => {
            const height = element ? element.clientHeight + 0 : 0;

            if(height && height !== this.state.calendarHeight){
                console.log("Setting height ", height);
                this.setState({calendarHeight: height});
            } 

        }

        return <div style={{display: 'flex', flexDirection: 'row'}}>
            <CalendarContainer> 
                <Controls 
                    month={selectedMonth}
                    year={selectedYear}
                    handleNext={this.incrementMonth}
                    handlePrevious={this.decrementMonth}
                    handleToday={this.goToCurrentMonth}
                    handleCompressEvents={this.handleCompressEvents}
                    handleHideDock={this.handleHideDock}
                    compressEvents={compressEvents}
                    hideDock={hideDock}
                />
                <BaseCalendar 
                    loading={loading}
                    hideWeekends={hideWeekends}
                    eventModal={eventModal}
                    compressEvents={compressEvents}
                    innerRef={elementRef}
                    onEventClick={this.handleEventClick}
                    onEventContextMenu={this.handleEventContextMenu}
                    onModalClose={this.handleModalClose}
                    weekStart={weekStart}
                    eventLengthDidChange={this.changeEventDaysLength}
                    eventStartTimeDidChange={this.changeEventStartTime}
                    eventEndTimeDidChange={this.changeEventEndTime}
                    eventDidDrop={this.handleEventDropOnDay}
                    calendar={this.calendar}
                    immutableEvents={events}
                    modalIsOpen={modalIsOpen}
                    modalEvent={events.get(modalEventId) || unscheduledEvents.get(modalEventId)}
                    modalDay={modalDay}
                    today={today}
                    month={selectedMonth}
                    year={selectedYear}
                />
            </CalendarContainer>
            {this.renderDock()}
        </div>
    }

    renderDock = () => {
        const { hideDock, unscheduledEvents } = this.state;
        const { loadUnscheduledEvents } = this.props;

        if(hideDock || !loadUnscheduledEvents) {
            return null;
        } else {
            return <DockContainer>
                <EventsDock 
                    calendarHeight={this.state.calendarHeight}
                    eventDidDrop={this.handleEventDropOnDock}
                    onEventClick={this.handleDockedEventClick}
                    immutableEvents={unscheduledEvents} />
            </DockContainer>
        }

    }

    handleCompressEvents = (e) => {
        this.setState({compressEvents: e.target.checked});
    }

    handleHideDock = (e) => {
        this.setState({hideDock: e.target.checked});
    }

    handleEventClick = (e, immutableEvent, day) => {
        //this.setState({modalIsOpen: true, modalEventId: immutableEvent.get('id'), modalDay: day});
        const { onEventClick } = this.props;
        if(onEventClick){
            onEventClick(immutableEvent.toJSON());
            e.stopPropagation();
        }
    }

    handleEventContextMenu = (e, immutableEvent, day) => {
        const { onEventContextMenu } = this.props;
        if(onEventContextMenu) {
            onEventContextMenu(immutableEvent.toJSON());
            e.stopPropagation();
        }
    }

    handleDockedEventClick = (e, immutableEvent ) => {
        //this.setState({modalIsOpen: true, modalEventId: immutableEvent.get('id')});
        const { onDockedEventClick } = this.props;
        if(onDockedEventClick) {
            onDockedEventClick(immutableEvent.toJSON());
            e.stopPropagation();
        }
    }

    handleModalClose = (e) => {
        console.log("Close modal");
        this.setState({modalIsOpen: false, modalEventId: null, modalDay: null});
        e.stopPropagation();
    }

    handleEventDropOnDock = (event) => {
        const { unscheduleEvent } = this.props;

        let immutableEvent = this.state.events.has(event.id) ?
                             this.state.events.get(event.id) :
                             this.state.unscheduledEvents.get(event.id);

        if(immutableEvent.has('startDate')){
            //if an event is scheduled for a startDate, it has to be unscheduled to be on the dock.
            this.setEventUnscheduled(event.id, false)
            .then(result => { 
                //api call
                return unscheduleEvent(event.id)
            })
            .then(result => {
                this.setEventUnscheduled(event.id, true);
            });

        } else {
            //the event is already unscheduled and no further action needs to be taken.
            return {}
        }

    }

    handleEventDropOnDay = (item, day) => {
        const { defaultEventStartHour } = this.props;

        let immutableEvent = this.state.events.has(item.id) ? 
                             this.state.events.get(item.id) : 
                             this.state.unscheduledEvents.get(item.id);

        let date = new Date(Date.UTC(day.year, day.month, day.day, 0,0,0));
        date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        let originalDate = immutableEvent.get('startDate');
        if(originalDate) {
            date.setHours(originalDate.getHours());
            date.setMinutes(originalDate.getMinutes());
        } else if(defaultEventStartHour !== null){
            date.setHours(defaultEventStartHour);
        }

        if(immutableEvent.has('startDate')){
            this.moveEventToDate(immutableEvent.get('id'), date);
        } else {
            this.scheduleEventForDate(immutableEvent.get('id'), date, immutableEvent);
        }

    }

    totalActiveDaysBetweenDates = (startDate, endDate, skippedDays = []) => {
        console.log("total active");
        let daySpan = moment(endDate).diff(moment(startDate), 'days') + 1;
        console.log("Day span is ", daySpan);
        if(skippedDays && skippedDays.length){
            daySpan -= countCertainDays(skippedDays, startDate, endDate);
        }

        console.log("With skipped, Day span is ", daySpan);

        return daySpan;
    }

    changeEventStartTime = (newStartDate, eventId) => {

        this.setState(prevState => {

            let newEvents = prevState.events.update(eventId, event => {

                let adjustedEndDate = null;
                let currentEndDate = event.get('endDate');
                if(currentEndDate < newStartDate){
                    adjustedEndDate = newStartDate;
                }

                return event.withMutations(e => {
                    e.set('startDate', newStartDate);
                    if(adjustedEndDate) {
                        e.set('endDate', adjustedEndDate);
                    }
                });
            });

            return {events: newEvents};
        });
    }

    changeEventEndTime = (newEndDate, eventId) => {

        this.setState(prevState => {

            let newEvents = prevState.events.update(eventId, event => {

                let currentStartDate = event.get('startDate');
                console.log("Compare ", newEndDate, currentStartDate, newEndDate < currentStartDate, newEndDate > currentStartDate);
                if(newEndDate < currentStartDate){
                    newEndDate = currentStartDate;
                }

                return event.withMutations(e => {
                    e.set('endDate', newEndDate);
                });
            });

            return {events: newEvents};
        });
    }

    changeEventDaysLength = (eventId, days, commit=false) => {

        if(commit){
            //make api call
            console.log("Save change");
        }

        this.setState(prevState => {

            let newMutations = prevState.eventDeltas.update(eventId, eventDelta => {
                if(!eventDelta) {
                    eventDelta = Map();
                }

                let event = prevState.events.get(eventId);
                let startDate = event.get('startDate');
                let newEndDate = this.getNextValidEndDateForEvent(event, startDate, days);

                return eventDelta.withMutations(e => {
                    e.set('days', days);
                    e.set('endDate', newEndDate);
                });
            });

            return { eventDeltas: newMutations };
        }, (param) => {
                     
            let changes = {
                days : this.state.eventDeltas.getIn([eventId, 'days']),
                endDate : this.state.eventDeltas.getIn([eventId, 'endDate'])
            };

            //api call
            console.log("Save eventId with changes ", eventId, changes);

            //on failure, delete the mutations
            this.deleteMutationsForEvent(eventId, Object.keys(changes));
                     
        });
    }

    deleteMutationsForEvent = (eventId, keys) => {
        this.setState(prevState => {

            let newMutations = prevState.eventDeltas.update(eventId, eventDeltas => {
                if(!eventDeltas) {
                    eventDeltas = Map();
                }

                return eventDeltas.withMutations(e => {
                    keys.forEach(key => {
                        e.delete(key);
                    });
                });
            });

            return { eventDeltas: newMutations };
        });

    }

    moveEventToDate = (eventId, selectedStartDate) => {
        const { moveEventToNewDate } = this.props;

        let event = this.state.events.get(eventId);
            
        let oldStartDate = event.get('startDate');
        let oldEndDate = event.get('endDate');
        let days;
        if(!event.has('days')) {
           days = this.totalActiveDaysBetweenDates(oldStartDate, oldEndDate, event.get('skippedDays') || []); 
        } else {
            days = event.get('days');
        }

        let actualStartDate = this.getNextValidStartDateForEvent(event, selectedStartDate);
        let newEndDate = this.getNextValidEndDateForEvent(event, actualStartDate, days);

        this.setEventWithDates(eventId, actualStartDate, newEndDate, false)
        .then(result => {
            return moveEventToNewDate(eventId, actualStartDate, newEndDate)
        })
        .then(success => {
            if(success) {
                return this.setEventWithDates(eventId, actualStartDate, newEndDate, true);
            } else {
                return this.setEventWithDates(eventId, oldStartDate, oldEndDate, true);
            }
        });
    }

    getNextValidStartDateForEvent = (event, startDate) => {

        if(event.has('skippedDays')){
            let skippedDays = event.get('skippedDays');
            if(skippedDays.length >= 7){
                //if no weekday is valid... just return the targetEndDate
                return startDate;
            }

            let daysChecked = 0;
            let actualStartDate = new Date(startDate);

            while(daysChecked < 7 && skippedDays.includes(actualStartDate.getDay())){
                //continue incrementing the actualStartDate until it lands on a valid weekday
                actualStartDate.setTime(actualStartDate.getTime() + 24*3600*1000);
                daysChecked++;
            }

            if(daysChecked === 7){
                //should never happen
                console.error("Failed to find end date for event, all days are marked as skipped");
            }

            return actualStartDate;
        } else { 
            return startDate;
        }


    }

    getNextValidEndDateForEvent = (event, startDate, daysEventIsActive = 1) => {
        const { defaultEventEndHour } = this.props;
       
        console.log("Days event is active ", daysEventIsActive);
        //add the days needed to reach the target end date, not including the start date
        let targetEndDate = new Date(startDate.getTime() + (24*3600*1000 * (daysEventIsActive-1)));

        let originalEndDate = event.get('endDate');
        if(originalEndDate) {
            targetEndDate.setHours(originalEndDate.getHours());
            targetEndDate.setMinutes(originalEndDate.getMinutes());
        } else if(event.get('defaultEndHour') !== null) {
            let defaultEndHour = event.get('defaultEndHour');
            targetEndDate.setHours(defaultEndHour);
        } else if(defaultEventEndHour !== null){
            targetEndDate.setHours(defaultEventEndHour);
        }

        console.log("Target end date is ", targetEndDate);

        if(event.has('skippedDays')){
            console.log("Count skipped days");
            let skippedDays = event.get('skippedDays');
            if(skippedDays.length >= 7){
                //if no weekday is valid... just return the targetEndDate
                return targetEndDate;
            }
            let numberOfSkippedDays = countCertainDays(skippedDays, startDate, targetEndDate);
            let missingDays = numberOfSkippedDays;
            let adjustedEndDate = new Date(targetEndDate.getTime());

            let failed = 0;
            console.log("Missing days is ", missingDays);
            while(missingDays > 0 && failed < 8){
                //extend the end date to accommodate missing event days
                adjustedEndDate.setTime(adjustedEndDate.getTime() + (24*3600*1000));

                if(!skippedDays.includes(adjustedEndDate.getDay())){
                    //added a new event day
                    missingDays--;
                    failed = 0;
                } else {
                    failed++;
                }
            }

            if(failed >= 8){
                console.error("Could not add time to account for missing event days, all weekdays are skipped!");
            }
                              
            console.log("Check from ", adjustedEndDate);

            let daysChecked = 0;
            while(daysChecked < 7 && skippedDays.includes(adjustedEndDate.getDay())){
                //continue incrementing the adjustedEndDate until it lands on a valid weekday
                adjustedEndDate.setTime(adjustedEndDate.getTime() + (24*3600*1000));
                daysChecked++;
            }

            if(daysChecked >= 7){
                //should never happen
                console.error("Failed to find end date for event, all days are marked as skipped");
            }

            console.log("Adjusted end date ", adjustedEndDate);
            return adjustedEndDate;
        } else { 
            return targetEndDate;
        }


    }

    scheduleEventForDate = (eventId, startDate, immutableEvent) => {
        const { scheduleEventOnDate } = this.props;

        //make API call
        let event = this.state.unscheduledEvents.get(eventId);
        let eventDays = event.get('days') || 1;
        let endDate = this.getNextValidEndDateForEvent(event, startDate, eventDays);

        console.log("Loading it");
        this.setEventWithDates(eventId, startDate, endDate, false)
        .then(result => {
            console.log("Calling API");
            return scheduleEventOnDate(eventId, startDate, endDate, immutableEvent.toJSON())
        })
        .then(success => {
            if(success) {
                return this.setEventWithDates(eventId, startDate, endDate, true);
            } else {
                return this.setEventUnscheduled(eventId, true);
            }
        });
    }

    setEventUnscheduled(eventId, commit) {

        return new Promise((resolve, reject) => {
            this.setState(prevState => {
                const { events, unscheduledEvents } = prevState;

                let newState = {};

                if(events.has(eventId)){
                    let newEvents = events.withMutations(map => {
                        let event = events.get(eventId);
                        if(commit){
                            map.delete(eventId);
                        } else {
                            let updatedEvent = event.set('updating', true);
                            map.set(eventId, updatedEvent);
                        }
                    });

                    if(commit) {
                        //TODO: experiment with doing this inside mutations..
                        newEvents = newEvents.sort(immutableEventsSort);
                    }

                    newState.events = newEvents;
                }


                let newUnscheduledEvents = unscheduledEvents.withMutations(map => {
                    let event = events.has(eventId) ? events.get(eventId) : unscheduledEvents.get(eventId);
                    let updatedEvent = event.withMutations(e => {
                        if(!e.has('days') && e.has('startDate') && e.has('endDate')){
                            let startDate = e.get('startDate');
                            let endDate = e.get('endDate');
                            let days = this.totalActiveDaysBetweenDates(startDate, endDate, e.get('skippedDays') || []); 
                            e.set('days', days)
                        }

                        e.delete('startDate');
                        e.delete('endDate');
                        e.set('updating', !commit);
                    });

                    map.set(eventId, updatedEvent);
                });

                newState.unscheduledEvents = newUnscheduledEvents;

                return newState;
            }, () => {
                resolve()
            });
        });
    }

    setEventWithDates(eventId, startDate, endDate, commit) {
        //set an event with a new startDate and endDate
        //commit is true if the backend has already confirmed the change
        const { eventWillUpdate, unscheduledEventsSort } = this.props;

        return new Promise((resolve, reject) => {
            this.setState(prevState => {
                const { events, unscheduledEvents } = prevState;

                let newEvents = prevState.events.withMutations(map => {
                    let event = events.has(eventId) ? events.get(eventId) : unscheduledEvents.get(eventId);

                    let changes = {};
                    if(eventWillUpdate) {
                        changes = eventWillUpdate(event.toJSON());
                    }

                    let updatedEvent = event.withMutations(e => {
                        e.set('startDate', startDate); 
                        e.set('endDate', endDate); 
                        e.set('updating', !commit); //if not commiting yet, show the event at the new location in the updating state
                        if(!e.has('days')){
                            let days = this.totalActiveDaysBetweenDates(startDate, endDate, e.get('skippedDays') || []); 
                            e.set('days', days)
                        }
                        Object.keys(changes).forEach(key => {
                            e.set(key, changes[key]);
                        });
                    });

                    map.set(eventId, updatedEvent);
                });

                newEvents = newEvents.sort(immutableEventsSort);
                let newState = { events: newEvents };

                if(unscheduledEvents.has(eventId)){
                    //this event is coming from the unscheduled events list, it must now be updated there

                    let newUnscheduledEvents; 
                    if(commit){
                        //if commiting, delete the event from the list of unscheduled events
                        newUnscheduledEvents = unscheduledEvents.delete(eventId);
                        if(unscheduledEventsSort){
                            newUnscheduledEvents = newUnscheduledEvents.sort(unscheduledEventsSort);
                        }
                    } else {
                        //if not commiting yet, show the event as updating in the list of unscheduled events
                        newUnscheduledEvents = prevState.unscheduledEvents.withMutations(map => {
                            let removedEvent = prevState.unscheduledEvents.get(eventId).withMutations(e => {
                                e.set('updating', !commit);
                            });

                            map.set(eventId, removedEvent);
                        });
                    }

                    newState.unscheduledEvents = newUnscheduledEvents;
                }

                return newState;
            }, () => {
                resolve(); 
            });
        });

    }


    goToCurrentMonth = () => {
        const { onMonthChange } = this.props;
        const originalSelectedMonth = this.getSelectedMonth();
        const originalSelectedYear = this.getSelectedYear();

        const today = new Date();
        const selectedMonth = today.getMonth();
        const selectedYear = today.getFullYear();

        if(onMonthChange){
            onMonthChange(selectedMonth, selectedYear);
        } else { 
            this.setState({selectedMonth, selectedYear});
        }
    }

    incrementMonth = () => {
        const { onMonthChange } = this.props;
        const originalSelectedMonth = this.getSelectedMonth();
        const originalSelectedYear = this.getSelectedYear();
        
        const selectedMonth = (originalSelectedMonth + 1) % 12; 
        const selectedYear = ((originalSelectedMonth + 1) >= 12 ? originalSelectedYear + 1 : originalSelectedYear); 

        if(onMonthChange){
            onMonthChange(selectedMonth, selectedYear);
        } else { 
            this.setState({selectedMonth, selectedYear});
        }
    }

    decrementMonth = () => {
        const { onMonthChange } = this.props;
        const originalSelectedMonth = this.getSelectedMonth();
        const originalSelectedYear = this.getSelectedYear();

        const selectedMonth = (originalSelectedMonth ? originalSelectedMonth : 12) - 1;
        const selectedYear = ((originalSelectedMonth - 1) < 0 ? originalSelectedYear - 1 : originalSelectedYear); 

        if(onMonthChange){
            onMonthChange(selectedMonth, selectedYear);
        } else { 
            this.setState({selectedMonth, selectedYear});
        }
    }
}

Scheduler.propTypes = propTypes;
Scheduler.defaultProps = defaultProps;

export default Scheduler;
