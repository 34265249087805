import colors from 'lib/constants/resourceColors';
import Privileges from 'lib/constants/privileges';
import moment from 'moment';
import api from 'api';
import formatPhoneNumber from 'lib/helpers/formatPhoneNumber';
import { EST_PALE_RED, EST_PALE_GREEN, EST_PALE_YELLOW, EST_PALE_GRAY_RED, EST_LIGHT_RED, EST_PALE_GRAY_BLUE } from 'lib/constants/colors';

const customerFunc = (customer) => {
    return {label: customer.name, value: customer.id}
}



const contactFunc = (contact) => {

    return {label: `${contact.name}${contact.email ? `, ${contact.email}` : ''}${contact.title ? ` (${contact.title})` : ''}`, 
            value: contact.id};

}

function statusBadgeStyle(value, data){
    const { contact_status } = data;

    if(data.completed) {
        return {status: 'default', text: 'Completed'};

    } else if(contact_status === 'waiting'){
        return {status: 'warning', text: 'Waiting for Reply'}; 

    } else if (contact_status === 'confirmed'){
        return {status: 'success', text: 'Confirmed'}; 

    } else if (contact_status === 'unconfirmed') {
        if(data.pending) {
            return {status: 'error', text: 'Pending'}; 
        } else {
            return {status: 'error', text: data.scheduled_date ? 'Unconfirmed' : 'Unscheduled'}; 
        }

    } else { 
        return null;
    }
}

const form = [

    {key: 'inspection_time_blocks',
        type: 'references',
        heading: 'Work Schedule',
        createLabel: 'Add New Work Hours',
        instantCreate: true,
        instantCreateObject: (values) => { return {inspection_id: values.id}},
        primaryCreate: false,
        createUrl: '/inspection_time_blocks',
        dataUrl: '/inspection_time_blocks?inspection_id=:id',
        formFields: [
                { key: 'inspection_id',
                 accessor: 'id',
                 type: 'hidden' },

                {key: 'scheduled_date',  
                    required: true,
                    placeholder: 'Select a Start Date', 
                    type: 'date', 
                    end_date_key: 'scheduled_end_date', 
                    time_key: 'start_time',
                },

                {key: 'start_time',  
                    required: true,
                    type: 'time',
                    date_key: 'scheduled_date',
                    defaultValue: moment(new Date()).hour(8).minutes(0).seconds(0),
                },

                {key: 'scheduled_end_date', 
                    required: true,
                    placeholder: 'Select the End Date', 
                    type: 'date', 
                    start_date_key:'scheduled_date',
                    time_key: 'end_time',
                },

                {key: 'end_time', 
                    required: true,
                    type: 'time',
                    date_key: 'scheduled_end_date',
                    defaultValue: moment(new Date()).hour(17).minutes(0).seconds(0),
                },

                //This can't be available during create. Also make sure no techs can be assigned to a time block with null times.
                {key: 'technicians',
                    type: 'relations',
                    label: 'Technicians',
                    placeholder: 'Add a Technician...',
                    submitLabel: 'Assign',
                    condition: (values) => { return values.scheduled_date !== null && values.scheduled_end_date !== null },
                    relationsUrl: (values) => `/inspection_workers?inspection_time_block_id=${values.id}`,
                    optionsUrl: (values) => `/lists/inspection_workers_available?inspection_time_block_id=${values.id}`,
                    optionTransform: (o) => { 
                        return {value: o.id, label: [o.first_name, o.last_name].join(' ')} 
                    }, 
                    relationTransform: (r) => { 
                        return {value: r.id, label: [r.first_name, r.last_name].join(' ')} 
                    } , 
                },
        
        ],
        tableProps: {
            rowURL:'/inspection_time_blocks/:id',
            columns: [
                {heading: 'Technicians', 
                    type: 'list',
                    itemMapper: (item) => `${item.first_name} ${item.last_name}`,
                    placeholder: "No Technicians Assigned",
                    accessor: 'technicians',
                },
                {heading: 'Scheduled Time', 
                    placeholder: 'Unscheduled',
                    type: 'dateRange', 
                    startDateAccessor: 'scheduled_date', 
                    endDateAccessor: 'scheduled_end_date',
                },
                {heading: 'Work Hours', 
                    placeholder: "N/A",
                    type: 'workDiff', 
                    keys: {
                        startDate: 'scheduled_date', 
                        endDate: 'scheduled_end_date',
                        includeBreaks: 'include_breaks',
                        breakLength: 'break_length'
                    },

                },
            ]
        }
    },

    {key: 'site_name', 
        static: true,
        label: 'Site Name'},

    {type: 'address', 
        key:'address', 
        static: true, 
        maplink: true,
        displayOnly: true},

    {type: 'date', 
        static: true,
        dateFormat: 'MMMM DD, YYYY',
        placeholder: 'None',
        key: 'last_inspection_date', 
        label: 'Last Inspection Date'},
        
    {type: 'date', 
        static: true,
        dateFormat: 'dddd, MMMM DD, YYYY',
        key: 'due_date', 
        label: 'Inspection Due Date'},

    // {type: 'date', 
    //     static: true,
    //     dateFormat: 'dddd, MMMM DD, YYYY',
    //     key: 'next_inspection_due_date', 
    //     label: 'Next Inspection Due'},

    {type: 'radio', 
        required: true, 
        key: 'inspection_type', 
        defaultValue: 'Fire Alarm', 
        label: 'Inspection Type', 
        options: [{label: 'Fire Alarm', value: 'Fire Alarm'}, 
            {label: 'Smoke Test', value:'Smoke Test'}, 
            {label: 'AOR', value: 'AOR'}, 
            {label: 'Elevator', value: 'Elevator'},
        ]},


    {type: 'radio', 
        required: true, 
        key: 'frequency', 
        defaultValue: 'yearly', 
        label: 'Frequency', 
        options: [{label: 'Yearly', value: 'yearly'}, 
                  {label: 'Semi-Annual', value:'semi annual'},
                  {label: 'Quarterly', value:'quarterly'}]},


    {type: 'currency', 
        required: true, 
        key: 'billed_amount'},

    {type: 'options', 
        inputRef: 'billing_customer_id',
        required: true, 
        key: 'billing_customer_id', 
        label: 'Billing Customer', 
        optionsURL: `/lists/customer_names`, 
        optionsMapper: customerFunc},

    {type: 'options',
        //defaultValueKey: 'defaultBillingContactId',
        //showDetails: true,
        //displayKey: 'contact_name',
        key: 'email_contact_id',
        label: 'Inspection Contact',
        optionsURL: (values, formState) => { 
            let billing_customer_id = (formState && formState.billing_customer_id) || (values && values.billing_customer_id) || null;
            let site_id = (formState && formState.site_id) || (values && values.site_id) || null;
            
            if(billing_customer_id && site_id) { 
                return `/lists/inspection_contacts?customer_id=${billing_customer_id}&site_id=${site_id}`;
            } else {
                return '';
            }
        },
        optionsMapper: contactFunc},

    {type: 'array',
        key: 'email_cc',
        label: 'Inspection CC',
        placeholder: 'Enter comma separated email addresses'},

    {type: 'boolean', 
        key: 'cash_on_delivery', 
        label: 'Cash on Delivery'},
        
    {type: 'boolean', 
        key: 'requires_po_number', 
        label: 'Requires PO#'},

    {key: 'po_number', label: 'PO Number'},

    {type: 'boolean', 
        key: 'overtime'},

    {key: 'active_on_weekend', 
        label: 'Work on Weekends', 
        type: 'boolean'},

    {type: 'boolean', 
        key: 'is_ul', 
        label: 'UL Site'},

    {type: 'currency', 
        label: 'UL Billed Amount',
        key: 'ul_billed_amount'},

    {type: 'boolean', 
        key: 'unit_access'},

    {type: 'longtext',
        key: 'notes', 
        placeholder: 'Enter any special instructions a technician should know for inspections...',
        label: 'Inspection Notes'},

    {key: 'work_hours', 
        required: true, 
        type: 'number',
    },

    {key: 'contact_status', required: true, label: 'Appointment Status', type: 'options', options: [
        {value: 'unconfirmed', label: 'Unconfirmed'},
        {value: 'waiting', label : 'Waiting For Reply'},
        {value: 'confirmed', label: 'Confirmed'}]},


    {key: 'contacts',
        type: 'references',
        heading: 'Customer Contacts',
        createLabel: 'Add New Contact',
        primaryCreate: false,
        createUrl: (values) => { return `/customers/${values.billing_customer_id}/contacts` },
        dataUrl: '/customers/:billing_customer_id/contacts',
        formFields: [
                {key: 'name',  
                    required: true,
                },
                {key: 'title',  
                },
                {key: 'phone', label: 'Office'
                },
                {key: 'mobile',  
                },
                {key: 'fax',  
                },
                {key: 'email',  
                },

        ],
        tableProps: {
            rowURL: '/contacts/:id',
            columns: [
                {heading: 'Name', 
                    accessor: 'name',
                },
                {heading: 'Title', 
                    accessor: 'title',
                },
                {heading: 'Office', 
                    accessor: 'phone',
                    valueFunc: ((d,v) => {
                        return formatPhoneNumber(v) || v;
                    })
                },
                {heading: 'Mobile', 
                    accessor: 'mobile',
                    valueFunc: ((d,v) => {
                        return formatPhoneNumber(v) || v;
                    })
                },
                {heading: 'Fax', 
                    accessor: 'fax',
                    valueFunc: ((d,v) => {
                        return formatPhoneNumber(v) || v;
                    })
                },
                {heading: 'Email', 
                    accessor: 'email',
                },
            ]
        }
    },

    {key: 'contacts',
        type: 'references',
        heading: 'Site Contacts',
        createLabel: 'Add New Contact',
        primaryCreate: false,
        createUrl: (values) => { return `/sites/${values.site_id}/contacts` },
        dataUrl: '/sites/:site_id/contacts',
        formFields: [
                {key: 'name',  
                    required: true,
                },
                {key: 'title',  
                },
                {key: 'phone', label: 'Office'
                },
                {key: 'mobile',  
                },
                {key: 'fax',  
                },
                {key: 'email',  
                },
        ],
        tableProps: {
            rowURL: '/contacts/:id',
            columns: [
                {heading: 'Name', 
                    accessor: 'name',
                },
                {heading: 'Title', 
                    accessor: 'title',
                },
                {heading: 'Office', 
                    accessor: 'phone',
                    valueFunc: ((d,v) => {
                        return formatPhoneNumber(v) || v;
                    })
                },
                {heading: 'Mobile', 
                    accessor: 'mobile',
                    valueFunc: ((d,v) => {
                        return formatPhoneNumber(v) || v;
                    })
                },
                {heading: 'Fax', 
                    accessor: 'fax',
                    valueFunc: ((d,v) => {
                        return formatPhoneNumber(v) || v;
                    })
                },
                {heading: 'Email', 
                    accessor: 'email',
                },
            ]
        }
    },

    {key: 'pending_documents', static: true, readOnly: true,  type: 'boolean'},
    {label: 'Documents Emailed', key: 'documents_sent', static: true, readOnly: true,  type: 'boolean'}, 
    {type: 'file',
        disableRender: (values) => {
            if(values.inspection_type === 'Elevator' || values.inspection_type === 'Smoke Test') {
                return true;
            }
        },
        showInCreateMode: false,
       key: 'nfpa_file',
        urlKey: 'nfpa_file_url',
        viewUploadText: 'View NFPA File',
        label: 'NFPA File'},
    {key: 'invoice_number', 
        showInCreateMode: false},
    {type: 'file',
        showInCreateMode: false,
       key: 'invoice_file',
        viewUploadText: 'View Invoice',
        urlKey: 'invoice_url',
        label: 'Invoice File'},
    // {type: 'file',
    //     showInCreateMode: false,
    //     disableRender: (values) => {
    //         if(values.is_ul === false) {
    //             return true;
    //         }

    //         if(values.inspection_type === 'Elevator') {
    //             return true;
    //         }
    //     },
    //     key: 'ul_certificate_file',
    //     viewUploadText: 'View UL Certificate',
    //     urlKey: 'ul_certificate_url',
    //     label: 'UL Certificate File'},
    // {key: 'ul_invoice_number', 
    //     disableRender: (values) => {
    //         if(values.is_ul === false) {
    //             return true;
    //         }

    //         if(values.inspection_type === 'Elevator') {
    //             return true;
    //         }

    //         if(values.billed_amount <= 0 && values.ul_billed_amount <= 0) {
    //             return true;
    //         }
    //     },
    //     showInCreateMode: false},
    // {type: 'file',
    //     disableRender: (values) => {
    //         if(values.is_ul === false) {
    //             return true;
    //         }

    //         if(values.inspection_type === 'Elevator') {
    //             return true;
    //         }

    //         if(values.billed_amount <= 0 && values.ul_billed_amount <= 0) {
    //             return true;
    //         }
    //     },
    //     showInCreateMode: false,
    //    key: 'ul_invoice_file',
    //     viewUploadText: 'View UL Invoice',
    //     urlKey: 'ul_invoice_url',
    //     label: 'UL Invoice File'},
    {type: 'file',
        disableRender: (values) => {
            if(values.billed_amount <= 0 && values.ul_billed_amount <= 0) {
                return true;
            }
        },
        showInCreateMode: false,
        key: 'signed_quote_file',
        urlKey: 'signed_quote_url',
        viewUploadText: 'View Signed Quote',
        label: 'Signed Quote'},
    // {type: 'file',
    //     disableRender: (values) => {
    //         if(values.inspection_type !== 'Elevator' && values.inspection_type !== 'Smoke Test') {
    //             return true;
    //         }
    //     },
    //     showInCreateMode: false,
    //     key: 'workorder_file',
    //     urlKey: 'workorder_file_url',
    //     viewUploadText: 'View Workorder',
    //     label: 'Workorder File'},
    {key: 'completed', static: true, readOnly: true,  type: 'boolean', writePrivileges: [Privileges.WRITE_INSPECTIONS_COMPLETED]},
    {label: 'Pending for Scheduling', key: 'pending', readOnly: true,  type: 'boolean'},
]

export default {
    defaults: {
        name: 'Inspection',
        color: colors.inspection,
        formFields: form,
    },
    
    view: {
        title: (i) => `${i.site_name} Inspection`,
        remarks: {
            title: 'Inspection Card History',
            object_id_key: 'inspection_card_id',
            header: (values) => values.site_name,
        },
        deletePrivileges: [Privileges.DELETE_INSPECTIONS],
        formActions: [
            { label: 'Mark Inspection Completed',
                icon: 'check-square',
          privileges: [Privileges.WRITE_INSPECTIONS_COMPLETED],
                validate: ({values}) => {
                    return values.contact_status === 'confirmed'
                },
                method: async ({values, reload}) => { 
                    try { 
                        await api.update(`/inspections/${values.id}`, {completed: true})
                    } catch (e) {
                        throw new Error(e);
                    }
                    reload();
                }
            },
            { label: 'Mark as Pending',
                icon: 'pause-circle',
                visible: ({values}) => {
                    return values.pending === false; 
                },
                method: async ({values, reload}) => { 
                    try { 
                        await api.update(`/inspections/${values.id}`, {pending: true})
                    } catch (e) {
                        throw new Error(e);
                    }
                    reload();
                }
            },
            { label: 'Mark as Not Pending',
                icon: 'play-circle',
                visible: ({values}) => {
                    return values.pending === true; 
                },
                method: async ({values, reload}) => { 
                    try { 
                        await api.update(`/inspections/${values.id}`, {pending: false})
                    } catch (e) {
                        throw new Error(e);
                    }
                    reload();
                }
            },
            {   label: 'View Billing Customer',
                icon: 'user',
                validate: ({values}) => {
                    return !!values['billing_customer_id'];
                },
                method: async ({values, history}) => { 
                    const billing_customer_id = values['billing_customer_id'];
                    if(billing_customer_id){ 
                        history.push(`/customers/${billing_customer_id}`);
                    }
                }
            },
            {   label: 'View Site',
                icon: 'shop',
                validate: ({values}) => {
                    return !!values['site_id'];
                },
                method: async ({values, history}) => { 
                    const site_id = values['site_id'];
                    if(site_id){
                        history.push(`/sites/${site_id}`);
                    }
                }
            },
            {   label: 'View Inspection Card',
                icon: 'schedule', 
                validate: ({values}) => {
                    return !!values['inspection_card_id'];
                },
                method: async ({values, history}) => { 
                    const card_id = values['inspection_card_id'];
                    if(card_id){
                        history.push(`/inspection_cards/${card_id}`);
                    }
                }
            },
            {
                label: 'Send Notice of Inspection',
                icon: 'mail',
                validate: ({values}) => {
                    return values.contact_email && values.contact_status === 'confirmed'
                },
                confirm: {
                    title: 'Confirm',
                    okText: 'Send',
                    getContent: (values) => { 
                        let cc_message = values.email_cc && values.email_cc.length !== 0 ? 
                            `. Additional recipients will be CCed (${values.email_cc.join(', ')}).` 
                                : '';
                        return `A Notice of Inspection will be sent to ${values.contact_name}${cc_message !== '' ? '' : '.'} (${values.contact_email})${cc_message}`
                    },
                },
                method: async ({values}) => {
                    const inspection_id = values.id;
                    if(inspection_id) {
                        try {
                            await api.rpc('sendNoticeOfInspection', { inspection_id })
                        } catch (e) {
                            throw new Error(e);
                        }
                    }
                }
            },
            {
                label: 'Send Completed Documents',
                icon: 'mail',
                validate: ({values}) => {
                    return values.completed && !values.documents_sent
                },
                confirm: {
                    title: 'Confirm',
                    okText: 'Send',
                    getContent: (values) => { 
                        let cc_message = values.email_cc && values.email_cc.length !== 0 ? 
                            `. Additional recipients will be CCed (${values.email_cc.join(', ')}).` 
                                : '';
                        return `Completed Inspection Documents will be sent to ${values.contact_name}${cc_message !== '' ? '' : '.'} (${values.contact_email})${cc_message}`
                    },
                },
                method: async ({values}) => {
                    const inspection_id = values.id;
                    if(inspection_id) {
                        try {
                            await api.rpc('sendCompletedInspectionDocumentsForInspection', { inspection_id })
                        } catch (e) {
                            throw new Error(e);
                        }
                    }
                }
            },
        ]
    },

    search: {
        allowDateFilter: true,
        fields: ['id', 'scheduled_date', 'due_date', 'site_name','inspection_type', 'billing_customer_name', 'contact_status', 'created_at', 'completed', 'pending'], 
        dateFieldOptions: [{label: 'Due Date', value: 'due_date', description: 'due'},
                           {type: 'date', dateFormat: 'MM DD, YYYY hh:mm A', label: 'Scheduled Date', value: 'scheduled_date', description: 'scheduled'},
                           ],
        filterOptions: [
            {label: 'Active', filters: {is_completed: false}},
            {label: 'Unscheduled', filters: {is_completed: false, is_scheduled: false}},
            {label: 'Pending', filters: {is_completed: false, is_pending: true}},
            {label: 'Unconfirmed', filters: {is_completed: false, is_scheduled: true, status: 'unconfirmed'}},
            {label: 'Waiting For Reply', filters: {is_completed: false, status: 'waiting'}},
            {label: 'Confirmed', filters: {is_completed: false, status: 'confirmed'}},
            {label: 'Completed', filters: {is_completed: true}},
        ],
        links: [
            {url: '/inspection_cards/new',
             title: 'New Inspection Card',
             icon: 'plus',
             requiredPrivileges: [Privileges.CREATE_INSPECTION_CARDS]
            }, 
            {url: '/inspections/upcoming',
             title: 'Upcoming Inspections',
             requiredPrivileges: [Privileges.LIST_UPCOMING_INSPECTIONS]
            }, 
            {url: '/inspections/confirmations',
             title: 'Confirmations',
             requiredPrivileges: [Privileges.LIST_UPCOMING_INSPECTIONS]
            }, 
            {downloadURL: '/files/upcoming_inspections',
                title: 'Download Unscheduled CSV',
                requiredPrivileges: [Privileges.FILE_READ_INSPECTIONS]
            }
        ],
        tableProps: {
            rowURL: '/inspections/:id',
            rowColor: (i) => {
                if(i.completed) {
                    return null;
                } else {
                    
                    if(i.contact_status === 'confirmed') {
                        return EST_PALE_GREEN;
                    } else if (i.contact_status === 'waiting') {
                        return EST_PALE_YELLOW;
                    } else if (i.scheduled_date && i.contact_status === 'unconfirmed') {
                        return EST_LIGHT_RED;
                    } else if (!i.scheduled_date) { 
                        return EST_PALE_RED;
                    } else if (i.is_pending) {
                        return EST_PALE_GRAY_RED;
                    }
                }
            },
            columns: [
                {heading: 'Due Date', cellWidth: '120px', type: 'date', dateFormat: 'MMM DD, YYYY', accessor: 'due_date'},
                {heading: 'Property Name', accessor: 'site_name', style: 'bold'},
                {heading: 'Type', accessor: 'inspection_type'},
                {type: 'date', placeholder: 'Unscheduled', dateFormat: 'MMM D, YYYY hh:mm A', heading: 'Scheduled Date', accessor: 'scheduled_date'},
                {type:'badge', 
                    badgePropsFunc: statusBadgeStyle, 
                    heading: 'Contact Status', 
                    accessor: 'contact_status'},
                {heading: 'Customer', accessor: 'billing_customer_name'},
            ]
        },
    },
    
};

