import React from 'react';
import { Button, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import './style.css';

function BackLink({text = 'Previous', style, history, ...props}) {
        return <Button className='backlink' style={style || {}} onClick={history.goBack}><Icon type='arrow-left' /> {text}</Button>
}

export default withRouter(BackLink);
