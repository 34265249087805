import React from 'react';
import { Menu } from 'antd';
import SubMenuTitle from '../SubMenuTitle';
import MenuLink from '../MenuLink';
import { userHasPrivileges } from '../../../../lib/helpers/renderCondition';

const { SubMenu } = Menu;


const Sub = ({ user, iconType, heading, features, ...other}) => {

    const links = featureNodesForUser(features, user);

    if(!links || links.length === 0) {
        //no links in this sub menu, return nothing.
        return null;
    } else {
        return <SubMenu {...other} key={heading} title={SubMenuTitle({type: iconType, title: heading})}>
            {links}
        </SubMenu>
    }
}

const featureNodesForUser = (features, user) => {


    const nodes = features.map((feature, index)=> {
        if(feature.features) {
            //Sub Sub Menu
            return Sub(Object.assign({}, {user, key: feature.heading + '_' + index}, feature));
        } else if((feature.role && userHasPrivileges([feature.role], user)) || !feature.role){
            return <MenuLink key={`${feature.route}-${feature.title}`} to={feature.route} label={feature.title} />;
        } else {
            return null;
        }

    }).filter(node => node)

    if(nodes.length === 0) {
        return null;
    } else {
        return nodes;
    }

}

export default Sub;

