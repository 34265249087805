import React from 'react';
import { BADGE_RED, BADGE_GREEN, BADGE_GRAY } from 'lib/constants/colors'
import './style.css';

function Label({type, label, style, outline = false}) {

    const hexColor = colorForType(type);
    const textColor = textColorForType(type);

    let fullStyle = Object.assign({}, style);
    if(outline) {
        fullStyle = Object.assign(fullStyle, { 
            backgroundColor: '#FFFFFF99', 
            color: hexColor, 
            padding: '1px 8px', 
            borderRadius: '4px', 
            fontWeight: 800, 
            border: '2px solid ' + hexColor});
    } else {
        fullStyle = Object.assign(fullStyle, { backgroundColor: hexColor, 
            color: textColor, 
        });
    }

    return <div className='label' id={label} style={fullStyle}>
            {/* iconName ? <LabelIcon  className={'fa ' + iconName} /> : null*/ }
            {label}
        </div>
}

function textColorForType(type){
    let color = '#DDDDDD';
    switch(type){
        case 'discontinued':
        case 'red':
        case 'approved':
        case 'green':
        case 'success':
            color = '#FEFEFE'; 
            break;
        case 'gray':
        case 'pending':
            color = '#A0A0B0';
            break;
        default:
            break;
    }

    return color;
}

function colorForType(type){
    let color = '#DDDDDD';
    switch(type){
        case 'discontinued':
        case 'red':
            color = BADGE_RED;
            break;
        case 'approved':
        case 'green':
        case 'success':
            color = BADGE_GREEN;
            break;
        case 'gray':
        case 'pending':
            color = BADGE_GRAY;
            break;
        default:
            break;
    }

    return color;
}

export default Label;

