import colors from 'lib/constants/resourceColors';
import { EST_PALE_GREEN , EST_PALE_RED } from 'lib/constants/colors';
import Privileges from 'lib/constants/privileges';
import { taskFields, taskTableProps } from './tasks';

export default {
    defaults: {
        name: 'Task',
        color: colors.tasks_inspection,
        appUrlBase: '/inspection/tasks',
    }, 
    search: {
        heading: 'Inspection Tasks', 
        cacheKey: 'cache_search_inspection_tasks',
        defaultFilter: (currentUser) => { return {task_type: 'INSPECTION' }},
        allowDateFilter: true,
        fields: taskFields,
        toggleOptions: [
            {key: 'hide_completed', 
                label: 'Hide Completed', 
                param: 'completed', 
                defaultValue: (currentUser) => true, 
                value: (currentUser) => false }
        ],
        searchBy: ['description', 'assigned user', 'creator', 'task parent', 'task type', 'task name or number'], 
        tableProps: taskTableProps, 
    }
}
