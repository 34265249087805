import React , { Component } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend'; 

import BaseScheduler from './components/Scheduler';

class Scheduler extends Component {

    render() {
        return (
                <BaseScheduler {...this.props} />

                
        );
    }
}

Scheduler.propTypes = {
    daysOfTheWeek: PropTypes.array,
    loadEvents: PropTypes.func,
    maxEventSlots: PropTypes.number,
    onEventClick: PropTypes.func,
    onEventMouseOut: PropTypes.func,
    onEventMouseOver: PropTypes.func,
    onDayClick: PropTypes.func,
    wrapTitle: PropTypes.bool,
};

Scheduler.defaultProps = {
    daysOfTheWeek: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ],
    events: [],
    wrapTitle: true,
    maxEventSlots: 50,
};

export default DragDropContext(HTML5Backend)(Scheduler);
