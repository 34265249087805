import React , { Component } from 'react';
import PropTypes from 'prop-types';
import { OrderedMap } from 'immutable';
import { Calendar } from 'calendar-base';
import styled from 'styled-components';
import Title from './ColumnTitle';
import ModalBackground from './ModalBackground';
import moment from 'moment';

import Day from './Day';
import Event from './Event';

const Main = styled.div`
    box-sizing: border-box;
    max-width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-x: visible;
    position: relative;
    width: 100%;
`

const propTypes = {
    loading: PropTypes.bool,
    calendar: PropTypes.object,
    dateAlign: PropTypes.string,
    immutableEvents: PropTypes.object,
    eventLengthDidChange: PropTypes.func,
    eventsPerDayLimit: PropTypes.number,
    locale: PropTypes.string.isRequired,
    month: PropTypes.number.isRequired,
    modalIsOpen: PropTypes.bool,
    modalEvent: PropTypes.object,
    modalDay: PropTypes.object,
    onEventClick: PropTypes.func,
    onEventContextMenu: PropTypes.func,
    onEventMouseOut: PropTypes.func,
    onEventMouseOver: PropTypes.func,
    onDayClick: PropTypes.func,
    today: PropTypes.object.isRequired,
    weekStart: PropTypes.number,
    wrapTitle: PropTypes.bool,
    year: PropTypes.number.isRequired,
    sortEvents: PropTypes.bool,
    eventModal: PropTypes.node,
};

const defaultProps = {
    loading: false,
    calendar: new Calendar({weekStart: 0, siblingMonths: true, }),
    dateAlign: 'left',
    immutableEvents: OrderedMap(),
    hideWeekends: false,
    eventsPerDayLimit: 100,
    locale: 'en',
    modalIsOpen: false,
    modalEvent: null,
    modalDay: null,
    weekStart: 0,
    wrapTitle: true,
    sortEvents: true,
    compressEvents: true,
    eventModal: null,
};


class BaseCalendar extends Component {

    getCalendarDaysForMonth = (month, year, eventsPerDayLimit) => {
        const { calendar } = this.props;

        return calendar.getCalendar(year, month).map((day) => {
            day.eventSlots = new Array(eventsPerDayLimit); 
            for (var i = 0; i < eventsPerDayLimit; i++){
                day.eventSlots[i] = {event: null, slotHeight: 1, needed: false};
            }
            return day;
        });
    }

    getEventMeta = (days, eventStart, eventEnd) => {
        const { calendar } = this.props;
        const eventStartInView = calendar.isDateSelected(eventStart);
        const eventEndInView = calendar.isDateSelected(eventEnd);
        const firstDayOfMonth = days[0];
        const lastDayIndex = days.length - 1;
        const lastDayOfMonth = days[lastDayIndex];

        const eventMeta = {
            // Asserts Event is visible in this month view
            isVisibleInView: false,
            visibleEventLength: days.length,
            // Returns the index (interval from first visible day) of [...days] of event's first "visible" day
            firstVisibleDayIndex: eventStartInView ? Calendar.interval(firstDayOfMonth, eventStart) - 1 : 0,
            lastVisibleDayIndex: eventEndInView ? Calendar.interval(firstDayOfMonth, eventEnd) - 1 : lastDayIndex
        };

        // Asserts Event is visible in this month view
        if (eventStartInView || eventEndInView) {
             // Asserts event's first or last day is visible in this month view
            eventMeta.isVisibleInView = true;
        } else if (eventStart.month < this.props.month && eventEnd.month > this.props.month) {
            // Asserts at least part of month is
            eventMeta.isVisibleInView = true;
        }

        // Determine the visible length of the event during the month
        if (eventStartInView && eventEndInView) {
            eventMeta.visibleEventLength = Calendar.interval(eventStart, eventEnd);
        } else if (!eventStartInView && eventEndInView) {
            eventMeta.visibleEventLength = Calendar.interval(firstDayOfMonth, eventEnd);
        } else if (eventStartInView && !eventEndInView) {
            eventMeta.visibleEventLength = Calendar.interval(eventStart, lastDayOfMonth);
        }

        return eventMeta;
    }

    getDaysWithEvents = () => {
        const { compressEvents, hideWeekends, calendar, modalEvent, immutableEvents, weekStart, month, year, eventsPerDayLimit } = this.props;
        const selectedEventId = modalEvent ? modalEvent.get('id') || null : null;
        const weekEnd = (weekStart + 6) % 7;
        const days = this.getCalendarDaysForMonth(month, year, eventsPerDayLimit);

        calendar.setStartDate(days[0]);
        calendar.setEndDate(days[days.length - 1]);

        immutableEvents.forEach((eventItem) => {
            const startDate = eventItem.get('startDate');
            let endDate = eventItem.get('days') <= 0 ? startDate : eventItem.get('endDate');

            const eventStart = this.getCalendarDayObject(startDate);
            const eventEnd = this.getCalendarDayObject(endDate);
            let eventMeta = this.getEventMeta(days, eventStart, eventEnd);

            if (eventMeta.isVisibleInView) {
                const visibleEventLength = eventMeta.visibleEventLength;
                const firstVisibleDay = days[eventMeta.firstVisibleDayIndex];
                const lastVisibleDay = days[eventMeta.lastVisibleDayIndex];
                let eventSlotIndex = firstVisibleDay.eventSlots.findIndex(e => !e.event);
                let dayIndex = 0;
                let firstVisibleDayFound = false;
                let firstVisibleDayOfWeekFound = false;

                const mutateEvent = (currentDay, currentDayIndex, dayIndex, visibleEventLength) => { 
                    
                    return (immutableEventData) => {

                        //if hiding weekends, we need to change the day that wraps
                        if(currentDay.weekDay === weekEnd && dayIndex < visibleEventLength - 1){
                            immutableEventData.set('wrapsToNextWeek', true);
                        }

                        if(currentDay.weekDay === weekStart && dayIndex !== 0){
                            immutableEventData.set('wrapsFromPreviousWeek', true);
                        }

                        if (selectedEventId && immutableEventData.get('id') === selectedEventId){
                            immutableEventData.set('isSelected', true);
                        }

                        let isSkippedDay = false;
                        console.log("Current day is ", currentDay.weekDay);
                        if (immutableEventData.has('skippedDays') && 
                            immutableEventData.get('skippedDays').includes(currentDay.weekDay)){
                            //This day of the even falls on a skipped day
                            immutableEventData.set('isSkipped', true);
                            isSkippedDay = true;
                        }

                        const weekends = [0,6];
                        if(hideWeekends && weekends.includes(currentDay.weekDay)) {
                            isSkippedDay = true;
                        }

                        if(!firstVisibleDayOfWeekFound && !isSkippedDay){
                            immutableEventData.set('isFirstVisibleDayOfWeek', true);
                            firstVisibleDayOfWeekFound = true;

                            //we need to know how many days the title and content of the first visible day can overflow
                            let visibleDaySpan = 1;
                            let nextDayCalendarIndex = currentDayIndex + 1;
                            let nextDayIndex = dayIndex + 1;
                            let overflowLimitReached = false;
                            let loops = 0;
                            if(visibleEventLength > 1){
                                while(overflowLimitReached === false && loops <= 7){
                                    
                                    if(nextDayIndex === visibleEventLength){
                                        //no more visible days remaining, end of days
                                        overflowLimitReached = true;
                                    } else {
                                        const nextDay = days[nextDayCalendarIndex]; 
                                        if(nextDay.weekDay === weekStart){
                                            //end of the week has been reached
                                            overflowLimitReached = true;
                                        } else if (immutableEventData.has('skippedDays') && 
                                            immutableEventData.get('skippedDays').includes(nextDay.weekDay)){
                                            //the next day is a skipped day
                                            overflowLimitReached = true;
                                        } else {
                                            visibleDaySpan++;
                                        }
                                    }

                                    nextDayCalendarIndex++;
                                    nextDayIndex++;
                                    loops++; 
                                }
                            }

                            immutableEventData.set('visibleDaySpan', visibleDaySpan);
                        }


                        if (!firstVisibleDayFound && !isSkippedDay) {
                            immutableEventData.set('isFirstVisibleDay', true);
                            firstVisibleDayFound = true;
                        }


                        if (dayIndex === 0 && 
                            firstVisibleDay.day === eventStart.day &&
                            firstVisibleDay.month === eventStart.month && 
                            firstVisibleDay.year === eventStart.year) {
                             // Flag first day of event, but only if the first visible day is actually the same as eventStart
                            immutableEventData.set('isFirstDay', true);
                        }
                        
                        if (dayIndex === visibleEventLength - 1 && 
                            lastVisibleDay.day === eventEnd.day && 
                            lastVisibleDay.month === eventEnd.month && 
                            lastVisibleDay.year === eventEnd.year
                           ) {
                            // Flag last day of event, but only if the last visible day is actually the same as eventEnd
                            immutableEventData.set('isLastDay', true);
                        }
                        
                        if (!immutableEventData.get('isFirstDay') || !immutableEventData.get('isLastDay')) {
                            // Flag between day of event
                            immutableEventData.set('isBetweenDay', true);
                        }

                        // Check that the slot for that day is in fact available
                        const existingSlot = days[eventMeta.firstVisibleDayIndex + dayIndex].eventSlots[eventSlotIndex] 
                        if(existingSlot.event !== null){
                            throw new Error("Sort failure: Unexpected collision between two events.");
                        }
                    }
                };

                // For each day in the event
                while (dayIndex < visibleEventLength) {
                    const currentDayIndex = eventMeta.firstVisibleDayIndex + dayIndex;
                    const currentDay = days[currentDayIndex];

                    if(currentDay.weekDay === weekStart){
                        //reset the event slot index at the beginning of each week to close gaps between events
                        eventSlotIndex = currentDay.eventSlots.findIndex(e => !e.event);
                    }

                    const newEvent = eventItem.withMutations(mutateEvent(currentDay, currentDayIndex, dayIndex, visibleEventLength));

                    // Apply Event Data to the correct slot for that day
                    const  eventSlot = currentDay.eventSlots[eventSlotIndex];

                    eventSlot.event = newEvent;
                    eventSlot.needed = true;

                    if(newEvent.has('rowHeight')){
                        eventSlot.slotHeight = newEvent.get('rowHeight');
                    }

                    // Move to next day of event
                    dayIndex++;

                    if(currentDay.weekDay === weekEnd){
                        //reset
                        firstVisibleDayOfWeekFound = false;
                    }
                }
            }

        });

        //adjust event heights
        if(!compressEvents){
            days.forEach((day, dayIndex) => {
                if(!day.eventSlots){
                    return;
                }

                day.eventSlots.forEach((slot, slotIndex) => {
                    let currentHeight = slot.slotHeight;
                    if(!slot.event || currentHeight <= 1){
                        return;
                    }

                    const event = slot.event;
                    if(event.get('isFirstDay')){
                        this.adjustEventSlotHeights(days, dayIndex, slotIndex, currentHeight, false, weekStart);
                    }

                    if(event.get('isLastDay')){
                        this.adjustEventSlotHeights(days, dayIndex, slotIndex, currentHeight, true, weekStart);
                    }
                });
            });
        }


        return days;
    }

    adjustEventSlotHeights(days, startDayIndex, slotIndex, height, forward, weekStart) {
        let i = startDayIndex; 
        let finished = false
        let property = forward ? 'isLastDay' : 'isFirstDay';
        let increment = forward ? 1 : -1;
        let weekBoundaryDay = forward ? (weekStart + 6) % 7 : weekStart; 
        while(!finished && (i >= 0 && i < days.length)){
            let currentDay = days[i];
            let currentSlot = currentDay.eventSlots[slotIndex];

            if(currentDay.weekDay === weekBoundaryDay){
                finished = true;
            } else if(this.allEventsInDayHavePropertyAfterIndex(currentDay, property, slotIndex) ){
                finished = true;
            } else {
                i += increment;
            }

            if(currentSlot){
                currentSlot.needed = true;
                currentSlot.slotHeight = Math.max(currentSlot.slotHeight, height);
            }
        }

    }

    allEventsInDayHavePropertyAfterIndex(day, property, slotIndex) {
        let result = true;
        day.eventSlots.forEach((slot, index) => {
            if(!result || index <= slotIndex){
                return;
            }

            const event = slot.event; 
            if(!event || event.get(property)){
                return;
            } else {
                result = false;
            }
        });

        return result;
    }

    getCalendarDayObject(date) {
        return {
            year: date.getFullYear(),
            // Subtract 1 from month to allow for human declared months
            month: date.getMonth(),
            day: date.getDate(),
        };
    }

    getLastIndexOfEvent(slots) {

        const lastIndexOfEvent = slots.map((slot, index) => {
            return slot.event !== null ? index : false;
        }).filter((element) => {
            return element;
        }).pop();

        //return lastIndexOfEvent;
        return lastIndexOfEvent === undefined ? 0 : lastIndexOfEvent;
        //return lastIndexOfEvent < 3 || lastIndexOfEvent === undefined ? 2 : lastIndexOfEvent;
    }

    getSerializedDay(day) {
        return [day.weekDay, day.day, day.month, day.year].join('');
    }

    renderDaysOfTheWeek() {
        const { hideWeekends, dateAlign, weekStart } = this.props;

        let weekdays = moment.weekdaysShort();
        if(hideWeekends) {
            weekdays = weekdays.splice(1,weekdays.length-2);
        }
        weekdays = weekdays.splice(weekStart).concat(weekdays);
        let numberOfColumns = weekdays.length;

        return weekdays.map((name, index) => {
            return (
                <Title numberOfColumns={numberOfColumns} key={`${name}_${index}`}> 
                    <span style={{width: '100%', textAlign: (dateAlign || 'right')}}>{name}</span>
                </Title>
            )   
        });
    }

    renderEvents(day, weekStart) {
        const { compressEvents, onEventMouseOver, onEventMouseOut, onEventContextMenu, onEventClick, wrapTitle } = this.props;

        // Trim excess slots
        //const eventSlots = day.eventSlots.filter(slot => slot.event);
        let eventSlots = day.eventSlots.slice(0, this.getLastIndexOfEvent(day.eventSlots) + 1)
        //eventSlots = eventSlots.filter(slot => slot.needed);

        return eventSlots.map((eventSlot, index) => {
            const eventData = eventSlot.event;
            const rowHeight = eventData && eventData.has('rowHeight') ? 
                              eventData.get('rowHeight') : 1;
            const slotHeight = eventSlot.slotHeight;

            return (
                <Event 
                    compressed={compressEvents}
                    key={'event_'+index+this.getSerializedDay(day)}
                    day={day}
                    weekStart={weekStart}
                    slotHeight={slotHeight}
                    rowHeight={rowHeight}
                    immutableEvent={eventData}
                    index={index}
                    onClick={onEventClick}
                    onContextMenu={onEventContextMenu}
                    onMouseOut={onEventMouseOut}
                    onMouseOver={onEventMouseOver}
                    wrapTitle={wrapTitle}
                    />
            );
        });
    }

    dayContainsHoliday(day) {

        let eventSlots = day.eventSlots.slice(0, this.getLastIndexOfEvent(day.eventSlots) + 1)
        let isHoliday = false;
        day.eventSlots.forEach(slot => {
            const eventData = slot.event;
            if(isHoliday) {
                return;
            }

            if(eventData && eventData.has('eventStatus') && eventData.get('eventStatus') === 'holiday') {
                isHoliday = true;
            }
        });

        return isHoliday;
    }

    renderCalendarDays() {
        const { hideWeekends, compressEvents, dateAlign, eventDidDrop, weekStart } = this.props;

        let monthNames = moment.monthsShort();
        let dayOfWeekIndex = weekStart - 1;
        return this.getDaysWithEvents().map((day, index) => {
            const { today } = this.props;

            dayOfWeekIndex = (dayOfWeekIndex + 1) % 7;
            const isToday = Calendar.interval(day, {year: today.year(), month: today.month(), day: today.date()}) === 1;
            const isHoliday = this.dayContainsHoliday(day);
            
            return (
                <Day key={'day_'+this.getSerializedDay(day)}
                     hideWeekends={hideWeekends}
                     compressed={compressEvents}
                     monthName={monthNames[day.month]}
                     dateAlign={dateAlign}
                     handleNewEvent={eventDidDrop}
                     index={index}
                     day={day} 
                     weekStart={weekStart}
                     isToday={isToday} 
                     isHoliday={isHoliday}
                     onClick={this.props.onDayClick}>
                    {this.renderEvents(day, weekStart)}
                </Day>
                );
        });
    }

    renderModal() {
        const { eventModal, modalIsOpen, modalEvent, 
                eventLengthDidChange, eventStartTimeDidChange, 
                eventEndTimeDidChange } = this.props;

        const ModalComponent = eventModal;

        if(eventModal && modalIsOpen && modalEvent){
            return <div style={{userSelect: 'none', zIndex: '400', position: 'absolute', left: '50%'}}>
                        <div style={{position: 'relative', left: '-50%'}}>
                        <ModalComponent 
                            onEventLengthChange={eventLengthDidChange}
                            onEventStartTimeChange={eventStartTimeDidChange}
                            onEventEndTimeChange={eventEndTimeDidChange}
                            immutableEvent={modalEvent} />
                            </div>
                    </div>;
        } else {
            return null;
        }
    }

    render() {
        const { loading, innerRef, modalIsOpen, onModalClose } = this.props;

        let style = {display: 'flex'};
        if(loading) {
            style.opacity = '0.25';
        }

        return (
            <div style={style}>
                {modalIsOpen ? <ModalBackground onClick={onModalClose}/> : null}
                {this.renderModal()}
                <Main innerRef={innerRef}>
                    {this.renderDaysOfTheWeek()}
                    {this.renderCalendarDays()}
                </Main>
            </div>
        );
    }

}

BaseCalendar.propTypes = propTypes; 
BaseCalendar.defaultProps = defaultProps; 

export default BaseCalendar;
