
import colors from 'lib/constants/resourceColors';
import Privileges from 'lib/constants/privileges';
import moment from 'moment';
import numeral from 'numeral';

const form = [

    {key: 'paid_at',
   label: 'Paid Date',
    type: 'date',
    defaultToday: true},

    {key: 'notes',
    type: 'longtext',
    showEmpty: false},

    {key: 'batch_total',
    label: 'Total Paid',
     type: 'currency',
    computeValue: (expense_payout) => {
        let sum = 0;
        if(expense_payout) {
            expense_payout.summary.forEach(s => {
                sum += s.total;
            });
        }

        return sum;
    },
   static: true},

    {key:'expenseIds', 
    label: 'Expenses',
    type: 'oneToMany', 
    emptyMessage: 'There are no approved expenses currently waiting for payment.',
    url: '/lists/expenses/unpaid/approved',
    outputArray: 'expenses',
    groupedData: (expense_payout) => {
        if(!expense_payout || !expense_payout.summary) {
            return null;
        }

        return expense_payout.summary.map(p => {
            p.expenses.forEach(o => {
                o.amount = numeral(o.amount).format('$0,0.00');
            });

            return {
                heading: `${p.employee} ($${p.total})`,
                relations: p.expenses
            }
        });
    },
    relations: (expense_payout) => {
        if(!expense_payout || !expense_payout.summary) {
            return null;
        }

        return expense_payout.summary.map(employee_expenses => {
            return employee_expenses.expenses.map(expense => {
                return {
                    expense_number: expense.expense_number,
                    name: employee_expenses.employee,
                    description: expense.description,
                    amount: numeral(expense.amount).format('$0,0.00'),
                }
            });
        }).flat(2);

    },
    heading: 'Approved Expenses',
    groupColumns: [
            {title: 'Expense Number', dataIndex: 'expense_number'},
            {title: 'Description', dataIndex: 'description'},
            {title: 'Amount', type:'currency', dataIndex: 'amount'}
    ],
    columns: [
            {title: 'Expense Number', dataIndex: 'expense_number'},
            {title: 'Employee', dataIndex: 'name'},
            {title: 'Description', dataIndex: 'description'},
            {title: 'Amount', type:'currency', dataIndex: 'amount'}
        ],
    },

]

export default {

    defaults: {
        name: 'Expense Payout',
        color: colors.expensePayout,
        formFields: form,
    },

    view: {
        title: (p) => `Payout ${moment(p.paid_at).format('MM-DD-YYYY')}`,
    },
    
    search: {
        allowDateFilter: true,
        fields: ['id', 'paid_at', 'notes'],
        links: [
            {url: '/expense_payouts/new',
             title: 'Pay Out',
             requiredPrivileges: [Privileges.CREATE_EXPENSE_PAYOUTS]
            },
        ],
        tableProps: {
            rowURL: '/expense_payouts/:id',
            columns: [
                {heading: 'Payout Date', key: 'paid_at', type: 'date', dateFormat: 'dddd, MMMM DD, YYYY'},
                {heading: 'Notes', accessor: 'notes'}
            ]
        }
    },
}
