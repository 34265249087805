import React, { Component } from 'react';
import { css } from 'glamor';
//import * as SignaturePad from "signature_pad";
//import { browserHistory, push } from 'react-router';
//import { Input, BackButton, Button } from '/client/components';
//import { createContainer } from 'meteor/react-meteor-data';
import numeral from 'numeral';
import estImage from 'lib/constants/estLogo';
import moment from 'moment';
//import * as Role from '/lib/constants/permissions';
import { EST_LIGHTGRAY } from 'lib/constants/colors';
//import generatePdf from '/client/lib/quote-pdf';
import api from 'api';
import { quoteSummary } from 'lib/helpers';
import SignaturePad from 'lib/signature_pad';
import { Spin, Button } from 'antd';
import BackLink from 'components/BackLink';
import ErrorMessage from 'components/ErrorMessage';
// eslint-disable-next-line
const debug = require('debug')('app:QuotePreview:');

const tableColors = {
    lightgray : '#F0F0F5',
    headerBackground: '#F0F0F5',
    grayLine : '#D7D7DF',
    cellText : '#555555',
    textColor: '#383838',
    cellHeader: '#494949',
};


const styles = {
    main : css({

    }),

    table: css({
        marginBottom: '0px !important',
        color: tableColors.textColor,
        fontSize: '10px',
        lineHeight: '10px',
        fontWeight: '400',
        width: '100%',
        '@media screen and (min-width: 640px)':{
            fontSize: '14px',
            lineHeight: '22px',
        }
    }),

    miniPadding: css({
        padding: '1px !important',
    }),

    oddRow: css({
        backgroundColor: 'white',
    }),

    evenRow: css({
        backgroundColor: tableColors.lightgray
    }),

    heading: css({
        marginRight: '10px',
        fontWeight: '700',
        display: 'inline-block',
    }),

    termsHeading: css({
        display: 'block',
        verticalAlign: 'top',
        fontWeight: '700',
        marginRight: '10px',
        position: 'relative',
        top: '13px',
        fontSize: '12px',
        '@media screen and (min-width: 640px)':{
            fontSize: '14px',
            top: '22px',
        }
    }),

    signature: css({
        display: 'block',
        border: '1px solid #EEEEEE',
        cursor: 'crosshair',
    }),

    terms: css({
        display: 'inline-block',
        overflow: 'wrap',
        verticalAlign: 'top',
        whiteSpace: 'pre-wrap',
        marginLeft: '45px',
        marginBottom: '48px',
        '@media screen and (min-width: 640px)':{
            marginLeft: '55px',
        }
    }),

    termsSection: css({
        verticalAlign: 'top',
        lineHeight: '14px',
        fontSize: '10px',
        width: '100%',
        '@media screen and (min-width: 640px)':{
            fontSize: '14px',
            lineHeight: '22px',
        }
    }),

    headingCell: css({
        padding: '1px !important',
        textAlign: 'center',
        border: '1px solid ' + tableColors.grayLine,
        backgroundColor: tableColors.lightgray,
    }),

    dataCell: css({
        padding: '1px !important',
        textAlign: 'center',
        color: tableColors.cellText,
        border: '1px solid ' + tableColors.grayLine,
    }),
    
    logo: css({
        display: 'inline',
        width: '62px',
        marginRight: '5px',
        marginBottom: '0px',
    }),

    companyDetails: css({
        fontSize: '10px',
        display: 'inline-block',
        lineHeight: '10px',
        verticalAlign: 'top',
    }),
    
    detailLine: css({
        display: 'block',
    }),

    textField: css({
        fontFamily: 'Raleway',
        width: '100%',
        backgroundColor: '#FBFBFC !important',
    }),

    disclaimer: css({
        lineHeight: '18px',
        paddingLeft: '150px',
        userSelect: 'none',
        pointerEvents: 'none',
        marginBottom: '15px',
        textAlign: 'right', 
        display:'inline-block', 
        //width: '100%',
        //position: 'relative',
        bottom: '14px',
        maxWidth:'430px', 
        fontSize: '14px', 
        color: '#CCCCCC',
    }),
};

class QuotePreview extends Component {
    
    state = {
        quote: {},
        loading: true,
        summary: {},
        generatingPdf: false,
        poNumber: '',
        pdfURL: null,
        signedBy: '', 
        uploadingSignature: false,
        approvalErrors: null,
        errors: null,
    };

    componentWillMount(){
        api.read('/quotes/' + this.props.match.params.quote_number)
        .then(({errors, body}) => {
            if(errors){
                throw errors;
            }
            const quote = body;
            const summary = quoteSummary(quote);
            debug("Made summary", summary);
            this.setState({loading:false, quote, summary, signedBy: quote.contact_name || ''});
        })
        .catch(errors => {
            this.setState({loading: false, errors});
        });
        //this.props.actions.getResource('quote', this.props.quote_number);

    }

    componentDidMount(){
        
        if(this.refs.signatureCanvas){
            this.signaturePad = new SignaturePad(this.refs.signatureCanvas);
            debug("Signature pad is ", this.signaturePad);
        }

    }

    render(){
        // const { quote_id } = this.props.params; 

        // if(!currentUser || quote.ownerId !== currentUser._id || !Roles.userIsInRole(currentUser._id, Role.DIGITAL_SIGNATURE)){
        //     return <span>You do not have authorization for collecting digitial signatures.</span> 
        // };
        //
        //
        
        return <div {...styles.main}>
                {this.previewDoc()}
                {this.signatureArea()}
            </div>
    }

    previewDoc = () => {
        const { quote, loading, error } = this.state;
        const { show_unit_prices } = quote;
        const imageUrl = 'data:image/jpeg;base64,' + estImage;

        if(loading){
            return <Spin tip='Loading' />;
        }

        if(error){
            return <span>{error}</span>;
        }

        return <div> 
            <BackLink text='Exit Preview' style={{marginBottom: '24px'}} />
                <div {...css({marginBottom: '10px', overflow:'hidden', borderBottom: '1px solid ' + EST_LIGHTGRAY})}>
                    <img {...styles.logo} src={imageUrl} />
                    <div {...styles.companyDetails}>
                        <span {...css({fontWeight: '700', fontSize: '14px', marginRight:'10px', lineHeight: '18px'})} 
                              {...styles.detailLine}>
                            Engineering Systems Technology, Inc.
                        </span>
                        <span {...styles.detailLine}>2400 W 84th Street, Unit 9</span>
                        <span {...styles.detailLine}>Hialeah, FL 33016</span>
                        <span {...styles.detailLine}>(305) 823-7444</span>
                        <span {...styles.detailLine}>(305) 823-0553</span>
                    </div>
                    <div {...css({float: 'right', clear:'both', marginBottom: '10px'})} {...styles.companyDetails}>
                        <span {...css({textAlign: 'right', fontSize: '14px', fontWeight: '500', lineHeight: '18px'})} 
                              {...styles.detailLine}>
                            Quotation
                        </span>
                        <span {...css({textAlign: 'right'})} {...styles.detailLine}>Quote #: {quote.quote_number}</span>
                        <span {...css({textAlign: 'right'})} {...styles.detailLine}>{moment(quote.quoted_at).format('MMMM D, YYYY')}</span>
                        <span {...css({marginTop: '1ch', textAlign: 'right'})} {...styles.detailLine}>{quote.description}</span>
                    </div>
                </div>
                <table {...styles.table}>
                    <thead>
                        <tr {...css({display:'flex'})}>
                            <th {...styles.headingCell} {...css({textAlign: 'left', flex:'1.65'})}>Part Number</th>
                            <th {...styles.headingCell} {...css({textAlign: 'left', flex:'3.5'})}>Description</th>
                            <th {...styles.headingCell} {...css({textAlign: 'center !important', flex:'0.65'})}>{show_unit_prices ? 'Qty' : 'Quantity'}</th>
                            {show_unit_prices ?  [
                            <th key="upHeader" {...styles.headingCell} {...css({flex:'1'})}>Unit Price</th>,
                            <th key="ttlHeader" {...styles.headingCell} {...css({flex:'1.5'})}>Total</th>
                            ] : 
                            null }
                        </tr>
                    </thead>
                    <tbody>

                        {quote && quote.quote_items ? quote.quote_items.map((part, index) => {
                            return <ItemRow key={part.id + '_' +  index} part={part} index={index} showUnitPrice={quote.show_unit_prices} />

                        }) : null}

                    </tbody>
                </table>
                {this.quoteTotal()}
                <div {...styles.termsSection}>
                    <div {...styles.termsHeading}>Terms:</div>
                    <div {...styles.terms}>{quote.terms}
                    </div>
                </div>
            </div>
    }

    quoteTotal(){
        const { summary, quote } = this.state;

        const trueTotal = quote.include_tax ? summary.grandTotal : summary.subtotal;

        const displayedTotal = quote.final_quoted_price; //(quoted_total !== null && (quoted_total !== 0 && !quote.show_unit_prices)) ? quoted_total : trueTotal;

        debug("FP ", quote.final_quoted_price);
        debug("TT ", trueTotal);
        debug("DT ", displayedTotal);

        const plusTaxAppend = quote.include_tax ? '' : ' + TAX';

        const m = '$0,0.00';
        const subtotalNode = <tr {...css({display: 'flex'})}>
        <td {...styles.miniPadding} {...css({flex: '6.8', textAlign: 'right'})} key="ttss">Subtotal:</td>
        <td {...styles.miniPadding} {...css({backgroundColor: tableColors.lightgray, flex:'1.5', textAlign:'right'})}>{numeral(summary.subtotal).format(m)}</td>
        </tr>

        let tableNode = null;

        if(quote.show_unit_prices) {
            const taxNode = quote.include_tax ? <tr {...css({display:'flex'})}>
                        <td {...styles.miniPadding} {...css({flex: '6.8', textAlign: 'right'})} key="ttt">Tax:</td>
                        <td {...styles.miniPadding} {...css({backgroundColor: tableColors.lightgray, flex:'1.5', textAlign:'right'})}>
                            <span {...styles.miniPadding}>{numeral(summary.tax).format(m)}</span>
                        </td>
                        </tr> : null;

            const freightNode = quote.include_freight ? <tr {...css({display:'flex'})}>
                        <td {...styles.miniPadding} {...css({flex: '6.8', textAlign: 'right'})} key="ttf">Freight:</td>
                        <td {...styles.miniPadding} {...css({flex: '1.5', textAlign:'right'})}>{numeral(summary.freightCost).format(m)}</td></tr> : null;
            
            const discountNode = quote.discount > 0 ? <tr {...css({display:'flex'})}>
                        <td {...styles.miniPadding} {...css({flex: '6.8', textAlign: 'right'})} key="ttf">Discount:</td>
                        <td {...styles.miniPadding}  {...css({flex: '1.5', textAlign:'right'})}>{'(' + numeral(summary.discountAmount).format(m) + ')'}</td></tr> : null;

            tableNode = <table key="tblen" {...styles.table}><tbody>
                                {freightNode}
                                {discountNode}
                                {subtotalNode}
                                {taxNode}
                              </tbody></table>
        }

        return [
                tableNode,
                <div {...css({fontWeight: '700', padding: '24px 0'})} key="tto">Total: 
                    <span>{numeral(displayedTotal).format(m) + plusTaxAppend}</span>
                </div>
                ];
    }

    signatureArea = () => {
        const { quote, uploadingSignature, pdfURL } = this.state;
    
        const visibility = uploadingSignature ? 'hidden' : 'initial';
        const downloadLink = {fontWeight: '800', marginBottom: '12px', display: 'block'};

        if(uploadingSignature){
            return <span>Uploading...</span>
        } else if(quote.approved_at){ 
            return <div {...css({float: 'right'})}>
                {quote.po_number ? <span {...css({display: 'block', fontWeight: '700'})}>PO Number: {quote.po_number}</span> : null } 
                {quote.approved_by ? <span {...css({display: 'block', fontWeight: '700'})}>Approved by {quote.approved_by}</span> : null }
                <img src={quote.signature_url} />
                <div {...css({display : 'block'})}>
                    {this.state.generatingPdf ? 
                        <span><i className="fa fa-spin fa-cog" /> Generating...</span> 
                    : 
                    <div>{pdfURL ? <span style={downloadLink}><a target='_blank' href={pdfURL}>Click Here</a> to open PDF.</span> : null}
                        <Button onClick={this.handleDownload} faIcon="fa-download" >Download</Button>
                    </div>
                    }
                </div>
                </div> 
        } else {
            return  <div {...css({float: 'right', paddingRight:'30px', })}> 
                        <div {...css({width:'350px', visibility})}>
                            <ErrorMessage errors={this.state.approvalErrors} />
                            <span {...styles.heading}>PO Number</span>
                            <input {...styles.textField} 
                                    type="text" 
                                   value={this.state.poNumber} 
                                onChange={(e) => {this.setState({poNumber: e.target.value})}}/>
                            {/*
                            <div>
                            <input type="Checkbox" style={{marginRight:'6px'}} />
                            <span {...css({display:'inline', maxWidth:'370px', fontSize: '14px', color: '#444444'})}>{"Click here if no PO Number is required."}</span>
                            </div>
                           */}
                            <span {...styles.heading}>Signed By</span>
                            <input {...styles.textField} 
                                    type="text" 
                                   value={this.state.signedBy} 
                                onChange={(e) => {this.setState({signedBy: e.target.value})}}/>
                            <div>
                                <i {...css({position: 'relative', top: '1px', marginRight:'3px'})} className='fa fa-pencil-square-o' />
                                <span {...styles.heading}>Signature</span>
                                <span {...css({display:'inline', maxWidth:'370px', fontSize: '14px', color: '#CCCCCC'})}>(Draw Signature Below)</span>
                            </div>
                            <div {...css({display: 'inline-block'})}>
                                <canvas {...styles.signature} ref="signatureCanvas" id="sketchpad" width="370" height="200"></canvas>
                                <Button onClick={this.handleClear} faIcon="fa-eraser">Clear</Button>
                            <Button type='primary' style={{float: 'right'}} faIcon="fa-thumbs-up" onClick={this.handleApprove}>Accept</Button>
                                <span {...styles.disclaimer}>{'By clicking "Accept" above, you agree to the terms and total listed in the quote, and consent to using electronic signatures.'}</span>
                            </div>
                        </div>
                    </div>
        }

    }

    handleDownload = () => {
        const { quote_number } = this.state.quote;

        this.setState({generatingPdf: true});

        api.create('/pdfs/quotes', { quote_number, filename: `Quote_${quote_number}` })
        .then(({errors, body, headers}) => {
            this.setState({pdfURL: body.url, generatingPdf: false});
            if(errors){
                throw errors;
            } else {
                window.open(body.url, '_blank');
            }
        })
        .catch(errors => {
            debug(`Error opening quote pdf: ${JSON.stringify(errors)}`);
            this.setState({generatingPdf: false});
        })
    }

    handleApprove = () => {
        const { quote } = this.state;
        //const { quote_id } = this.props.params;
    
        const dataURL = this.signaturePad.toDataURL();

        //assign imageURL to Quote
        this.setState({uploadingSignature: true});
        let data = {
            approved_by: this.state.signedBy,
            po_number: this.state.poNumber,
            quote_id: quote.id,
            quote_number: quote.quote_number,
            imageData: dataURL,
            method: 'PDF Preview'
        };

        api.create('/quote_approvals', data)
        .then(response => {
            if(response.errors){
                throw response.errors;
            }

            debug("Created approval ", response.body);
            this.setState(previousState => {
                
                let newQuote = JSON.parse(JSON.stringify(previousState.quote));
                newQuote.approved_at = response.body.approved_at;
                newQuote.signature_url = response.body.signature_url;
                newQuote.approved_by = response.body.approved_by;
                newQuote.po_number = response.body.po_number;
                let newState = { quote: newQuote, uploadingSignature: false };
                return newState; 
            });
        })
        .catch(errors => {
            this.setState({uploadingSignature: false, approvalErrors: errors});
        });

        // uploadDataURL(dataURL, 'image/png', quote_id + '-signature.png', 'signatureUpload', (error, downloadURL) => {
        //     if(error){
        //         console.log(error.reason);
        //         return;
        //     }

        //     console.log("File is at ", downloadURL);
        //     Meteor.call('applySignatureForQuote', 
        //                 quote_id, 
        //                 downloadURL, 
        //                 this.state.signedBy, 
        //                 this.state.poNumber, 
        //                 (error, result) => {
                           
        //         this.setState({uploadingSignature: false});

        //         if(error) {
        //             console.log(error);
        //         }
                
        //     });
        // });
    }

    handleClear = () => {

        this.signaturePad.clear();
    }

}

let ItemRow = ({part, index, showUnitPrice}) => {

    const backgroundColor = (index % 2 ? styles.evenRow : styles.oddRow );

    const dollar = '$0,0.00';

    const total = part.unit_price * part.quantity;

    return <tr {...css({display: 'flex' })} {...backgroundColor}>
        
        <td {...styles.dataCell} {...css({textAlign:'left', flex:'1.65'})}>{part.product_number}</td>
        <td {...styles.dataCell} {...css({textAlign: 'left', flex:'3.5'})}>{part.description}</td>
        <td {...styles.dataCell} {...css({flex:'0.65'})}>{part.hide_quantity ? '1' : part.quantity}</td>
        {showUnitPrice ? <td {...styles.dataCell} {...css({flex:'1'})}>{numeral(part.hide_quantity ? total : part.unit_price).format(dollar)}</td> : null} 
        {showUnitPrice ? <td {...styles.dataCell} {...css({textAlign:'right', flex:'1.5'})}>{numeral(total).format(dollar)}</td> : null}
    </tr>
}

export default QuotePreview;
