import React, { Component } from 'react';
import BackLink from 'components/BackLink';
import Form from './Form';
import './style.css';
// eslint-disable-next-line
const debug = require('debug')('app:Quote:');

const defaultProps = {};

// const style = {
//     button: {marginRight: '12px'},
//     actionButtons: css({ 
//      minHeight: '12px', 
//      maxHeight: '24px', 
//          float: 'right',
//      marginBottom: '24px',
//     }),
//     heading: css({
//          clear:'both', 
//        display: 'block', 
//          width: '100%',
//         '@media(min-width: 680px)': {
//             clear: 'none'
//         },
//     }),
//     copyInput: css({display: 'block', marginRight: '10px'}),
//     label: css({
//         fontSize: '12px',
//         color: '#A9A9A9',
//         fontWeight: '400 !important',
//     }),
//     deleteMessage: css({
//         maxWidth: '250px',
//         '@media(min-width: 680px)': {
//             maxWidth: 'none',
//         },
//     }),
//     deleteText: css({
//         marginBottom: '24px',
//     }),
//     deleteOptions: css({
//         display: 'block',
//     }),
// };

class Quote extends Component {

    state = {
        quote: {},
    }

    render(){
        const { quote_number } = this.props.match.params;

        return <div className='quote_main'>
            <BackLink />
        {/*<div {...style.actionButtons}>
                <Button label="Copy" onClick={e => this.setState({copying: true})} style={style.button} />
                {renderCondition([Privileges.DELETE_QUOTE], <Button label="Delete" onClick={e => this.setState({deleting: true})} />)}
            </div>
           */}
           <Form currentUser={this.props.currentUser} 
                     quote_number={quote_number} 
                         onSubmit={this.handleSave} /> 
        </div>
    }

    handleSave = (e) => {

        debug("Quote save");

    }

}


Quote.defaultProps = defaultProps;

export default Quote;
