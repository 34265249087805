
export const EST_RED = '#E46657';
export const EST_YELLOW = '#FFFFAD';
export const EST_LIGHTGRAY = '#D7D7DF';
export const OFFSET_GRAY = '#FBFBFF';
export const THIN_GRAY = '#F0F0FC';
export const EST_PALE_YELLOW = '#fffbd6';
export const EST_PALE_GREEN = '#e0f6e8';
export const EST_LIGHT_GREEN = '#c6ebd3';
export const EST_PALE_GRAY_BLUE = '#f0f6f8';
export const EST_PALE_GRAY_RED = '#f8eaea';
export const EST_PALE_RED = '#fff0f0';
export const EST_LIGHT_RED= '#ffd9d9';
export const EST_GREEN = '#55c37a';


export const INPUT_YELLOW = '#FAFFBD';
export const FOCUS_BLUE = '#33C3F0';
export const LIGHT_FOCUS_BLUE = '#9EDCF0';

export const FOCUS_BLUE_2 = '#BAE3FF';
export const FOCUS_BLUE_3 = '#99D6FF';

export const BADGE_RED = '#ff2d4b';
export const BADGE_GREEN = '#55c37a';
export const BADGE_GRAY = '#f0f0f5';
