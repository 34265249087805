import React, { Component } from 'react';
import { Spin, Row } from 'antd';
import './style.css';


class AccountAction extends Component {

    render() {
        const { message } = this.props;
        return <Row style={{height: '100vh'}} type="flex" justify="center" align="middle" >
            <div className='action'> 
                <span className='actionText'>{message}</span><Spin style={{float: 'right'}} />
            </div>
        </Row>
    }
}

export default AccountAction;
