import React from 'react';
import { Menu, Icon } from 'antd';
import { withRouter } from 'react-router-dom';

const LinkFunc = (to, history) => {
    return ({item, key, keyPath}) => {
        history.push(to);
    };
}

export default withRouter((props) => {
        const { location, iconType, match, history, label, to, ...other } = props;
        const Item = Menu.Item;

        return <Item key={'link_'+to} {...other} onClick={LinkFunc(to, history)}>
            {iconType ? <Icon type={iconType} /> : null} <span>{label}</span>
        </Item>
});

