
import colors from 'lib/constants/resourceColors';
import Privileges from '../lib/constants/privileges';
import api from 'api';
import { Modal } from 'antd';

const nameMapping = (task_type) => {
    return {label: task_type.name, value: task_type.id}
}

export function paymentRequestBadgeStyle(value, data) {
    const { status, voided } = data;

    debugger;
    if (voided || status === 'voided') {
        return {status: 'error', text: 'Void'};
    } else if(status === 'open'){
        return {status: 'processing', text: 'Open'}; 
    } else if (status === 'closed'){
        return {status:'success', text:'Closed'}; 
    } else if (status === 'partial') {
        return {status: 'processing', text: 'Partial'}; 
    } else if (status === 'paid'){
        return {status:'success', text:'Paid'}; 
    } else if (status === 'overpaid') {
        return {status: 'error', text: 'Overpaid'}; 
    } else {
        return null;
    }
}

export function statusBadgeStyle(value, data){
    const { status } = data;

    debugger;
    if(status === 'closed') {
        return {status: 'default', text: 'Closed'};

    } else if (status === 'open'){
        return {status: 'success', text: 'Open'}; 

    } else if (status === 'void') {
        return {status: 'error', text: 'Void'}; 
    } else { 
        return null;
    }
}

export default {
    defaults: {
        name: 'Project',
        color: colors.projects,
        formFields: [
            {key: 'status',
                //showInCreateMode: false,
                type: 'options',
                defaultValue: 'open',
                options: [
                    {value: 'open', label: 'Open'},
                    {value: 'closed', label: 'Closed'},
                    {value: 'void', label: 'Void'},
                ],
            },
            {static: true, key: 'project_number'},
            {key: 'description'},
            {key: 'site_id', 
             displayKey: ['site_name', 'site_address', 'site_address2'],
                label: 'Site',
                type: 'autocomplete', 
                suggestFormat: (c) => { 
                    let text = `${c.name}`;
                    let subtexts = [];
                    if(c.address) {
                        subtexts.push(c.address);
                    } else {
                        subtexts.push('(No Address)');
                    }

                    if(c.parent_site_name) {
                        subtexts.push(c.parent_site_name);
                    }
                    return {value: c.id, text, subtexts}; 
                },
                link: { url: '/sites/:site_id', privileges: Privileges.READ_SITES },
                suggestUrl: '/sites', 
                suggestFields: ['id', 'name', 'address', 'parent_site_name']},
            {key: 'salesperson_id', 
             displayKey: 'salesperson',
                label: 'Salesperson',
                type: 'autocomplete', 
                link: { url: '/users/:salesperson_id', privileges: Privileges.READ_USERS },
                suggestFormat: (c) => { return {value: c.id, text: `${c.first_name} ${c.last_name}`} },
                suggestUrl: '/users?employed=true', 
                suggestFields: ['id', 'first_name', 'last_name']},
            {key: 'manager_id', 
             displayKey: 'manager',
                label: 'Project Manager',
                type: 'autocomplete', 
                link: { url: '/users/:manager_id', privileges: Privileges.READ_USERS },
                suggestFormat: (c) => { return {value: c.id, text: `${c.first_name} ${c.last_name}`} },
                suggestUrl: '/users?employed=true', 
                suggestFields: ['id', 'first_name', 'last_name']},
            {key: 'commission', type: 'percentage', 
                readPrivileges:[Privileges.READ_PROJECTS_COMMISSION], 
                writePrivileges: [Privileges.WRITE_PROJECTS_COMMISSION]},
            {key: 'customer_id', 
             displayKey: ['customer_name', 'customer_address', 'customer_address2'],
                label: 'Customer',
                type: 'autocomplete', 
                link: { url: '/customers/:customer_id', privileges: Privileges.READ_CUSTOMERS },
                suggestFormat: (c) => {
                    let text = `${c.name} (${c.address || 'No Address'})`;
                    return {value: c.id, text}
                },
                suggestUrl: '/customers',
                suggestFields: ['id', 'name', 'address']},
            {key: 'customer_project_number'},
            {key: 'contract_number'},
            {key: 'retainer', type: 'percentage',
                readPrivileges:[Privileges.READ_PROJECTS_RETAINER],
                writePrivileges: [Privileges.WRITE_PROJECTS_RETAINER]},
            {key: 'total_project_base_cost', type: 'currency', static: true, label: 'Material Base Cost',
                readPrivileges:[Privileges.READ_PROJECTS_TOTAL_PROJECT_BASE_COST]},
            {key: 'total_equipment_released_base_cost', type: 'currency', static: true, label: 'Material Released Base Cost',
                readPrivileges:[Privileges.READ_PROJECTS_TOTAL_EQUIPMENT_RELEASED_BASE_COST]},
            {key: 'total_earned_retainer', type: 'currency', static: true,
                readPrivileges:[Privileges.READ_PROJECTS_TOTAL_EARNED_RETAINER]},
            {key: 'original_contract_sum', type: 'currency',
                readPrivileges:[Privileges.READ_PROJECTS_ORIGINAL_CONTRACT_SUM],
                writePrivileges:[Privileges.WRITE_PROJECTS_ORIGINAL_CONTRACT_SUM]},
            {key: 'contract_sum_to_date', type: 'currency', static: true,
                readPrivileges:[Privileges.READ_PROJECTS_CONTRACT_SUM_TO_DATE]},
            {label: 'Total Payment Requested', key: 'total_payment_requested', type: 'currency', static: true,
                readPrivileges:[Privileges.READ_PROJECTS_OUTSTANDING_PAYMENT_REQUESTS]},
            {label: 'Total Invoiced', key: 'total_invoiced', type: 'currency', static: true,
                readPrivileges:[Privileges.READ_PROJECTS_OUTSTANDING_PAYMENT_REQUESTS]},
            {label: 'Outstanding PR Balance', key: 'outstanding_payment_request_balance', type: 'currency', static: true,
                readPrivileges:[Privileges.READ_PROJECTS_OUTSTANDING_PAYMENT_REQUESTS]},
            {key: 'balance_to_finish', type: 'currency', static: true,
                readPrivileges:[Privileges.READ_PROJECTS_BALANCE_TO_FINISH]},
            {key: 'attachments',
                type:'files',
            },
             {key: 'customer_purchase_orders',
                 type:'references',
                 heading: 'Customer Purchase Orders',
                 createLabel: 'Add New PO',
                 createPrivileges: [Privileges.CREATE_CUSTOMER_PURCHASE_ORDERS],
                 readPrivileges: [Privileges.READ_CUSTOMER_PURCHASE_ORDERS],
                 initialLoadFromValues: true,
                 dataUrl: '/projects/:id?fields=customer_purchase_orders',
                 createUrl: (values) => { return `/customer_purchase_orders` },
                 dataTransform: 'customer_purchase_orders',
                 injectedValues: (values) => { return { project_id: values.id, customer_id: values.customer_id }},
                 formFields: [
                     {key: 'po_number'},
                     {key: 'description'},
                     {key: 'amount', type: 'currency'},
                     {key: 'issued_date', type: 'date'},
                     {key: 'poFile', type: 'file', label: 'PO File', urlKey: 'file_url'},
                 ],
                 tableProps: { 
                     rowURL: '/customer_purchase_orders/:id',
                     columns: ['po_number','description','change_order_number',{type: 'currency', accessor: 'amount'}, {type: 'file', key: 'file_url', label: 'View PO File'}] 
                 }
             },
             {key: 'change_orders',
                 type: 'references',
                 heading: 'Change Orders',
                 createLabel: 'Add New Change Order',
                 createPrivileges: [Privileges.CREATE_CHANGE_ORDERS],
                 readPrivileges: [Privileges.READ_CHANGE_ORDERS],
                 createUrl: (values) => { return `/change_orders` },
                 dataTransform: 'change_orders',
                 initialLoadFromValues: true,
                 dataUrl: '/projects/:id?fields=change_orders',
                 injectedValues: (values) => { return {project_id: values.id}},
                 formFields: [
                     {key: 'change_order_number' },
                     {key: 'description' },
                     {key: 'amount', type: 'currency'},
                 ],
                 tableProps: { 
                     rowURL: '/change_orders/:id',
                     columns: [{type: 'date', key:'issued_date'}, 'change_order_number', 'description', {type: 'currency', accessor: 'amount'}, {type: 'file', key: 'file_url', label: 'View Change Order'}] 
                 }
             },
             {key: 'materials',
                 type: 'references',
                 heading: 'Materials',
                 createLabel: 'Add Materials',
                 createPrivileges: [Privileges.CREATE_PROJECT_PRODUCTS],
                 readPrivileges: [Privileges.READ_PROJECT_PRODUCTS],
                 deletePrivileges: [Privileges.DELETE_PROJECT_PRODUCTS],
                 initialLoadFromValues: true,
                 dataUrl: '/projects/:id?fields=materials',
                 createUrl: (values) => { return `/projects/${values.id}/materials` },
                 deleteUrl: (values) => { return `/projects/${values.id}/materials/:id` },
                 dataTransform: 'materials',
                 formFields: [
                     {key: 'product_id', 
                      displayKey: 'product_number',
                         label: 'Part Number',
                         type: 'autocomplete', 
                         suggestFormat: (c) => { return {value: c.id, text: c.product_number} },
                         suggestUrl: '/parts?exclude_discontinued=true',
                         suggestFields: ['id', 'product_number']},
                     {key: 'quantity' },
                 ],
                 tableProps: { columns: ['product_number', 'quantity', 'source_id', 'description'] }
             },
             {key: 'services',
                 type: 'references',
                 heading: 'Services',
                 createLabel: 'Add Services',
                 createPrivileges: [Privileges.CREATE_PROJECT_PRODUCTS],
                 readPrivileges: [Privileges.READ_PROJECT_PRODUCTS],
                 deletePrivileges: [Privileges.DELETE_PROJECT_PRODUCTS],
                 initialLoadFromValues: true,
                 dataUrl: '/projects/:id?fields=services',
                 createUrl: (values) => { return `/projects/${values.id}/services` },
                 deleteUrl: (values) => { return `/projects/${values.id}/services/:id` },
                 dataTransform: 'services',
                 formFields: [
                     {key: 'product_id', 
                      displayKey: 'product_number',
                         label: 'Service',
                         type: 'autocomplete', 
                         suggestFormat: (c) => { return {value: c.id, text: c.product_number} },
                         suggestUrl: '/services', 
                         suggestFields: ['id', 'product_number']},
                     {key: 'quantity', label: 'Hours'},
                 ],
                 tableProps: { columns: ['product_number', 'quantity', 'source_id', 'description'] },
             },
             {key: 'equipment_releases',
                 type: 'references',
                 heading: 'Equipment Releases',
                 createLabel: 'Add New Equipment Release',
                 createPrivileges: [Privileges.CREATE_EQUIPMENT_RELEASES],
                 readPrivileges: [Privileges.READ_EQUIPMENT_RELEASES],
                 initialLoadFromValues: true,
                 dataUrl: '/projects/:id?fields=equipment_releases',
                 createUrl: (values) => { return `/equipment_releases` },
                 dataTransform: 'equipment_releases',
                 injectedValues: (values) => { return {project_id: values.id}},
                 formFields: [
                     {key: 'release_number' },
                     {key: 'description' },

                 ],
                 tableProps: { 
                     rowURL:'/equipment_releases/:id',
                     columns: ['release_number', {type: 'date', key:'created_at'}, 'description'] 
                 }
             },
             {key: 'company_purchase_orders',
                 type:'references',
                 heading: 'Purchase Orders',
                 createLabel: 'Add New PO',
                 createPrivileges: [Privileges.CREATE_PURCHASE_ORDERS],
                 readPrivileges: [Privileges.READ_PURCHASE_ORDERS],
                 initialLoadFromValues: true,
                 dataUrl: '/projects/:id?fields=company_purchase_orders',
                 createUrl: (values) => { return `/company_purchase_orders` },
                 dataTransform: 'company_purchase_orders',
                 injectedValues: (values) => { return {project_id: values.id, customer_id: values.customer_id}},
                 formFields: [
                     {key: 'vendor_id', 
                      displayKey: 'vendor_name',
                         label: 'Vendor',
                         type: 'autocomplete', 
                         suggestFormat: (c) => { return {value: c.id, text: c.name} },
                         suggestUrl: '/vendors', 
                         suggestFields: ['id', 'name']},
                     {key: 'description' },
                     {key: 'amount', type: 'currency'},
                     {key: 'file_url', type: 'file'},
                 ],
                 tableProps: {
                     rowURL: '/company_purchase_orders/:id',
                     columns: [{accessor: 'release_number', cellWidth: '30px'}, 
                         'po_number', {type: 'date', key:'created_at'}, 'vendor', {type: 'currency', accessor:'amount'}, 'status']
                 }
             },
             {key: 'payment_requests',
                 type:'references',
                 heading: 'Payment Requests',
                 createLabel: 'Add New Blank Payment Request',
                 createPrivileges: [Privileges.CREATE_PAYMENT_REQUESTS],
                 readPrivileges: [Privileges.READ_PAYMENT_REQUESTS],
                 initialLoadFromValues: true,
                 dataUrl: '/projects/:id?fields=payment_requests',
                 createUrl: (values) => { return `/payment_requests` },
                 dataTransform: 'payment_requests',
                 instantCreate: true,
                 injectedValues: (values) => { return {project_id: values.id}},
                 tableProps: {
                     rowURL: '/payment_requests/:id',
                     columns: [{type: 'date', key:'date', cellWidth: '150px'}, 'ref_number', 'request_number', 
                         'description', 
                 {type: 'currency', accessor:'grand_total'},
                 {type: 'currency', accessor:'open_balance'},
                 {heading: 'Status', type: 'badge', accessor: 'status', badgePropsFunc: paymentRequestBadgeStyle},
 ]
                 }
             },
            {key: 'invoices',
                type:'references',
                heading: 'Invoices',
                readPrivileges: [Privileges.READ_INVOICES],
                initialLoadFromValues: true,
                dataUrl: '/projects/:id?fields=invoices',
                dataTransform: 'invoices',
                tableProps: {
                    rowURL: '/invoices/:id',
                    columns: [{type: 'date', accessor:'date', cellWidth: '150px'}, 'invoice_number',
                        'payment_request_number', {type: 'currency', accessor:'grand_total'}, {type: 'currency', accessor: 'open_balance'},
                {type:'badge',
                    badgePropsFunc: paymentRequestBadgeStyle,
                    heading: 'Status',
                    accessor: 'status'},
]
                }
            },
            {key: 'packing_slips',
                type:'references',
                heading: 'Packing Slips',
                createLabel: 'Add New Packing Slip',
                createPrivileges: [Privileges.CREATE_PROJECT_PACKING_SLIPS],
                readPrivileges: [Privileges.READ_PROJECT_PACKING_SLIPS],
                initialLoadFromValues: true,
                dataUrl: '/projects/:id?fields=packing_slips',
                dataTransform: 'packing_slips',
                createUrl: (values) => { return `/project_packing_slips` },
                injectedValues: (values, user) => { return { project_id: values.id, created_by: user.id }},
                formFields: [
                    {   key: 'posted_date',
                        required: true,
                        label: 'Posted Date',
                        placeholder: 'Select the Posted Date',
                        type: 'date',
                    },
                    { key: 'description' },
                    { key: 'file', type: 'file', label: 'Packing Slip File', urlKey: 'file_url'},
                ],
                tableProps: {
                    rowURL: '/project_packing_slips/:id',
                    columns: [{type: 'date', accessor:'posted_date', cellWidth: '150px'}, {accessor: 'posted_user', cellWidth: '200px'},
                        'description', {heading: 'Attachment', label:'View Packing Slip', type:'file', key: 'file_url'},
],
                }
            },
            {type: 'array',
                key: 'tracking_numbers',
                label: 'Tracking Numbers',
                placeholder: 'Enter comma separated tracking numbers'},
            {key: 'activity_logs',
                parent_type: 'projects',
                label: 'Activity',
                type:'activity_logs',
            },
            {key: 'tasks',
                type: 'references',
                heading: 'Tasks',
                createLabel: 'Add New Task',
                createPrivileges: [Privileges.CREATE_TASKS],
                initialLoadFromValues: true,
                dataUrl: '/projects/:id?fields=tasks',
                dataTransform: 'tasks',
                createUrl: (values) => { return '/tasks' },
                injectedValues: (values) => { return {parent_id: values.id, parent_type: 'project', parent_description: `${values.description} (${values.project_number})`}},
                editorActions: [ 
                    { label: 'Add Pre-made Tasks', 
                       icon: 'ordered-list', 
                 submitText: 'Add Tasks',
                    parametersForm: [
                        {key: 'task_group_type', 
                            required: true,
                            label: 'Tasks Template Type',
                            type: 'options',
                            options: [
                                {value: 'install_city_of_miami', label: 'Installation - City of Miami'},
                                {value: 'install_miami_dade', label: 'Installation - Miami-Dade'},
                                {value: 'install_miami_dade_municipal', label: 'Installation - Miami-Dade-Municipal'},
                                {value: 'install_miami_beach', label: 'Installation - Miami Beach'},
                                {value: 'coral_gables_hialeah', label: 'Coral Gables/Hialeah'},
                                {value: 'sales_with_contract', label: 'Sales with Contract'},
                                {value: 'sales', label: 'Sales'},
                            ],
                        },
                    ],
                    method: async ({values, parameters, reload, loading, success, error}) => {
                            const { task_group_type} = parameters;
                            let project_id = values.id;

                            loading('Creating');
                            let result;
                            try {
                                result = await api.rpc('createTemplatedTasksForProject', {task_group_type, project_id})
                            } catch (e) {
                                throw new Error(e);
                            }
                            if(result.success) {
                                success({
                                    title: 'Success', 
                                    content: 'New tasks have been created for the project.',
                                    okText : 'Ok',
                                });
                            } else {
                                error({
                                    title: 'Error', 
                                    content: `Couldn't create tasks`,
                                    okText : 'Ok',
                                });
                            }

                            reload();
                        }
                    },
                ],
                formFields: [
                    {key: 'task_name'},
                    {key: 'task_type_id', 
                        //showDetails: true,
                        type: 'options', 
                        label: 'Task Type', 
                        optionsURL: `/task_types`, 
                        optionsMapper: nameMapping},
                    {type: 'date', 
                        key: 'due_date', 
                        label: 'Due Date'},
                    {type: 'longtext', key: 'description'},
                    {key: 'user_id', 
                     displayKey: 'user',
                        label: 'Assign to',
                        type: 'autocomplete', 
                        suggestFormat: (c) => { return {value: c.id, text: `${c.first_name} ${c.last_name}`} },
                        suggestUrl: '/users?employed=true', 
                        suggestFields: ['id', 'first_name', 'last_name']},
                ],
                tableProps: { 
                    rowURL: '/tasks/:id',
                    columns: [{heading: 'Task #', accessor: 'task_number', cellWidth: '60px'},
                        {accessor: 'task_type', cellWidth: '95px'},
                        {type: 'date', key: 'created_at', cellWidth: '110px'}, 
                        {type: 'date', key: 'due_date', cellWidth: '110px'}, 
                        'task_name', 
                        {heading: 'Files', type: 'count', icon: 'file-text', accessor: 'attachment_count', cellWidth: '60px'},
                        {accessor: 'user', cellWidth: '120px'},
                        {type: 'boolean', accessor: 'completed', cellWidth: '50px'}] 
                }
            },
            {key: 'collaborators',
                type: 'collaborators',
            },
            {key: 'notified_users',
                type: 'notifications',
            },

        ],
    },
    view: {
        accessControl: true,
        title: (p) => `Project ${p.project_number} (${p.description})`,
        contextLinks: (project) => {
            if(project.quote_id) {
                return {
                    label: 'Imported from Quote',
                    text: project.quote_number,
                    url: `/quotes/${project.quote_number}`,
                    privileges: [Privileges.READ_QUOTES]
                }
            } else {
                return null;
            }
        },
        formActions: [
            { label: 'Import Data from Quote', 
               icon: 'import', 
         submitText: 'Import',
         privileges: [Privileges.IMPORT_DATA_FROM_QUOTE],
            parametersForm: [
                {key: 'quote_id', 
                 displayKey: 'quote_number',
                    label: 'Quote Number',
                    type: 'autocomplete', 
                    suggestFormat: (q) => { 
                        let text = `${q.quote_number} (${q.description})`;
                        return {value: q.id, text };
                    },
                    suggestUrl: '/quotes', 
                    suggestFields: ['id', 'quote_number', 'description']
                },
            ],
            method: async ({values, parameters, reload, loading, success, error}) => {
                    const { quote_id } = parameters;
                    let project_id = values.id;

                    loading('Importing');
                    let result;
                    try {
                        result = await api.rpc('importDataFromQuote', {quote_id, project_id})
                    } catch (e) {
                        throw new Error(e);
                    }
                    
                    if(result.success) {
                        success({
                            title: 'Success', 
                            content: 'The project has been set with the products and services from the selected quote.',
                            okText : 'Ok',
                        });
                    } else {
                        let error = result.errors.error;
                        console.log("Got error ", result);
                        error({
                            title: 'Error', 
                            content: error.message,
                            okText : 'Ok',
                        });
                    }

                    reload();
                }
            },
            {   label: 'View Customer',
                icon: 'user',
                validate: ({values}) => {
                    return !!values['customer_id'];
                },
                method: async ({values, history}) => { 
                    const customer_id = values['customer_id'];
                    if(customer_id){
                        history.push(`/customers/${customer_id}`);
                    }
                }
            },
            {   label: 'View Site',
                icon: 'shop',
                validate: ({values}) => {
                    return !!values['site_id'];
                },
                method: async ({values, history}) => { 
                    const site_id = values['site_id'];
                    if(site_id){
                        history.push(`/sites/${site_id}`);
                    }
                }
            },
            { label: 'Create New Site', 
               icon: 'shop',
             method: async ({values, form, history}) => {
                try {
                    history.push('/sites/new');
                } catch (e) {
                    throw new Error(e);
                }
             },
            },
            { label: 'Create New Customer', 
               icon: 'contacts',
             method: async ({values, form, history}) => {
                try {
                    history.push('/customers/new');
                } catch (e) {
                    throw new Error(e);
                }
             },
            },
        ],
    },
    search: {
        allowDateFilter: true,
        heading: 'Your Projects',
        fields: ['id', 'status', 'project_number', 'description', 'logs_last_activity_description', 'logs_last_activity_user', 'customer_name', 'site_name', 'site_address', 'site_address2', 'site_city', 'site_state', 'site_zipcode', 'salesperson', 'created_at', 'updated_at'],
        filterOptions: [
             {label: 'All', filters: { view: 'related', related_to: (currentUser) => currentUser.id, exclude_status: 'void' }},
             {label: 'Created by me', filters: { creator: (currentUser) => currentUser.id, exclude_status: 'void' }},
             {label: 'Void', filters: { status: 'void', creator: (currentUser) => currentUser.id}},
        ],
        toggleOptions: [
            {key: 'hide_closed_void', 
                label: 'Hide Closed/Voided Projects', 
                param: 'status', 
                defaultValue: (currentUser) => true, 
                value: (currentUser) => 'open' }
        ],
        links: ['new'],
        searchBy: ['description', 'address', 'project numbers', 'tracking numbers', 'contract number', 'customer', 'salesperson'],
        tableProps: {
            rowURL: '/projects/:id',
            columns: [
                {accessor: 'created_at', type: 'date', dateFormat: 'MMM DD, YYYY' },
                {heading: 'Project Number', accessor: 'project_number', cellWidth: '110px', style: 'bold'},
                {accessor: 'salesperson'},
                {heading: 'Description', type:'multiple', rows: [
                    {heading: 'Description', style:'bold', accessor:'description'},
                    {heading: 'Activity', style: 'subtext', accessor:'logs_last_activity_description'},
                    {heading: 'User', style: 'subtext', accessor: 'logs_last_activity_user'},
                ]},
                {accessor: 'customer_name'},
                {heading: 'Site', type: 'multiple', rows: [
                    {heading: 'Site Name', accessor: 'site_name'},
                    {heading: 'Site Address', separator: ', ',accessor: ['site_address', 'site_address2', 'site_city', 'site_state', 'site_zipcode']}, 
                ]},
                {type:'badge', 
                    badgePropsFunc: statusBadgeStyle, 
                    heading: 'Status', 
                    accessor: 'status'},
            ]
        }
    },
}
            

