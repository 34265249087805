import api from 'api';
import colors from 'lib/constants/resourceColors';
import Privileges from '../lib/constants/privileges';

const inventoryLocationName = (o) => {
    const label = o.sublocation ? `${o.description}/${o.sublocation}` : o.description;
    return {label, value: o.id}
}

export default {
    defaults: {
        name: 'Company Purchase Order',
        color: colors.companyPurchaseOrders,
        color: '#6A98FF',
        formFields: [
            {key: 'created_at', static: true, type: 'date'},
            {key: 'customer_name', static: true, link: {url: '/customers/:customer_id', privileges: Privileges.READ_CUSTOMERS}},
            {key: 'customer_number', static: true},
            'description',
            {key: 'status', 
                defaultValue: 'OPEN',
                required: true,
                label: 'Status',
                type: 'options',
                options: [
                    {value: 'OPEN', label: 'Open'},
                    {value: 'PARTIAL', label: 'Partial'},
                    {value: 'CLOSED', label: 'Closed'},
                ],
            },
            {key: 'vendor_id', 
                showInCreateMode: false,
                displayKey: 'vendor_name',
                label: 'Vendor',
                link: {url: '/vendors/:vendor_id', privileges: Privileges.READ_VENDORS},
                type: 'autocomplete', 
                suggestFormat: (c) => { return {value: c.id, text: c.name} },
                suggestUrl: '/vendors', 
                suggestFields: ['id', 'name']},
            {key: 'project_id', 
                showInCreateMode: false,
                displayKey: 'project_description',
                label: 'Project',
                link: {url: '/projects/:project_id', privileges: Privileges.READ_PROJECTS},
                type: 'autocomplete', 
                suggestFormat: (p) => { 
                    const text = `(${p.project_number}) ${p.description}`;
                    return {value: p.id, text} 
                },
                suggestUrl: '/projects', 
                suggestFields: ['id', 'description', 'project_number']},
            {key: 'amount', type: 'currency', showInCreateMode: false},
            {key: 'ship_to_attn', label: 'Ship To Attention'},
            {key: 'parts',
                type: 'references',
                heading: 'Parts',
                createLabel: 'Add Part',
                dataTransform: 'parts',
                dataUrl: `/company_purchase_orders/:id?fields=parts`,
                createUrl: (values) => { return `/company_purchase_orders/${values.id}/parts` },
                deleteUrl: (values) => { return `/company_purchase_orders/${values.id}/parts/:product_id` },
                formFields: [
                    {key: 'product_id', 
                     displayKey: 'product_number',
                        label: 'Part Number',
                        type: 'autocomplete', 
                        suggestFormat: (c) => { return {value: c.id, text: c.product_number} },
                        suggestUrl: '/parts?exclude_discontinued=true',
                        suggestFields: ['id', 'product_number']},
                    {key: 'quantity' },
                ],
                tableProps: {
                    rowURL: '/company_purchase_order_products/:id',
                    columns: [
                    'product_number', 
                    {accessor: 'description', maxWidth: '100px'},
                    'quantity', 
                    {heading:'Base Cost', accessor: 'base_cost', type: 'currency'},
                    {heading: 'Total Cost', accessor: 'total_base_cost', type: 'currency'},
                ] },
            },
            {key: 'charges',
                type: 'references',
                heading: 'Charges',
                createLabel: 'Add Charge',
                dataTransform: 'charges',
                dataUrl: `/company_purchase_orders/:id?fields=charges`,
                createUrl: (values) => { return `/company_purchase_orders/${values.id}/charges` },
                deleteUrl: (values) => { return `/company_purchase_orders/${values.id}/charges/:id` },
                formFields: [
                    {key: 'description'},
                    {key: 'ref_number', title: 'Reference Number'},
                    {key: 'amount', type:'currency'},
                ],
                tableProps: { columns: [
                    'description',
                    'ref_number', 
                    {accessor:'amount', type: 'currency'},
                ] },
            },
            {key: 'distributions_summary',
                type: 'datatable',
                heading: 'Parts Distributions',
                headers: (values) => { return ['Part Number',  'Allocated', 'Ordered', 'Received'] },
                data: (values) => {
                    const { distributions } = values;
                    return distributions.map(d => [d.product_number, d.allocated, d.ordered, d.received]);
                },
                dynamicStyles: (row, allRows) => {
                    const allocated = row[1];
                    const ordered = row[2];

                    if(allocated > ordered) {
                        return [null, {color: 'red'}, null];
                    } else {
                        return null;
                    }
                }
            },
            {key: 'distributions',
                type: 'references',
                heading: 'Parts Distribution',
                createLabel: 'Distribute a Part',
                dataTransform: (data) => {
                    const { distributions } = data;

                    let d = [];
                    distributions.forEach(dist => {
                        let r = dist.distributions;
                        let pn = dist.product_number;
                        let pid = dist.product_id;
                        if(r) {
                            d = d.concat(r.map(data => { return { product_id: pid, product_number: pn, ...data}}));
                        }
                    });

                    return d;
                },
                dataUrl: `/company_purchase_orders/:id?fields=distributions`,
                createUrl: (values) => { return `/company_purchase_orders/${values.id}/distributions` },
                deleteUrl: (values) => { return `/company_purchase_orders/${values.id}/distributions/:id` },
                formFields: [
                    {key: 'product_id', 
                        label: 'Part Number',
                        type: 'autocomplete', 
                        suggestFormat: (c) => { return {value: c.id, text: `${c.product_number}`} },
                        suggestUrl: '/parts?exclude_discontinued=true',
                        suggestFields: ['id', 'product_number']},
                    {key: 'quantity', type: 'number',},
                    {key: 'project_id', 
                        type: 'autocomplete', 
                        suggestFormat: (c) => { return {value: c.id, text: `${c.description} ${c.project_number}`} },
                        suggestUrl: '/projects', 
                        suggestFields: ['id', 'project_number', 'description']},
                    // {key: 'inventory_location_id', 
                    //     type: 'autocomplete', 
                    //     suggestFormat: (c) => { return {value: c.id, text: `${c.description}/${c.sublocation}`} },
                    //     suggestUrl: '/inventory_locations', 
                    //     suggestFields: ['id', 'description', 'sublocation']},
                    {key: 'inventory_location_id', 
                        type: 'options', 
                        label: 'Inventory Location',
                        optionsMapper: inventoryLocationName,
                        optionsURL: '/inventory_locations'},
                    {key: 'bin_number'},
                ],
                tableProps: { 
                    rowURL: '/company_purchase_order_distributions/:id',
                    columns: [
                    {accessor:'product_number', readOnly: true, heading: 'Part Number', cellWidth: '150px'},
                    {accessor: 'quantity', type: 'number', cellWidth: '50px'},
                    {heading: 'Distribution', type: 'multiple', rows: [
                        {heading: 'Project', accessor: 'project_description'},
                        {heading: 'Project Number', accessor: 'project_number',  style: 'subtext'}
                    ]},
                    {heading: 'Inventory Location', type: 'multiple', rows: [
                        {heading: 'Description', accessor: 'inventory_location_description'},
                        {heading: 'Sublocation', accessor: 'inventory_location_sublocation',  style: 'bold'},
                        {heading: 'Bin Number', accessor: 'inventory_bin_number', style: 'bold'},
                    ]},
                    {heading: 'Bin Number', accessor: 'inventory_bin_number', cellWidth: '75px'},
                ] },
            },
            {key: 'receiving_slips',
                type: 'references',
                heading: 'Receiving Slips',
                createLabel: 'Add Receiving Slip',
                dataTransform: 'receiving_slips',
                dataUrl: `/company_purchase_orders/:id?fields=receiving_slips`,
                createUrl: (values) => { return `/company_purchase_orders/${values.id}/receiving_slips` },
                //deleteUrl: (values) => { return `/company_purchase_orders/${values.id}/receiving_slips/:id` },
                injectedValues: (values, user) => { return { received_by: user.id }},
                formFields: [
                    {key: 'description'},
                    {key: 'ref_number', title: 'Reference Number'},
                    {key: 'received_at', type:'date'},
                ],
                tableProps: { 
                    rowURL: '/receiving_slips/:id',
                    columns: [
                    'description',
                    'ref_number', 
                    {accessor:'received_at', type:'date'},
                    {heading: 'Packing Slip', type: 'file', key: 'packing_slip_url', label: 'View Packing Slip'}
                ] },
            }

        ],
    },
    search: {
        fields: ['id','created_at', 'po_number', 'project_description', 'description', 'status', 'vendor_name'],
        links: ['new'],
        searchBy: ['PO number', 'description', 'project description', 'vendor'],
        filterOptions: [
             {label: 'Open', filters: { exclude_status: 'CLOSED' }}, 
             {label: 'Closed', filters: { status: 'CLOSED'}},
        ],
        tableProps: {
            rowURL: '/company_purchase_orders/:id',
            columns: [{heading: 'Created', type: 'date', dateFormat: 'MMM DD, YYYY (H:mm A)', cellWidth: '120px', accessor: 'created_at'}, {heading: 'PO Number', cellWidth: '120px', style: 'bold', accessor: 'po_number'}, {heading: 'Vendor', accessor: 'vendor_name'}, {accessor:'status', cellWidth: '100px'}, {accessor: 'project_description', heading: 'Project', showCopyToClipboard: true}, 'description'],
        }
    },
    view: {
        formActions: [
            {   label: 'Print Purchase Order',
                icon: 'printer-o',
                method: async ({values, history, inputValues}) => {
                    let pdfData = {
                        proforma: false,
                        po_number: values.po_number,
                        description: values.description,
                        project_description: values.project_description,
                        vendor_name: values.vendor_name,
                        salesperson: values.ship_to_attn || values.salesperson_name || 'Sales Department',
                        vendor_account_number: values.vendor_account_number,
                        bill_name: 'Engineering Systems Technology, Inc.',
                        bill_address: '2400 W 84th Street',
                        bill_address2: 'Suite 9',
                        bill_city: 'Hialeah',
                        bill_state: 'FL',
                        bill_zipcode: '33016',
                        ship_name: 'Engineering Systems Technology, Inc.',
                        ship_address: '2400 W 84th Street',
                        ship_address2: 'Suite 9',
                        ship_city: 'Hialeah',
                        ship_state: 'FL',
                        ship_zipcode: '33016',
                        shorten_descriptions: values.shorten_descriptions,
                        parts: values.parts,
                        amount_due: values.parts.reduce((sum, part) => sum + (part.total_base_cost), 0),
                        created_at: values.created_at
                    };

                    const filename = `purchase_order_{values.po_number}`

                    let response;
                    try {
                        response = await api.create('/pdfs/company_purchase_orders', {pdfData, filename});
                        console.log("Got response ", response);
                        window.open(response.body.url, '_blank');
                    } catch (e) {
                        console.log(`Got error ${e}`);
                        throw new Error(e);
                    }

                }
            },
            {   label: 'Download Quantity/Category CSV',
                icon: 'file-text',
                method: async ({values, history, inputValues}) => {
                    const params = {
                        purchase_order_id: values.id,
                        po_number: values.po_number
                    }

                    try {
                        await api.download('/files/po_part_quantity', params);
                    } catch (e) {
                        console.log(`Got error ${e}`);
                        throw new Error(e);
                    }

                }
            },
        ],
        title: (p) => `Purchase Order # ${p.po_number}`,
    }
}
