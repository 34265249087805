export default function(numberString){
    if(typeof numberString === 'number'){
        //numberString is already a number
        return numberString;
    }

    if(typeof numberString === 'string'){
        var numberNoCommas = numberString.replace(/[^0-9\.]+/g, '');
        return parseFloat(numberNoCommas);
    }

    throw new Error('Number passed to parseFloatIgnoreCommas is not a string or number');
}
