import React, { useRef, useState, useEffect } from 'react';
import './style.css';
import Chart from 'chart.js';
import api from 'api';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const colors = ['#FF7F50','#6495ED','#DC143C','#00FFFF','#00008B','#008B8B','#B8860B','#A9A9A9','#A9A9A9','#006400','#BDB76B','#8B008B','#556B2F','#FF8C00','#9932CC','#8B0000','#E9967A','#8FBC8F','#483D8B','#2F4F4F','#2F4F4F','#00CED1','#9400D3','#FF1493','#00BFFF','#696969','#696969','#1E90FF','#B22222','#FFFAF0','#228B22','#FF00FF','#DCDCDC','#F8F8FF','#FFD700','#DAA520','#808080','#808080','#008000','#ADFF2F','#F0FFF0','#FF69B4','#CD5C5C','#4B0082','#FFFFF0','#F0E68C','#E6E6FA','#FFF0F5','#7CFC00','#FFFACD','#ADD8E6','#F08080','#E0FFFF','#FAFAD2','#D3D3D3','#D3D3D3','#90EE90','#FFB6C1','#FFA07A','#20B2AA','#87CEFA','#778899','#778899','#B0C4DE','#FFFFE0','#00FF00','#32CD32','#FAF0E6','#FF00FF','#800000','#66CDAA','#0000CD','#BA55D3','#9370DB','#3CB371','#7B68EE','#00FA9A','#48D1CC','#C71585','#191970','#F5FFFA','#FFE4E1','#FFE4B5','#FFDEAD','#000080','#FDF5E6','#808000','#6B8E23','#FFA500','#FF4500','#DA70D6','#EEE8AA','#98FB98','#AFEEEE','#DB7093','#FFEFD5','#FFDAB9','#CD853F','#FFC0CB','#DDA0DD','#B0E0E6','#800080','#663399','#FF0000','#BC8F8F','#4169E1','#8B4513','#FA8072','#F4A460','#2E8B57','#FFF5EE','#A0522D','#C0C0C0','#87CEEB','#6A5ACD','#708090','#708090','#FFFAFA','#00FF7F','#4682B4','#D2B48C','#008080','#D8BFD8','#FF6347','#40E0D0','#EE82EE','#F5DEB3','#F5F5F5','#FFFF00','#9ACD32'];

function BarChart({ unit, data = [], topValueRow, formatTopValues=true, includeRowMatch=[], excludeRowMatch=[], topValueSuffix, dataTransform, dataURL, title, labelKey, columnKeys, showSum=false, stacked=true}) {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [fetchedData, setFetchedData] = useState([]);
    const [topValues, setTopValues] = useState([]);

    const canvasRef = useRef();

    useEffect(() => {

        if(data.length === 0 && dataURL) {
            async function fetch() {
                setLoading(true);

                let response;

                try { 
                    response = await api.get(dataURL);
                } catch (e) {
                    setError(e);
                }
                

                if(response.errors) {
                    setFetchedData([]);
                    setError(response.errors);
                } else {
                    let d = response.body; 

                    console.log(d);

                    if(topValueRow) {
                        let topRow = d.filter(row => { 
                            let label = row[labelKeyForRow(row)];
                            return label === topValueRow;
                        });

                        if(!topRow || topRow.length === 0) {
                            throw new Error(`No row in dataset has label ${topValueRow} for sourcing top values`);
                        }

                        if(topRow.length > 1) {
                            throw new Error(`Found too many rows in dataset with label ${topValueRow}`);
                        }

                        topRow = topRow[0];

                        setTopValues(columnKeys.map(key => topRow[key]));
                    }

                    if(includeRowMatch && includeRowMatch.length) {
                        d = d.filter(row => { 
                            let label = row[labelKeyForRow(row)].toLowerCase();
                            return includeRowMatch.reduce((acc, val) => acc |= label.includes(val.toLowerCase()), false);
                        });
                    }

                    if(excludeRowMatch && excludeRowMatch.length) {
                        d = d.filter(row => { 
                            let label = row[labelKeyForRow(row)].toLowerCase();
                            return !excludeRowMatch.reduce((acc, val) => acc |= label.includes(val.toLowerCase()), false);
                        });
                    }

                    d = d.filter(row => {
                        //remove any meta rows which are identified by # as first char for label
                        let label = row[labelKeyForRow(row)];
                        return label.charAt(0) !== '#';
                    });

                    setFetchedData(d);
                    setError(null);
                }


                setLoading(false);
            };

            fetch();
        }
    }, []);

    const labelKeyForRow = (row) => {
        return labelKey || Object.keys(row).reduce((acc, val) => val.charAt(0) === '_' ? val : acc, null);
    }

    const formatData = (data) => {

        return data.map((row, index) => {


            let rowKeys = Object.keys(row);
            let label = labelKeyForRow(row);

            if(!label) {
                throw  new Error('No Label Key defined or no label key prefixed with underscore _');
            }

            let rowData = rowKeys.filter(k => k !== label).map(key => {
                return row[key];
            });

            return {
                label: row[label],
                data: rowData,
                backgroundColor: colors[index],
                datalabels: {
                    display: false,
                }
            }
        });
    }

    function number_format(number, decimals, dec_point, thousands_sep) {
    // from https://stackoverflow.com/questions/36918887/chart-js-2-0-formatting-y-axis-with-currency-and-thousands-separator
    // *     example: number_format(1234.56, 2, ',', ' ');
    // *     return: '1 234,56'
        number = (number + '').replace(',', '').replace(' ', '');
        var n = !isFinite(+number) ? 0 : +number,
                prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
                dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
                s = '',
                toFixedFix = function (n, prec) {
                    var k = Math.pow(10, prec);
                    return '' + Math.round(n * k) / k;
                };
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }


    function formatNumberToChartUnit(number) {

        let value = number || 0;
        if(unit) {
            switch(unit.toLowerCase()) {
                case 'dollars':
                        value = `$ ${number_format(value, 2)}`;
                    break;
                default:
                    break;
            }
        }

        return value;
    }

    useEffect(() => {
        const d = fetchedData.length ? fetchedData : data;
        
        let datasets = formatData(d);

        let ticks = {}; 
        let tooltips = {};
        if(unit) {
            switch(unit.toLowerCase()) { 
                case 'dollars':
                    ticks = { beginAtZero:true, callback: function(value, index, values) { return '$' + number_format(value); }}
                    tooltips = {
                            callbacks: {
                                label: function(tooltipItem, chart){
                                    var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
                                    return datasetLabel + ': $' + number_format(tooltipItem.yLabel, 2);
                                }
                            }
                        };
                    break;
                default:
                    break;
            }
        }
        
        if((topValueRow || showSum) && datasets.length && (stacked || (!stacked && datasets[0].data.length === 1))) {
           
            let tops;

            if(topValueRow) {
                tops = topValues;
            } else {
                tops = datasets[0].data.map((d, index) => {
                    return datasets.reduce((acc,dataset) => { 
                        return Number(acc) + Number(dataset.data[index])
                    }, 0);
                });
            }

            let hiddenDataset = {
                label: 'Total',
                data: (fetchedData.length || data.length) ? datasets[0].data.map(d => 0) : [],
                backgroundColor: 'rgba(0,0,0,0)',
                datalabels: {
                    backgroundColor: function (context) {

                        return 'rgba(0,0,0,0)';

                    },
                    formatter: (value, ctx) => {
                        let topValue = tops[ctx.dataIndex];
                        let formattedValue = formatTopValues ? formatNumberToChartUnit(topValue) : topValue;
                        formattedValue = topValueSuffix ? `${formattedValue} ${topValueSuffix}` : formattedValue;
                        return topValue ? formattedValue : null;
                    },
                    align: 'end',
                    anchor: 'end',
                    display: function (ctx) {
                        return true;
                    }
                }
            }

            datasets.push(hiddenDataset);
        }


        new Chart(canvasRef.current, {
            type: 'bar', 
            data: {
                labels: columnKeys,
                datasets,
            },
            options: {
                title: {
                    display: true,
                    text: unit ? `${title} (${unit})` : title,
                },
                 legend: {
                     display: false,
                     position: 'right',
                 },
                scales: {
                    xAxes: [{stacked}],
                    yAxes: [{stacked, ticks}]
                },
                tooltips,
            }
        });
    }, [fetchedData, data]);

    return <canvas ref={canvasRef} />
}

export default BarChart;

