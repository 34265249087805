import React, { Component } from 'react';
import { Select, Spin } from 'antd';
import api from 'api';

const Option = Select.Option;

class RemoteSelect extends Component {

  state = {
    data: [],
    fetching: false,
  }

  componentDidMount(){
      this.fetchData();
  }

  componentDidUpdate(prevProps, prevState, snapshot){
        const { url } = this.props;

        if(prevProps.url !== url){
            this.fetchData();
        } 

  }

  fetchData = () => {
      const { value, onChange, url, dataTransform } = this.props;

      this.setState({fetching: true}, () => {
          api.get(url)
          .then(results => {
              if(results.status === 200){
                  let data = results.body.map(dataTransform);
                  let currentValueIsValid = false;
                  data.forEach(item => {
                      if(currentValueIsValid){
                          return;
                      }

                      if(item.value === value) {
                          currentValueIsValid = true;
                      }
                  });

                  if(!currentValueIsValid) {
                      //onChange(undefined);
                  }

                  this.setState({data, fetching: false});
              } else {
                this.setState({data: [], fetching: false});
              }
          }).catch(e => {
                this.setState({fetching: false, data: []});
          })
      });
  }

  render() {
    const { value, ...other } = this.props;
    const { fetching, data } = this.state;
    return (
      <Select
        disabled={fetching}
        value={value || undefined}
        allowClear={true}
        placeholder="Choose an Option"
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...other}>
        {data.map((d, index) => <Option key={'option_'+d.value+'_'+index} value={d.value}>{d.label}</Option>)}
      </Select>
    );
  }
}

export default RemoteSelect;

