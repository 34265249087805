import React, { Component }  from 'react';
//eslint-disable-next-line
const debug = require('debug')('app:DropDown:');

// const propTypes = {
//     options : PropTypes.array.isRequired,
//     selectedOption : PropTypes.oneOfType([
//                         PropTypes.string, 
//                         PropTypes.bool, 
//                         PropTypes.number]),
//     onChange : PropTypes.func,
//     allowsNullSelection : PropTypes.bool,
//     noSelectionText : PropTypes.string,
//     showEmptySelection : PropTypes.bool,
//     style : PropTypes.object,
//     useOptionsAsValues : PropTypes.bool,
// };


const defaultProps = {
    allowsNullSelection : false,
    selectedOption : null,
    showEmptySelection : false,
    noSelectionText : "",
    useOptionsAsValues : false //rename this to useIndexAsValues and reverse the logic
};


class DropDown extends Component {
    
    render(){
        //eslint-disable-next-line
        const { id, onChange, showEmptySelection, allowsNullSelection, style, noSelectionText, useOptionsAsValues, options, selectedOption, ...otherProps } = this.props;
        
        let defaultValue;
        if(useOptionsAsValues) { //if !useIndexAsValues 
            defaultValue = selectedOption;
        } else {
            if(typeof selectedOption !== 'undefined'){
                let index = 0;
                options.forEach(option => {
                
                    if(option.value === selectedOption){
                        return index;
                    }

                    index++;
                });

                defaultValue = index;
            } else {
                defaultValue = 0;
            }
        }

        return (
            <select id={id || 'dropdown'}  onChange={onChange} defaultValue={defaultValue} {...otherProps}>
                {this.renderEmptyOption()}
                {options.map((option, index) => {
                    let value;
                    let label;
                    if(typeof option === 'object'){
                        value = option.value;
                        label = option.label;
                    } else {
                        value = useOptionsAsValues ? option : index;
                        label = option;
                    }
                    return <option key={value+label} value={value}>{label}</option>
                })}
            </select>
        );
    }

    renderEmptyOption = () => {
        if(this.props.showEmptySelection){
            return <option disabled={!this.props.allowsNullSelection} 
                              value={null}>
                              {this.props.noSelectionText}
                    </option>
        }

        return null
    }

}

//DropDown.propTypes = propTypes;
DropDown.defaultProps = defaultProps;

export default DropDown;
