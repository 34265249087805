import React from 'react';
import { Prompt, Redirect, withRouter } from 'react-router';
import { Spin, Modal } from 'antd';
import api from 'api';
import ErrorMessage from 'components/ErrorMessage';
import bindUrl from 'lib/helpers/bindURL';

const parseBoolean = (string) => {

    if(typeof string !== 'string' || string === null){
        return string;
    }

    if(string === 'false'){
        return false;
    } else if (string === 'true'){
        return true;
    } else {
       throw new Error(`Failed to parse boolean from string '${string}'`);
    }
}

export default (dataUrl, options = {}) => (Component) => {
        return withRouter(class extends React.Component {

            state = { 
                allow_write: null,
                allow_delete: null,
                saving: false,
                loading: true,
                loadingMessage: null,
                deleting: false,
                isEditing: false,
                redirectTo: null,
                data: null,
                errors: null,
            }


            componentDidMount() {
                console.log("Did Moount ", this.props);
                this.fetchData();
            }

            fetchData = () => {
                const { dataUrl } = this.props;
                const { params } = this.props.match;

                const url = bindUrl(dataUrl, params);
                console.log("Data URL ", url);
                const fields = options.fields
                this.setState({loading: true});
                api.read(url, fields)
                .then(({headers, errors, body}) => {
                    if(errors){
                        throw errors;
                    } else {
                        let allow_write = headers['x-allow-write'] || null;
                        let allow_delete = headers['x-allow-delete'] || null;

                        allow_write = parseBoolean(allow_write);
                        allow_delete = parseBoolean(allow_delete);

                        this.setState({allow_write, allow_delete, errors: null, data: body, loading: false});
                    }
                })
                .catch(errors => {
                    console.log("Errors with resource", errors);
                    this.setState(previousState => ({
                        data: null,
                        loading: false,
                        errors: (previousState.errors || []).concat(errors)
                    }));        
                });
            }

            render(){
                const { loadingMessage, allow_write, allow_delete, redirectTo, saving, deleting, loading, data, errors, isEditing } = this.state;

                if(redirectTo){
                    return <Redirect to={redirectTo} />;
                }
                
                if(errors){
                    return <ErrorMessage errors={errors} />; 
                }

                if(saving){
                    return <div>
                        <Spin tip={'Saving Changes'} /> 
                        <Prompt when={saving} message={location => `You are still submitting data, if you exit this page your changes may be lost.`} />
                    </div>
                }

                if(deleting){
                    return <div>
                        <Spin tip={'Deleting'} />
                        <Prompt when={deleting} message={location => `You are still deleting data, if you exit this page the deletion may be canceled.`} />
                    </div>
                }

                if(loading){
                    console.log("Loading up ");
                    return <Spin tip={loadingMessage}/> 
                } else {
                    //isEditing and startEditing might be obsolete
                    return <Component data={data} 
                               allow_write={allow_write}
                              allow_delete={allow_delete}
                                 isEditing={isEditing}
                              startEditing={this.enterEditMode}
                                  onDelete={this.handleDelete}
                                reloadData={this.handleReload}
                           startProcessing={this.startProcessing}
                          finishProcessing={this.finishProcessing}
                                saveUpdate={this.handleSave} 
                              cancelUpdate={this.handleCancel} {...this.props} />
                }
                
            }

            enterEditMode(e) {
                this.setState({isEditing: true});
            }

            startProcessing = (message) => {
                this.setState({loading: true, loadingMessage: message});
            }

            finishProcessing = (result) => {
                this.setState({loading: false, loadingMessage: false});
            }

            handleReload = (e) => {
                this.fetchData();
            }

            handleDelete = (e) => {
                const { history } = this.props;
                const { params } = this.props.match;

                let url = bindUrl(dataUrl, params);
                this.setState({deleting: true});
                api.destroy(url)
                .then(({errors, body}) => {
                    if(errors){
                        throw errors;
                    } else {
                        this.setState({data: null, deleting: false });
                        history.goBack();
                    }
                })
                .catch(errors => {
                    this.setState(previousState => ({
                        data: (previousState.data),
                        deleting: false,
                        errors: (previousState.errors || []).concat("Failed to Delete", errors)
                    }));
                });
            }

            handleSave = async (values) => {
                const { params } = this.props.match;
                const { dataUrl } = this.props;

                //let originalValues = data;
                this.setState(previousState => ({saving: true}));

                let formData = new FormData();
                for ( var key in values) {
                    formData.append(key, values[key]);
                }

                console.log("Send form data ", values);
                const url = bindUrl(dataUrl, params);
                return api.update(url, formData)
                .then(({errors, body}) => {
                    if(errors){
                        throw errors;
                    } else {
                        const fields = options.fields
                        return api.read(url, fields);
                    }
                })
                .then(response => {
                    if(response.errors){
                        throw response.errors;
                    } else {
                        this.setState(previousState => ({data: Object.assign(previousState.data, response.body), saving: false}));
                        return response;
                    }
                })
                .catch(errors => {
                    this.setState(previousState => ({
                    //     data: Object.assign(previousState.data, originalValues),
                         saving: false,
                         errors: (previousState.errors || []).concat("Failed to Save Changes", errors)
                    }));        
                });

            }

            handleCancel = (e) => {
                e.preventDefault();
                this.setState({isEditing: false});
            }

        });
    }


