import React, { Component } from 'react';
import Form from 'components/Form';
import { Affix } from 'antd';
import withResource from 'enhancers/withResource';
import BackLink from 'components/BackLink';
import Remarks from 'components/Remarks';
import { UserContext } from 'contexts/User';
import { userHasPrivileges } from 'lib/helpers/renderCondition';
import './style.css';

// eslint-disable-next-line
const debug = require('debug')('app:Editor:');

// const propTypes = {
//     data: PropTypes.object,
//     getTitle: PropTypes.func,
//     formFieldsProps: PropTypes.object,
//     backURL : PropTypes.string,
//     backTitle: PropTypes.string,
//     resourceName: PropTypes.string,
// };

class Editor extends Component {

    state = {
        headerAffixed: false
    }

    affixedFunc = (affixed) => {
        //this causes infinite re renders
        // if(this.state.headerAffixed !== affixed) {
        //     this.setState({headerAffixed: affixed});
        // }
    }

    hasSaveableFieldForUser = (user) => {
        const { editPrivileges, formFields } = this.props;

        if(!formFields){ 
            return false;
        }

        //TODO: Check also for fields that user has no privilege to and skip them
        const nonSaveableTypes = ['hidden', 'oneToMany', 'relations', 'references']; 
        let hasField = false;
        formFields.forEach((field) => {
            if(hasField){
                return;
            }

            let privileges = field.writePrivileges || [];

            if( (field.createOnly || 
                field.static || 
                field.readOnly || 
                userHasPrivileges(privileges, user) === false ||
                nonSaveableTypes.includes(field.type)) === false) {
                hasField = true;
            };
        });

        return hasField;
    }

    renderContextLinks = (data) => {
        const { contextLinks } = this.props;

        console.log("data is ", data);
        if(!contextLinks) {
            return null;
        }

        let links = contextLinks(data);

        if(!Array.isArray(links)) {
            links = [links];
        }

        links = links.filter(v => v);
        let placeholder = 'No Description'

        let nodes = links.map(link => {
            if(link.url) {
                return <div><span className='contextLabel'>{link.label}:</span> <a href={link.url}>{link.text || placeholder}</a></div>
            } else {
                return <div><span className='contextLabel'>{link.label}:</span> <span>{link.text}</span></div>
            }
        });

        console.log("Nodes are ", nodes);

        return <div className='context'>{nodes}</div>;

    }

    render(){
        const { startProcessing, finishProcessing, enableDelete, accessControl, mainImageURLKey, mainImageWidth, embedded, deletePrivileges, 
                editPrivileges, extraRender, color, title, allow_write, allow_delete, formActions, 
                remarks, formFields, onDelete, cancelUpdate, data, reloadData } = this.props;

        const { headerAffixed } = this.state;

        let readOnly = typeof allow_write === 'boolean' ? !allow_write : false;
        let deleteEnabled = enableDelete ? (typeof allow_delete === 'boolean' ? allow_delete : true) : false;
        

        if(!data) {
            return 'No data';
        }

        return <React.Fragment>
            {embedded ?  null : 
            <div >
                <BackLink /> 
                <Affix onChange={this.affixedFunc} offsetTop={0}>
                    <div style={{backgroundColor: color || '#ffffff', color: color ? '#ffffff' : '#505050', marginBottom: '5px', padding: '10px 5px', boxShadow: headerAffixed ? '0px 5px 5px #55556655' : null}}>
                        <span style={{fontSize:'20px', fontWeight: 800}}>{title ? title(data) : null}</span> 
                        {this.renderContextLinks(data)}
                    </div>
                </Affix>
            </div>}
            {data && data[mainImageURLKey] ? <a href={data[mainImageURLKey]} target="_blank"><img alt='' src={data[mainImageURLKey]} style={{maxWidth: mainImageWidth, margin: '0 auto', marginBottom: '12px'}} /></a>: null}
            <UserContext.Consumer>
                {({state}) => {
                    let header = remarks ? remarks.header(data) : null;
                    const hasFieldsThatCanBeEdited = this.hasSaveableFieldForUser(state.user);
                    return <React.Fragment><Form onDelete={onDelete} 
                        embedded={embedded}
                        readOnly={readOnly}
                        showEditButtons={hasFieldsThatCanBeEdited}
                        accessControlList={accessControl}
                        deleteEnabled={deleteEnabled}
                        deletePrivileges={deletePrivileges}
                        editPrivileges={editPrivileges}
                        formActions={formActions}
                        onCancel={cancelUpdate} 
                        onSubmit={this.saveForm} 
                        onReload={reloadData}
                        onActionStart={startProcessing}
                        onActionEnd={finishProcessing}
                        values={data}
                        fields={formFields} /> 
                        {extraRender ? extraRender(this.props) : null}
                        {remarks ? <Remarks title={remarks.title} header={header} object_type={remarks.object_type} user={state.user} object_id={data[remarks.object_id_key]} /> : null}
                    </React.Fragment>
                }}
            </UserContext.Consumer>
        </React.Fragment>
    }

    saveForm = async (values) => {
        console.log("Save form ", values);
        return await this.props.saveUpdate(values);
    }

}


export default (props) => {

    const DecoratedComponent = withResource(props.dataUrl, props)(Editor);

    return <DecoratedComponent {...props} />
}
