import React from 'react';
import './style.css';


const isProduction = (process.env.REACT_APP_ENVIRONMENT === 'production');
const deployDate = process.env.REACT_APP_DEPLOY_DATE || '(Local Only)';
const API_URL = process.env.REACT_APP_API_URL || 'localhost';


function VersionHeader() {
    if(isProduction) { 
        return null; 
    } else {
        return <div className='devStyle'>Development Build, Deployed: {deployDate}, API_URL: {API_URL} </div>
    }
};

export default React.memo(VersionHeader);
