import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Spin, Dropdown, Menu, Button, Icon } from 'antd';
import Form from 'components/Form';
import Table from 'components/Table';
import api from 'api';
import AutoComplete from 'components/Form/AutoComplete';
import bindUrl from 'lib/helpers/bindURL';
import './style.css';
import { userHasPrivileges } from 'lib/helpers/renderCondition';

const defaultProps = {
    editorActions: [],
    formActions: [],
    formTemplates: {},
    formTemplateSearch: {},
    size: 'middle',
}

function AllocationRow({product_id, company_purchase_order_id, onDeleted, onCreated, allocation, locations, ...props}) {
    const blank = {quantity: null, inventory_location_id: locations[0] ? locations[0].id : null, project_id: null} 
    const initialValues = allocation ? allocation : blank ;
    let initialDistributionId = null;
    let initialDistributionName = null;
    let initialDistributionType = 'project';
    if(initialValues.project_id) {
       initialDistributionId = initialValues.project_id;
       initialDistributionName = initialValues.project_number;
       initialDistributionType = 'project';
    } else {

    }


    const [saving, setSaving] = useState(false);
    const [values, setValues] = useState(initialValues);
    const [distributionId, setDistributionId] = useState(initialDistributionId);
    const [distributionName, setDistributionName] = useState(initialDistributionName);
    const [distributionType, setDistributionType] = useState(initialDistributionType);
    const [error, setError] = useState(null);

    console.log("Initial ", initialValues);

    const set = (key) => {
        return ({ target: { value } }) => { 
            console.log("Set ", key, value);
            setValues(oldValues => ({...oldValues, [key]: value })); 
        }
    };

    const onSave = async (e) => {
        e.preventDefault();
        setSaving(true);
        let params = {product_id, quantity: values.quantity, inventory_location_id: values.inventory_location_id};

        switch(distributionType) {
            case 'project':
                params.project_id = distributionId;
                //set other things null
                break;
            default:
                break;
        }

        console.log("Save ", params);
        let result = await api.update(`/company_purchase_orders/${company_purchase_order_id}/distributions/${allocation.id}`, params);
        if(result.success) {
            console.log("saved ", result.body);
        } else {

        }
        setSaving(false);

    }

    const onDelete = async (e) => {
        e.preventDefault();

        console.log("Delete ", allocation);
        let result = await api.destroy(`/company_purchase_orders/${company_purchase_order_id}/distributions/${allocation.id}`);
        if(result.success) {
            console.log("Deleted ", allocation);
            onDeleted(allocation);
        } else {

        }
    }

    const onCreate = async (e) => {
        e.preventDefault();
        let params = {product_id, quantity: values.quantity, inventory_location_id: values.inventory_location_id};
        switch(distributionType) {
            case 'project':
                params.project_id = distributionId;
                break;
            default:
                break;
        }

        console.log("Create ", params);
        let result = await api.create(`/company_purchase_orders/${company_purchase_order_id}/distributions`, params);
        if(result.success) {
            console.log("Created ", result.body);
            let savedValues = result.body;
            if(savedValues.project_id) {
                savedValues.project_number = distributionName;
            }
            setValues(blank);
            setDistributionId(null);
            setDistributionName(null);
            setDistributionType('project');
            onCreated(savedValues);
        } else {

        }

    }

    const submitButton = allocation ? <Button className='distsave' onClick={onSave}>Save</Button> : <Button onClick={onCreate}>Create</Button>
    const deleteButton = allocation ? <Button className='distdelete' onClick={onDelete}>Delete</Button> : null;

    const handleDistributionChange = (e) => {
        let val = e.target.value;

        setDistributionId(val.value);
        setDistributionName(val.label);
    }

    const handleTypeChange = (e) => {
        e.preventDefault();
        console.log("Change to ", e.target.value);
        setDistributionType(e.target.value);
    }

    return <tr>
        <NumberInput value={values.quantity} onChange={set('quantity')} />
        <DistributionInput value={distributionId} displayedValue={distributionName} 
                            type={distributionType} onChangeType={handleTypeChange} onChange={handleDistributionChange}/>
        <LocationInput value={values.inventory_location_id} 
                            options={locations} 
                           onChange={set('inventory_location_id')}/>
        <td className='distactionscell'>{saving ? 'Saving...' : submitButton}{deleteButton}</td>
    </tr>
}

function DistributionInput({type, value, displayedValue, initialValue, onChangeType, onChange, ...props}) {


    let suggestUrl = [];
    let suggestFields = [];
    let placeholder;

    if(type === 'project') {
        suggestUrl = '/projects';
        suggestFields = ['id', 'description', 'project_number'];
        placeholder = 'Project Number';
    } else if (type === 'work_order') {

    } else {

    }


    const handleChange = (value, v) => {
        onChange({target: {value: {value: value.key, label: value.label[0], type}}});
    }

    const suggestFormat = (c) => { 

        let text;
        let subtexts = [];
        switch(type) {
            case 'project':
                text = `${c.project_number}`;
                subtexts.push(c.description);
                break;
            default:
                break;
        }



        return {value: c.id, text, subtexts} 
    }


    return <React.Fragment><td className='distselectCell'>
        <select name='type' onChange={onChangeType}>
            <option value='project' selected={type === 'project'}>Project</option>
        </select>
    </td>
    <td>
        <AutoComplete value={value} 
            labelInValue={true}
            style={{width: '150px'}}
            onChange={handleChange} 
            suggestUrl={suggestUrl} 
            suggestFields={suggestFields} 
            suggestFormat={suggestFormat}
            placeholder={placeholder} 
            valueLabel={displayedValue}/>
    </td>
    </React.Fragment>
}

function LocationInput({onChange, options, value, ...props}) {

    return <td><select onChange={onChange}>
            {options.map(o => {
                return <option value={o.id} selected={o.id === value}>{o.name}</option>
            })}
        </select></td>
}

function NumberInput({value, onChange, ...props}) {

    return <td className='distquantityCol'><input type='number' onChange={onChange} value={value} /></td>
}

function DistributionRow(props) {


}

function DistributionSummary({product_number, ordered, allocated, ...props}) {
    let allocatedStyle = {fontWeight: 'bold'};
    if(allocated > ordered) {
        allocatedStyle.color = 'red';
    }

    return <div className='distsum'><span className='distprod'>{product_number}</span><span className='diststat'>Ordered: <span style={{fontWeight: 'bold'}}>{ordered}</span></span><span className='diststat'>Allocated: <span style={allocatedStyle}>{allocated}</span></span></div>
}

function DistributionSection({company_purchase_order_id, locations, part, ...props}) {
        const { distributions, product_number, allocated, ordered } = part;
        const [newDistributions, setNewDistributions] = useState([]);
        const [deletedDistributions, setDeletedDistributions] = useState([]);


        const handleCreated = (newDistribution) => {
            setNewDistributions(old => {
                setNewDistributions([newDistribution, ...old]);
            });
        }

        const handleDeleted = (deleted) => {
            setDeletedDistributions(old => {
                setDeletedDistributions([deleted.id, ...old]);
            });
        }

        return <div className='distsection'>
            <DistributionSummary product_number={product_number} ordered={ordered} allocated={allocated} />
            <table className='disttable'>
                <thead className='distheader'>
                    <th>Quantity</th>
                    <th>Distribution</th>
                    <th></th>
                    <th>Location</th>
                    <th></th>
                </thead>
                <tbody className='distbody'>
                <AllocationRow product_id={part.product_id} company_purchase_order_id={company_purchase_order_id} onCreated={handleCreated} locations={locations} />
                {newDistributions.map(d => {
                    if(deletedDistributions.indexOf(d.id) !== -1){
                        return null;
                    }
                    return <AllocationRow product_id={part.product_id} locations={locations} onDeleted={handleDeleted} allocation={d} company_purchase_order_id={company_purchase_order_id} />
                })}
                {distributions.map(d => {
                    if(deletedDistributions.indexOf(d.id) !== -1){
                        return null;
                    }
                    return <AllocationRow product_id={part.product_id} locations={locations} allocation={d} onDeleted={handleDeleted} company_purchase_order_id={company_purchase_order_id}/>
                })}
                </tbody>
            </table>
        </div>

}

function DistributionEditor(props) { 

    const { user, editorActions, addExisting, refreshUrl, createUrl, heading, tableProps, size, list, createPrivileges, deletePrivileges, deleteUrl, deleteLabel, values, dataTransform, dataUrl, injectedValues, create, modalValuesTransform, instantCreateObject, instantCreate, primaryCreate, createLabel, formFields, formTemplates, formTemplateSearch, formActions } = props;

    const [editorModalAction, setEditorModalAction] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [creating, setCreating] = useState(false);
    const [deleteActive, setDeleteActive] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [errors, setErrors] = useState([]);
    const [submittingParameters, setSubmittingParameters] = useState(false);
    const [locations, setLocations] = useState([]);
    const [submission, setSubmission] = useState({});

    useEffect(() => {
        if(editorModalAction) {
            const { method } = editorModalAction;
            //onFormAction(actionInModal.method, submission);
            setSubmittingParameters(true);

            method({values, 
                parameters: submission, 
                loading: () => { setLoading(true); },
                success: () => { setLoading(false); },
                error: () => { setLoading(false); },
                reload: fetchData});
            setEditorModalAction(null);
        }

    }, [submission]);

    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {
        setLoading(true);

        let extract = dataTransform;
        if(typeof dataTransform === 'string') {
            extract = (data) => data[dataTransform]
        }

        let result = await api.get('/inventory_locations');
        console.log("Got locs ", result);
        if(result.success) {
            let locs = result.body.map(l => {
                return {id: l.id, name: `${l.description}${l.sublocation ? ` / ${l.sublocation}` : ''}`};
            });
            setLocations(locs);
        } else {
            //need a good way to clear irrelevant errors
            if(result.status === 403) {
                setErrors(oldErrors => {
                    return ['You do not have permission to access Inventory Locations.', ...oldErrors];
                });
            } else {
                setErrors(oldErrors => {
                    return ['An error occurred fetching inventory locations.', ...oldErrors];
                });
            }
        }

        if(dataUrl){ 
            let boundUrl = bindUrl(dataUrl, values);
            let response = await api.get(boundUrl);

            if(response.status === 200){
                const data = extract ? extract(response.body) : response.body;
                console.log("Got data ", data);
                setData(data);
                setLoading(false);

            } else {
                setData([]);
                setLoading(false);
            }
        } else {
            const data = extract ? extract(values) : values;
            setData(data);
            console.log("Got data ", data);
            setLoading(false);
        }
    }

    const refresh = () => {
        fetchData();
    }


    return <React.Fragment> 
        <Button onClick={refresh}>Refresh</Button>
        {loading ? <div><Spin tip='Loading...' /></div> : null}
        {!loading ? errors.map(e => {
            return <span style={{color: 'red'}}>{e}</span>
        }) : null}
        {!loading ? data.map((part, index) => {
            return <DistributionSection company_purchase_order_id={values.id} locations={locations} part={part} />
        }) : null}
         </React.Fragment>

}

DistributionEditor.defaultProps = defaultProps;

export default withRouter(DistributionEditor);
