import colors from 'lib/constants/resourceColors';

const form = [
    'email',
    '#attachment_limit',
];
// const form = [
//     {key: 'email'},
//     {key: 'attachment_limit', type: 'number'}
// ];

export default {
    defaults: {
        name: 'Attachment Limit',
        color: colors.attachmentLimits,
        formFields: form,
    },

    search: {
        fields: ['id', 'email', 'attachment_limit', 'created_at'],
        links: ['new'],
        tableProps: {
            rowURL: '/attachment_limits/:id',
            columns: [
                {accessor: 'email'},
                {accessor: 'attachment_limit', type: 'number'},
            ],
        }
    }
}


