import React, { Component } from 'react';
import View from './view';
import { UserContext } from '../../../contexts/User';
import ResetPassword from './ResetPassword';


class Login extends Component {

    state = {
        showPasswordReset: false
    }

    render() {
        const { showPasswordReset } = this.state;

        return showPasswordReset ? this.renderResetForm() : this.renderLoginForm()
    }

    cancelReset = (e) => {
        e.preventDefault();
        this.setState({showPasswordReset: false});
    }

    showReset = (e) => {
        e.preventDefault();
        this.setState({showPasswordReset: true});
    }

    renderResetForm = () => {
        return <UserContext.Consumer>
            {({state, actions}) => <ResetPassword 
                onSubmit={actions.resetPassword} 
                resetting={state.resetting}
                error={state.resetError} 
                onCancel={this.cancelReset}/>}
        </UserContext.Consumer>
    }

    renderLoginForm = () => {
        return <UserContext.Consumer>
            {({state, actions}) => {
            return <View 
                loggingIn={state.loggingIn} 
                error={state.error} 
                onLogin={actions.logIn} 
                onForgot={this.showReset}
                {...this.props} 
                {...this.state} />
            }}
        </UserContext.Consumer>
    }

}

export default Login;
