import Privileges from '../lib/constants/privileges';
import colors from 'lib/constants/resourceColors';
import api from 'api';

export default {
    defaults: {
        name: 'Change Order',
        color: colors.changeOrders,
        formFields: [
            'change_order_number',
            {key: 'project_number', static: true, link: {url: '/projects/:project_id', privileges: Privileges.READ_PROJECTS}},
            {key: 'project_description', static: true},
            {key: 'customer', static: true, link: {url: '/customers/:customer_id', privileges: Privileges.READ_CUSTOMERS}},
            'description',
            '$amount',
            '^file(File){file_url}',
            {key: 'customer_purchase_orders',
                type:'references',
                heading: 'Customer Purchase Orders',
                createLabel: 'Add New PO',
                dataUrl: '/change_orders/:id?fields=customer_purchase_orders',
                createUrl: (values) => { return '/customer_purchase_orders' },
                dataTransform: 'customer_purchase_orders',
                injectedValues: (values) => { return { change_order_id: values.id, project_id: values.project_id, customer_id: values.customer_id }},
                formFields: [
                    {key: 'po_number'},
                    {key: 'description'},
                    {key: 'amount', type: 'currency'},
                    {key: 'issued_date', type: 'date'},
                    {key: 'poFile', type: 'file', label: 'PO File', urlKey: 'file_url'},
                ],
                tableProps: {
                    rowURL: '/customer_purchase_orders/:id',
                    columns: ['po_number','description','change_order_number', {accessor: 'amount', type: 'currency'}]
                }
            },
            {key: 'materials',
                type: 'references',
                heading: 'Materials',
                createLabel: 'Add Materials',
                createPrivileges: [Privileges.CREATE_CHANGE_ORDER_PRODUCTS],
                readPrivileges: [Privileges.READ_CHANGE_ORDER_PRODUCTS],
                deletePrivileges: [Privileges.DELETE_CHANGE_ORDER_PRODUCTS],
                dataUrl: '/change_orders/:id?fields=materials',
                createUrl: (values) => { return `/change_order_products` },
                deleteUrl: (values) => { return `/change_order_products/:id`},
                dataTransform: 'materials',
                injectedValues: (values) => { return { change_order_id: values.id }},
                formFields: [
                    {key: 'product_id', 
                     displayKey: 'product_number',
                        label: 'Product Number',
                        type: 'autocomplete', 
                        suggestFormat: (c) => { return {value: c.id, text: c.product_number} },
                        suggestUrl: '/parts', 
                        suggestFields: ['id', 'product_number']},
                    {key: 'quantity', type: 'number'}
                ],
                tableProps: { columns: ['product_number', 'quantity', 'source_id', 'description'] }
            },
            {key: 'services',
                type: 'references',
                heading: 'Services',
                createLabel: 'Add Services',
                createPrivileges: [Privileges.CREATE_CHANGE_ORDER_PRODUCTS],
                readPrivileges: [Privileges.READ_CHANGE_ORDER_PRODUCTS],
                deletePrivileges: [Privileges.DELETE_CHANGE_ORDER_PRODUCTS],
                dataUrl: '/change_orders/:id?fields=services',
                createUrl: (values) => { return `/change_order_products` },
                deleteUrl: (values) => { return `/change_order_products/:id`},
                dataTransform: 'services',
                injectedValues: (values) => { return { change_order_id: values.id }},
                formFields: [
                    {key: 'product_id', 
                     displayKey: 'product_number',
                        label: 'Product Number',
                        type: 'autocomplete', 
                        suggestFormat: (c) => { return {value: c.id, text: c.product_number} },
                        suggestUrl: '/services', 
                        suggestFields: ['id', 'product_number']},
                    {key: 'quantity', type: 'number'}
                ],
                tableProps: { columns: ['product_number', 'quantity', 'source_id', 'description'] }
            },
        ]
    },
    view: {
        contextLinks: (change_order) => {
            if(change_order.quote_id) {
                return {
                    label: 'Imported from Quote',
                    text: change_order.quote_number,
                    url: `/quotes/${change_order.quote_number}`,
                    privilege: [Privileges.READ_QUOTES]
                }
            } else {
                return null;
            }
        },
        formActions: [
            { label: 'Import Data from Quote', 
               icon: 'import', 
         submitText: 'Import',
            parametersForm: [
                {key: 'quote_id', 
                 displayKey: 'quote_number',
                    label: 'Quote Number',
                    type: 'autocomplete', 
                    suggestFormat: (q) => { 
                        let text = `${q.quote_number} (${q.description})`;
                        return {value: q.id, text };
                    },
                    suggestUrl: '/quotes', 
                    suggestFields: ['id', 'quote_number', 'description']
                },
            ],
            method: async ({values, parameters, reload, loading, success, error}) => {
                    const { quote_id } = parameters;
                    let change_order_id = values.id;

                    loading('Importing');
                    let result;
                    try {
                        result = await api.rpc('importChangeOrderDataFromQuote', {quote_id, change_order_id})
                    } catch (e) {
                        throw new Error(e);
                    }
                    
                    if(result.success) {
                        success({
                            title: 'Success', 
                            content: 'The change order has been set with the products and services from the selected quote.',
                            okText : 'Ok',
                        });
                    } else {
                        let error = result.errors.error;
                        console.log("Got error ", result);
                        error({
                            title: 'Error', 
                            content: error.message,
                            okText : 'Ok',
                        });
                    }

                    reload();
                }
            },
        ],
    }
}
