import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DragSource } from 'react-dnd';
import { Map } from 'immutable';

// eslint-disable-next-line
const debug = require('debug')('app:DockedEvent:');

const propTypes = {
    immutableEvent: PropTypes.object

};

const defaultProps = {
    immutableEvent: Map()
};

const Main = styled.div`
    background-color: #FFFFFF;
    box-shadow: inset 0px -1px 0px 0px lightgray;
    ${({updating}) => updating ? 'opacity: 0.25;' : ''}
    color: #666666;
    cursor: move;
    display: flex;
    font-size: 14px;
    font-family: sans-serif;
    flex-shrink: 0;
    flex-direction: column;
    padding: 0.5em 0.5em;
    user-select: none;

    &:nth-child(even){
        background-color: #FBFBFF;

    }
`

const Confirmed = Main.extend`
`

const Unconfirmed = Main.extend`
`
const Generic = Main.extend`
`

const Title = styled.div`
    font-weight: 800;
`

const Subtitle = styled.div`
    font-weight: 400;
`


const boxSource = {

    canDrag(props, monitor) {
        const { immutableEvent } = props;
        if(immutableEvent.has('updating') && immutableEvent.get('updating') === true){
            return false;
        } else {
            return true;
        }
    },

	beginDrag(props) {
        console.log("Props Docked ", props);
		return {
            id: props.id,
		}
	}
}


class DockedEvent extends Component {

    render(){
        const { immutableEvent, connectDragSource } = this.props;
        const title = immutableEvent.get('title');
        const badges = immutableEvent.get('badges');
        let subtitles = immutableEvent.get('subtitles');
        const requiresConfirmation = immutableEvent.get('requiresConfirmation');
        const confirmed = immutableEvent.get('confirmed');
        const updating = immutableEvent.get('updating');

        let Container;
        if(requiresConfirmation){
            Container = confirmed ? Confirmed : Unconfirmed;
        } else {
            Container = Generic;
        }

        if(typeof subtitles === 'object') {
            if(subtitles.unscheduled) {
                subtitles = subtitles.unscheduled;
            }
        }

        return <Container updating={updating} onClick={this.handleClick} innerRef={instance => connectDragSource(instance)}>
            <Title>{title}</Title>
            {this.renderSubtitles(subtitles)}
            {this.renderBadges(badges)}
        </Container>
    }

    handleClick = (e) => {
        const { immutableEvent, onClick } = this.props;
        if(onClick){
            onClick(e, immutableEvent);
            e.stopPropagation();
        }
    }

    renderSubtitles = (subtitles) => {
        if(!subtitles){
            return null;
        }

        return subtitles.map((title, index) => {
            return <Subtitle key={`${index}_st`}>{title}</Subtitle>
        });
    }

    renderBadges = (badges) => {
        if(!badges || !Array.isArray(badges) || badges.length === 0) {
            return null;
        }


        return badges.map((badge, index) => {
            if(!badge) {
                return null;
            }

            const { bgColor, textColor, text } = badge;

            let backgroundColor = bgColor || 'gray';
            let color = textColor || '#FFFFFF';
            
            let style = { padding: '2px 5px', fontWeight: 'bold',fontSize: '12px', 
            borderRadius: '4px', marginRight: 'auto',  whiteSpace:'nowrap', 
            textTransform: 'uppercase', backgroundColor, color };
            return <span style={style} key={`b${index}_t`}>{text}</span>
        });
    }
}

DockedEvent.propTypes = propTypes;
DockedEvent.defaultProps = defaultProps;

export default DragSource('EVENT', boxSource, (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	isDragging: monitor.isDragging(),
}))(DockedEvent);

