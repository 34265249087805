import Privileges from '../lib/constants/privileges';
import colors from 'lib/constants/resourceColors';

const form = [
    {key: 'transaction_id',
     displayKey: ['transaction_number'],
        label: 'Transaction #',
        createOnly: true,
        type: 'autocomplete',
        link: { url: '/transactions/:transaction_id', privileges: Privileges.READ_TRANSACTIONS },
        suggestFormat: (t) => {
            let text = `${t.transaction_number} (${t.customer_name}) ${t.memo ? `[Available: $${t.available_credit}]` : ''}`;
            return {value: t.id, text}
        },
        suggestUrl: '/transactions',
        suggestFields: ['id', 'transaction_number', 'memo', 'available_credit', 'customer_name']},
    {key: 'check_number', static: true},
    {label: 'Credit Card Last 4 Digits', key: 'last_digits', static: true},
    {key: 'invoice_id',
     displayKey: ['invoice_number'],
        label: 'Invoice #',
        createOnly: true,
        type: 'autocomplete',
        link: { url: '/invoices/:invoice_id', privileges: Privileges.READ_INVOICES },
        suggestFormat: (i) => {
            let text = `${i.invoice_number} (${i.customer_name}) ${i.description ? `(${i.description})` : ''}`;
            return {value: i.id, text}
        },
        suggestUrl: '/invoices',
        suggestFields: ['id', 'invoice_number', 'description', 'customer_name']},
    {type: 'currency',
        createOnly: true,
        key: 'amount',
        required: true},
    'memo',
    {type: 'file',
       key: 'transaction_file_url',
        urlKey: 'transaction_file_url',
        label: 'Attachment', 
        static: true},
    {key: 'voided', static: true, readOnly: true,  type: 'boolean'},
];

export default {
    defaults: {
        name: 'Invoice Payment',
        color: colors.invoicePayments,
        formFields: form,
    },
    search: {
        fields: ['id', 'created_at', 'invoice_number', 'check_number', 'last_digits', 'memo', 'transaction_number', 'transaction_created_at', 'amount', 'memo', 'paying_customer_name', 'transaction_file_url'],
        searchBy: ['customer name', 'memos', 'check_number', 'last four digits', 'amount', 'transaction number'],
        links: [
            'new',
        ],
        tableProps: {
            rowURL: '/invoice_payments/:id',
            columns: [
                {accessor: 'created_at', type: 'date', heading: 'Invoice Payment Date', cellWidth: '100px'},
                {accessor: 'paying_customer_name', heading: 'Customer', cellWidth: '200px'},
                {accessor: 'invoice_number', cellWidth: '100px', style: 'bold'},
                {heading: 'Transaction Details', type:'multiple', cellWidth: '220px', rows: [
                    {accessor: 'transaction_number'},
                    {label: 'Check #', style:'subtext', accessor:'check_number'},
                    {label: 'Last Four Digits CC', style: 'subtext', accessor:'last_digits'},
                    {label: 'Memo', style: 'subtext', accessor: 'transaction_memo'},
                    {label: 'Total Amount', type:'currency', style: 'subtext', accessor: 'amount'},
                    {label: 'Date', type: 'date', style: 'subtext', accessor: 'transaction_created_at'}
                ]},
                {heading: 'Payment Amount', accessor: 'amount', type: 'currency', cellWidth: '150px'},
                {accessor: 'memo', heading: 'Payment Memo'},
                {type: 'file', key: 'transaction_file_url', label: 'View File'}
            ]
        }
    }
}


