import colors from 'lib/constants/resourceColors';
import React from 'react';
import { form as siteForm, createActions as siteCreateActions } from 'resources/site/';
import formatPhoneNumber from 'lib/helpers/formatPhoneNumber';
import api from 'api';
import Privileges from '../lib/constants/privileges';

function statusBadgeStyle(value, data){
    const { status } = data;

    if(status === 'closed') {
        return {status: 'default', text: 'Closed'};

    } else if (status === 'open'){
        return {status: 'success', text: 'Open'}; 

    } else if (status === 'void') {
        return {status: 'error', text: 'Void'}; 
    } else { 
        return null;
    }
}
const form = [
    {key: 'customer_number', label: 'Customer Number', writePrivileges: [Privileges.WRITE_CUSTOMERS_CUSTOMER_NUMBER]},
    '!name',
    'care_of(C/O)=Accounts Payable',
    'code',
    {key: 'address', label: 'Address', maplink: true},
    'address2(Address Line 2)',
    'city',
    '_state{US_STATES}=Florida',
    'zipcode',
    'tax_exempt',
    '?requires_po(Requires PO#)',
    '!_payment_term{COD|COD,DUE_ON_RECEIPT|Due On Receipt,NET30|Net 30,NET60|Net 60,NET90|Net 90,PROJECT|Project}',
    '?credit_hold',
    {key: 'contacts',
        type: 'references',
        heading: 'Contacts',
        createLabel: 'Add New Contact',
        primaryCreate: false,
        createUrl: (values) => { return `/customers/${values.id}/contacts` },
        dataUrl: '/customers/:id/contacts',
        formFields: [
                {key: 'name',  
                    required: true,
                },
                {key: 'title',  
                },
                {key: 'phone', label: 'Office Phone',
                },
                {key: 'mobile',  
                },
                {key: 'fax',  
                },
                {key: 'email',  
                },
        ],
        tableProps: {
            rowURL: '/contacts/:id',
            columns: [
                {heading: 'Name', 
                    accessor: 'name',
                },
                {heading: 'Title', 
                    accessor: 'title',
                },
                {heading: 'Office', 
                    accessor: 'phone',
                    valueFunc: ((d,v) => {
                        return formatPhoneNumber(v) || v;
                    })
                },
                {heading: 'Mobile', 
                    accessor: 'mobile',
                    valueFunc: ((d,v) => {
                        return formatPhoneNumber(v) || v;
                    })
                },
                {heading: 'Fax', 
                    accessor: 'fax',
                    valueFunc: ((d,v) => {
                        return formatPhoneNumber(v) || v;
                    })
                },
                {heading: 'Email', 
                    accessor: 'email',
                },
            ]
        }
    },

    {key: 'sites',
        type: 'references',
        heading: 'Sites',
        createLabel: 'Create New Site',
        modalValuesTransform: (values) => { 
            return {billing_customer_id: values.id}; 
        },
        create: {
            label: 'Create New Site',
            url: (values) => { return `/sites` },
            formActions: siteCreateActions, 
        },
        addExisting: {
            label: 'Add Existing Site',
            searchUrl: '/sites',
            searchFields: ['id', 'name', 'address', 'address2', 'city', 'state', 'zipcode', 'parent_site_name'],
            optionValue: (site) => {
                return site.id;
            },
            optionContent: (site) => {
                const { address2, address, city, state, zipcode, name, parent_site_name } = site;
                return <div>
                    <span style={{display: 'block', fontWeight: '600'}}>{name}</span>
                    <span style={{display: 'block', fontStyle: 'italics', fontSize: '12px'}}>{address}, {address2}, {city}, {state}, {zipcode}</span>
                    <span style={{fontStyle: 'italics', fontSize: '12px'}}>{parent_site_name}</span>
                </div>
            },
            onAdd: (values, optionValue) => {
                let params = { customer_id : values.id, site_id: optionValue };
                return api.rpc('addCustomerSiteDefaultBilling', params);
            }
        },
        primaryCreate: false,
        createUrl: (values) => { return `/sites` },
        dataUrl: '/customers/:id/sites?is_default_billing_customer=true',
        formFields: siteForm,
        tableProps: {
            rowURL: '/sites/:id', 
            columns: [{heading: 'Site Info', type:'multiple', rows: [
                        {heading: 'Name', style: 'bold', accessor:'name'},
                        {heading: 'Address', separator: ', ', style: 'subtext', accessor:['address', 'address2', 'city', 'state', 'zipcode']},
                        {heading: 'Parent Site', style: 'subtext', accessor:'parent_site_name'}
                       ]
            },
            {heading: 'Site Credit Hold', accessor: 'customer_site_has_credit_hold', type: 'boolean'}
            ],
        }
    },
    {key: 'customer_projects',
        type: 'references',
        heading: `Customer Projects`,
        readPrivileges: [Privileges.LIST_CUSTOMER_PROJECTS],
        dataUrl: '/lists/customer_projects?customer_id=:id',
        tableProps: {
            rowURL: '/projects/:id',
            columns: [
                {accessor: 'created_at', type: 'date', dateFormat: 'MMM DD, YYYY' },
                {heading: 'Project Number', accessor: 'project_number', cellWidth: '110px', style: 'bold'},
                {accessor: 'salesperson'},
                {heading: 'Description', type:'multiple', rows: [
                    {heading: 'Description', style:'bold', accessor:'description'},
                    {heading: 'Activity', style: 'subtext', accessor:'logs_last_activity_description'},
                    {heading: 'User', style: 'subtext', accessor: 'logs_last_activity_user'},
                ]},
                {heading: 'Site', type: 'multiple', rows: [
                    {heading: 'Site Name', accessor: 'site_name'},
                    {heading: 'Site Address', separator: ', ',accessor: ['site_address', 'site_address2', 'site_city', 'site_state', 'site_zipcode']}, 
                ]},
                {type:'badge', 
                    badgePropsFunc: statusBadgeStyle, 
                    heading: 'Status', 
                    accessor: 'status'},
                {heading: 'Open Balance',
                    type: 'currency',
                accessor: 'outstanding_payment_request_balance',
              privileges: [Privileges.READ_CUSTOMERS_TOTAL_OUTSTANDING_PAYMENT_REQUEST_BALANCES, Privileges.READ_PROJECTS_OUTSTANDING_PAYMENT_REQUESTS]},
                {heading: 'Pending to Request',
                    type: 'currency',
                accessor: 'balance_to_finish',
              privileges: [Privileges.READ_CUSTOMERS_TOTAL_BALANCE_TO_FINISH, Privileges.READ_PROJECTS_BALANCE_TO_FINISH]},
                {heading: 'Total Project Value',
                    type: 'currency',
                accessor: 'contract_sum_to_date',
              privileges: [Privileges.READ_PROJECTS_CONTRACT_SUM_TO_DATE]}
                ]
        }
    },
];

// const form = [
//     {key: 'customer_number', label: 'Customer Number', static: true, readOnly: true},
//     {key: 'name', label: 'Name', required: true},
//     {key: 'care_of', label: 'C/O', defaultValue: 'Accounts Payable'},
//     {key: 'code', label: 'Code'},
//     {key: 'address', label: 'Address'},
//     {key: 'address2', label: 'Address Line 2'},
//     {key: 'city', label: 'City'},
//     {key: 'state', 
//         defaultValue: 'Florida',
//         label: 'State',
//         type: 'options',
//         options: [
//             {value: 'Alabama', label: 'Alabama'},
//             {value: 'Alaska', label: 'Alaska'},
//             {value: 'Arizona', label: 'Arizona'},
//             {value: 'Arkansas', label: 'Arkansas'},
//             {value: 'California', label: 'California'},
//             {value: 'Colorado', label: 'Colorado'},
//             {value: 'Connecticut', label: 'Connecticut'},
//             {value: 'Delaware', label: 'Delaware'},
//             {value: 'Florida', label: 'Florida'},
//             {value: 'Georgia', label: 'Georgia'},
//             {value: 'Hawaii', label: 'Hawaii'},
//             {value: 'Idaho', label: 'Idaho'},
//             {value: 'Illinois', label: 'Illinois'},
//             {value: 'Indiana', label: 'Indiana'},
//             {value: 'Iowa', label: 'Iowa'},
//             {value: 'Kansas', label: 'Kansas'},
//             {value: 'Kentucky', label: 'Kentucky'},
//             {value: 'Louisiana', label: 'Louisiana'},
//             {value: 'Maine', label: 'Maine'},
//             {value: 'Maryland', label: 'Maryland'},
//             {value: 'Massachusetts', label: 'Massachusetts'},
//             {value: 'Michigan', label: 'Michigan'},
//             {value: 'Minnesota', label: 'Minnesota'},
//             {value: 'Mississippi', label: 'Mississippi'},
//             {value: 'Missouri', label: 'Missouri'},
//             {value: 'Montana', label: 'Montana'},
//             {value: 'Nebraska', label: 'Nebraska'},
//             {value: 'Nevada', label: 'Nevada'},
//             {value: 'New Hampshire', label: 'New Hampshire'},
//             {value: 'New Jersey', label: 'New Jersey'},
//             {value: 'New Mexico', label: 'New Mexico'},
//             {value: 'New York', label: 'New York'},
//             {value: 'North Carolina', label: 'North Carolina'},
//             {value: 'North Dakota', label: 'North Dakota'},
//             {value: 'Ohio', label: 'Ohio'},
//             {value: 'Oklahoma', label: 'Oklahoma'},
//             {value: 'Oregon', label: 'Oregon'},
//             {value: 'Pennsylvania', label: 'Pennsylvania'},
//             {value: 'Rhode Island', label: 'Rhode Island'},
//             {value: 'South Carolina', label: 'South Carolina'},
//             {value: 'South Dakota', label: 'South Dakota'},
//             {value: 'Tennessee', label: 'Tennessee'},
//             {value: 'Texas', label: 'Texas'},
//             {value: 'Utah', label: 'Utah'},
//             {value: 'Vermont', label: 'Vermont'},
//             {value: 'Virginia', label: 'Virginia'},
//             {value: 'Washington', label: 'Washington'},
//             {value: 'West Virginia', label: 'West Virginia'},
//             {value: 'Wisconsin', label: 'Wisconsin'},
//             {value: 'Wyoming', label: 'Wyoming'}
//         ],
//     },
//     {key: 'zipcode', label: 'Zipcode'},

//     {key: 'tax_exempt', label: 'Tax Exempt'},

//     {key: 'requires_po', label: 'Requires PO#', type: 'boolean'},
//     {key: 'payment_term', label: 'Payment Term', type: 'options', required:true, options: [
//         {value: 'COD', label: 'COD'},
//         {value: 'NET30', label: 'Net 30'},
//         {value: 'NET60', label: 'Net 60'},
//         {value: 'NET90', label: 'Net 90'},
//     ]},
//     {key: 'credit_hold', label: 'Credit Hold', type: 'boolean'},

//     {key: 'contacts',
//         type: 'references',
//         heading: 'Contacts',
//         createLabel: 'Add New Contact',
//         primaryCreate: false,
//         createUrl: (values) => { return `/customers/${values.id}/contacts` },
//         dataUrl: '/customers/:id/contacts',
//         formFields: [
//                 {key: 'name',  
//                     required: true,
//                 },
//                 {key: 'title',  
//                 },
//                 {key: 'phone', label: 'Office Phone',
//                 },
//                 {key: 'mobile',  
//                 },
//                 {key: 'fax',  
//                 },
//                 {key: 'email',  
//                 },

//         ],
//         tableProps: {
//             rowURL: '/contacts/:id',
//             columns: [
//                 {heading: 'Name', 
//                     accessor: 'name',
//                 },
//                 {heading: 'Title', 
//                     accessor: 'title',
//                 },
//                 {heading: 'Office', 
//                     accessor: 'phone',
//                     valueFunc: ((d,v) => {
//                         return formatPhoneNumber(v) || v;
//                     })
//                 },
//                 {heading: 'Mobile', 
//                     accessor: 'mobile',
//                     valueFunc: ((d,v) => {
//                         return formatPhoneNumber(v) || v;
//                     })
//                 },
//                 {heading: 'Fax', 
//                     accessor: 'fax',
//                     valueFunc: ((d,v) => {
//                         return formatPhoneNumber(v) || v;
//                     })
//                 },
//                 {heading: 'Email', 
//                     accessor: 'email',
//                 },
//             ]
//         }
//     },

//     {key: 'sites',
//         type: 'references',
//         heading: 'Sites',
//         createLabel: 'Create New Site',
//         modalValuesTransform: (values) => { 
//             return {billing_customer_id: values.id}; 
//         },
//         create: {
//             label: 'Create New Site',
//             url: (values) => { return `/sites` },
//             formActions: siteCreateActions, 
//         },
//         addExisting: {
//             label: 'Add Existing Site',
//             searchUrl: '/sites',
//             searchFields: ['id', 'name', 'address', 'address2', 'city', 'state', 'zipcode', 'parent_site_name'],
//             optionValue: (site) => {
//                 return site.id;
//             },
//             optionContent: (site) => {
//                 const { address2, address, city, state, zipcode, name, parent_site_name } = site;
//                 return <div>
//                     <span style={{display: 'block', fontWeight: '600'}}>{name}</span>
//                     <span style={{display: 'block', fontStyle: 'italics', fontSize: '12px'}}>{address}, {address2}, {city}, {state}, {zipcode}</span>
//                     <span style={{fontStyle: 'italics', fontSize: '12px'}}>{parent_site_name}</span>
//                 </div>
//             },
//             onAdd: (values, optionValue) => {
//                 let params = { customer_id : values.id, site_id: optionValue };
//                 return api.rpc('addCustomerSiteDefaultBilling', params);
//             }
//         },
//         primaryCreate: false,
//         createUrl: (values) => { return `/sites` },
//         dataUrl: '/customers/:id/sites?is_default_billing_customer=true',
//         formFields: siteForm,
//         tableProps: {
//             rowURL: '/sites/:id', 
//             columns: [{heading: 'Site Info', type:'multiple', rows: [
//                         {heading: 'Name', style: 'bold', accessor:'name'},
//                         {heading: 'Address', separator: ', ', style: 'subtext', accessor:['address', 'address2', 'city', 'state', 'zipcode']},
//                         {heading: 'Parent Site', style: 'subtext', accessor:'parent_site_name'}
//                        ]
//             },
//             {heading: 'Site Credit Hold', accessor: 'customer_site_has_credit_hold', type: 'boolean'}
//             ],
//         }
//     },
// ];


export default {
    defaults: {
        name: 'Customer',
        color: colors.customers,
        formFields: form,
    },

    view: {
        formActions: [
            {   label: 'Download Full Open Balance Report',
                icon: 'file',
                privileges: [Privileges.FILE_READ_CUSTOMER_OPEN_BALANCES, Privileges.READ_CUSTOMERS_TOTAL_OUTSTANDING_PAYMENT_REQUEST_BALANCES, Privileges.READ_CUSTOMERS_TOTAL_BALANCE_TO_FINISH],
                method: async ({values, history, inputValues}) => {
                    api.download(`/files/customer_open_balances?has_projects=true&customer_id=${values.id}`)
                }
            },
            {   label: 'Download Customer Simple Open Balance Report',
                icon: 'file',
                privileges: [Privileges.FILE_READ_CUSTOMER_OPEN_BALANCES_SIMPLE, Privileges.READ_CUSTOMERS_TOTAL_OUTSTANDING_PAYMENT_REQUEST_BALANCES, Privileges.READ_CUSTOMERS_TOTAL_BALANCE_TO_FINISH],
                method: async ({values, history, inputValues}) => {
                    api.download(`/files/customer_open_balances_simple?has_projects=true&customer_id=${values.id}`)
                }
            }
        ],
        remarks: {
            header: (values) => values.name,
        },
    },

    search: {
        searchBy: ['name', 'customer number', 'address', 'tax id'],
        fields: ['id', 'name', 'total_outstanding_payment_request_balances', 'total_balance_to_finish', 'created_at'],
        filterOptions: [
             {label: 'Project Customers', filters: { has_projects: true }},
             {label: 'Non-Project Customers', filters: { has_projects: false }},
             {label: 'All Customers', filters: {}}
        ],
        links: ['new',
            {downloadURL: '/files/customer_open_balances?has_projects=true',
                title: 'Download Open Balances Report',
                requiredPrivileges: [Privileges.FILE_READ_CUSTOMER_OPEN_BALANCES, Privileges.READ_CUSTOMERS_TOTAL_OUTSTANDING_PAYMENT_REQUEST_BALANCES, Privileges.READ_CUSTOMERS_TOTAL_BALANCE_TO_FINISH]
            },
        ],
        tableProps: {
            rowURL: '/customers/:id',
            columns: [{heading: 'Name', accessor: 'name'},
                      {heading: 'Open Balances',
                          type: 'currency',
                      accessor: 'total_outstanding_payment_request_balances',
                    privileges: [Privileges.READ_CUSTOMERS_TOTAL_OUTSTANDING_PAYMENT_REQUEST_BALANCES]},
                      {heading: 'Total Pending to Request',
                          type: 'currency',
                      accessor: 'total_balance_to_finish',
                    privileges: [Privileges.READ_CUSTOMERS_TOTAL_BALANCE_TO_FINISH]},
                    {accessor: 'created_at', type: 'date', dateFormat: 'MMM DD, YYYY' },
            ],
            showHeading: false,
        }
    }
}
