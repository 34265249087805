import React, { Component } from 'react';
import View from './view';

class SideMenu extends Component {

    render() {
        return <View {...this.props} {...this.state} />
    }

}

export default SideMenu;
