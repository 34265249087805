import colors from 'lib/constants/resourceColors';

const inventoryLocationName = (o) => {
    const label = o.sublocation ? `${o.description}/${o.sublocation}` : o.description;
    return {label, value: o.id}
}

const form = [
    {key: 'product_number', 
        readOnly: true,
        static: true,
        label: 'Part Number'},
    {key: 'quantity', type: 'number',},
    {key: 'project_id', 
        displayKey: 'project_description',
        type: 'autocomplete', 
        suggestFormat: (c) => { return {value: c.id, text: `${c.description} ${c.project_number}`} },
        suggestUrl: '/projects', 
        suggestFields: ['id', 'project_number', 'description']},
    {key: 'inventory_location_id', 
        type: 'options', 
        label: 'Inventory Location',
        optionsMapper: inventoryLocationName,
        optionsURL: '/inventory_locations'},
    // {key: 'inventory_location_id', 
    //     displayKey: 'inventory_location_description',
    //     type: 'autocomplete', 
    //     suggestFormat: (c) => { return {value: c.id, text: `${c.description}/${c.sublocation}`} },
    //     suggestUrl: '/inventory_locations', 
    //     suggestFields: ['id', 'description', 'sublocation']},
    {key: 'bin_number'}
];

export default {
    defaults: {
        name: 'Company Purchase Order Distribution',
        color: colors.companyPurchaseOrderDist,
        formFields: form,
        dataUrlBase: '/company_purchase_orders/distributions'
    },

}


